import { TimeInput } from "@doctomatic/components-react/build/AlertConfig/TimeInput";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";

interface Props {
  value: number | undefined;
  unit: string;
  onChangeTime: (e: any) => void;
  onChangeUnit: (e: any) => void;
  menuItems: JSX.Element[];
  helperText: string;
  error: boolean;
}

export default function TimeRangeTooltip({
  value,
  unit,
  onChangeTime,
  onChangeUnit,
  menuItems,
  helperText,
  error,
}: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <TimeInput
        time={value}
        onChangeTime={onChangeTime}
        onChangeTimeUnit={onChangeUnit}
        timeUnit={unit}
        menuItems={menuItems}
        helperText={helperText}
        error={error}
        maxWidth={"80%"}
      />
      <Tooltip
        title={
          <>
            {t("FieldTimeInputTooltipHeader")}
            <ul>
              <li>{t("FieldTimeInputTooltipInterval")}</li>
              <ul>
                <li>{t("FieldTimeInputTooltipCondition1")}</li>
                <li>{t("FieldTimeInputTooltipCondition2")}</li>
                <li>{t("FieldTimeInputTooltipCondition3")}</li>
                <li>{t("FieldTimeInputTooltipCondition4")}</li>
              </ul>
            </ul>
          </>
        }
      >
        <IconButton>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}
