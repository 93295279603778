import React, { useState, useEffect } from 'react'
import { TextField, Box, Button, Checkbox, Typography } from '@mui/material'
import { UpdateCompanyRequestDto } from '@doctomatic/sdk/build/dto/Company'
import { useApi } from '@doctomatic/sdk/build/Api'
import { useTranslation } from 'react-i18next'
import { BreadcrumbProps } from '@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto'
import { Page } from '../../Page'
import { BreadcrumbNameMap } from '../../../../breadcrumbMap'
import { toast } from 'react-toastify'

//Mui color picker
import {
  MuiColorInput,
  matchIsValidColor,
  MuiColorInputColors,
  MuiColorInputFormat
} from 'mui-color-input'

const CompanyEdit = (): React.ReactElement => {
  const { useCompany, companyId } = useApi()
  const { t } = useTranslation()
  const { response: companyResponse, update } = useCompany(companyId, true)
  const company = companyResponse?.data;
  const dto: UpdateCompanyRequestDto = new UpdateCompanyRequestDto()
  Object.assign(dto, company);
  const [companydata, setCompanyData] = useState(dto)
  const [saving, setSaving] = useState(false)

  useEffect( ()=> {
    if(company){
      setPrimaryColor(company.primary);
      setSecondaryColor(company.secondary);
    }
  }, [company]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'showExternalId') {
      setCompanyData({ ...companydata, [event.target.name]: !companydata.showExternalId })
      return;
    }
    setCompanyData({ ...companydata, [event.target.name]: event.target.value })
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setSaving(true)
    await update(companydata)
    setSaving(false)
    toast.success(t('CompanyUpdated'));
  }

  const getBreadCrumbProps = (items: { url?: string, name: string }[]): BreadcrumbProps => {
    return ({
      breadcrumbItems: [...items]
    } as BreadcrumbProps);
  };
  const breadcrumbItems = [{ name: t('Administration'), url: BreadcrumbNameMap.Administration.url }, { url: "", name: t('Company') }, { url: "", name: t('Edit') }];


  // Color picker config
  const [primaryColor, setPrimaryColor] = useState<string>('#ffffff')

  const handleChangePrimaryColor = (newValue: string, colors: MuiColorInputColors) => {
    if (matchIsValidColor(newValue)) {
      setPrimaryColor(newValue);
      companydata['primary'] = newValue;
      setCompanyData(companydata)
    }

  }

  const [secondaryColor, setSecondaryColor] = useState<string>('#ffffff')

  const handleChangeSecondaryColor = (newValue: string, colors: MuiColorInputColors) => {
    if (matchIsValidColor(newValue)) {
      setSecondaryColor(newValue);
      companydata['secondary'] = newValue;
      setCompanyData(companydata);
    }
  }

  const format: MuiColorInputFormat = 'hex';



  return (
    <Page title={""} breadCrumbProps={getBreadCrumbProps(breadcrumbItems)}>
      <Box>
        <form noValidate onSubmit={handleSubmit}>
          <TextField required fullWidth margin='normal' label={t('FieldCompanyName')} name='name' variant='outlined' onChange={handleInputChange} autoFocus value={companydata.name} />
          <MuiColorInput value={primaryColor} onChange={handleChangePrimaryColor} format={format} fullWidth margin='normal' label={t('FieldPrimaryColor')} name='primary' variant='outlined' isAlphaHidden={true} />
          <MuiColorInput value={secondaryColor} onChange={handleChangeSecondaryColor} format={format} fullWidth margin='normal' label={t('FieldSecondaryColor')} name='secondary' variant='outlined' isAlphaHidden={true} />

          <Box display={'flex'}>
            <Typography>{t('ShowExternalIdCheckBox')}</Typography>
            <Checkbox name='showExternalId' style={{ padding: '0 9px' }} checked={companydata.showExternalId} onChange={handleInputChange} />
          </Box>
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' variant='contained' disabled={saving}>{t('ButtonSave')}</Button>
          </Box>
        </form>
      </Box>
    </Page>
  )
}

export { CompanyEdit }