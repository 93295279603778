import { Device, Sign } from "@doctomatic/components-react/build/Graphs/models";
import { Box, Button, Modal, SelectChangeEvent } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { AlertConfigByNoMeasure } from "../alertConfigByNoMeasure.interface";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";
import { ModalBaseProps } from "../../../../Modals/modalBaseProps";
import { IUseDevices } from "@doctomatic/sdk/build/modules/Devices";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { IUseAlertConfigsByNoMeasureIndividual } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByNoMeasure";
import { processError } from "../../../../../App/errorToast";
import { toast } from "react-toastify";
import { AddAlertConfigByNoMeasureIndividualRequestDto } from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByNoMeasureIndividual";
import { SignDto } from "@doctomatic/sdk/build/dto/Sign/Signs";
import { SubscriptionChecks } from "../../../../AlertsConfig/SubscriptionChecks";
import { AddAlertConfigByNoMeasure } from "../../../../AlertsConfig/AddAlertConfigByNoMeasureForm";
import { colors } from "../../../../AlertsConfig/AlertConfigBaseForm";
import { TimeUnit } from "../../../../AlertsConfig/alertConfig.interface";
import { getDeviceName, getSignName } from "../../../utils";
import { INotification } from "@doctomatic/sdk/build/dto/Alerts/group/IAlertGroup";
import { useNavigate } from "react-router-dom";

export interface AddAlertConfigByNoMeasureModalProps extends ModalBaseProps {
  patientId: number;
  permissions: Array<string>;
  currentLanguage: string;
  messengerChannel: string;
}

export const AddAlertConfigByNoMeasureModal = (
  props: AddAlertConfigByNoMeasureModalProps
): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { useDevices, useAlertConfigsByNoMeasure, logout }: IProvideApi =
    useApi();
  const { response: responseApiDevices }: IUseDevices = useDevices(
    true,
    processError(logout, navigate, t)
  );
  const { add }: IUseAlertConfigsByNoMeasureIndividual =
    useAlertConfigsByNoMeasure(false, processError(logout, navigate, t));

  const timeUnits: TimeUnit[] = [
    { text: t("hours"), value: "h" },
    { text: t("days"), value: "d" },
  ];

  const [timeUnit, setTimeUnit] = useState<string>(timeUnits[0].value);
  const [devices, setDevices] = useState<Device[]>([]);
  const [signs, setSigns] = useState<Sign[]>([]);
  const [requestedSigns, setRequestedSigns] = useState<any>({});
  const [time, setTime] = useState<number>(1);
  const [subscribe, setSubscribe] = useState<boolean>(true);
  const [notification, setNotification] = useState<INotification>({
    sms: false,
    email: true,
    pushNotification: true,
  });

  const initialValue: AlertConfigByNoMeasure = {
    id: 0,
    patientId: props.patientId,
    notification: { sms: false, email: true, pushNotification: true },
    color: colors[0],
    deviceId: devices && devices.length > 0 ? devices[0].id : 0,
    max_time_between: `${1}${timeUnits[0].value}`,
    label: "",
  };
  const [alertConfig, setAlertConfig] = useState(initialValue);

  const handleAlertConfig = useCallback(
    (value: any, field: string): void => {
      setAlertConfig({ ...alertConfig, [field]: value });
    },
    [alertConfig]
  );

  useEffect(() => {
    if (
      responseApiDevices &&
      responseApiDevices.success === true &&
      responseApiDevices.data!
    ) {
      const devices: Device[] =
        responseApiDevices?.data?.map((device: GetDeviceResponseDto) => {
          const signs: Sign[] = device.signs?.map(
            (sign: SignDto) =>
              ({
                id: sign.id,
                decimals: sign.decimals,
                max: sign.defaultMax,
                min: sign.defaultMin,
                name: getSignName(props.currentLanguage, sign, t),
                unit: sign.unit,
              } as Sign)
          );

          return {
            id: device.id,
            name: getDeviceName(props.currentLanguage, device, t),
            signs: signs,
          } as Device;
        }) ?? [];
      setDevices(devices);
    }
  }, [responseApiDevices]);

  useEffect(() => {
    if (devices && devices.length > 0) {
      const device: Device | undefined = devices.find(
        (d: Device) => d.id === alertConfig?.deviceId
      );
      if (device !== undefined) {
        setSigns(device.signs);
        const signs: any = {};
        setRequestedSigns(signs);
        device.signs.forEach((sign: any) => {
          signs[sign.name] = true;
        });
        setRequestedSigns(signs);
      }
    }
  }, [devices, alertConfig.deviceId]);

  useEffect(() => {
    if (devices && devices.length > 0)
      handleAlertConfig(devices[0].id, "deviceId");
  }, [devices]);

  useEffect(() => {
    handleAlertConfig(`${time}${timeUnit}`, "max_time_between");
  }, [time, timeUnit]);

  useEffect(() => {
    setNotification(alertConfig.notification);
  }, [alertConfig.notification]);

  useEffect(() => {
    handleAlertConfig(notification, "notification");
  }, [notification]);

  const handleFormSubmit = async (): Promise<void> => {
    const dto: AddAlertConfigByNoMeasureIndividualRequestDto =
      new AddAlertConfigByNoMeasureIndividualRequestDto();
    Object.assign(dto, alertConfig);
    const requestedSignsFormatted = [];
    for (const property in requestedSigns) {
      if (requestedSigns[property]) {
        requestedSignsFormatted.push(property);
      }
    }
    dto.requestedSigns = requestedSignsFormatted.join(", ");
    dto.subscribe = subscribe;
    try {
      await add(dto);
      props.handleClose();
      setAlertConfig(initialValue);
    } catch (error: any) {
      toast.error(error.response?.data?.message ?? error.message);
    }
  };

  const handleOnChangeLabel = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleAlertConfig(e.target.value, "label");
  };

  const handleOnColorChange = (e: SelectChangeEvent) => {
    handleAlertConfig(e.target.value, "color");
  };

  const handleNotification = (value: boolean, field: string) => {
    setNotification({ ...notification, [field]: value });
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          <AddAlertConfigByNoMeasure
            deviceId={alertConfig.deviceId}
            setDeviceId={(value: number) =>
              handleAlertConfig(value, "deviceId")
            }
            devices={devices}
            label={alertConfig.label ?? ""}
            handleOnChangeLabel={handleOnChangeLabel}
            color={alertConfig.color}
            handleOnChangeColor={handleOnColorChange}
            time={time}
            setTime={setTime}
            timeUnit={timeUnit}
            setTimeUnit={setTimeUnit}
            signs={signs}
            requestedSigns={requestedSigns}
            setRequestedSigns={setRequestedSigns}
          />
          <SubscriptionChecks
            sms={alertConfig.notification.sms}
            setSms={(checked: boolean) => handleNotification(checked, "sms")}
            email={alertConfig.notification.email}
            setEmail={(checked: boolean) =>
              handleNotification(checked, "email")
            }
            pushNotification={alertConfig.notification.pushNotification}
            setPushNotification={(checked: boolean) =>
              handleNotification(checked, "pushNotification")
            }
            subscribe={subscribe}
            setSubscribe={setSubscribe}
            messengerChannel={props.messengerChannel}
          />
          <Box style={styles.btnSubmit}>
            <Button variant="contained" onClick={handleFormSubmit}>
              {t("CreateAlert")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
