import React from "react";
import {
  GetDiseaseResponseDto,
  UpdateDiseaseRequestDto,
} from "@doctomatic/sdk/build/dto/Diseases";
import { useTranslation } from "react-i18next";
import { TrackingTypeListGridColumns } from "./TrackingTypeListDataGridColumns";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { GridColumns } from "@mui/x-data-grid";
import { UpdateFrequenciesTemplateRequestDto } from "@doctomatic/sdk/build/dto/FrequenciesTemplate/FrequenciesTemplate";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";

interface TrackingTypeListProps {
  onEdit: (disease: GetDiseaseResponseDto) => void;
  onDelete: (disease: GetDiseaseResponseDto) => void;
  onFrequenciesTemplate: (disease: GetDiseaseResponseDto) => void;
  currentLanguage: string;
  diseases: GetDiseaseResponseDto[] | undefined;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  diseasesPaginationInfo: any;
  patch: (id: number, dto: UpdateDiseaseRequestDto) => Promise<any>;
}

const TrackingTypeList = ({
  onDelete,
  onEdit,
  onFrequenciesTemplate,
  currentLanguage,
  diseases,
  setPage,
  setPageSize,
  diseasesPaginationInfo,
  patch,
}: TrackingTypeListProps): React.ReactElement => {
  const { t, i18n } = useTranslation();

  const onClickCheckbox = async (disease: GetDiseaseResponseDto) => {
    const dto: UpdateDiseaseRequestDto = new UpdateDiseaseRequestDto();
    dto.devices = disease.devices.map((device: GetDeviceResponseDto) => {
      const dto: UpdateFrequenciesTemplateRequestDto =
        new UpdateFrequenciesTemplateRequestDto();
      dto.deviceId = device.id;
      return dto;
    });
    dto.forceFrequenciesCreation = !disease.forceFrequenciesCreation;
    await patch(disease.id, dto);
  };

  if (!diseases) return <></>;

  const datagridColumns: GridColumns<GetDiseaseResponseDto> =
    TrackingTypeListGridColumns(
      t,
      onEdit,
      onDelete,
      onFrequenciesTemplate,
      onClickCheckbox,
      currentLanguage
    );

  return (
    <FlexLayoutGrid
      language={i18n.language}
      columns={datagridColumns}
      rows={diseases}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      pagination={diseasesPaginationInfo}
    ></FlexLayoutGrid>
  );
};

export { TrackingTypeList };
