import React from 'react';
import { Box, Typography, Select, MenuItem } from '@mui/material';
var styles = {
    select: {
        padding: '22px 24px',
        fontSize: '1rem',
    },
    menu: {
        maxHeight: '320px'
    }
};
var Time = function (_a) {
    var min = _a.min, onChange = _a.onChange;
    var validHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    var validMinutes = [0, 15, 30, 45];
    var _b = React.useState(Math.floor(min / 60)), hours = _b[0], setHours = _b[1];
    var _c = React.useState(min % 60), minutes = _c[0], setMinutes = _c[1];
    var handleChange = function (hours, minutes) {
        setHours(hours);
        setMinutes(minutes);
        onChange(hours * 60 + minutes);
    };
    return (React.createElement(Box, { display: 'flex', alignItems: 'center' },
        React.createElement(Select, { sx: { select: styles.select }, MenuProps: { sx: { list: styles.menu } }, variant: 'outlined', name: 'hour', onChange: function (event) { return handleChange(event.target.value, minutes); }, value: hours }, validHours.map(function (h) { return React.createElement(MenuItem, { key: "h".concat(h), value: h }, "".concat(h).padStart(2, '0')); })),
        React.createElement(Typography, { style: { margin: '0 8px' } }, ":"),
        React.createElement(Select, { sx: { select: styles.select }, variant: 'outlined', name: 'minute', onChange: function (event) { return handleChange(hours, event.target.value); }, value: minutes }, validMinutes.map(function (m) { return React.createElement(MenuItem, { key: "m".concat(m), value: m }, "".concat(m).padStart(2, '0')); }))));
};
export { Time };
