/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import {
  IconButton,
  styled,
  CircularProgress,
  useMediaQuery,
  Switch,
  alpha,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { useApi } from "@doctomatic/sdk/build/Api";
import { processError } from "../../../App/errorToast";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import CameraPermissionModal from "./CameraPermissionModal";
import { Theme } from "@mui/material/styles";
import { CreateImageResponseDto } from "@doctomatic/sdk/build/dto/Images";
import { manipulateImage } from "../utils";
import { Processing } from "./Processing";
import { Zoom } from "./components/ZoomComponent";
import { Timer } from "./components/TimerComponent";
import QRCodeCanvas, { ErrorLevel } from "../../QRCodes/QRCodeCanvas";
import AddCommentIcon from "@mui/icons-material/AddComment";

const currentHost =
  process.env.REACT_APP_URL === "" || process.env.REACT_APP_URL === undefined
    ? `${window.location.protocol}//${window.location.hostname}`
    : process.env.REACT_APP_URL;

const CANVAS_IMAGE_WIDTH = 415;
interface Props {
  userId: string;
  deviceId: string | undefined;
  isProcessing: boolean;
  setIsProcessing: (state: boolean) => void;
  createImageResponseDto: CreateImageResponseDto | null;
  setCreateImageResponseDto: (response: CreateImageResponseDto | null) => void;
  onTakePhoto: (image: Blob) => Promise<void>;
  takenPhoto: Blob | undefined;
  setOpenDescription: (status: boolean) => void;
  bgColor?: string;
  parametersUrl?: string;
  showSwitch?: boolean;
  onClose?: () => void;
  onNextPage?: () => void;
  saveReadDeviceIdToLocalStorage?: (readDeviceId: number) => void;
}

export const CameraCore = ({
  userId,
  deviceId,
  isProcessing,
  setIsProcessing,
  createImageResponseDto,
  setCreateImageResponseDto,
  onTakePhoto,
  takenPhoto,
  setOpenDescription,
  bgColor,
  parametersUrl,
  showSwitch,
  onClose,
  onNextPage,
  saveReadDeviceIdToLocalStorage,
}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showModalPermissions, setShowModalPermissions] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const {
    logout,
    useDevices,
    usePatient,
    id: loggedId,
    token,
    readDeviceDescription,
  } = useApi();
  const { getDevice } = useDevices(false, processError(logout, navigate, t));
  const { findOneWithDevicesAndMeasurements } = usePatient(
    +userId,
    false,
    processError(logout, navigate, t)
  );
  const [cameraLoading, setCameraLoading] = useState<boolean>(true);
  const [device, setDevice] = useState<GetDeviceResponseDto>();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isEmbedded = searchParams.get("embedded") === "true";

  const [indexZoom, setIndexZoom] = useState<number>();
  const [zoom, setZoom] = useState<number>();
  const [timer, setTimer] = useState<number>();
  const [remainingTime, setRemainingTime] = useState(0);
  const [showTimer, setShowTimer] = useState(false);
  const [showZoom, setShowZoom] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [patientName, setPatientName] = useState<string | undefined>(undefined);

  const fetchUser = async () => {
    const user = await findOneWithDevicesAndMeasurements();
    if (user && user.data) {
      setPatientName(user.data.patient.name);
    }
  };

  const getDeviceSelected = async () => {
    if (deviceId) {
      const device = await getDevice(parseInt(deviceId));
      setDevice(device.data);
    }
  };

  const setupVideo = async () => {
    setCameraLoading(true);
    try {
      // "user" para la cámara frontal, "environment" para la trasera
      const stream = await getDeviceStream("environment");
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error("Error al acceder a la cámara:", err);
      setShowModalPermissions(true);
    }
    setCameraLoading(false);
  };

  const getDeviceStream = async (facingMode: "user" | "environment") => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );
    const facingModeVideoDevices = videoDevices.filter(
      (device) =>
        device.label.toLowerCase().includes(facingMode) ||
        device.label
          .toLowerCase()
          .includes(facingMode === "user" ? "front" : "back")
    );

    const videoDeviceId = facingModeVideoDevices.length
      ? facingModeVideoDevices[0].deviceId
      : undefined;

    const constraints = {
      audio: false,
      video: {
        width: { ideal: CANVAS_IMAGE_WIDTH },
        height: { ideal: CANVAS_IMAGE_WIDTH },
        facingMode,
        ...(videoDeviceId ? { deviceId: videoDeviceId } : {}),
      },
    };

    return navigator.mediaDevices.getUserMedia(constraints);
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    }
  };

  const savePhotoToLocalStorage = async () => {
    setIsProcessing(true);
    try {
      const blob = await manipulateImage();
      await onTakePhoto(blob);
    } catch (error) {
      console.error(error);
    }
    setIsProcessing(false);
  };

  const takePhoto = async () => {
    setIsLoading(true);
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        canvasRef.current.width = CANVAS_IMAGE_WIDTH;
        canvasRef.current.height = CANVAS_IMAGE_WIDTH;

        const imageZoom = zoom ?? 1;

        context.scale(imageZoom, imageZoom);

        // Obtener las dimensiones del video
        const videoWidth = videoRef.current.videoWidth;
        const videoHeight = videoRef.current.videoHeight;
        // Calcular el tamaño visible después del zoom
        const visibleWidth = videoWidth / imageZoom;
        const visibleHeight = videoHeight / imageZoom;

        // Calcular el desplazamiento para centrar la imagen
        const offsetX = (videoWidth - visibleWidth) / 2;
        const offsetY = (videoHeight - visibleHeight) / 2;

        // Dibujar la porción visible centrada en el canvas
        context.drawImage(
          videoRef.current,
          offsetX, // Coordenada X de la porción visible en el video
          offsetY, // Coordenada Y de la porción visible en el video
          CANVAS_IMAGE_WIDTH, // Ancho de la porción visible
          CANVAS_IMAGE_WIDTH, // Altura de la porción visible
          0, // Coordenada X en el canvas
          0, // Coordenada Y en el canvas
          CANVAS_IMAGE_WIDTH, // Ancho en el canvas
          CANVAS_IMAGE_WIDTH // Altura en el canvas
        );

        const imageData = canvasRef.current.toDataURL("image/png");
        localStorage.setItem("capturedPhoto", imageData);
      }
      stopCamera();

      savePhotoToLocalStorage();
    }
  };

  const handleTimer = () => {
    setShowTimer(!showTimer);
  };

  const handleZoom = () => {
    setShowZoom(!showZoom);
  };

  const handleQR = (checked: boolean) => {
    setShowQR(checked);
    if (checked === false) {
      setupVideo();
    } else {
      setShowZoom(false);
      setShowTimer(false);
    }
  };

  const startTimer = () => {
    setIsLoading(true);
    const duration = (timer as number) * 1000;
    setRemainingTime(timer as number);

    const timerInterval = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
      takePhoto();
      setRemainingTime(0);
    }, duration);
  };

  useEffect(() => {
    getDeviceSelected();
    setupVideo();
  }, [deviceId]);

  useEffect(() => {
    if (+loggedId !== +userId) {
      fetchUser();
    }
  }, [userId]);

  const zoomComponent = (
    <Zoom
      indexZoom={indexZoom}
      setIndexZoom={setIndexZoom}
      setZoom={setZoom}
      deviceId={deviceId}
    />
  );

  const timerComponent = (
    <Timer timer={timer} setTimer={setTimer} t={t} deviceId={deviceId} />
  );

  const imageView = () => {
    if (showQR) {
      return (
        <QRCodeCanvas
          QRString={currentHost + location.pathname + `?token=${token}`}
          options={{ width: 250, errorCorrectionLevel: ErrorLevel.low }}
        />
      );
    } else {
      if (takenPhoto) {
        return (
          <img
            src={URL.createObjectURL(takenPhoto)}
            alt="Taken Photo"
            style={{
              height: "auto",
              maxWidth: "100%",
              transform: `scale(${zoom})`,
            }}
          />
        );
      } else {
        return (
          <video
            ref={videoRef}
            autoPlay
            playsInline
            style={{
              height: "auto",
              maxWidth: "100%",
              transform: `scale(${zoom})`,
            }}
          />
        );
      }
    }
  };
  return (
    <>
      {showModalPermissions && (
        <CameraPermissionModal
          onOpen={showModalPermissions}
          onRequestPermission={setupVideo}
          onClose={() => setShowModalPermissions(false)}
        />
      )}
      {!isProcessing && !createImageResponseDto ? (
        <div
          style={{
            backgroundColor: bgColor ? bgColor : "#edeaea",
            ...(isMobile && {
              height: "100vh",
              maxHeight: "100vh",
            }),
            // overflowY: "hidden",
          }}
        >
          <>
            <IconButton
              onClick={() => {
                if (onClose) {
                  onClose();
                } else {
                  let path = "/devices";
                  let userIdPath = +loggedId !== +userId ? `/${userId}` : "";
                  if (searchParams) {
                    navigate(`${path}${userIdPath}?${searchParams.toString()}`);
                  } else {
                    navigate(`${path}${userIdPath}`);
                  }
                }
              }}
              color="inherit"
              style={{ color: "white" }}
              sx={{
                position: "absolute",
                top: isMobile ? 65 : isEmbedded ? 10 : 75,
                right: 10,
                backgroundColor: "gray",
                borderRadius: 50,
              }}
            >
              <CloseIcon />
            </IconButton>
            {device && (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: 5,
                }}
              >
                <h2
                  style={{
                    color: "black",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "white",
                    borderRadius: 15,
                    paddingInline: 10,
                    maxWidth: "70%",
                    margin: 5,
                    textAlign: "center",
                  }}
                >
                  {t(device.name)}
                </h2>
              </div>
            )}
            {patientName && (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <h3
                  style={{
                    color: "black",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "white",
                    borderRadius: 10,
                    paddingInline: 10,
                    maxWidth: "70%",
                    textAlign: "center",
                    margin: 5,
                  }}
                >
                  {patientName}
                </h3>
              </div>
            )}

            {/* QR Code Switch */}
            {(showSwitch == undefined || showSwitch) && (
              <div
                style={{
                  marginBlockStart: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CameraAltIcon sx={{ fontSize: 30 }} />
                <ColoredSwitch
                  sx={{ transform: "scale(1.8)", marginX: 2 }}
                  checked={showQR}
                  name={"QR"}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => handleQR(checked)}
                />
                <QrCodeIcon sx={{ fontSize: 30 }} />
              </div>
            )}

            <div
              style={{
                marginBlockStart: 10,
              }}
            >
              {!cameraLoading && !showQR && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: isMobile ? "90%" : "417px",
                      aspectRatio: 1,
                      position: "absolute",
                      zIndex: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ width: "85%", height: "85%" }}>
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              width: 3,
                              height: 80,
                              backgroundColor: "white",
                              position: "absolute",
                              top: 5,
                              left: 5,
                            }}
                          />
                          <div
                            style={{
                              width: 3,
                              height: 80,
                              backgroundColor: "white",
                              position: "absolute",
                              top: -34,
                              left: 44,
                              rotate: "-90deg",
                            }}
                          />
                          <div
                            style={{
                              width: 3,
                              height: 80,
                              backgroundColor: "white",
                              position: "absolute",
                              bottom: isMobile ? 5 : 8,
                              left: 5,
                            }}
                          />
                          <div
                            style={{
                              width: 3,
                              height: 80,
                              backgroundColor: "white",
                              position: "absolute",
                              bottom: isMobile ? -34 : -31,
                              left: 44,
                              rotate: "-90deg",
                            }}
                          />
                          <div
                            style={{
                              width: 3,
                              height: 80,
                              backgroundColor: "white",
                              position: "absolute",
                              top: 5,
                              right: 5,
                            }}
                          />
                          <div
                            style={{
                              width: 3,
                              height: 80,
                              backgroundColor: "white",
                              position: "absolute",
                              top: -35,
                              right: 44,
                              rotate: "-90deg",
                            }}
                          />
                          <div
                            style={{
                              width: 3,
                              height: 80,
                              backgroundColor: "white",
                              position: "absolute",
                              bottom: isMobile ? 5 : 8,
                              right: 5,
                            }}
                          />
                          <div
                            style={{
                              width: 3,
                              height: 80,
                              backgroundColor: "white",
                              position: "absolute",
                              bottom: isMobile ? -34 : -31,
                              right: 44,
                              rotate: "-90deg",
                            }}
                          />
                          {remainingTime !== 0 && (
                            <div
                              style={{
                                color: "white",
                                textAlign: "center",
                                width: "100%",
                                top: "20%",
                                marginInline: "auto",
                                fontSize: 150,
                                position: "absolute",
                              }}
                            >
                              {remainingTime}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    marginInline: "5%",
                    aspectRatio: 1,
                  }}
                >
                  {imageView()}
                </div>
              </div>

              {cameraLoading && (
                <CameraLoadingText style={{ color: "black" }}>
                  {t("AccessingCamera")}
                </CameraLoadingText>
              )}
            </div>
            <StyledCanvas ref={canvasRef} />

            {!cameraLoading && (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "5%",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {!showTimer && !showQR && (
                    <IconButton
                      onClick={handleZoom}
                      color="inherit"
                      style={{
                        backgroundColor: "gray",
                        borderRadius: 50,
                        marginRight: 10,
                        color: indexZoom !== 0 ? "#83c986" : "white",
                      }}
                    >
                      <ZoomInIcon sx={{ fontSize: 35 }} />
                    </IconButton>
                  )}
                  <div style={{ display: showZoom ? "block" : "none" }}>
                    {zoomComponent}
                  </div>
                  <div style={{ display: showTimer ? "block" : "none" }}>
                    {timerComponent}
                  </div>
                  {!showZoom && !showQR && (
                    <IconButton
                      onClick={handleTimer}
                      color="inherit"
                      style={{
                        backgroundColor: "gray",
                        borderRadius: 50,
                        marginLeft: 10,
                        color: timer !== 0 ? "#83c986" : "white",
                      }}
                    >
                      <AvTimerIcon sx={{ fontSize: 35 }} />
                    </IconButton>
                  )}
                </div>
              </div>
            )}

            <div
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: "5%",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {!isLoading ? (
                !cameraLoading &&
                !showQR && (
                  <>
                    <IconButton
                      disabled={cameraLoading || isLoading}
                      onClick={startTimer}
                      color="inherit"
                      style={{ color: "white" }}
                      size="large"
                      sx={{
                        borderRadius: 50,
                        backgroundColor: "gray",
                        marginBottom: "5%",
                      }}
                    >
                      <CameraAltIcon sx={{ fontSize: 60 }} />
                    </IconButton>
                    {!showTimer && !showQR && readDeviceDescription && (
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginBottom: "5%",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          maxWidth: 417,
                          width: "90%",
                          alignSelf: "center",
                        }}
                      >
                        <IconButton
                          color="gray"
                          edge="start"
                          onClick={() => setOpenDescription(true)}
                        >
                          <AddCommentIcon fontSize="large" />
                        </IconButton>
                      </div>
                    )}
                  </>
                )
              ) : (
                <CircularProgress size={30} />
              )}
            </div>
          </>
        </div>
      ) : (
        <Processing
          isLoading={isProcessing}
          device={device}
          createImageResponseDto={createImageResponseDto}
          setCreateImageResponseDto={setCreateImageResponseDto}
          bgColor={bgColor}
          parametersUrl={parametersUrl}
          onNextPage={onNextPage}
          saveReadDeviceIdToLocalStorage={saveReadDeviceIdToLocalStorage}
        />
      )}
    </>
  );
};

// styles material ui mode
const CameraLoadingText = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 18px;
`;

const StyledCanvas = styled("canvas")`
  display: none;
`;

export const ColoredSwitch = styled(Switch)(({ theme }) => {
  const color = theme.palette.primary.main;

  return {
    "& .MuiSwitch-switchBase": {
      color: color,
      "&.Mui-checked": {
        color: color,
        "&:hover": {
          backgroundColor: alpha(color, theme.palette.action.hoverOpacity),
        },
      },
    },
    "& .MuiSwitch-track": {
      backgroundColor: color,
      opacity: 0.5,
      ".Mui-checked.Mui-checked + &": {
        backgroundColor: color,
        opacity: 0.7,
      },
    },
  };
});
