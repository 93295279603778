export const alertTypeMap = [
  ["min", "FieldConditionMin"],
  ["max", "FieldConditionMax"],
  ["get_and_lt", "FieldConditionGETLT"],
  //['get_and_lt_time', 'FieldConditiondGTLETAndTime'],
  ["range_absolute", "FieldConditionRangeAbsolure"],
  ["range_relative", "FieldConditionRangeRelative"],
  ["time_absolute", "FiledConditionTimeAbsolute"],
  ["time_relative", "FieldConditionTimeRelative"],
];
