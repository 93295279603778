import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  Select,
  DialogContent,
  DialogTitle,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import { validate, ValidationError } from "class-validator";
import { Phone } from "../../Phone";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { UpdatePatientRequestDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { Gender } from "@doctomatic/sdk/build/dto/User";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router-dom";

interface UserEditProps {
  open: boolean;
  userToEditId: number;
  onClose: () => void;
  onSave: (user: UpdatePatientRequestDto) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  visible: { overflowY: "visible" },
}));

const PatientEdit = ({
  open,
  userToEditId,
  onClose,
  onSave,
}: UserEditProps): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { usePatient, logout } = useApi();
  const { response: patientResponse } = usePatient(
    userToEditId,
    true,
    processError(logout, navigate, t)
  );
  const patient = patientResponse?.data;
  const [updateForm, setUpdateForm] = useState<UpdatePatientRequestDto>({
    name: patient?.name,
    email: patient?.email,
    phone: patient?.phone,
    externalId: patient?.externalId,
    birthYear: patient?.birthYear,
    gender: patient && patient.gender ? Gender[patient.gender] : undefined,
  });
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const classes = useStyles();
  const genders = Object.keys(Gender);
  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 120;

  useEffect(() => {
    setUpdateForm({ ...patient });
  }, [
    open,
    patient,
    patient?.id,
    patient?.name,
    patient?.email,
    patient?.phone,
    patient?.externalId,
    patient?.birthYear,
    patient?.gender,
  ]);

  useEffect(() => {
    setErrors([]);
  }, [
    updateForm?.name,
    updateForm?.email,
    updateForm?.phone,
    updateForm?.externalId,
    updateForm?.birthYear,
    updateForm?.gender,
  ]);

  const checkErrors = async (
    dto: UpdatePatientRequestDto
  ): Promise<boolean> => {
    let errors: ValidationError[] = await validate(dto);
    if (!dto.name || dto.name.length === 0) {
      let validationError: ValidationError = new ValidationError();
      validationError.property = "name";
      errors.push(validationError);
    }
    if (dto.email && dto.email.length === 0) {
      let validationError: ValidationError = new ValidationError();
      validationError.property = "email";
      errors.push(validationError);
    }
    if (
      dto.birthYear &&
      (dto.birthYear > currentYear || dto.birthYear < minYear)
    ) {
      let validationError: ValidationError = new ValidationError();
      validationError.property = "birthYear";
      errors.push(validationError);
    }

    setErrors(errors);
    return errors.length === 0;
  };

  const getError = (name: string): ValidationError | undefined => {
    return errors.find((v: ValidationError) => v.property === name);
  };

  const getHelperText = (name: string, message: string): string => {
    return getError(name) ? message : "";
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.visible }}
    >
      <DialogTitle>
        {t("ButtonEdit")} <span>{t("FieldPatient")}</span>
      </DialogTitle>

      <DialogContent className={classes.visible}>
        <TextField
          autoComplete="off"
          error={!!getError("name")}
          helperText={getHelperText(
            "name",
            t("ErrorRequiredField", { field_name: t("FieldName") })
          )}
          margin="dense"
          variant="outlined"
          name="name"
          label={t("FieldName")}
          type="text"
          fullWidth
          onChange={(e) =>
            setUpdateForm({ ...updateForm, name: e.target.value })
          }
          value={updateForm.name || ""}
          autoFocus
          required
        />
        <TextField
          autoComplete="off"
          error={!!getError("email")}
          helperText={getHelperText(
            "email",
            t("ErrorRequiredField", { field_name: t("FieldEmail") })
          )}
          margin="dense"
          variant="outlined"
          name="email"
          label={t("FieldEmail")}
          type="email"
          fullWidth
          onChange={(e) =>
            setUpdateForm({ ...updateForm, email: e.target.value })
          }
          value={updateForm.email || ""}
          required
        />
        <Phone
          error={!!getError("phone")}
          helperText={getHelperText(
            "phone",
            t("ErrorRequiredField", { field_name: t("FieldPhone") })
          )}
          disabled
          onUpdate={(e) => setUpdateForm({ ...updateForm, phone: e })}
          value={updateForm.phone || ""}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          variant="outlined"
          name="externalId"
          label={t("FieldExternalId")}
          type="text"
          fullWidth
          onChange={(e) =>
            setUpdateForm({ ...updateForm, externalId: e.target.value })
          }
          value={updateForm.externalId || ""}
        />
        <TextField
          autoComplete="off"
          error={!!getError("birthYear")}
          helperText={getHelperText(
            "birthYear",
            t("ErrorBirthYearField", {
              fieldName: t("FieldBirthYear"),
              currentYear,
              minYear,
            })
          )}
          margin="dense"
          variant="outlined"
          name="birthYear"
          label={t("FieldBirthYear")}
          type="number"
          fullWidth
          onChange={(e) =>
            setUpdateForm({ ...updateForm, birthYear: +e.target.value })
          }
          value={updateForm.birthYear || ""}
        />
        {
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel>{t("Gender")}</InputLabel>
            <Select
              id="gender"
              label={t("Gender")}
              variant="outlined"
              onChange={(event) =>
                setUpdateForm({
                  ...updateForm,
                  gender: event.target.value as Gender,
                })
              }
              value={updateForm.gender || ""}
            >
              {genders.map((key, index) => {
                const genderFormatted = key[0].toUpperCase() + key.slice(1);
                return (
                  <MenuItem key={index} value={key}>
                    {t(genderFormatted)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        }
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={onClose} size="small">
              {t("ButtonCancel")}
            </Button>
          </Box>
          <Button
            disabled={errors.length > 0}
            onClick={async () => {
              const valid: boolean = await checkErrors(updateForm);
              if (!valid) return;

              let dto = new UpdatePatientRequestDto();
              if (patient?.email !== updateForm.email)
                dto.email = updateForm.email;
              if (patient?.name !== updateForm.name) dto.name = updateForm.name;
              if (patient?.phone !== updateForm.phone)
                dto.phone = updateForm.phone;
              if (patient?.externalId !== updateForm.externalId)
                dto.externalId = updateForm.externalId;
              if (patient?.gender !== updateForm.gender)
                dto.gender = updateForm.gender as Gender;
              if (patient?.birthYear !== updateForm.birthYear)
                dto.birthYear = updateForm.birthYear;
              onSave(dto);
            }}
            variant="contained"
            size="small"
          >
            {t("ButtonSave")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { PatientEdit };
