import { Box } from "@mui/material";
import {
  AlertConfigBaseForm,
  AlertConfigBaseProps,
} from "./AlertConfigBaseForm";
import {
  AlertConfigByNoMeasureBaseForm,
  AlertConfigByNoMeasureBaseProps,
} from "./AlertConfigByNoMeasureBaseForm";
import { styles } from "./styles";

export interface EditAlertConfigByNoMeasureProps
  extends AlertConfigBaseProps,
    AlertConfigByNoMeasureBaseProps {}

export const EditAlertConfigByNoMeasure = ({
  label,
  handleOnChangeLabel,
  color,
  handleOnChangeColor,
  time,
  setTime,
  timeUnit,
  setTimeUnit,
  signs,
  requestedSigns,
  setRequestedSigns,
}: EditAlertConfigByNoMeasureProps): React.ReactElement => {
  return (
    <Box style={styles.titleContainer}>
      <AlertConfigBaseForm
        label={label}
        handleOnChangeLabel={handleOnChangeLabel}
        color={color}
        handleOnChangeColor={handleOnChangeColor}
      />
      <AlertConfigByNoMeasureBaseForm
        time={time}
        setTime={setTime}
        timeUnit={timeUnit}
        setTimeUnit={setTimeUnit}
        signs={signs}
        requestedSigns={requestedSigns}
        setRequestedSigns={setRequestedSigns}
      />
    </Box>
  );
};
