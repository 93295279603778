var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Checkbox } from "@mui/material";
import React from "react";
export var SelectMultipleGridColumns = function (props) {
    return __spreadArray([
        {
            field: "",
            headerName: "",
            width: 100,
            renderCell: function (params) {
                return (React.createElement(Checkbox, { checked: props.selectedItems.find(function (x) { return x.id == params.row.id; }) ? true : false, onChange: function (event, checked) {
                        props.selectEvent(params.row, checked);
                    } }));
            },
        }
    ], props.columns, true);
};
