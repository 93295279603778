import React from "react";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { Page } from "../../Page";
import { FormComponent } from "./FormComponent";
import { useTranslation } from "react-i18next";
import { handleSaveForm } from "./utils";

const FormsCreatePage = (): React.ReactElement => {
  const { t } = useTranslation();

  const getBreadCrumbProps = (): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        {
          url: BreadcrumbNameMap.Forms.url,
          name: t(BreadcrumbNameMap.Forms.name),
        },
        { url: "", name: t(BreadcrumbNameMap.FormsCreate.name) },
      ],
    } as BreadcrumbProps;
  };

  return (
    <Page title={""} breadCrumbProps={getBreadCrumbProps()}>
      <FormComponent handleSaveForm={handleSaveForm} />
    </Page>
  );
};

export { FormsCreatePage };
