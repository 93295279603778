import React from "react";
import { Page } from "../Page";

import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ce from "../../../assets/symbol-ce.svg";
import instruction from "../../../assets/symbol-instruction.svg";
import manufacturer from "../../../assets/symbol-manufacturer.svg";
import md from "../../../assets/symbol-md.svg";
import { WEB_VERSION } from "../../../version";
import { useApi } from "@doctomatic/sdk/build/Api";
import { processError } from "../../../App/errorToast";

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    backgroundColor: theme.palette.common.white,
    textDecoration: "none",
    color: theme.palette.primary.main,
    textTransform: "none",
    fontWeight: 400,
    padding: 0,
    textAlign: "left",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  symbolContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderRadius: 4,
  },
  symbolText: {
    paddingLeft: 20,
  },
}));

const About = (): React.ReactElement => {
  const { useCompany, logout, companyId } = useApi();
  let companyData = undefined;
  if (companyId) {
    const { response: company } = useCompany(
      companyId,
      true,
      processError(logout, history)
    );
    companyData = company?.data;
  }
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  let doctomaticURL = process.env.REACT_APP_WEB_URL || "";
  const doctomaticPhone = process.env.REACT_APP_PHONE || "";
  const doctomaticEmail = process.env.REACT_APP_EMAIL || "";

  const language = i18n.language;
  if (language === "es") doctomaticURL += "/es";

  const en = [
    " is a remote patient monitoring medical software which transmits the data provided by non-invasive medical devices via a mobile app to a medical platform whereby medical professionals analyze and evaluate the information of their patients.",
    "Fore more information, please visit ",
    ", call ",
    " or email ",
    "Manufacturer: Doctomatic S.L. C/Pallars 242, Ent.2, 08005 Barcelona, España.",
    "Follow the instructions before using the product.",
    "This is a Medical Device that meets the requirements set by EU Regulation 2017/745.",
    "CE Marked.",
    "Current software version of Doctomatic:",
  ];

  const es = [
    " es un producto sanitario software destinado al seguimiento remoto de pacientes mediante la transmisión de los datos proporcionados por dispositivos de monitorización rutinaria no invasiva a una aplicación web donde el profesional sanitario analiza y evalúa dicha información del paciente.",
    "Para más información visite nuestra web ",
    " o contacte con nosotros en el teléfono ",
    " o a través del email ",
    "Fabricante: Doctomatic S.L. C/Pallars 242, Ent.2, 08005 Barcelona, España.",
    "Follow the instructions before using the product.",
    "Se trata de un producto sanitario que cumple los requisitos aplicables indicados en el Reglamento UE 2017/745.",
    "Marcado CE.",
    "A continuación, se identifica la versión actual de Doctomatic:",
  ];

  const pt_BR = [
    " é um software médico de monitoramento remoto de pacientes que transmite os dados fornecidos por dispositivos médicos não invasivos por meio de um aplicativo móvel para uma plataforma médica na qual profissionais médicos analisam e avaliam as informações de seus pacientes.",
    "Para mais informações, visite ",
    ", ligar ",
    " ou email ",
    "Fabricante: Doctomatic S.L. C/Pallars 242, Ent.2, 08005 Barcelona, España.",
    "Siga as instruções antes de usar o produto.",
    "Este é um dispositivo médico que atende aos requisitos estabelecidos pelo Regulamento da UE 2017/745.",
    "Marcado CE.",
    "Versão atual do software Doctomatic:",
  ];

  const ca = [
    " és un programari mèdic de seguiment remot de pacients que transmet les dades proporcionades per dispositius mèdics no invasius mitjançant una aplicació mòbil a una plataforma mèdica on els professionals mèdics analitzen i avaluen la informació dels seus pacients",
    "Per obtenir més informació, visiteu ",
    ", anomenada ",
    "o correu electrònic",
    "Fabricant: Doctomatic S.L. C/Pallars 242, Ent.2, 08005 Barcelona, España.",
    "Seguiu les instruccions abans d'utilitzar el producte.",
    "Aquest és un dispositiu mèdic que compleix els requisits establerts pel Reglament UE 2017/745.",
    "Marcat CE.",
    "Versió de programari actual de Doctomatic:",
  ];

  const fr = [
    "est un produit logiciel de santé destiné au suivi à distance des patients en transmettant les données fournies par des dispositifs de suivi de routine non invasifs à une application web où le professionnel de santé analyse et évalue lesdites informations patient.",
    "Pour plus d'informations, visitez notre site web ",
    "ou contactez-nous par téléphone ",
    "ou par mail ",
    "Fabricant : Doctomatic S.L. C/Pallars 242, Ent.2, 08005 Barcelone, Espagne.",
    "Suivez les instructions avant d'utiliser le produit.",
    "Il s'agit d'un dispositif médical qui répond aux exigences applicables indiquées dans le règlement UE 2017/745.",
    "Marquage CE.",
    "La version actuelle de Doctomatic est identifiée ci-dessous :",
  ];

  const it = [
    "è un prodotto sanitario software destinato al monitoraggio remoto dei pazienti trasmettendo i dati forniti dai dispositivi di monitoraggio di routine non invasivi a un'applicazione web in cui il professionista sanitario analizza e valuta tali informazioni sul paziente.",
    "Per maggiori informazioni, visita il nostro sito web ",
    "o contattaci telefonicamente ",
    "o via e-mail ",
    "Produttore: Doctomatic S.L. C/Pallars 242, Ent.2, 08005 Barcellona, Spagna.",
    "Seguire le istruzioni prima di utilizzare il prodotto.",
    "È un dispositivo medico che soddisfa i requisiti applicabili indicati nel Regolamento UE 2017/745.",
    "Marchio CE.",
    "L'attuale versione di Doctomatic è identificata di seguito:",
  ];

  const textObj = [
    { id: "es", lang: es },
    { id: "en", lang: en },
    { id: "ca", lang: ca },
    { id: "pt_BR", lang: pt_BR },
    { id: "fr", lang: fr },
    { id: "it", lang: it },
  ];

  const langObj = textObj.find((e) => e.id === language);

  const custom =
    companyData && companyData.logo && companyData.internDescription
      ? true
      : false;

  return (
    <Page title={t("AboutTitle")}>
      {custom ? (
        <>
          <Box my={2}>
            <Box
              width={"100%"}
              marginBottom={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src={companyData?.logo} alt="Logo" width={150} />
            </Box>
            <Box
              width={"100%"}
              height={"100%"}
              marginBottom={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <iframe
                src={companyData?.internDescription}
                style={{ border: "none", display: "flex" }}
                width="700"
                height="500"
              ></iframe>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box my={2}>
            <Typography variant="body1">
              <b>DOCTOMATIC</b>
              {langObj ? langObj.lang[0] : en[0]}
            </Typography>
          </Box>
          <Box my={2}>
            <Typography variant="body1">
              {langObj ? langObj.lang[1] : en[1]}
              <a className={classes.link} href={doctomaticURL}>
                {doctomaticURL}
              </a>{" "}
              {langObj ? langObj.lang[2] : en[2]}
              <a className={classes.link} href={`tel:${doctomaticPhone}`}>
                {doctomaticPhone}
              </a>{" "}
              {langObj ? langObj.lang[3] : en[3]}
              <a className={classes.link} href={`mailto:${doctomaticEmail}`}>
                {doctomaticEmail}
              </a>
              .
            </Typography>
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              margin: "0 auto",
              marginTop: 20,
            }}
          >
            <div className={classes.symbolContainer}>
              <img
                src={manufacturer}
                style={{ width: 50 }}
                alt="Manufacturer"
              />
              <Typography className={classes.symbolText} variant="body1">
                {langObj ? langObj.lang[4] : en[4]}
              </Typography>
            </div>

            <div className={classes.symbolContainer}>
              <img src={instruction} style={{ width: 50 }} alt="Instructions" />
              <Typography className={classes.symbolText} variant="body1">
                {langObj ? langObj.lang[5] : en[5]}
              </Typography>
            </div>

            <div className={classes.symbolContainer}>
              <img src={md} style={{ width: 50 }} alt="Medical Device" />
              <Typography className={classes.symbolText} variant="body1">
                {langObj ? langObj.lang[6] : en[6]}
              </Typography>
            </div>

            <div className={classes.symbolContainer}>
              <img src={ce} style={{ width: 50 }} alt="CE" />
              <Typography className={classes.symbolText} variant="body1">
                {langObj ? langObj.lang[7] : en[7]}
              </Typography>
            </div>

            <Box my={4}>
              <Typography
                variant="body1"
                style={{ textAlign: "center", alignSelf: "center" }}
              >
                {langObj ? langObj.lang[8] : en[8]}
                {" " + WEB_VERSION}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Page>
  );
};

export { About };
