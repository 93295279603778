var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Button } from "@mui/material";
import React from "react";
export var SelectGridColumns = function (props) {
    return __spreadArray(__spreadArray([], props.columns, true), [
        {
            field: '',
            headerName: '',
            width: 150,
            renderCell: function (params) {
                return React.createElement(Button, { onClick: function () {
                        props.selectEvent(params.row);
                        props.handleClose();
                    } }, props.textButton);
            }
        }
    ], false);
};
