import React, { useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { NurseListGridColumns } from "./NurseListDataGridColumns";
import { ItemNurseListDto } from "@doctomatic/sdk/build/dto/Actors/Nurse/Nurse";
import { DoctomaticStylingRowsGrid } from "../../../Theme/ThemeDataGrid";
import { GridRowClassNameParams } from "@mui/x-data-grid";

interface Props {
  showDeleted: boolean;
  onEdit: (nurse: ItemNurseListDto) => void;
  onDelete: (nurse: ItemNurseListDto) => void;
  onSendSms: (nurse: ItemNurseListDto) => void;
  onSendEmail: React.Dispatch<
    React.SetStateAction<{ id: number; name: string; email?: string } | null>
  >;
  onRestore: (nurse: ItemNurseListDto) => void;
  setQuantityDeleted: React.Dispatch<React.SetStateAction<number>>;
  nurses: ItemNurseListDto[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  nursesPaginationInfo: any;
}

export const NurseList = ({
  showDeleted,
  onDelete,
  onEdit,
  onSendSms,
  onSendEmail,
  onRestore,
  setQuantityDeleted,
  nurses,
  setPage,
  setPageSize,
  nursesPaginationInfo,
}: Props): React.ReactElement => {
  const { role } = useApi();
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = useState<string>("");

  const iamAdmin = role === Role.admin;
  const iamDoctor = role === Role.doctor;
  const iamNurse = role === Role.nurse;
  const iamTeam = iamNurse || iamAdmin || iamDoctor;

  const actions = (nurse: ItemNurseListDto) => {
    const onClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setAnchorEl(event.currentTarget);
      setPopoverId(`m${nurse.id}`);
    };

    const onClose = () => {
      setAnchorEl(null);
      setPopoverId("");
    };

    return (
      <>
        <IconButton onClick={onClick}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={`m${nurse.id}`}
          open={popoverId === `m${nurse.id}`}
          anchorEl={anchorEl}
          onClose={onClose}
        >
          <List>
            {!nurse.deletedAt && (
              <>
                <ListItem
                  button
                  onClick={() => {
                    onClose();
                    onEdit(nurse);
                  }}
                >
                  <ListItemIcon>
                    <EditOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${t("ButtonEdit")} ${t("FieldNurse")}`}
                  />
                </ListItem>

                <ListItem
                  disabled={!nurse.haveEmail}
                  button
                  onClick={() => {
                    onClose();
                    onSendSms(nurse);
                  }}
                >
                  <ListItemIcon>
                    <TextsmsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("UserListSendSMS")} />
                </ListItem>

                <ListItem
                  disabled={!nurse.email}
                  button
                  onClick={() => {
                    onClose();
                    onSendEmail({
                      id: nurse.id,
                      name: nurse.name,
                      email: nurse.email,
                    });
                    console.log("EMAIL--->", nurse);
                  }}
                >
                  <ListItemIcon>
                    <MailOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("UserListSendEmail")} />
                </ListItem>

                <ListItem
                  button
                  onClick={() => {
                    onClose();
                    onDelete(nurse);
                  }}
                >
                  <ListItemIcon>
                    <DeleteOutlineIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${t("ButtonDelete")} ${t("FieldNurse")}`}
                  />
                </ListItem>
              </>
            )}
            {iamTeam && nurse.deletedAt && (
              <ListItem
                button
                onClick={() => {
                  onClose();
                  onRestore(nurse);
                }}
              >
                <ListItemIcon>
                  <RestoreFromTrashOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${t("ButtonRestore")} ${t("FieldNurse")}`}
                />
              </ListItem>
            )}
          </List>
        </Popover>
      </>
    );
  };

  if (!nurses) return <></>;
  setQuantityDeleted(nurses.filter((x) => x.deletedAt).length);

  const datagridColumns = NurseListGridColumns(t, actions);

  const setRowClassName = (
    params: GridRowClassNameParams<ItemNurseListDto>
  ): string => (params.row.deletedAt ? "super-app-theme--Rejected" : "");

  return (
    <FlexLayoutGrid
      language={i18n.language}
      columns={datagridColumns}
      rows={nurses}
      setRowClassName={setRowClassName}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      pagination={nursesPaginationInfo}
      sx={DoctomaticStylingRowsGrid()}
    />
  );
};
