import React from 'react';
import 'react-phone-input-2/lib/style.css';
import { Box, Link } from '@mui/material';
import { HtmlTooltipLines } from '../Tooltips/HtmlTooltip';
var isOverflown = function (el) {
    if (!el)
        return true;
    var curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === "visible")
        el.style.overflow = "hidden";
    var isOverflowing = el.clientWidth < el.scrollWidth
        || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflowing;
};
var TextWordsQuantityCell = function (props) {
    var containerClassName = "words-container-".concat(Math.floor(Math.random() * 1000));
    var transString = function (words) {
        var el = document.getElementById(containerClassName);
        var words_Formated;
        var wordLeft;
        if (!words || words.length === 0)
            return { words_Formated: ['---'], wordLeft: '' };
        if (!isOverflown(el)) {
            words_Formated = words;
            wordLeft = 0;
        }
        else {
            var lettersToShow = words.length === 1 ? 20 : 10;
            var newWords = words[0].length > lettersToShow ? [words[0].substring(0, lettersToShow) + '...'] : [words[0]];
            words_Formated = newWords;
            wordLeft = props.words.length - newWords.length;
        }
        return { words_Formated: words_Formated, wordLeft: wordLeft };
    };
    var wordsFormatted2 = transString(props.words);
    var RouterLink = props.routerLink;
    // Display a common div if the component has no linked page
    var content = (props.page || props.onClickModal) ? (RouterLink ?
        React.createElement(RouterLink, { id: containerClassName, to: props.page || '', style: { whiteSpace: 'nowrap', color: '#1f28a1', width: '100%' } },
            wordsFormatted2.words_Formated.join(', '),
            wordsFormatted2.wordLeft > 0 && ' +' + (wordsFormatted2.wordLeft))
        :
            React.createElement(Link, { id: containerClassName, href: props.page, style: { whiteSpace: 'nowrap', color: '#1f28a1', width: '100%' } },
                wordsFormatted2.words_Formated.join(', '),
                wordsFormatted2.wordLeft > 0 && ' +' + (wordsFormatted2.wordLeft))) :
        React.createElement("div", { id: containerClassName, style: { whiteSpace: 'nowrap', width: '100%' } },
            React.createElement("p", null,
                wordsFormatted2.words_Formated.join(', '),
                wordsFormatted2.wordLeft > 0 && ' +' + (wordsFormatted2.wordLeft)));
    if (!wordsFormatted2)
        return React.createElement(React.Fragment, null, "--");
    return (
    // Display default cursor if item is not clickable.
    // For this component, it will display a pointer cursor if it has an onClick prop or it has a link
    React.createElement(Box, { style: { cursor: (props.page || props.onClickModal) ? 'pointer' : 'default', width: '100%' }, display: 'flex', justifyContent: 'left', alignItems: 'center', onClick: function () { return props.onClickModal && props.onClickModal(); } }, props.onClickModal
        ? (React.createElement(React.Fragment, null, props.tooltipContent
            ?
                HtmlTooltipLines(props.tooltipContent.title, props.tooltipContent.lines, React.createElement("p", { id: containerClassName, style: { whiteSpace: 'nowrap', color: '#1f28a1', width: '100%' } },
                    wordsFormatted2.words_Formated.join(', '),
                    wordsFormatted2.wordLeft > 0 && ' +' + (wordsFormatted2.wordLeft)))
            :
                React.createElement("p", { id: containerClassName, style: { whiteSpace: 'nowrap', color: '#1f28a1', width: '100%' } },
                    wordsFormatted2.words_Formated.join(', '),
                    wordsFormatted2.wordLeft > 0 && ' +' + (wordsFormatted2.wordLeft)))) : (props.tooltipContent ?
        HtmlTooltipLines(props.tooltipContent.title, props.tooltipContent.lines, content)
        :
            content)));
};
export { TextWordsQuantityCell };
