import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { TextWordsQuantityCell } from '@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell';
import { BreadcrumbNameMap } from '../../../../breadcrumbMap';
import { ItemNurseListDto } from '@doctomatic/sdk/build/dto/Actors/Nurse/Nurse';
import { Link } from "react-router-dom";

export const NurseListGridColumns = (
  t: (text: string) => string,
  actions: (user: ItemNurseListDto) => JSX.Element
): GridColumns<ItemNurseListDto> => {
  return [
    {
      field: 'name',
      headerName: t('HeaderName'),
      width: 200,
      align: 'left',
    },
    {
      field: 'locations',
      headerName: t('HeaderLocations'),
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        const words: string[] = (params.row.locations && params.row.locations.length > 0)
          ? params.row.locations.map((location: any) => location.name)
          : [t('GoToLocations')];
        return words.join(', ');
      },
      sortable: false,
      renderCell: (params) => {
        const page: string = BreadcrumbNameMap.LocationsUser.url
          .replace(':role', 'nurses')
          .replace(':actorId', params.row.id.toString());
        const words: string[] = (params.row.locations && params.row.locations.length > 0)
          ? params.row.locations.map((location: any) => location.name)
          : [t('GoToLocations')];
        return (
          <TextWordsQuantityCell routerLink={Link}
            item={params.row}
            page={page}
            words={words}
          />
        );
      }
    },
    {
      field: 'groupPermissions',
      headerName: t('HeaderGroupPermissions'),
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        const words: string[] = (params.row.groupPermissions && params.row.groupPermissions.length > 0)
          ? params.row.groupPermissions.map((groupPermission: any) => groupPermission.name)
          : [t('Go_to_groupPermissions')];
        return words.join(', ');
      },
      sortable: false,
      renderCell: (params) => {
        const page: string = BreadcrumbNameMap.GroupPermissionsNurse.url.replace(':actorId', params.row.id.toString());
        const words: string[] = (params.row.groupPermissions && params.row.groupPermissions.length > 0)
          ? params.row.groupPermissions.map((groupPermission: any) => groupPermission.name)
          : [t('Go_to_groupPermissions')];
        return (
          <TextWordsQuantityCell routerLink={Link}
            item={params.row}
            page={page}
            words={words}
          />
        );
      }
    },
    {
      field: 'permissions',
      headerName: t('HeaderPermissions'),
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        const words: string[] = (params.row.permissions && params.row.permissions.length > 0)
          ? params.row.permissions.map((permission: any) => permission.name)
          : [t('Go_to_permissions')];
        return words.join(', ');
      },
      sortable: false,
      renderCell: (params) => {
        const page: string = BreadcrumbNameMap.GroupPermissionsNurse.url.replace(':actorId', params.row.id.toString());
        const words: string[] = (params.row.permissions && params.row.permissions.length > 0)
          ? params.row.permissions.map((permission: any) => permission.name)
          : [t('Go_to_permissions')];
        return (
          <TextWordsQuantityCell routerLink={Link}
            item={params.row}
            page={page}
            words={words}
          />
        );
      }
    },
    {
      field: 'actions',
      headerName: t('Actions'),
      width: 100,
      filterable: false,
      sortable: false,
      renderCell: (params) => actions(params.row)
    },
  ];
}
