import { useState } from "react";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";

export interface RadioItem {
  id: string;
  value: number;
  textValue: string;
  color: string;
  label: string;
  isValueEditable: boolean;
  isTextValueEditable: boolean;
  checked: boolean;
}

interface RadioGroupProps {
  onValueChange: (optionId: string, value: number, valueText: string) => void;
  items: RadioItem[];
  isQuestionForm?: boolean;
}

export default function RadioGroupForm({
  onValueChange,
  items,
  isQuestionForm,
}: RadioGroupProps) {
  const [checked, setChecked] = useState<string>("noselect");

  const onValueChangeRadioGroup = (itemSelrialized: string) => {
    const itemSelected: RadioItem = JSON.parse(itemSelrialized);
    setChecked(itemSelrialized);
    onValueChange(itemSelected.id, itemSelected.value, itemSelected.textValue);
  };

  return (
    <div
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
        marginBlock: "2em",
      }}
    >
      <RadioGroup
        value={checked}
        onChange={(e) => onValueChangeRadioGroup(e.target.value)}
      >
        {items.map((item) => (
          <FormControlLabel
            key={item.value}
            checked={item.checked == undefined ? undefined : item.checked}
            value={JSON.stringify(item)}
            control={<Radio color="info" />}
            label={
              isQuestionForm ? (
                <Typography fontWeight={500}>{item.label}</Typography>
              ) : (
                <Typography
                  fontWeight={500}
                >{`${item.value}   ${item.label}`}</Typography>
              )
            }
            labelPlacement="end"
            style={
              isQuestionForm
                ? {
                    color: "white",
                    margin: "15px",
                    backgroundColor: item.color,
                    borderRadius: "8px",
                    minWidth: "200px",
                    minHeight: "80px",
                  }
                : {
                    color: "white",
                    marginTop: "20px",
                    height: "80px",
                    borderRadius: "8px",
                    backgroundColor: item.color,
                  }
            }
          />
        ))}
      </RadioGroup>
    </div>
  );
}
