import { Box, MenuItem, SelectChangeEvent } from "@mui/material";
import {
  AlertConfigBaseForm,
  AlertConfigBaseProps,
} from "./AlertConfigBaseForm";
import { Device } from "@doctomatic/components-react/build/Graphs/models";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "@doctomatic/components-react/build/AlertConfig/CustomSelect";
import {
  AlertConfigByNoMeasureBaseForm,
  AlertConfigByNoMeasureBaseProps,
} from "./AlertConfigByNoMeasureBaseForm";
import { styles } from "./styles";

export interface AddAlertConfigByNoMeasureProps
  extends AlertConfigBaseProps,
    AlertConfigByNoMeasureBaseProps {
  deviceId: number;
  setDeviceId: (value: number) => void;
  devices: Device[];
}

export const AddAlertConfigByNoMeasure = ({
  deviceId,
  setDeviceId,
  devices,
  label,
  handleOnChangeLabel,
  color,
  handleOnChangeColor,
  time,
  setTime,
  timeUnit,
  setTimeUnit,
  signs,
  requestedSigns,
  setRequestedSigns,
}: AddAlertConfigByNoMeasureProps): React.ReactElement => {
  const { t } = useTranslation();
  const deviceMenuItems: JSX.Element[] =
    devices &&
    devices.map((d: Device, index: number) => (
      <MenuItem key={index} value={d.id.toString()}>
        {t(d.name)}
      </MenuItem>
    ));

  const handleDevideId = (e: SelectChangeEvent): void => {
    setDeviceId(Number(e.target.value));
  };

  return (
    <Box style={styles.titleContainer}>
      <AlertConfigBaseForm
        label={label}
        handleOnChangeLabel={handleOnChangeLabel}
        color={color}
        handleOnChangeColor={handleOnChangeColor}
      />
      <CustomSelect
        value={deviceId?.toString()}
        onChange={handleDevideId}
        menuItems={deviceMenuItems}
        helperText={`${t("FieldDevice")}`}
        required={true}
        minWidth={150}
      />
      <AlertConfigByNoMeasureBaseForm
        time={time}
        setTime={setTime}
        timeUnit={timeUnit}
        setTimeUnit={setTimeUnit}
        signs={signs}
        requestedSigns={requestedSigns}
        setRequestedSigns={setRequestedSigns}
      />
    </Box>
  );
};
