import { GridColumns } from "@mui/x-data-grid";
import TreatmentsDataRow from "../TreatmentsDataRow";
import { TreatmentsGridColumns, TreatmentsGridColumnsProps } from "../TreatmentsGridColumns";

export interface TreatmentsPatientGridColumnsProps extends TreatmentsGridColumnsProps {
  patientId: number;
}

export const TreatmentsPatientGridColumns = ({
  patientId,
  t,
  timezone,
  popoverConf
}: TreatmentsPatientGridColumnsProps): GridColumns<TreatmentsDataRow> => {
  let columns = TreatmentsGridColumns({
    t,
    timezone,
    popoverConf
  }) as GridColumns<TreatmentsDataRow>
  return columns;
};
