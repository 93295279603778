import { Device, Measurement } from "@doctomatic/components-react/build/Graphs/models";
import { ViewModalProps } from "@doctomatic/components-react/build/ViewModalProps";
import AlertSubscribersModal from "@doctomatic/components-react/build/Alerts/AlertSubscribersModal";
import AlertStateModal, { AlertStateModalProps } from "@doctomatic/components-react/build/Alerts/AlertStateModal";
import { AlertState, GetAlertUserAckDto } from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import { toLocaleString } from "../utils";

export interface GraphViewModalProps extends ViewModalProps {
  measurements: Measurement[];
  devices: Device[];
}

export interface AlertSubscribersModalProps extends ViewModalProps {
  timezone: string;
}

interface AckByFormatProps {
  ackBys: { id: number; userId: number; userName: string; createdAt: Date; }[] | undefined;
}

export const formatAckBysForModal = (props: AckByFormatProps) => {
  const ackBy = props && props.ackBys!.map((ack) => {
    return {
      ...props.ackBys,
      user: { name: ack.userName },
      ack_at: ack.createdAt
    }
  })
  return { ackBy };
}

export const showAlertSubscribersModal = (
  props: AlertSubscribersModalProps
): React.ReactElement => {

  return (
    <AlertSubscribersModal
      open={props.open}
      handleClose={props.handleClose}
      subscribers={props.item.ackBy.map((ack: GetAlertUserAckDto) => {
        return {name: ack.user.name, date: toLocaleString(new Date(ack.ack_at))}
      })}
    />
  );
};

const getStateName = (state: string): string => state[0].toUpperCase() + state.substr(1).toLowerCase();

export const showAlertStateModal = (
  props: AlertStateModalProps
): React.ReactElement => {
  const states = Object.values(AlertState)
    .filter((value: unknown) => value !== AlertState.newReadReceived)
    .map((state: unknown) => getStateName(String(state)));
  props.currentState = props.currentState === AlertState.newReadReceived
    ? null
    : {
      ...props.currentState,
      state: getStateName(props.currentState.state)
    }
  return (
    <AlertStateModal
      open={props.open}
      handleClose={props.handleClose}
      states={states}
      currentState={props.currentState} t={props.t}
    />
  );
};


