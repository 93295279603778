var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { FormControl, Box, TextField, Select } from '@mui/material';
import { styles } from "./styles";
var timeInputStyles = {
    formControl: {
        m: 1,
        minWidth: '100%'
    },
    box: {
        display: 'flex'
    },
};
export var TimeInput = function (_a) {
    var time = _a.time, onChangeTime = _a.onChangeTime, timeUnit = _a.timeUnit, onChangeTimeUnit = _a.onChangeTimeUnit, menuItems = _a.menuItems, helperText = _a.helperText, _b = _a.required, required = _b === void 0 ? false : _b, _c = _a.autoWidth, autoWidth = _c === void 0 ? true : _c, _d = _a.error, error = _d === void 0 ? false : _d, _e = _a.maxWidth, maxWidth = _e === void 0 ? '100%' : _e;
    return (React.createElement(FormControl, { required: required, sx: __assign(__assign({}, timeInputStyles.formControl), { maxWidth: maxWidth }) },
        React.createElement(Box, { sx: timeInputStyles.box },
            React.createElement(TextField, { type: 'number', label: helperText, value: time, onChange: onChangeTime, InputProps: { inputProps: { min: 1 } }, style: styles.maxTimeBetweenInputLeft, error: error, required: true }),
            React.createElement(Select, { value: timeUnit, onChange: onChangeTimeUnit, style: styles.maxTimeBetweenInputRight, autoWidth: autoWidth, error: error }, menuItems))));
};
