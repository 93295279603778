var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { List, ListItem, ListItemText } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
export var DragAndDrop = function (props) {
    var _a = useState([]), items = _a[0], setItemsState = _a[1];
    var _b = useState(false), isDragging = _b[0], setIsDragging = _b[1];
    var draggingItem = useRef(null);
    var handleDragStart = useCallback(function (e, item) {
        setIsDragging(true);
        draggingItem.current = item;
        e.dataTransfer.setData("text/plain", "");
    }, []);
    var handleDragEnd = useCallback(function () {
        draggingItem.current = null;
        setIsDragging(false);
    }, []);
    var handleDragOver = useCallback(function (e) {
        e.preventDefault();
    }, []);
    var handleDrop = useCallback(function (e, targetItem) {
        var item = draggingItem.current;
        if (!item || !items)
            return;
        var currentIndex = items.indexOf(item);
        var targetIndex = items.indexOf(targetItem);
        if (currentIndex !== -1 && targetIndex !== -1) {
            var updatedItems = __spreadArray([], items, true);
            updatedItems.splice(currentIndex, 1);
            updatedItems.splice(targetIndex, 0, item);
            // Actualizar el atributo order
            var reorderedItems = updatedItems.map(function (item, index) { return (__assign(__assign({}, item), { order: index })); });
            setItemsState(reorderedItems);
            props.setItems(reorderedItems);
        }
    }, [items, props]);
    useEffect(function () {
        setItemsState(props.items);
    }, [props.items]);
    if (items === undefined)
        return React.createElement(React.Fragment, null);
    return (React.createElement(List, { sx: { width: "100%", maxWidth: 400 } }, items.map(function (item, index) { return (React.createElement(ListItem, { key: item.id, style: {
            opacity: isDragging ? 0.6 : "",
            background: isDragging && item != draggingItem.current ? "#f0f0f0" : "#ffffff",
            border: "1px solid #f0f0f0",
        }, draggable: "true", onDragStart: function (e) { return handleDragStart(e, item); }, onDragEnd: handleDragEnd, onDragOver: handleDragOver, onDrop: function (e) { return handleDrop(e, item); } },
        React.createElement(ListItemText, { id: "item-".concat(index), key: "item-".concat(index), primary: item.name }))); })));
};
