import { Device, Sign } from "@doctomatic/components-react/build/Graphs/models";
import { Box, Button, Modal, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";
import { IUseDevices } from "@doctomatic/sdk/build/modules/Devices";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { SignDto } from "@doctomatic/sdk/build/dto/Sign/Signs";
import { processError } from "../../../../../../App/errorToast";
import { ModalBaseProps } from "../../../../../Modals/modalBaseProps";
import { AddAlertConfigByNoMeasureTemplateRequestDto } from "@doctomatic/sdk/build/dto/Alerts/config/template/AlertConfigByNoMeasureTemplate";
import { useCallback } from "react";
import { AddAlertConfigByNoMeasure } from "../../../../../AlertsConfig/AddAlertConfigByNoMeasureForm";
import { colors } from "../../../../../AlertsConfig/AlertConfigBaseForm";
import { TimeUnit } from "../../../../../AlertsConfig/alertConfig.interface";
import { getDeviceName, getSignName } from "../../../../utils";
import { useNavigate } from "react-router-dom";

interface Props extends ModalBaseProps {
  open: boolean;
  onSave: (
    alertGroup: AddAlertConfigByNoMeasureTemplateRequestDto
  ) => Promise<void>;
  currentLanguage: string;
}

export const AddAlertConfigByNoMeasureModal = (
  props: Props
): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { useDevices, logout }: IProvideApi = useApi();
  const { response: responseApiDevices }: IUseDevices = useDevices(
    true,
    processError(logout, navigate, t)
  );

  const timeUnits: TimeUnit[] = [
    { text: t("hours"), value: "h" },
    { text: t("days"), value: "d" },
  ];

  const [timeUnit, setTimeUnit] = useState<string>(timeUnits[0].value);
  const [devices, setDevices] = useState<Device[]>([]);
  const [signs, setSigns] = useState<Sign[]>([]);
  const [requestedSigns, setRequestedSigns] = useState<any>({});
  const [time, setTime] = useState<number>(1);
  const initialValue = {
    id: 0,
    color: colors[0],
    deviceId: devices && devices.length > 0 ? devices[0].id : 0,
    device: devices && devices.length > 0 ? devices[0] : undefined,
    max_time_between: `${1}${timeUnits[0].value}`,
    label: "",
  };
  const [alertConfig, setAlertConfig] = useState(initialValue);

  const handleAlertConfig = useCallback(
    (value: any, field: string): void => {
      setAlertConfig({ ...alertConfig, [field]: value });
    },
    [alertConfig]
  );

  useEffect(() => {
    if (
      responseApiDevices &&
      responseApiDevices.success === true &&
      responseApiDevices.data!
    ) {
      const devices: Device[] =
        responseApiDevices?.data?.map((device: GetDeviceResponseDto) => {
          const signs: Sign[] = device.signs?.map(
            (sign: SignDto) =>
              ({
                id: sign.id,
                decimals: sign.decimals,
                max: sign.defaultMax,
                min: sign.defaultMin,
                name: getSignName(props.currentLanguage, sign, t),
                unit: sign.unit,
              } as Sign)
          );

          return {
            id: device.id,
            name: getDeviceName(props.currentLanguage, device, t),
            signs: signs,
          } as Device;
        }) ?? [];
      setDevices(devices);
    }
  }, [responseApiDevices]);

  useEffect(() => {
    if (devices && devices.length > 0) {
      const device: Device | undefined = devices.find(
        (d: Device) => d.id === alertConfig?.deviceId
      );
      if (device !== undefined) {
        setSigns(device.signs);
        const signs: any = {};
        setRequestedSigns(signs);
        device.signs &&
          device.signs.forEach((sign: any) => {
            signs[sign.name] = true;
          });
        setRequestedSigns(signs);
      }
    }
  }, [devices, alertConfig.deviceId]);

  useEffect(() => {
    if (devices && devices.length > 0)
      handleAlertConfig(devices[0].id, "deviceId");
  }, [devices]);

  useEffect(() => {
    handleAlertConfig(`${time}${timeUnit}`, "max_time_between");
  }, [time, timeUnit]);

  const handleFormSubmit = async (): Promise<void> => {
    const dto: AddAlertConfigByNoMeasureTemplateRequestDto =
      new AddAlertConfigByNoMeasureTemplateRequestDto();
    Object.assign(dto, alertConfig);
    const requestedSignsFormatted = [];
    for (const property in requestedSigns) {
      if (requestedSigns[property]) {
        requestedSignsFormatted.push(property);
      }
    }
    dto.requestedSigns = requestedSignsFormatted.join(", ");
    props.onSave(dto);
  };

  const handleOnChangeLabel = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleAlertConfig(e.target.value, "label");
  };

  const handleOnColorChange = (e: SelectChangeEvent) => {
    handleAlertConfig(e.target.value, "color");
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          <AddAlertConfigByNoMeasure
            deviceId={alertConfig.deviceId}
            setDeviceId={(value: number) =>
              handleAlertConfig(value, "deviceId")
            }
            devices={devices}
            label={alertConfig.label}
            handleOnChangeLabel={handleOnChangeLabel}
            color={alertConfig.color}
            handleOnChangeColor={handleOnColorChange}
            time={time}
            setTime={setTime}
            timeUnit={timeUnit}
            setTimeUnit={setTimeUnit}
            signs={signs}
            requestedSigns={requestedSigns}
            setRequestedSigns={setRequestedSigns}
          />
          <Box style={styles.btnSubmit}>
            <Button variant="contained" onClick={handleFormSubmit}>
              {t("CreateAlert")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
