import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import {
  Box, Button, TextField, Dialog, DialogActions, DialogContent,
  DialogTitle, FormControl, InputLabel, Select, MenuItem
} from '@mui/material'
import { Role } from '@doctomatic/sdk/build/dto/User'
import { validate, ValidationError } from 'class-validator'
import { Phone } from '../../../Phone'
import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'
import timezones from 'timezones-list';
import { GetDoctorResponseDto, UpdateDoctorRequestDto } from '@doctomatic/sdk/build/dto/Actors/Doctor/Doctor';

interface DoctorEditProps {
  open: boolean,
  getDoctorResponseDto: GetDoctorResponseDto,
  onClose: () => void
  onSave: (doctor: UpdateDoctorRequestDto) => void
}

const useStyles = makeStyles((theme: Theme) => ({ visible: { overflowY: 'visible' } }))

const DoctorEdit = ({ open, getDoctorResponseDto, onClose, onSave }: DoctorEditProps): React.ReactElement => {
  const { t } = useTranslation()
  const [name, setName] = useState<string | undefined>(getDoctorResponseDto.name)
  const [email, setEmail] = useState<string | undefined>(getDoctorResponseDto.email)
  const [phone, setPhone] = useState<string | undefined>(getDoctorResponseDto.phone)
  const [licenseNumber, setLicenseNumber] = useState<string | undefined>(getDoctorResponseDto.licenseNumber)
  const [timezone, setTimezone] = useState<string | undefined>(getDoctorResponseDto.timezone)
  const [errors, setErrors] = useState<ValidationError[]>([])
  const classes = useStyles()

  useEffect(() => {
    const checkErrors = async () => {
      const dto = new UpdateDoctorRequestDto()
      dto.name = name;
      const errors = await validate(dto);
      setErrors(errors)
    }

    checkErrors()
  }, [open, name])

  if (!getDoctorResponseDto) return <></>

  let doctorToEdit: GetDoctorResponseDto = getDoctorResponseDto;

  let alert = ''
  let cameraAlert = ''
  let notificationsAlert = ''


  const nameError = Boolean(errors.find(e => e.property === 'name'))

  if (!doctorToEdit.hasPassword) alert = t('UserEditPasswordAlert')
  if (doctorToEdit.role === Role.doctor && !doctorToEdit.cameraPermission) cameraAlert = t('UserCameraPermissionAlert')

  return (
    <Dialog open={open} fullWidth={true} maxWidth='sm' classes={{ paper: classes.visible }}>
      <DialogTitle>
        {/* {t('ButtonEdit')} <span>{doctorToEdit.role === Role.doctor ? t('FieldDoctor') : t('FieldPatient')}</span> */}
        {t('ButtonEdit')} <span>{t('FieldDoctor')}</span>
      </DialogTitle>

      <DialogContent className={classes.visible}>
        <TextField autoComplete='off' error={nameError} margin='dense' variant='outlined' name='name' label={t('FieldName')} type='text' fullWidth onChange={(event) => setName(event.target.value)} value={name} autoFocus required />
        <TextField autoComplete='off' margin='dense' variant='outlined' name='email' label={t('FieldEmail')} type='email' fullWidth onChange={(event) => setEmail(event.target.value)} value={email} required disabled />
        <Phone onUpdate={(value) => setPhone(value)} value={phone ? phone : ''} disabled />
        <TextField autoComplete='off' margin='dense' variant='outlined' name='licenseNumber' label={t('FieldLicenseNumber')} type='text' fullWidth onChange={(event) => setLicenseNumber(event.target.value)} value={licenseNumber} disabled />
        <FormControl variant="outlined" fullWidth margin='normal' required>
          <InputLabel>{t('FieldTimezone')}</InputLabel>
          <Select
            label={t('FieldTimezone')}
            variant='outlined'
            onChange={(event) => setTimezone(event.target.value as string)}
            value={timezone}>
            {timezones.map(t => <MenuItem key={t.tzCode} value={t.tzCode}>{t.label}</MenuItem>)}
          </Select>
        </FormControl>
        {/* { role === Role.admin && doctorToEdit.doctorId && <DoctorData id={doctorToEdit.doctorId} />}         */}
        {alert !== '' && <Box mt={2}><Alert severity="warning">{alert}</Alert></Box>}
        {cameraAlert !== '' && <Box mt={2}><Alert severity="warning">{cameraAlert}</Alert></Box>}
        {notificationsAlert !== '' && <Box mt={2}><Alert severity="warning">{notificationsAlert}</Alert></Box>}

      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display='inline-block'>
            <Button onClick={onClose} size='small'>{t('ButtonCancel')}</Button>
          </Box>
          <Button disabled={errors.length > 0} onClick={() => {
            let dto = new UpdateDoctorRequestDto()
            if (doctorToEdit.email !== email) dto.email = email
            if (doctorToEdit.name !== name) dto.name = name
            if (doctorToEdit.phone !== phone) dto.phone = phone
            if (doctorToEdit.timezone !== timezone) dto.timezone = timezone
            if (doctorToEdit.licenseNumber !== licenseNumber) dto.licenseNumber = licenseNumber
            onSave(dto)
          }} variant='contained' size='small'>{t('ButtonSave')}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}


export { DoctorEdit }



