var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { FormControl, FormHelperText, Switch } from '@mui/material';
import { styles } from "./styles";
export var CustomSwitch = function (_a) {
    var checked = _a.checked, onChange = _a.onChange, helperText = _a.helperText, _b = _a.required, required = _b === void 0 ? false : _b, _c = _a.minWidth, minWidth = _c === void 0 ? 120 : _c, _d = _a.fontSize, fontSize = _d === void 0 ? 16 : _d;
    return (React.createElement(FormControl, { required: required, sx: { m: 1, minWidth: minWidth, alignItems: 'center' } },
        helperText !== null && helperText !== undefined && (React.createElement(FormHelperText, { variant: 'outlined', style: __assign(__assign({}, styles.formHelperText), { fontSize: fontSize }) }, helperText)),
        React.createElement(Switch, { checked: checked, onChange: onChange })));
};
