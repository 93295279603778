import React, { useState } from "react";
import {
  TextField,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Gender,
  Role,
  UpdateProfileRequestDto,
} from "@doctomatic/sdk/build/dto/User";
import { toast } from "react-toastify";
import { PasswordForm } from "./PasswordForm";
import { useApi } from "@doctomatic/sdk/build/Api";
import timezones from "timezones-list";
import { useTranslation } from "react-i18next";
import { GetAdminCompanyResponseDto } from "@doctomatic/sdk/build/dto/Actors/AdminCompany/AdminCompany";
import { GetDoctorResponseDto } from "@doctomatic/sdk/build/dto/Actors/Doctor/Doctor";
import { GetNurseResponseDto } from "@doctomatic/sdk/build/dto/Actors/Nurse/Nurse";
import { GetPatientResponseDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { GetSuperadminResponseDto } from "@doctomatic/sdk/build/dto/Superadmin";
import { processError } from "../../../../App/errorToast";
import { useNavigate } from "react-router-dom";
interface ProfileProps {
  profile:
    | GetPatientResponseDto
    | GetDoctorResponseDto
    | GetNurseResponseDto
    | GetAdminCompanyResponseDto
    | GetSuperadminResponseDto;
}

const DefaultForm = ({ profile }: ProfileProps): React.ReactElement => {
  const navigate = useNavigate();
  const { useProfile, logout, refreshTokens, setToken } = useApi();
  const { t } = useTranslation();
  const { update, mutate } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const genders = Object.keys(Gender);
  const [name, setName] = useState(profile.name);
  const [email, setEmail] = useState(profile.email);
  const [timezone, setTimezone] = useState(profile.timezone);
  const [birthYear, setBirtYear] = useState(profile.birthYear);
  const [gender, setGender] = useState(
    (profile as GetPatientResponseDto).gender?.toString()
  );
  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSaving(true);
    try {
      let user = new UpdateProfileRequestDto();
      user.email = email;
      user.name = name;
      user.timezone = timezone;
      user.birthYear = birthYear;
      if (profile.role === Role.user && gender) {
        user.gender = gender as Gender;
      }
      await update(user);
      const refreshToken = localStorage.getItem("refreshToken");
      let { accessToken, refreshToken: refreshTokenNew } = await refreshTokens(
        refreshToken || "",
        user
      );
      localStorage.setItem("token", accessToken || "");
      localStorage.setItem("refreshToken", refreshTokenNew || "");
      setToken(accessToken || "");
      mutate();
      toast.success(t("ProfileUpdated"));
    } catch (err: any) {
      setSaving(false);
      throw err;
      // toast.error(`${t('ErrorEdit')}: ${err.response?.data?.message || err.message}`)
    }
    setSaving(false);
  };

  return (
    <Box maxWidth={400}>
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          required
          fullWidth
          margin="normal"
          label={t("FieldName")}
          name="name"
          variant="outlined"
          onChange={(e) => setName(e.target.value)}
          value={name}
          autoFocus
        />
        <TextField
          required
          fullWidth
          margin="normal"
          label={t("FieldEmail")}
          name="email"
          variant="outlined"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          label={t("FieldBirthYear")}
          name="birthYear"
          variant="outlined"
          onChange={(e) => setBirtYear(+e.target.value)}
          value={birthYear}
        />

        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel>{t("FieldTimezone")}</InputLabel>
          <Select
            label={t("FieldTimezone")}
            variant="outlined"
            onChange={(event) => setTimezone(event.target.value as string)}
            value={timezone}
          >
            {timezones.map((t) => (
              <MenuItem key={t.tzCode} value={t.tzCode}>
                {t.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {profile.role === Role.user && (
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel>{t("Gender")}</InputLabel>
            <Select
              label={t("Gender")}
              variant="outlined"
              onChange={(event) => setGender(event.target.value as string)}
              value={gender}
            >
              {genders.map((key, index) => {
                const genderFormatted = key[0].toUpperCase() + key.slice(1);
                return (
                  <MenuItem key={index} value={key}>
                    {t(genderFormatted)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}

        <Box display="flex" justifyContent="flex-end">
          <Button type="submit" variant="contained" disabled={saving}>
            {t("ButtonSave")}
          </Button>
        </Box>
      </form>
      <Box mt={3}>
        <Button variant="outlined" onClick={() => setOpen(true)}>
          {t("ButtonChangePassword")}
        </Button>
      </Box>

      <Dialog open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>{t("ButtonChangePassword")}</DialogTitle>

        <DialogContent>
          <Box mb={4}>
            <PasswordForm
              onClose={() => setOpen(false)}
              onSave={() => setOpen(false)}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export { DefaultForm };
