import { CustomSwitch } from "@doctomatic/components-react/build/AlertConfig/CustomSwitch";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styles } from "./styles";

interface SubscriptionChecksProps {
  sms: boolean;
  setSms: (value: boolean) => void;
  email: boolean;
  setEmail: (value: boolean) => void;
  subscribe: boolean;
  setSubscribe: (value: boolean) => void;
  pushNotification: boolean;
  setPushNotification: (value: boolean) => void;
  messengerChannel: string;
}

export const SubscriptionChecks = ({
  sms,
  setSms,
  email,
  setEmail,
  subscribe,
  setSubscribe,
  pushNotification,
  setPushNotification,
  messengerChannel,
}: SubscriptionChecksProps): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <Box style={styles.switchesContainer}>
      <CustomSwitch
        checked={pushNotification}
        onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
          setPushNotification(checked)
        }
        helperText={`${t("FieldPushNotifyPatient")}*`}
        required={true}
        minWidth={180}
      />
      {JSON.parse(messengerChannel).sms && (
        <CustomSwitch
          checked={sms}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
          ) => setSms(checked)}
          helperText={`${t("FieldSMSNotifyPatient")}*`}
          required={true}
          minWidth={180}
        />
      )}
      {JSON.parse(messengerChannel).email && (
        <CustomSwitch
          checked={email}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
          ) => setEmail(checked)}
          helperText={`${t("FieldEmailNofityPatient")}*`}
          required={true}
          minWidth={200}
        />
      )}
      <CustomSwitch
        checked={subscribe}
        onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
          setSubscribe(checked)
        }
        helperText={`${t("FieldSubscribe")}*`}
        required={true}
        minWidth={200}
      />
    </Box>
  );
};
