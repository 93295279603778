var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var datePickerStyles = {
    height: '100%',
    display: 'inline-flex',
    fontSize: '1rem',
    alignItems: 'center',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 400,
    width: 'fit-content',
};
export var customDatePicker = __assign(__assign({}, datePickerStyles), { "> div.react-datepicker-wrapper": datePickerStyles, "> div > div.react-datepicker__input-container": datePickerStyles, "> div > div.react-datepicker__input-container input": __assign(__assign({}, datePickerStyles), { border: '1px solid #c4c4c4', borderRadius: '4px', padding: '16.5px 14px', ":hover": {
            border: '1px solid #000'
        }, ":focus": {
            outline: 'none',
            border: '2px solid #2196F3'
        } }) });
