import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (text: string | undefined) => void;
  description: string | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  visible: { overflowY: "visible" },
}));

const DescriptionModal = ({
  open,
  onClose,
  onSave,
  description,
}: Props): React.ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [text, setText] = useState<string | undefined>(description);

  const handleOnClose = () => {
    // Restore status onClose
    setText(description);
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={() => handleOnClose()}
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.visible }}
    >
      <DialogTitle>{t("DescriptionTitle")}</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          rows={4}
          value={text}
          onChange={(e) => setText(e.target.value)}
          margin="dense"
          variant="outlined"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={() => handleOnClose()}>{t("Cancel")}</Button>
          </Box>
          <Button variant="contained" onClick={() => onSave(text)}>
            {t("ButtonSave")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { DescriptionModal };
