import "reflect-metadata"
import React, { StrictMode } from 'react'
import { App } from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { ProvideApi } from '@doctomatic/sdk/build/Api'
import { ProvideLoading } from './components/Loading/Loading'
import { Theme } from './components/Theme'
import './i18n'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from "@sentry/tracing";
import 'react-toastify/dist/ReactToastify.css'
import "react-datepicker/dist/react-datepicker.css";
import { WEB_VERSION } from './version'
import { createRoot } from 'react-dom/client';

if (process.env.SENTRY_ENABLED === 'true'){
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    release:WEB_VERSION
  })  
}


const container = document.getElementById('root');

const root = createRoot(container!);
root.render(
  <StrictMode>
  <Router>
    <ProvideApi baseURL={process.env.REACT_APP_API_URL || ''}>
      <Theme>
        <ProvideLoading>   
          <App />
        </ProvideLoading>
      </Theme>
    </ProvideApi>
  </Router>
</StrictMode>
)

