import { ReactElement, useState } from "react";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { IGetTreatmentResponseDto } from "@doctomatic/sdk/build/dto/Treatment/ITreatments";
import { GridColumns } from "@mui/x-data-grid";
import {
  TreatmentsPatientGridColumns,
  TreatmentsPatientGridColumnsProps,
} from "./TreatmentsPatientGridColumns";
import { IUseProfile } from "@doctomatic/sdk/build/modules/Profile";
import { processError } from "../../../../App/errorToast";
import { IUsePatientWithTreatments } from "@doctomatic/sdk/build/modules/PatientWithTreatments";
import { Treatments } from "../Treatments";
import TreatmentsDataRow from "../TreatmentsDataRow";
import { useNavigate } from "react-router-dom";

export const TreatmentsPatient = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    useProfile,
    usePatientWithTreatments,
    logout,
    timezone,
    id,
  }: IProvideApi = useApi();
  const { response: profile }: IUseProfile = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const [showEnded, setShowEnded] = useState<boolean>(false);

  const { response: patientResponse }: IUsePatientWithTreatments =
    usePatientWithTreatments(id, showEnded, true, processError(logout, navigate, t));
  const patient = patientResponse?.data;

  const treatmentsGridColumns: GridColumns<TreatmentsDataRow> =
    TreatmentsPatientGridColumns({
      patientId: id,
      t,
      timezone,
    } as TreatmentsPatientGridColumnsProps);

  const treatmentsDataRows: TreatmentsDataRow[] =
    patient?.treatments.map(
      (treatment: IGetTreatmentResponseDto, index: number) =>
        ({
          id: treatment.id,
          patient: patient,
          name: treatment.name,
          start: new Date(treatment.createdAt),
          end: treatment.closed_at ? new Date(treatment.closed_at) : undefined,
          lastMeasurement: treatment.lastMeasurement
            ? new Date(treatment.lastMeasurement)
            : undefined,
          alert: treatment.alert ? new Date(treatment.alert) : undefined,
        } as TreatmentsDataRow)
    ) ?? [];

  const getBreadCrumbProps = (actorName: string): BreadcrumbProps =>
    ({
      breadcrumbItems: [
        { url: "/profile", name: actorName },
        { url: "", name: t("TreatmentsTitle") },
      ],
    } as BreadcrumbProps);

  let actorName = profile?.data?.name ? profile!.data!.name : "";

  return (
    <Treatments
      breadcrumbs={getBreadCrumbProps(actorName)}
      treatmentsDataRows={treatmentsDataRows}
      treatmentsGridColumns={treatmentsGridColumns}
      treatments={patient?.treatments}
      showEnded={showEnded}
      setShowEnded={setShowEnded}
    />
  );
};
