import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from '@doctomatic/components-react/build/DataGrid/DataGrid'
import { DoctomaticStylingRowsGrid } from "../../../Theme/ThemeDataGrid";
import { ExternalReferencePatientsGridColumns } from "./ExternalReferencePatientsGridColumns";
import TableSkeleton from "../../../Skeleton/TableSkeleton";

type Props = {
    actors: any;
    isLoading: boolean;
    pagination?: any;
    onPageChange: (newPageNumber: number) => void;
    onPageSizeChange: (newPageSize: number) => void;
    setSelectedUserId: (userId: number) => void;
}

export default function ExternalReferencesList({ actors, isLoading, setSelectedUserId, onPageChange, onPageSizeChange, pagination }: Props) {
    const { t, i18n } = useTranslation();

    const onEdit = (userId: number) => {
        setSelectedUserId(userId);
    }

    const actorGridColumns = ExternalReferencePatientsGridColumns(t, onEdit);
    const actorsDataRow: any[] = actors?.map((patient: any) => {
        const actorDataRow: any = {
            id: patient.id,
            name: patient.name,
            role: patient.role
        }
        return actorDataRow;
    }) || [];

    return (
        <div>
            {isLoading ?
                <TableSkeleton numRows={10} />
                : <FlexLayoutGrid
                    language={i18n.language}
                    columns={actorGridColumns}
                    rows={actorsDataRow}
                    sx={DoctomaticStylingRowsGrid()}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    pagination={pagination}
                    isLoading={isLoading}
                ></FlexLayoutGrid>}
        </div>
    )
}
