import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { FormControl, FormHelperText } from '@mui/material'
import useStyles from './styles'
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'
import './Phone.css'

interface PhoneProps {
  value: string | null
  error?: boolean
  onUpdate: (formattedValue: string) => void
  disabled?: boolean
  helperText?: string
}

interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

const Phone = ({ value, error, onUpdate, disabled, helperText }: PhoneProps): React.ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()

  const updatePhone = async (value: string, data: CountryData | {}, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
    await onUpdate(formattedValue)
  }

  return (
    <FormControl className={classes.formControl}>
      <PhoneInput
        buttonClass={clsx({[classes.error]: Boolean(error)} )}
        inputClass={clsx({[classes.error]: Boolean(error)} )}
        containerClass={classes.borderClass}
        preferredCountries={['es', 'pt', 'br']}
        specialLabel={t('FieldPhone') + '*'}
        country={'es'}
        onChange={updatePhone}
        value={value}
        countryCodeEditable={false}
        inputProps={{required: true}}
        disabled={disabled}
      />
      {
        helperText && (
          <FormHelperText className={classes.errorText}>{helperText}</FormHelperText>
        )
      }
    </FormControl>
  )
}

export { Phone }
