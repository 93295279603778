import React, { useEffect, useRef } from "react";
import QRCode from "qrcode";
import DefaultLogo from "../../assets/logo.png";
import { drawLogo } from "./drawLogo";

export enum ErrorLevel {
  low = "L",
  medium = "M",
  quartile = "Q",
  high = "H",
}

export interface QRCodeOptions {
  width: number;
  colorDark?: string;
  colorLight?: string;
  errorCorrectionLevel?: ErrorLevel;
}

interface QRCodeProps {
  QRString: string;
  options: QRCodeOptions;
  logo?: { img: string; width?: number; margin?: number };
  hideLogo?: boolean;
  setCanvas?: (qrCode: HTMLCanvasElement) => any;
}

const QRCodeCanvas: React.FC<QRCodeProps> = ({
  QRString,
  options,
  logo,
  hideLogo,
  setCanvas,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const colorLight = options.colorLight ?? "#ffffff";
  const colorDark = options.colorDark ?? "#000000";

  const generateQR = async () => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    const containerWidth = canvas.parentElement?.clientWidth ?? options.width;
    const scale = window.devicePixelRatio || 1;
    canvas.width = containerWidth * scale;
    canvas.height = containerWidth * scale;

    // Establecer el tamaño visual del canvas
    canvas.style.width = `${containerWidth}px`;
    canvas.style.height = `${containerWidth}px`;

    // Escalar el contexto para la correcta relación de aspecto
    ctx.scale(scale, scale);

    // Generar el código QR en el canvas
    await QRCode.toCanvas(canvas, QRString, {
      errorCorrectionLevel: options.errorCorrectionLevel ?? ErrorLevel.high,
      margin: 1,
      color: {
        dark: colorDark,
        light: colorLight,
      },
    });

    // Dibujar el logo si no está oculto
    if (!hideLogo) {
      const logoImg = logo?.img ?? DefaultLogo;
      const img = new Image();
      img.src = logoImg;
      img.onload = () => {
        const margin = logo?.margin ?? Math.round(canvas.width * 0.05); // Margen alrededor del logo
        const aspectRatio = img.width / img.height;
        const logoWidth = logo?.width ?? containerWidth * 0.15;
        const logoHeight = logoWidth / aspectRatio;
        const centerX = (canvas.width - logoWidth) / 2;
        const centerY = (canvas.height - logoHeight) / 2;

        drawLogo(
          ctx,
          logoImg,
          logoWidth,
          logoHeight,
          centerX,
          centerY,
          colorLight,
          margin
        );
      };
    }
  };

  useEffect(() => {
    generateQR();
    window.addEventListener("resize", generateQR);
    return () => window.removeEventListener("resize", generateQR);
  }, [QRString, options, logo]);

  useEffect(() => {
    if (setCanvas && canvasRef.current) {
      setCanvas(canvasRef.current);
    }
  }, [setCanvas, canvasRef.current]);

  return (
    <div
      className="canvas-container"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <canvas ref={canvasRef} />
    </div>
  );
};

export default QRCodeCanvas;
