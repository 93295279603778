import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { CreateDiseaseRequestDto } from "@doctomatic/sdk/build/dto/Diseases";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { processError } from "../../../../App/errorToast";
import { getDeviceName } from "../../utils";
import { useNavigate } from "react-router-dom";

interface TrackingTypeCreateProps {
  open: boolean;
  onClose: () => void;
  onSave: (dto: CreateDiseaseRequestDto) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  visible: { overflowY: "visible" },
}));

const TrackingTypeCreate = ({
  open,
  onClose,
  onSave,
}: TrackingTypeCreateProps): React.ReactElement => {
  const { useDevices, logout } = useApi();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { response: allDevicesResponse } = useDevices(
    true,
    processError(logout, navigate, t)
  );
  const allDevices = allDevicesResponse?.data;
  const [name, setName] = useState("");
  const [stripePriceId, setStripePriceId] = useState("");
  const [devices, setDevices] = useState<number[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  const classes = useStyles();

  const handleDeviceChange = async (checked: boolean, deviceId: number) => {
    if (checked) {
      setDevices([...devices, deviceId]);
    } else {
      setDevices(devices.filter((d) => d !== deviceId));
    }
  };

  useEffect(() => {
    setName("");
    setStripePriceId("");
    setDevices([]);
  }, [open]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.visible }}
    >
      <DialogTitle>{t("DiseasesCreateTitle")}</DialogTitle>

      <DialogContent className={classes.visible}>
        <TextField
          margin="dense"
          variant="outlined"
          name="name"
          label={t("FieldName")}
          type="text"
          fullWidth
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <TextField
          margin="dense"
          variant="outlined"
          name="priceId"
          label={t("FieldStripePriceId")}
          type="text"
          fullWidth
          onChange={(e) => setStripePriceId(e.target.value)}
          value={stripePriceId}
        />
        <FormControl
          component="fieldset"
          style={{
            marginTop: 10,
            marginBottom: 10,
            marginRight: 20,
            marginLeft: 20,
          }}
        >
          <FormGroup>
            {allDevices?.map((d) => (
              <FormControlLabel
                key={d.id}
                control={
                  <Checkbox
                    name={getDeviceName(currentLanguage, d, t)}
                    checked={devices.includes(d.id)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleDeviceChange(event.target.checked, d.id)
                    }
                  />
                }
                label={getDeviceName(currentLanguage, d, t)}
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={onClose} size="small">
              {t("ButtonCancel")}
            </Button>
          </Box>
          <Button
            onClick={() => {
              const dto = new CreateDiseaseRequestDto();
              dto.name = name;
              dto.devices = devices;
              dto.stripePriceId = stripePriceId;
              onSave(dto);
            }}
            variant="contained"
            size="small"
          >
            {t("ButtonSave")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { TrackingTypeCreate };
