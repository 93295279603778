import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  GetFrequencyResponseDto,
  Weekday,
} from "@doctomatic/sdk/build/dto/Frequencies";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { IProvideLoading, useLoading } from "../../../Loading/Loading";
import { toast } from "react-toastify";
import { Frequency } from "@doctomatic/components-react/build/Frequencies/Frequency";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import { GetDiseaseResponseDto } from "@doctomatic/sdk/build/dto/Diseases";
import { IUseDiseases } from "@doctomatic/sdk/build/modules/Diseases";
import {
  GetFrequenciesTemplateResponseDto,
  UpdateFrequenciesTemplateRequestDto,
} from "@doctomatic/sdk/build/dto/FrequenciesTemplate/FrequenciesTemplate";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { UpdateDiseaseRequestDto } from "@doctomatic/sdk/build/dto/Diseases";
import { minStringToNumbers } from "../../../../utils/frequencies";
import { getDeviceName } from "../../utils";

const frequenciesTemplateToDTO = (
  frequenciesTemplate: GetFrequenciesTemplateResponseDto
): UpdateFrequenciesTemplateRequestDto =>
  ({
    deviceId: frequenciesTemplate.device?.id,
    weekdays: frequenciesTemplate.weekdays,
    min: frequenciesTemplate.min,
  } as UpdateFrequenciesTemplateRequestDto);

interface Props {
  open: boolean;
  disease: GetDiseaseResponseDto;
  onClose: () => void;
  afterSave: () => Promise<void>;
}

const FrequenciesTemplateModal = ({
  open,
  disease,
  onClose,
  afterSave,
}: Props): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const week: { [key: string]: string } = {
    [Weekday.Monday]: t("Monday"),
    [Weekday.Tuesday]: t("Tuesday"),
    [Weekday.Wednesday]: t("Wednesday"),
    [Weekday.Thursday]: t("Thursday"),
    [Weekday.Friday]: t("Friday"),
    [Weekday.Saturday]: t("Saturday"),
    [Weekday.Sunday]: t("Sunday"),
  };
  const { useDiseases }: IProvideApi = useApi();
  const { setIsLoading }: IProvideLoading = useLoading();
  const { patch, mutate }: IUseDiseases = useDiseases();
  const [frequencies, setFrequencies] = useState<
    UpdateFrequenciesTemplateRequestDto[]
  >(
    disease.frequenciesTemplates?.map(
      (f: GetFrequenciesTemplateResponseDto) =>
        frequenciesTemplateToDTO(f) as UpdateFrequenciesTemplateRequestDto
    ) ?? []
  );
  const [errors, setErrors] = useState<{ [id: string]: boolean }>({});
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    disease?.frequenciesTemplates &&
      disease.frequenciesTemplates.length > 0 &&
      setFrequencies(
        disease.frequenciesTemplates?.map(
          (f: GetFrequenciesTemplateResponseDto) =>
            frequenciesTemplateToDTO(f) as UpdateFrequenciesTemplateRequestDto
        )
      );
  }, [disease]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <Dialog open={open} maxWidth={false}>
      <DialogTitle>{t("FrequencyTitle", { name: disease.name })}</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table style={{ tableLayout: "fixed", width: "1080px" }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "100px", paddingLeft: 0 }}>
                  {t("TableDevice")}
                </TableCell>
                <TableCell style={{ width: "200px" }}>
                  {t("TableFrequency")}
                </TableCell>
                <TableCell style={{ width: "680px", padding: "16px 8px" }}>
                  {t("TableHours")}
                </TableCell>
                <TableCell style={{ width: "100px", padding: 0 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {disease.devices?.map(
                (device: GetDeviceResponseDto, index: number) => {
                  let frequenciesTemplate:
                    | UpdateFrequenciesTemplateRequestDto
                    | undefined;
                  let frequency: GetFrequencyResponseDto | undefined;

                  frequenciesTemplate = frequencies.find(
                    (f) => f.deviceId === device.id
                  );
                  if (frequenciesTemplate) {
                    frequency = new GetFrequencyResponseDto();
                    frequency.device = { ...device } as GetDeviceResponseDto;
                    frequency.weekdays = frequenciesTemplate.weekdays;
                    frequency.min = frequenciesTemplate.min;
                  }

                  const initialWeekdays: string[] =
                    frequency?.weekdays && frequency.weekdays !== ""
                      ? frequency.weekdays.split(",")
                      : [];
                  const initialMin: number[] =
                    frequency?.min && frequency.min !== ""
                      ? minStringToNumbers(frequency.min)
                      : [0];

                  return (
                    <TableRow key={`d${device.id}`}>
                      <TableCell style={{ paddingLeft: 0, height: "81px" }}>
                        {getDeviceName(currentLanguage, device, t)}
                      </TableCell>
                      {frequenciesTemplate && frequency ? (
                        <>
                          <Frequency
                            initialWeekdays={initialWeekdays}
                            initialMin={initialMin}
                            week={week}
                            onChange={async (d: string[], m: number[]) => {
                              let f: UpdateFrequenciesTemplateRequestDto =
                                new UpdateFrequenciesTemplateRequestDto();
                              f.deviceId = device.id;
                              f.weekdays =
                                d.length > 1 ? d.join(",") : d[0] ?? "";
                              f.min = m.length > 1 ? m.join(",") : String(m[0]);
                              setFrequencies([
                                ...frequencies.filter(
                                  (f) => f.deviceId !== device.id
                                ),
                                f,
                              ]);
                              await mutate();
                            }}
                            onValidate={(val: boolean, msg: string) => {
                              setErrors({ ...errors, [device.id]: !val });
                            }}
                            translator={t}
                          />
                          <TableCell style={{ padding: 0 }}>
                            <Box display="flex">
                              <Tooltip
                                placement="top"
                                title={t("FrequencyDelete") || ""}
                              >
                                <IconButton
                                  style={{ padding: "8px" }}
                                  onClick={async () => {
                                    setFrequencies(
                                      frequencies.filter(
                                        (f) => f.deviceId !== device.id
                                      )
                                    );
                                    const { [device.id]: _, ...newErrors } =
                                      errors;
                                    setErrors(newErrors);
                                    await mutate();
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </>
                      ) : (
                        <TableCell colSpan={6}>
                          <Button
                            variant="text"
                            startIcon={<AddCircleIcon />}
                            onClick={async () => {
                              let f: UpdateFrequenciesTemplateRequestDto =
                                new UpdateFrequenciesTemplateRequestDto();
                              f.deviceId = device.id;
                              f.weekdays = "";
                              f.min = "0";
                              setFrequencies([...frequencies, f]);
                              await mutate();
                            }}
                          >
                            {t("FrequencyAdd")}
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={onClose} size="small">
              {t("ButtonCancel")}
            </Button>
          </Box>
          <Button
            disabled={Object.values(errors).includes(true)}
            variant="contained"
            size="small"
            onClick={async () => {
              setIsLoading(true);
              try {
                let dto: UpdateDiseaseRequestDto =
                  new UpdateDiseaseRequestDto();
                dto.devices = disease.devices.map((d: GetDeviceResponseDto) => {
                  let updateDto: UpdateFrequenciesTemplateRequestDto =
                    new UpdateFrequenciesTemplateRequestDto();
                  updateDto.deviceId = d.id;
                  const freq: UpdateFrequenciesTemplateRequestDto | undefined =
                    frequencies.find(
                      (f: UpdateFrequenciesTemplateRequestDto) =>
                        d.id === f.deviceId
                    );
                  if (freq) {
                    updateDto.weekdays = freq.weekdays;
                    updateDto.min = freq.min;
                  }
                  return updateDto;
                });

                disease && (await patch(disease.id, dto));
                toast.success(`${t("FrequencySaved")}`);
              } catch (err: any) {
                toast.error(
                  `${t("FrequencyError")}: ${
                    err.response?.data?.message || err.message
                  }`
                );
              }
              await afterSave();
              setIsLoading(false);
            }}
          >
            {t("ButtonSave")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { FrequenciesTemplateModal };
