import React, { useEffect, useMemo, useState } from "react";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { GetAdminCompanyResponseDto } from "@doctomatic/sdk/build/dto/Actors/AdminCompany/AdminCompany";
import { GetDoctorResponseDto } from "@doctomatic/sdk/build/dto/Actors/Doctor/Doctor";
import { GetNurseResponseDto } from "@doctomatic/sdk/build/dto/Actors/Nurse/Nurse";
import { GetPatientResponseDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { GetSuperadminResponseDto } from "@doctomatic/sdk/build/dto/Superadmin";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { IUsePatient } from "@doctomatic/sdk/build/modules/Patient";
import { IUseProfile } from "@doctomatic/sdk/build/modules/Profile";
import { AddCircle } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { GridColumns, GridRowClassNameParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Page } from "../../Page";
import AlertConfigByFrequencyDataRow from "./AlertConfigByFrequencyDataRow";
import {
  AlertConfigByFrequencyGridColumns,
  IAlertConfigByFrequencyGridColumnsProps,
} from "./AlertConfigByFrequencyGridColumns";
import { AddAlertConfigByFrequencyModal } from "./ManageAlertConfigByFrequency/AddAlertConfigByFrequencyModal";
import { UpdateAlertConfigByFrequencyModal } from "./ManageAlertConfigByFrequency/UpdateAlertConfigByFrequencyModal";
import { processError } from "../../../../App/errorToast";
import {
  ItemAlertConfigByFrequencyIndividualDto,
  UpdateAlertConfigByFrequencyIndividualRequestDto,
} from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByFrequencyIndividual";
import {
  ActiveAlertFilter,
  ModeActivefilter,
} from "@doctomatic/components-react/build/ActiveAlertFilter/ActiveAlertFilter";
import { DoctomaticStylingRowsGrid } from "../../../Theme/ThemeDataGrid";
import { checkAlertConfigByFrequencyPathParam } from "../../utils";
import { IUseAlertConfigsByFrequencyIndividual } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByFrequency";
import { IUseAlertConfigsByFrequencyByPatient } from "@doctomatic/sdk/build/modules/AlertConfigsByFrequencyByPatient";

type IAlertConfigByFrequency = {
  patientId: string;
};

export const AlertConfigByFrequency = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const { patientId }: IAlertConfigByFrequency = useParams();
  const { t, i18n } = useTranslation();
  const [mode, setMode] = useState<ModeActivefilter>(ModeActivefilter.all);
  const {
    companyId,
    useCompany,
    useProfile,
    usePatient,
    useAlertConfigsByFrequencyByPatient,
    useAlertConfigsByFrequency,
    logout,
  }: IProvideApi = useApi();
  const { response: patient }: IUsePatient = usePatient(
    +patientId,
    true,
    processError(logout, navigate, t)
  );
  const { response: profile }: IUseProfile = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );

  const { response: company } = useCompany(
    companyId,
    true,
    processError(logout, navigate, t)
  );

  const [open, setOpen] = useState<boolean>(false);

  // declaration of hooks to handle pagination
  const [pageSizeByFrequency, setPageSizeByFrequency] = useState<number>(10);
  const [pageByFrequency, setPageByFrequency] = useState<number>(1);

  // Store the pagination value
  // Update pagination when page or pageSize changes
  const paginationFrequency = useMemo(() => {
    return {
      page: pageByFrequency,
      limit: pageSizeByFrequency,
    };
  }, [pageByFrequency, pageSizeByFrequency]);

  useEffect(() => {
    setPageByFrequency(1);
  }, [mode]);

  const {
    response: alertConfigsResponse,
    mutate,
  }: IUseAlertConfigsByFrequencyByPatient = useAlertConfigsByFrequencyByPatient(
    +patientId,
    paginationFrequency,
    mode,
    true,
    processError(logout, navigate, t)
  );
  const { patch }: IUseAlertConfigsByFrequencyIndividual =
    useAlertConfigsByFrequency();

  const alertConfigs = alertConfigsResponse?.data;
  const alertConfigsByFrequencyPaginationInfo = alertConfigsResponse?.meta;

  const handleOpenAdd = () => setOpen(true);
  const handleCloseAdd = async () => {
    await mutate();
    setOpen(false);
  };
  const [openAlertConfigEdit, setOpenAlertConfigEdit] =
    useState<boolean>(false);
  const handleCloseUpdate = async () => {
    await mutate();
    setOpenAlertConfigEdit(false);
  };
  const [alertConfigToEdit, setAlertConfigToEdit] = useState<any | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = useState<string>("");

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(`m${item.id}`);
  };

  const onEdit = (item: any) => {
    setAlertConfigToEdit(item);
    setOpenAlertConfigEdit(true);
  };

  const onActivate = async (
    alertConfig: ItemAlertConfigByFrequencyIndividualDto
  ) => {
    const dto: UpdateAlertConfigByFrequencyIndividualRequestDto =
      new UpdateAlertConfigByFrequencyIndividualRequestDto();
    Object.assign(dto, alertConfig);
    dto.notification = JSON.parse(alertConfig.notification);
    dto.active = alertConfig.active ? false : true;
    try {
      if (alertConfig && alertConfig.id) {
        await patch(alertConfig.id, dto);
        await mutate();
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      return;
    }
  };

  const onClose = () => {
    setAnchorEl(null);
    setPopoverId("");
  };

  const alertConfigByFrequencyGridColumns: GridColumns<AlertConfigByFrequencyDataRow> =
    AlertConfigByFrequencyGridColumns({
      popoverConf: {
        onClick,
        onClose,
        anchorEl,
        popoverId,
        onEdit,
        onActivate,
      },
      mutate,
      messengerChannel: company?.data?.messengerChannel,
    } as IAlertConfigByFrequencyGridColumnsProps);

  const alertConfigByFrequencyDataRows: AlertConfigByFrequencyDataRow[] =
    alertConfigs ?? [];

  const alertConfigIndividualFiltered = checkAlertConfigByFrequencyPathParam(
    location.search,
    alertConfigByFrequencyDataRows
  );

  const getBreadCrumbProps = (
    profile:
      | ResponseApi<
          | GetPatientResponseDto
          | GetDoctorResponseDto
          | GetNurseResponseDto
          | GetAdminCompanyResponseDto
          | GetSuperadminResponseDto
        >
      | undefined,
    patient: ResponseApi<GetPatientResponseDto> | undefined
  ): BreadcrumbProps | undefined =>
    patient?.data?.name && profile?.data?.name
      ? ({
          breadcrumbItems: location.search.includes("alertConfigId=")
            ? [
                { url: "/profile", name: profile.data.name },
                { url: "/patients", name: t("Patients") },
                { url: `/patients/${patientId}`, name: patient.data.name },
                {
                  url: `/alerts_config/measure/${patientId}`,
                  name: t("AlertConfigByFrequencyTitle"),
                },
                {
                  url: "",
                  name:
                    alertConfigIndividualFiltered &&
                    alertConfigIndividualFiltered.length > 0
                      ? alertConfigIndividualFiltered[0].label
                      : "",
                },
              ]
            : [
                { url: "/profile", name: profile.data.name },
                { url: "/patients", name: t("Patients") },
                { url: `/patients/${patientId}`, name: patient.data.name },
                { url: "", name: t("AlertConfigByFrequencyTitle") },
              ],
        } as BreadcrumbProps)
      : undefined;

  const buttons: JSX.Element = (
    <>
      <Box display="flex">
        <IconButton onClick={handleOpenAdd}>
          <AddCircle color="primary" fontSize="large" />
        </IconButton>
      </Box>
      {open && (
        <AddAlertConfigByFrequencyModal
          patientId={+patientId}
          open={open}
          handleClose={handleCloseAdd}
          item={undefined}
          // TODO
          permissions={[]}
          messengerChannel={company?.data?.messengerChannel as string}
        />
      )}
    </>
  );

  const filters: JSX.Element = (
    <ActiveAlertFilter mode={mode} setMode={setMode} t={t} />
  );

  const setRowClassName = (
    params: GridRowClassNameParams<AlertConfigByFrequencyDataRow>
  ): string => {
    if (!params.row.active) {
      return "no-actived-alert-config";
    }
    return "";
  };

  return (
    <Page title="" breadCrumbProps={getBreadCrumbProps(profile, patient)}>
      <Page
        title={t("CustomAlertconfigByFrequency")}
        buttons={buttons}
        filters={filters}
      >
        <FlexLayoutGrid
          language={i18n.language}
          columns={alertConfigByFrequencyGridColumns}
          rows={alertConfigIndividualFiltered}
          setRowClassName={setRowClassName}
          onPageChange={setPageByFrequency}
          onPageSizeChange={setPageSizeByFrequency}
          pagination={alertConfigsByFrequencyPaginationInfo}
          sx={DoctomaticStylingRowsGrid()}
        ></FlexLayoutGrid>
      </Page>
      {openAlertConfigEdit && (
        <UpdateAlertConfigByFrequencyModal
          patientId={+patientId}
          open={openAlertConfigEdit}
          handleClose={handleCloseUpdate}
          item={alertConfigToEdit}
          permissions={[]}
          alertConfigByFrequencyIndividual={alertConfigToEdit}
          messengerChannel={company?.data?.messengerChannel as string}
        />
      )}
    </Page>
  );
};
