import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import {
  GridColumns,
  GridRowClassNameParams,
  GridSortItem,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../../Page";
import LastAlertsByFrequencyDataRow from "./LastAlertsByFrequencyDataRow";
import {
  ILastAlertsByFrequencyGridColumnsProps,
  LastAlertsByFrequencyGridColumns,
} from "./LastAlertsByFrequencyGridColumns";
import { LastAlertsByFrequencyPatient } from "@doctomatic/sdk/build/dto/Alerts/Alerts";
import { IUseLastAlertsByFrequencyByActor } from "@doctomatic/sdk/build/modules/LastAlertsByFrequencyByActor";
import { processError } from "../../../../../App/errorToast";
import {
  AlertState,
  IItemAlertByFrequency,
} from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import {
  showAlertStateModal,
  showAlertSubscribersModal,
} from "../../../Alerts/utils";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { useLoading } from "../../../../Loading/Loading";
import { DoctomaticStylingRowsGrid } from "../../../../Theme/ThemeDataGrid";
import TableSkeleton from "../../../../Skeleton/TableSkeleton";
import { useNavigate } from "react-router-dom";

const getItemAlert = (
  itemAlert: IItemAlertByFrequency
): IItemAlertByFrequency =>
  ({
    ...itemAlert,
    createdAt: new Date(itemAlert.createdAt),
    ackBys: itemAlert.ackBys.map((ackBy: any) => ({
      ...ackBy,
      createdAt: new Date(ackBy.createdAt),
    })),
  } as IItemAlertByFrequency);

export interface ILastAlertsByFrequencyListProps {
  actorId: number;
  actorRole: Role;
  showExternalId: boolean | undefined;
}

export const LastAlertsByFrequencyList = ({
  actorId,
  actorRole,
  showExternalId,
}: ILastAlertsByFrequencyListProps): React.ReactElement => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setIsLoading } = useLoading();
  const {
    useLastAlertsByFrequencyByActor,
    useAlerts,
    logout,
    timezone,
  }: IProvideApi = useApi();
  const { updateAlertComments, updateAlertState } = useAlerts();
  const [lastAlerts, setLastAlerts] = useState<LastAlertsByFrequencyPatient[]>(
    []
  );
  const [item, setItem] = useState(null);
  const [currentAlertState, setCurrentAlertState] = useState<{
    id: number;
    state: AlertState;
  } | null>(null);

  // Declaration of hooks to handle pagination
  const [pageSizeByFrequency, setPageSizeByFrequency] = useState<number>(10);
  const [pageByFrequency, setPageByFrequency] = useState<number>(1);
  const [sortBy, setSortBy] = useState<GridSortItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationValue, setPaginationValue] = useState<any>({
    page: 1,
    limit: 10,
    sortBy: [],
  });

  const { response, mutate }: IUseLastAlertsByFrequencyByActor =
    useLastAlertsByFrequencyByActor(
      actorId,
      actorRole,
      true,
      paginationValue,
      processError(logout, navigate, t)
    );

  const alertsByFrequency = response?.data;
  const alertsByFrequencyPaginationInfo = response?.meta;

  const openAlertStateModal = (id: number, state: AlertState) => {
    setCurrentAlertState({ id, state });
  };

  const setAlertState = (id: number, state: AlertState) => {
    const setAlertStatusAsync = async (id: number) => {
      setIsLoading(true);
      try {
        await updateAlertState(id, { state });
        mutate();
      } catch (err) {
        setIsLoading(false);
        throw err;
      }
      setIsLoading(false);
    };
    setAlertStatusAsync(id);
  };

  const setAlertComments = (id: number, comments: string | undefined) => {
    const setAlertCommentsAsync = async (
      id: number,
      comments: string | undefined
    ) => {
      const response = await updateAlertComments(id, { comments });
      mutate();
      return response.success;
    };
    return setAlertCommentsAsync(id, comments);
  };

  useEffect(() => {
    if (alertsByFrequency) {
      setLastAlerts(alertsByFrequency);
    }
  }, [alertsByFrequency, alertsByFrequencyPaginationInfo]);

  useEffect(() => {
    setLoading(true);
    setPaginationValue({
      page: pageByFrequency,
      limit: pageSizeByFrequency,
      sortBy: sortBy,
    });
  }, [pageByFrequency, pageSizeByFrequency, sortBy]);

  useEffect(() => {
    setLoading(false);
  }, [lastAlerts]);

  const openAckByModal = (item: any) => {
    setItem(item);
  };

  const lastAlertsGridColumns: GridColumns<LastAlertsByFrequencyDataRow> =
    LastAlertsByFrequencyGridColumns({
      actorId,
      timezone,
      mutate,
      setLastAlerts,
      showExternalId,
      showModal: openAckByModal,
      showStateModal: openAlertStateModal,
      setAlertComments,
    } as ILastAlertsByFrequencyGridColumnsProps);

  const showModal =
    item &&
    showAlertSubscribersModal({
      open: Boolean(item),
      item,
      handleClose: () => setItem(null),
      timezone: timezone,
    });

  const lastAlertsDataRows: LastAlertsByFrequencyDataRow[] = lastAlerts?.map(
    (lastAlertsPatient: LastAlertsByFrequencyPatient, index: number) =>
      ({
        id: index,
        patient: lastAlertsPatient.patient,
        alert: getItemAlert(lastAlertsPatient.alert!),
      } as LastAlertsByFrequencyDataRow)
  );

  const alertStateModal =
    currentAlertState &&
    showAlertStateModal({
      open: Boolean(currentAlertState),
      currentState: currentAlertState,
      handleClose: (stateSelected: any) => {
        if (
          typeof stateSelected === "string" &&
          currentAlertState.state.toString() !==
            stateSelected.toString().toLowerCase()
        ) {
          setAlertState(currentAlertState.id, stateSelected as AlertState);
        }
        setCurrentAlertState(null);
      },
      t,
    });

  const setRowClassName = (
    params: GridRowClassNameParams<LastAlertsByFrequencyDataRow>
  ): string => {
    if (
      params.row.alert?.individualConfig &&
      !params.row.alert.individualConfig.active
    ) {
      return "no-actived-alert-config";
    }
    return "";
  };

  return (
    <Page title={t("LastAlertsByFrequencyTitle")}>
      {loading ? (
        <TableSkeleton numRows={10} />
      ) : (
        <FlexLayoutGrid
          language={i18n.language}
          columns={lastAlertsGridColumns}
          rows={lastAlertsDataRows}
          onPageChange={setPageByFrequency}
          onPageSizeChange={setPageSizeByFrequency}
          pagination={alertsByFrequencyPaginationInfo}
          isLoading={loading}
          setRowClassName={setRowClassName}
          sx={DoctomaticStylingRowsGrid()}
          sortBy={sortBy}
          onSortChange={setSortBy}
        ></FlexLayoutGrid>
      )}
      {showModal}
      {alertStateModal}
    </Page>
  );
};
