import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Role, UpdateProfileRequestDto } from "@doctomatic/sdk/build/dto/User";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";

import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { AddressType } from "@doctomatic/sdk/build/dto/Addresses";
import Close from "@mui/icons-material/Close";
import { CountryDropdown } from "react-country-region-selector";
import { DocumentType } from "@doctomatic/sdk/build/dto/Legal/Legal";
import ReactMarkdown from "react-markdown";
import { UpdateDoctorProfileRequestDto } from "@doctomatic/sdk/build/dto/Actors/Doctor/Doctor";
import { processError } from "../../../../App/errorToast";
import { useNavigate } from "react-router-dom";

interface UserFormProps {
  profile: any;
}

const defaultErrorsState: any = {
  password: false,
  repeatPassword: false,
  dni: false,
  phone: false,
  type: false,
  address1: false,
  cp: false,
  city: false,
  country: false,
  name: false,
};

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    background: theme.palette.common.white,
    marginTop: "16px",
    marginBottom: "8px",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[400]}`,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    height: "100%",
    padding: "18.5px 14px",
    "& .StripeElement": {
      width: "100%",
    },
  },
  select: {
    color: theme.palette.grey[600],
    fontSize: "1rem",
    "-webkit-appearance": "none",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 400,
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <Button
          onClick={onClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </Button>
      ) : null}
    </DialogTitle>
  );
};

const UserForm = ({ profile }: UserFormProps): React.ReactElement => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const { useProfile, useLegal, role, logout } = useApi();
  const { update, mutate } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const { render, accept } = useLegal();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const defaultUserDto: any = {
    password: "",
    dni: "",
    phone: profile.phone || "",
    type:
      profile.role === Role.user ? AddressType.personal : AddressType.doctor,
    address1: "",
    address2: "",
    cp: "",
    city: "",
    country: "ES",
    name: profile.name || "",
    companyName: profile.name || "",
    nationality: "",
    licenseNumber:
      profile.role === Role.doctor ? profile.licenseNumber : undefined,
  };

  const [loading, setLoading] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [userDto, setUserDto] =
    useState<UpdateProfileRequestDto>(defaultUserDto);
  const [errors, setErrors] = useState<any>(defaultErrorsState);
  const [open, setOpen] = useState(false);
  const [contract, setContract] = useState("");

  const isDoctor = profile.role === Role.doctor;
  const isPatient = profile.role === Role.user;

  useEffect(() => {
    const getContract = async () => {
      let { data: markdown } = await render(DocumentType.contract, profile.id);
      markdown && setContract(markdown.md);
    };
    if (role === Role.user) getContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, profile.id]);

  let {
    password,
    dni,
    phone,
    type,
    address1,
    cp,
    city,
    country,
    name,
    nationality,
  } = userDto;
  const licenseNumber = (userDto as UpdateDoctorProfileRequestDto)
    .licenseNumber;
  let validations: any = {
    password: !!password && password.length >= 6,
    repeatPassword: !!repeatPassword && repeatPassword === userDto.password,
    dni: !!dni,
    phone: !!phone,
    type: !!type && Object.values(AddressType).includes(type),
    address1: !!address1,
    cp: !!cp,
    city: !!city,
    country: !!country,
    name: !!name,
    nationality: !!nationality,
    licenseNumber:
      (role === Role.doctor && !!licenseNumber) || role !== Role.doctor,
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (role === Role.user) await accept(DocumentType.contract);
      await update(userDto);
      await mutate();
    } catch (err: any) {
      toast.error(`${err.response?.data?.message || err.message}`);
    }
    setLoading(false);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setUserDto({ ...userDto, [event.target.id]: event.target.value });
  };

  const validate = (): boolean => {
    return Object.values(validations).every((value) => value === true);
  };

  const getHelperText = (id: string, message: string): string => {
    return errors[id] && !validations[id] ? message : "";
  };

  const handleErrors = (id: string): any => {
    setErrors({ ...errors, [id]: !validations[id] });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      {isPatient && (
        <Dialog
          fullScreen={fullScreen}
          maxWidth={false}
          fullWidth={true}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {t("TitleConditions")}
          </BootstrapDialogTitle>
          <DialogContent style={{ maxHeight: "100%", height: "100rem" }}>
            <ReactMarkdown children={contract} />
          </DialogContent>
        </Dialog>
      )}
      <TextField
        id="name"
        type="text"
        label={t("FieldFullname")}
        error={errors.name}
        helperText={getHelperText(
          "name",
          t("ErrorRequiredField", { field_name: t("FieldFullname") })
        )}
        defaultValue={userDto.name}
        onChange={({ target: { value } }) => {
          setUserDto({ ...userDto, name: value, companyName: value });
        }}
        onBlur={() => handleErrors("name")}
        margin="normal"
        variant="outlined"
        fullWidth
        required
      />
      <TextField
        id="password"
        type="password"
        label={t("FieldPassword")}
        error={errors.password}
        helperText={getHelperText("password", t("ChangePasswordInvalid"))}
        onChange={handleInputChange}
        onBlur={() => handleErrors("password")}
        margin="normal"
        variant="outlined"
        fullWidth
        required
      />
      <TextField
        id="repeatPassword"
        type="password"
        label={t("FieldRepeatPassword")}
        error={errors.repeatPassword}
        helperText={getHelperText("repeatPassword", t("ErrorPasswordMatch"))}
        onChange={(e) => setRepeatPassword(e.target.value)}
        onBlur={() => handleErrors("repeatPassword")}
        margin="normal"
        variant="outlined"
        fullWidth
        required
      />
      <TextField
        id="address1"
        type="text"
        label={t("FieldAddress")}
        error={errors.address1}
        helperText={getHelperText(
          "address1",
          t("ErrorRequiredField", { field_name: t("FieldAddress") })
        )}
        onChange={handleInputChange}
        onBlur={() => handleErrors("address1")}
        margin="normal"
        variant="outlined"
        fullWidth
        required
      />
      <TextField
        id="address2"
        type="text"
        label={t("FieldAddress2")}
        onChange={handleInputChange}
        margin="normal"
        variant="outlined"
        fullWidth
      />
      <TextField
        id="city"
        type="text"
        label={t("FieldCity")}
        error={errors.city}
        helperText={getHelperText(
          "city",
          t("ErrorRequiredField", { field_name: t("FieldCity") })
        )}
        onChange={handleInputChange}
        onBlur={() => handleErrors("city")}
        margin="normal"
        variant="outlined"
        fullWidth
        required
      />
      <CountryDropdown
        id="country"
        classes={`${classes.field} ${classes.select}`}
        showDefaultOption={false}
        valueType="short"
        value={userDto.country || ""}
        onChange={(val: string) => {
          setUserDto({ ...userDto, country: val });
        }}
        onBlur={() => handleErrors("country")}
      />
      <TextField
        id="phone"
        type="tel"
        label={t("FieldPhone")}
        error={errors.phone}
        helperText={getHelperText(
          "phone",
          t("ErrorRequiredField", { field_name: t("FieldPhone") })
        )}
        defaultValue={userDto.phone}
        onChange={handleInputChange}
        onBlur={() => handleErrors("phone")}
        margin="normal"
        variant="outlined"
        fullWidth
        required
      />
      <TextField
        id="dni"
        type="text"
        label={t("FieldDni")}
        error={errors.dni}
        helperText={getHelperText(
          "dni",
          t("ErrorRequiredField", { field_name: t("FieldDni") })
        )}
        onChange={handleInputChange}
        onBlur={() => handleErrors("dni")}
        margin="normal"
        variant="outlined"
        fullWidth
        required
      />
      <TextField
        id="cp"
        type="text"
        label={t("FieldPostalCode")}
        error={errors.cp}
        helperText={getHelperText(
          "cp",
          t("ErrorRequiredField", { field_name: t("FieldPostalCode") })
        )}
        onChange={handleInputChange}
        onBlur={() => handleErrors("cp")}
        margin="normal"
        variant="outlined"
        fullWidth
        required
      />
      <TextField
        id="nationality"
        type="text"
        label={t("FieldNationality")}
        error={errors.nationality}
        helperText={getHelperText(
          "nationality",
          t("ErrorRequiredField", { field_name: t("FieldNationality") })
        )}
        onChange={handleInputChange}
        onBlur={() => handleErrors("nationality")}
        margin="normal"
        variant="outlined"
        fullWidth
        required
      />
      {isDoctor && (
        <TextField
          id="licenseNumber"
          type="text"
          label={t("FieldLicenseNumber")}
          error={errors.licenseNumber}
          helperText={getHelperText(
            "licenseNumber",
            t("ErrorRequiredField", { field_name: t("FieldLicenseNumber") })
          )}
          defaultValue={
            (userDto as UpdateDoctorProfileRequestDto).licenseNumber
          }
          onChange={handleInputChange}
          onBlur={() => handleErrors("licenseNumber")}
          margin="normal"
          variant="outlined"
          fullWidth
          required
        />
      )}
      {isPatient && (
        <Box display="flex" justifyContent="center">
          <Button
            variant="text"
            style={{
              textTransform: "none",
              fontSize: "1.25rem",
              textAlign: "center",
            }}
            onClick={handleOpen}
          >
            {t("ViewConditions")}
          </Button>
        </Box>
      )}
      <Box display="flex" justifyContent="center" my={3}>
        <Button
          type="submit"
          variant="contained"
          disabled={!validate()}
          style={{ color: "white", textTransform: "none", fontSize: "20px" }}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : isPatient ? (
            t("AcceptConditions")
          ) : (
            t("ButtonSave")
          )}
        </Button>
      </Box>
    </form>
  );
};

export { UserForm };
