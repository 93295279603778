import { useEffect, useState } from "react";
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  Typography,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

export interface CheckboxItem {
  id: string;
  value: number;
  textValue: string;
  color: string;
  label: string;
  isValueEditable: boolean;
  isTextValueEditable: boolean;
  checked: boolean;
}

interface CheckboxProps {
  onValueChange: (
    optionId: string[],
    value: number[],
    valueText: string[]
  ) => void;
  items: CheckboxItem[];
  questionIndex: number;
  isQuestionForm?: boolean;
}

export default function MultiSelectForm({
  onValueChange,
  items,
  questionIndex,
  isQuestionForm,
}: CheckboxProps) {
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const [checkedTextValues, setCheckedTextValues] = useState<string[]>([]);
  const [isNewQuestion, setIsNewQuestion] = useState<number>(questionIndex);

  const onValueChangeCheckbox = (itemSelrialized: string) => {
    const itemSelected: CheckboxItem = JSON.parse(itemSelrialized);

    const isChecked = checkedIds.includes(itemSelected.id);
    if (isChecked) {
      const index = checkedIds.indexOf(itemSelected.id);
      checkedIds.splice(index, 1);
      setCheckedIds([...checkedIds]);
      checkedValues.splice(index, 1);
      setCheckedValues([...checkedValues]);
      checkedTextValues.splice(index, 1);
      setCheckedTextValues([...checkedTextValues]);
    } else {
      setCheckedIds([...checkedIds, itemSelected.id]);
      setCheckedValues([...checkedValues, itemSelected.value]);
      setCheckedTextValues([...checkedTextValues, itemSelected.textValue]);
    }
  };

  useEffect(() => {
    onValueChange(checkedIds, checkedValues, checkedTextValues);
  }, [checkedIds, checkedValues, checkedTextValues]);

  useEffect(() => {
    if (isNewQuestion !== questionIndex) {
      setCheckedIds([]);
      setCheckedValues([]);
      setCheckedTextValues([]);
      setIsNewQuestion(questionIndex);
    }
  }, [questionIndex]);

  return (
    <div
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
        marginBlock: "2em",
      }}
    >
      <FormGroup onChange={(e) => onValueChangeCheckbox(e.target.value)}>
        {items.map((item) => (
          <FormControlLabel
            key={`${item.value}-${questionIndex}`}
            value={JSON.stringify(item)}
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
                color="info"
                checked={item.checked == undefined ? undefined : item.checked}
              />
            }
            label={
              isQuestionForm ? (
                <Typography fontWeight={500}>{item.label}</Typography>
              ) : (
                <Typography
                  fontWeight={500}
                >{`${item.value}   ${item.label}`}</Typography>
              )
            }
            labelPlacement="end"
            style={
              isQuestionForm
                ? {
                    color: "white",
                    margin: "15px",
                    backgroundColor: item.color,
                    borderRadius: "8px",
                    minWidth: "200px",
                    minHeight: "80px",
                  }
                : {
                    color: "white",
                    marginTop: "20px",
                    height: "80px",
                    borderRadius: "8px",
                    backgroundColor: item.color,
                  }
            }
          />
        ))}
      </FormGroup>
    </div>
  );
}
