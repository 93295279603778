var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Tooltip } from "@mui/material";
import { SignType } from "../Graphs/models";
export var ReadDeviceFormGridColumns = function (_a) {
    var measurements = _a.measurements, t = _a.t, showImageModal = _a.showImageModal, additionalData = _a.additionalData, currentLanguage = _a.currentLanguage, setSigns = _a.setSigns;
    var _b = React.useState(measurements), newMeasurements = _b[0], setMeasurements = _b[1];
    var _c = React.useState(undefined), questions = _c[0], setQuestions = _c[1];
    var _d = React.useState([]), values = _d[0], setValues = _d[1];
    var _e = React.useState([0]), questionIndex = _e[0], setQuestionIndex = _e[1];
    var _f = React.useState(false), isTypeQuestionDevice = _f[0], setIsTypeQuestionDevice = _f[1];
    function getQuestionName(currentLanguage, question, t, name) {
        var questionName = question
            ? question
                .map(function (data) {
                if (data.lang === currentLanguage) {
                    return data.text;
                }
                return null;
            })
                .filter(function (text) { return text !== null; })[0] || t(name)
            : t(name);
        return questionName;
    }
    var getName = function (index, name, unit) {
        var questionName = name;
        if (questionName.includes("question")) {
            if (questions) {
                questionName = getQuestionName(currentLanguage, questions[questionIndex[index]].text, t, name);
            }
            if (unit) {
                var questionUnit = getUnit(questionIndex[index], unit);
                questionName += " (".concat(questionUnit, ")");
            }
        }
        return questionName;
    };
    var getUnit = function (index, unit) {
        if (questions) {
            return getQuestionName(currentLanguage, questions[index].unit, t, unit);
        }
        return unit;
    };
    useEffect(function () {
        if (additionalData !== "") {
            var data = JSON.parse(additionalData);
            setQuestions(data.questions);
            data.questions.forEach(function (question) {
                if (question.typeQuestion === "device") {
                    setIsTypeQuestionDevice(true);
                }
            });
        }
    }, [additionalData]);
    useEffect(function () {
        if (questions && measurements.length > questions.length) {
            var newMeasurements_1 = [];
            measurements.forEach(function (measurement) {
                var index = newMeasurements_1.findIndex(function (m) { return m.signId === measurement.signId; });
                if (index === -1) {
                    newMeasurements_1.push(measurement);
                }
                else {
                    newMeasurements_1[index].valueText += ", ".concat(measurement.valueText);
                }
            });
            var sortMeasurements_1 = [];
            questions.forEach(function (question) {
                newMeasurements_1.forEach(function (measurement) {
                    if (question.signId.includes(measurement.signId)) {
                        sortMeasurements_1.push(measurement);
                    }
                });
            });
            setMeasurements(sortMeasurements_1);
        }
    }, [measurements, questions]);
    useEffect(function () {
        if (questions && isTypeQuestionDevice) {
            var index = measurements.map(function (measurement) {
                return questions.findIndex(function (question) {
                    return question.signId.includes(measurement.signId);
                });
            });
            setQuestionIndex(index.sort(function (a, b) { return a - b; }));
        }
    }, [isTypeQuestionDevice]);
    useEffect(function () {
        if (questions &&
            newMeasurements.length == questions.length &&
            !isTypeQuestionDevice) {
            setValues(newMeasurements === null || newMeasurements === void 0 ? void 0 : newMeasurements.map(function (measurement, index) {
                var _a, _b, _c, _d, _e, _f;
                return ({
                    field: getName(index, t((_b = (_a = measurement.sign) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "")),
                    headerName: getName(index, t((_d = (_c = measurement.sign) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : ""), t((_f = (_e = measurement.sign) === null || _e === void 0 ? void 0 : _e.unit) !== null && _f !== void 0 ? _f : "")),
                    width: 150,
                    sortable: true,
                    filterable: true,
                    sortComparator: function (v1, v2) {
                        return v1.toString().localeCompare(v2.toString());
                    },
                    renderCell: function (params) {
                        var _a, _b;
                        var measurementData = params.row.values.find(function (value) { var _a; return value.signId === ((_a = measurement.sign) === null || _a === void 0 ? void 0 : _a.id); });
                        var value = "";
                        if (measurementData) {
                            if (measurementData.sign.typeSign === SignType.image) {
                                return (React.createElement(IconButton, { onClick: function () { return showImageModal(measurementData); }, color: "inherit" },
                                    React.createElement(VisibilityIcon, null)));
                            }
                            else {
                                value = measurementData.valueText
                                    ? measurementData.sign.typeSign === SignType.time
                                        ? measurementData.valueText
                                        : t(measurementData.valueText)
                                    : (_b = (_a = measurementData.value) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "";
                            }
                        }
                        return (React.createElement(Tooltip, { title: value },
                            React.createElement("p", null, value)));
                    },
                });
            }));
        }
        if (questions &&
            newMeasurements.length != questions.length &&
            isTypeQuestionDevice &&
            questionIndex.length > 0) {
            setValues(newMeasurements === null || newMeasurements === void 0 ? void 0 : newMeasurements.map(function (measurement, index) {
                var _a, _b, _c, _d, _e, _f;
                return ({
                    field: getName(index, t((_b = (_a = measurement.sign) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "")),
                    headerName: getName(index, t((_d = (_c = measurement.sign) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : ""), t((_f = (_e = measurement.sign) === null || _e === void 0 ? void 0 : _e.unit) !== null && _f !== void 0 ? _f : "")),
                    width: 150,
                    sortable: true,
                    filterable: true,
                    sortComparator: function (v1, v2) {
                        return v1.toString().localeCompare(v2.toString());
                    },
                    renderCell: function (params) {
                        var _a, _b;
                        var measurementData = params.row.values.find(function (value) { var _a; return value.signId === ((_a = measurement.sign) === null || _a === void 0 ? void 0 : _a.id); });
                        var value = "";
                        if (measurementData) {
                            if (measurementData.sign.typeSign === SignType.image) {
                                return (React.createElement(IconButton, { onClick: function () { return showImageModal(measurementData); }, color: "inherit" },
                                    React.createElement(VisibilityIcon, null)));
                            }
                            else {
                                value = measurementData.valueText
                                    ? t(measurementData.valueText)
                                    : (_b = (_a = measurementData.value) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "";
                            }
                        }
                        return (React.createElement(Tooltip, { title: value },
                            React.createElement("p", null, value)));
                    },
                });
            }));
        }
    }, [newMeasurements, questions, questionIndex]);
    useEffect(function () {
        var sings = "";
        newMeasurements.forEach(function (measurement, index) {
            var _a, _b, _c, _d;
            if (sings === "") {
                sings = getName(index, t((_b = (_a = measurement.sign) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ""));
            }
            else {
                sings = sings + ", " + getName(index, t((_d = (_c = measurement.sign) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : ""));
            }
        });
        setSigns(sings);
    }, [newMeasurements]);
    return __spreadArray([
        {
            field: t("Date"),
            headerName: t("Date"),
            width: 130,
            sortable: true,
            filterable: true,
            valueGetter: function (params) { return params.row.date; },
            sortComparator: function (v1, v2) {
                return new Date(v2).getTime() - new Date(v1).getTime();
            },
            renderCell: function (params) { return React.createElement("p", null, params.row.date); },
        }
    ], values, true);
};
