import React, { ChangeEvent, useState } from "react";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { GroupPermissionListGridColumns } from "./GroupPermissionListGridColumns";
import {
  IGroupPermissionUserDto,
  IPermissionDto,
  IPermissionUserDto,
} from "@doctomatic/sdk/build/dto/GroupPermission/IGroupPermission";
import { Page } from "../../../Page";
import { Grid } from "@mui/material";
import { BreadcrumbNameMap } from "../../../../../breadcrumbMap";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { useNavigate, useParams } from "react-router-dom";
import { IUseGroupPermission } from "@doctomatic/sdk/build/modules/GroupPermissions";
import { PermissionListGridColumns } from "./PermissionListGridColumns";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { GroupPermissionModal } from "./GroupPermissionModal";
import { processError } from "../../../../../App/errorToast";

class GroupPermissionListPageParams {
  actorId: string = "";
}

export interface GroupPermissionListPageProps {
  role: Role;
}

const GroupPermissionListPage = (
  props: GroupPermissionListPageProps
): React.ReactElement => {
  const navigate = useNavigate();
  const { actorId }: GroupPermissionListPageParams = useParams();
  const {
    useProfile,
    useGroupPermissions,
    useDoctor,
    useNurse,
    useAdminCompany,
    logout,
  } = useApi();
  const { t, i18n } = useTranslation();
  const { response: responseApiProfile } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const { response: responseDoctor } = useDoctor(
    +actorId,
    true,
    processError(logout, navigate, t)
  );
  const { response: responseAdmin } = useAdminCompany(
    +actorId,
    true,
    processError(logout, navigate, t)
  );
  const { response: responseNurse } = useNurse(
    +actorId,
    true,
    processError(logout, navigate, t)
  );
  const {
    getPermissionsByActor,
    assignPermission,
    unassignPermission,
    assignGroupPermission,
    unassignGroupPermission,
    response: responseGroupPermissionUser,
  }: IUseGroupPermission = useGroupPermissions(
    +actorId,
    true,
    processError(logout, navigate, t)
  );

  const groupPermissionsActor = responseGroupPermissionUser?.data ?? [];

  const [permissionsActor, setPermissionsActor] =
    useState<IPermissionUserDto[]>();

  const [modalPermissions, setModalPermissions] = useState<
    IPermissionDto[] | null
  >(null);

  const getPermission = async (actorId: number) => {
    const responseUserPermission = await getPermissionsByActor(+actorId);
    if (
      responseUserPermission &&
      responseUserPermission.success === true &&
      responseUserPermission.data
    ) {
      setPermissionsActor(responseUserPermission.data);
    }
  };

  if (isNaN(+actorId)) return <>{t("parameter actorId is not a number")}</>;
  if (!responseApiProfile) return <></>;
  if (!responseGroupPermissionUser) return <></>;

  if (!permissionsActor) {
    getPermission(+actorId);
    return <></>;
  }

  const onChangeSwitchGroupPermission = async (
    row: IGroupPermissionUserDto,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.currentTarget.checked) {
      await unassignGroupPermission(row.userGroupPermissionId!);
    } else {
      await assignGroupPermission(+actorId, row.id);
    }
  };

  const onChangeSwitchPermission = async (
    row: IPermissionUserDto,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.currentTarget.checked) {
      await unassignPermission(row.userPermissionId!);
    } else {
      await assignPermission(+actorId, row.id);
    }
    await getPermission(+actorId);
  };

  const onViewPermissions = (permissions: IPermissionDto[]) => {
    setModalPermissions(permissions);
  };

  const showPermissionsModal = modalPermissions && (
    <GroupPermissionModal
      open={Boolean(modalPermissions)}
      t={t}
      language={i18n.language}
      permissions={modalPermissions}
      onClose={() => setModalPermissions(null)}
    />
  );

  const groupPermissionGridColumns = GroupPermissionListGridColumns(
    onChangeSwitchGroupPermission,
    t,
    onViewPermissions
  );
  const permissionGridColumns = PermissionListGridColumns(
    onChangeSwitchPermission,
    t
  );

  const getBreadCrumbProps = (
    role: Role,
    actorName: string | undefined
  ): BreadcrumbProps => {
    let urlAdministrationActor: string = "";
    let nameTypeActors: string = "";

    if (role === Role.doctor) {
      urlAdministrationActor = BreadcrumbNameMap.AdministrationDoctors.url;
      nameTypeActors = BreadcrumbNameMap.AdministrationDoctors.name;
    }

    if (role === Role.admin) {
      urlAdministrationActor =
        BreadcrumbNameMap.AdministrationAdminCompanies.url;
      nameTypeActors = BreadcrumbNameMap.AdministrationAdminCompanies.name;
    }

    if (role === Role.nurse) {
      urlAdministrationActor = BreadcrumbNameMap.AdministrationNurses.url;
      nameTypeActors = BreadcrumbNameMap.AdministrationNurses.name;
    }

    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        { url: urlAdministrationActor, name: t(nameTypeActors) },
        { url: "", name: actorName },
        { url: "", name: t("Permissions") },
      ],
    } as BreadcrumbProps;
  };

  const getUserNameForBearCrumb = (): string | undefined => {
    if (props.role === Role.doctor) return responseDoctor?.data?.name;
    if (props.role === Role.admin) return responseAdmin?.data?.name;
    if (props.role === Role.nurse) return responseNurse?.data?.name;
    return "";
  };

  const actorName = getUserNameForBearCrumb();
  return (
    <Page
      title={""}
      breadCrumbProps={getBreadCrumbProps(props.role, actorName)}
    >
      {showPermissionsModal}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FlexLayoutGrid
            language={i18n.language}
            columns={groupPermissionGridColumns}
            rows={groupPermissionsActor}
          ></FlexLayoutGrid>
        </Grid>
        <Grid item xs={6}>
          <FlexLayoutGrid
            language={i18n.language}
            columns={permissionGridColumns}
            rows={permissionsActor}
          ></FlexLayoutGrid>
        </Grid>
      </Grid>
    </Page>
  );
};

export { GroupPermissionListPage };
