import React from "react";
import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { useTranslation } from "react-i18next";
import LastAlertsByFrequencyByPatientDataRow from "./LastAlertsByFrequencyByPatientDataRow";
import { ViewAlertsCell } from "@doctomatic/components-react/build/Alerts/ViewAlertsCell";
import { Link } from "react-router-dom";
import { BreadcrumbNameMap } from "../../../../../breadcrumbMap";
import { AlertState, GetAlertUserAckDto } from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import { ResponseApiList } from "@doctomatic/sdk/build/global";
import { KeyedMutator } from "swr";
import { ItemAlertByFrequencyList } from "@doctomatic/sdk/build/dto/Alerts/Alerts";
import { ElementGridStringEdit } from "@doctomatic/components-react/build/ElementColumn/ElementGridStringEdit";
import { Link as NavLink } from "react-router-dom";
import { sortText } from "../../../../../App/sortFunctions";
import { toLocaleString } from "../../../utils";
import { Launch } from "@mui/icons-material";

export interface ILastAlertsByFrequencyByPatientGridColumnsProps {
  actorId: number;
  timezone: string;
  mutate: KeyedMutator<ResponseApiList<ItemAlertByFrequencyList>>;
  setLastAlerts: React.Dispatch<
    React.SetStateAction<ItemAlertByFrequencyList[]>
  >;
  showModal?: (item: any) => void;
  showStateModal?: (
    id: number | undefined,
    item: AlertState | undefined
  ) => void;
  setAlertComments?: (
    id: number,
    comments: string | undefined
  ) => Promise<boolean>;
}

export const LastAlertsByFrequencyByPatientGridColumns = ({
  actorId,
  mutate,
  setLastAlerts,
  showModal,
  showStateModal,
  setAlertComments,
  timezone,
}: ILastAlertsByFrequencyByPatientGridColumnsProps): GridColumns<LastAlertsByFrequencyByPatientDataRow> => {
  const { t } = useTranslation();

  let gridColumns: any[] = [];
  gridColumns.push(
    ...[
      {
        field: "alertColor",
        headerName: t("AlertColor"),
        width: 70,
        align: "left",
        valueGetter: (params: GridValueGetterParams) =>
          params.row.color ?? "green",
        sortable: false,
        renderCell: (params: {
          row: LastAlertsByFrequencyByPatientDataRow;
        }) => (
          <ViewAlertsCell
            alerts={[
              {
                id: params.row.id ?? 0,
                color: params.row.color ?? "green",
              },
            ]}
            row={undefined}
          />
        ),
      },
      {
        field: "state",
        headerName: t("AlertState"),
        width: 120,
        align: "center",
        valueGetter: (params: GridValueGetterParams) => params.row.state,
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: {
          row: LastAlertsByFrequencyByPatientDataRow;
        }) => {
          const lastAlertState = params.row.state;
          const alertStateCapitalized = `${lastAlertState
            ?.charAt(0)
            .toUpperCase()}${lastAlertState?.substring(1).toLowerCase()}`;
          return (
            <TextWordsQuantityCell
              onClickModal={() =>
                showStateModal!(params.row.id, lastAlertState)
              }
              item={params.row}
              words={[t(alertStateCapitalized)]}
            ></TextWordsQuantityCell>
          );
        },
      },
      {
        field: "created_at",
        headerName: t("CreatedAt"),
        width: 150,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.createdAt,
        sortComparator: (v1: any, v2: any) =>
          new Date(v2!).getTime() - new Date(v1!).getTime(),
        renderCell: (params: {
          row: LastAlertsByFrequencyByPatientDataRow;
        }) => {
          const content: JSX.Element = (
            <p>
              {params.row.created_at
                ? toLocaleString(new Date(params.row.created_at))
                : "--"}
            </p>
          );
          return content;
        },
      },
      {
        field: "alertName",
        headerName: t("AlertName"),
        width: 200,
        align: "left",
        valueGetter: (params: GridValueGetterParams) => params.row.label,
        sortable: false,
        renderCell: (params: {
          row: LastAlertsByFrequencyByPatientDataRow;
        }) => {
          const alertConfigUrl =
            BreadcrumbNameMap.AlertConfigByFrequency.url.replace(
              ":patientId",
              params.row.patient.id.toString()
            );
          const content: JSX.Element = (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {params.row.individualConfig  && params.row.individualConfig.id ? (
                <NavLink
                  to={alertConfigUrl.concat(
                    `?alertConfigId=${params.row.individualConfig.id}`
                  )}
                >
                  <Launch />
                </NavLink>
              ) : (
                "--"
              )}
              <p style={{ marginLeft: 5 }}></p>
              <p>{params.row.label}</p>
            </div>
          );

          return content;
        },
      },
      {
        field: "typeAlert",
        headerName: t("TypeAlert"),
        width: 120,
        align: "left",
        valueGetter: (params: GridValueGetterParams) =>
          params.row.typeAlert,
        sortable: false,
        renderCell: (params: {
          row: LastAlertsByFrequencyByPatientDataRow;
        }) => {
          const content: JSX.Element = <p>{params.row.label ?? ""}</p>;
          return content;
        },
      },
      {
        field: "timeout",
        headerName: t("Timeout"),
        width: 150,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.max_time_between,
        sortable: false,
        renderCell: (params: {
          row: LastAlertsByFrequencyByPatientDataRow;
        }) => <p>{params.row.timeout}</p>,
      },
      {
        field: "timeToValidate",
        headerName: t("TimeToValidate"),
        width: 150,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.max_time_between,
        sortable: false,
        renderCell: (params: {
          row: LastAlertsByFrequencyByPatientDataRow;
        }) => <p>{params.row.timeToValidate}</p>,
      },
      {
        field: "comments",
        headerName: t("Comments"),
        width: 95,
        align: "center",
        valueGetter: (params: GridValueGetterParams) => params.row.comments,
        sortable: false,
        renderCell: (params: {
          row: LastAlertsByFrequencyByPatientDataRow;
        }) => {
          return (
            <ElementGridStringEdit
              textCancel={t("Cancel")}
              textSave={t("Save")}
              textLabelField={t("Comments")}
              itemToAddCommentId={params.row.id}
              text={params.row.comments}
              onSave={
                setAlertComments
                  ? setAlertComments
                  : async (
                      alertId: number,
                      comment: string | undefined
                    ): Promise<boolean> => {
                      return true;
                    }
              }
              onClose={() => {}}
            />
          );
        },
      },
      {
        field: "trackings",
        headerName: t("Trackings"),
        width: 150,
        sortable: false,
        renderCell: (params: {
          row: LastAlertsByFrequencyByPatientDataRow;
        }) => {
          const page: string = `/trackings/patients/${params.row.patient.id}`;

          const content: JSX.Element = (
            <TextWordsQuantityCell
              item={params.row}
              words={[t("Go_to_trankings")]}
              page={page}
              routerLink={NavLink}
            />
          );
          return content;
        },
      },
      {
        field: "active",
        headerName: t("ConfigAlert"),
        width: 200,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.individualConfig
            ? params.row.individualConfig?.active
              ? t("Active")
              : t("Deactive")
            : params.row.templateConfig?.active
            ? t("Active")
            : t("Deactive"),
        sortable: false,
        renderCell: (params: {
          row: LastAlertsByFrequencyByPatientDataRow;
        }) => {
          const active = params.row.individualConfig?.active;
          return (
            <TextWordsQuantityCell
              routerLink={Link}
              item={params.row}
              words={[active ? t("Active") : t("Deactive")]}
              page={BreadcrumbNameMap.AlertConfigByFrequency.url.replace(
                ":patientId",
                params.row.patient.id.toString()
              )}
            ></TextWordsQuantityCell>
          );
        },
      },
      {
        field: 'ackBy',
        headerName: t('HeaderAckBy'),
        width: 200,
        valueGetter: (params: GridValueGetterParams) => {
          const texts =
            params.row.ackBy && params.row.ackBy.map((ack: GetAlertUserAckDto) => ack.user.name);
          if (texts.length === 0) texts.push('---');
          return texts.join(', ');
        },
        sortable: false,
        renderCell: (params: { row: LastAlertsByFrequencyByPatientDataRow }) => {
          const texts =
            params.row.ackBy && params.row.ackBy.map((ack: GetAlertUserAckDto) => ack.user.name);
          if (texts.length === 0) texts.push('---');
          return (
            <TextWordsQuantityCell routerLink={Link}
              item={params.row}
              words={texts}
              onClickModal={() => showModal!(params.row)}
            ></TextWordsQuantityCell>
          );
        },
      }
    ]
  );
  return gridColumns;
};
