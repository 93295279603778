export interface UrlElement {
  name: string;
  url: string;
  urlAlt?: string;
}

export class UrlList {
  About: UrlElement = { name: "About", url: "/about" };
  Administration: UrlElement = {
    name: "Administration",
    url: "/administration",
  };
  AdministrationPatients: UrlElement = {
    name: "Patients",
    url: "/administration/patients",
  };
  AdministrationDoctors: UrlElement = {
    name: "Doctors",
    url: "/administration/doctors",
  };
  AdministrationAdminCompanies: UrlElement = {
    name: "Administrators",
    url: "/administration/adminCompanies",
  };
  AdministrationNurses: UrlElement = {
    name: "Nurses",
    url: "/administration/nurses",
  };
  AlertConfigByNoMeasure: UrlElement = {
    name: "Alerts config by no measure",
    url: "/alerts_config/measure/:patientId",
  };
  AlertConfigByValue: UrlElement = {
    name: "Alerts config by value",
    url: "/alerts_config/value/:patientId",
  };
  AlertConfigByFrequency: UrlElement = {
    name: "Alerts config by frequency",
    url: "/alerts_config/frequency/:patientId",
  };
  AlertConfigSubscribers: UrlElement = {
    name: "Alerts config subscribers",
    url: "/alerts_config/subscribers/:alertConfigId",
  };
  AlertsByPatient: UrlElement = {
    name: "AlertsByPatient",
    url: "/alerts/patient/:patientId",
  };
  Alerts: UrlElement = { name: "Alerts", url: "/alerts" };
  DeviceByPatient: UrlElement = {
    name: "DevicesByPatient",
    url: "/devices/patient/:patientId",
  };
  Locations: UrlElement = { name: "Locations", url: "/locations" };
  LocationsUser: UrlElement = {
    name: "LocationsUser",
    url: "/:role/:actorId/locations",
  };
  AdminLocations: UrlElement = {
    name: "AdminLocations",
    url: "/administration/location",
  };
  GroupPermissionsDoctor: UrlElement = {
    name: "DoctorPermissions",
    url: "/administration/grouppermissions/doctor/:actorId",
  };
  GroupPermissionsNurse: UrlElement = {
    name: "NursePermissions",
    url: "/administration/grouppermissions/nurse/:actorId",
  };
  GroupPermissionsAdminCompany: UrlElement = {
    name: "AdminPermissions",
    url: "/administration/grouppermissions/adminCompany/:actorId",
  };
  GroupPermissionAdd: UrlElement = {
    name: "Permission Add",
    url: "/administration/grouppermissions/add",
  };
  GroupPermissionEdit: UrlElement = {
    name: "Permission Edit",
    url: "/administration/grouppermissions/:groupPermissionId/edit",
  };
  Trackings: UrlElement = { name: "Tracking", url: "/trackings" };
  Devices: UrlElement = {
    name: "Devices",
    url: "/devices",
  };
  TrackingTypes: UrlElement = {
    name: "Trackingtypes",
    url: "/administration/trackingtypes",
  };
  CreateTrackingPatient: UrlElement = {
    name: "Create Tracking Patient",
    url: "/trackings/patients/create/:patientId",
  };
  TrackingPatient: UrlElement = {
    name: "Tracking Patient",
    url: "/trackings/patients/:patientId",
  };
  TrackingGraph: UrlElement = {
    name: "Tracking Graph",
    url: "/trackings/:trackingId/patientId/:patientId",
  };
  Tracking: UrlElement = { name: "Tracking", url: "/trackings/:trackingId" };
  Patient: UrlElement = { name: "Patient", url: "/patients/:patientId" };
  Patients: UrlElement = { name: "Patients", url: "/patients" };
  Profile: UrlElement = { name: "Profile", url: "/profile" };
  Support: UrlElement = { name: "Support", url: "/support" };
  Doctor: UrlElement = { name: "Doctor", url: "/doctors/:actorId" };
  Doctors: UrlElement = { name: "Doctors", url: "/doctors" };
  Nurse: UrlElement = { name: "Nurse", url: "/nurses/:actorId" };
  Nurses: UrlElement = { name: "Nurses", url: "/nurses" };
  AdminCompany: UrlElement = {
    name: "Administrator",
    url: "/admincompanies/:actorId",
  };
  AdminCompanies: UrlElement = {
    name: "Administrators",
    url: "/admincompanies",
  };
  CompanyEdit: UrlElement = { name: "Company", url: "/company/edit" };
  WebHooks: UrlElement = { name: "Webhooks", url: "/webhooks" };
  Recover: UrlElement = { name: "Recover", url: "/recover" };
  AlertGroups: UrlElement = {
    name: "AlertGroups",
    url: "/administration/alertgroups",
  };
  AlertGroupSubscribers: UrlElement = {
    name: "Alert group subscribers",
    url: "/administration/alertgroups/subscribers/:id",
  };
  AlertConfigTemplates: UrlElement = {
    name: "AlertConfigTemplates",
    url: "/administration/alertconfigtemplates",
  };
  ExternalReferences: UrlElement = {
    name: "ExternalReferences",
    url: "/administration/externalreferences",
  };
  Forms: UrlElement = { name: "Forms", url: "/forms" };
  FormsCreate: UrlElement = { name: "Create", url: "/forms/create" };
  FormsEdit: UrlElement = { name: "Edit", url: "/forms/:formId" };
  ReadDevice: UrlElement = {
    name: "ReadDevice",
    url: "/read-device/:readDeviceId",
  };
}

export const BreadcrumbNameMap = new UrlList();
