import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Appbar } from "../../components/Appbar";
import { Profile } from "../../components/Pages/Profile";
import { Theme } from "../../components/Theme";
import { useApi } from "@doctomatic/sdk/build/Api";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { ChangePassword } from "../../components/Pages/Profile/ChangePassword";
import { About } from "../../components/Pages/About";
import { Support } from "../../components/Pages/Support";
import { Doctors } from "../../components/Pages/Administration/Doctors";
import { Webhooks } from "../../components/Pages/Webhooks";
import { GetPatientResponseDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { Patients } from "../../components/Pages/Patients/Patients";
import { Alerts } from "../../components/Pages/Alerts/Alerts";
import { UserLocations } from "../../components/Pages/UserLocations/UserLocations";
import { AdministrationPage } from "../../components/Pages/Administration/AdministrationPage";
import { AdminLocationPage } from "../../components/Pages/Administration/Locations/AdminLocationPage";
import { BreadcrumbNameMap } from "../../breadcrumbMap";
import { TreatmentsDoctorNurse } from "../../components/Pages/Treatments/TreatmentsDoctorNurse/TreatmentsDoctorNurse";
import { TreatmentsGraph } from "../../components/Pages/Treatments/TreatmentsGraph";
import { LocationPage } from "../../components/Pages/Locations/LocationPage";
import { GroupPermissionCreatePage } from "../../components/Pages/Administration/GroupPermission/GroupPermissionCreate/GroupPermissionCreate";
import { TreatmentsPatientByStaff } from "../../components/Pages/Treatments/TreatmentsPatient/TreatmentsPatientByStaff";
import { GroupPermissionEditPage } from "../../components/Pages/Administration/GroupPermission/GroupPermissionEdit/GroupPermissionEdit";
import { GroupPermissionListPage } from "../../components/Pages/Administration/GroupPermission/GroupPermissionList/GroupPermissionList";
import { TrackingTypePage } from "../../components/Pages/Administration/TrackingType/TrackingTypes";
import { AlertsByValueGraph } from "../../components/Pages/Alerts/AlertsByValueList/AlertsByValueGraph";
import { AlertsByNoMeasureGraph } from "../../components/Pages/Alerts/AlertsByNoMeasureList/AlertsByNoMeasureGraph";
import { AlertsByPatient } from "../../components/Pages/Alerts/AlertsByPatient";
import { AlertConfigByNoMeasure } from "../../components/Pages/AlertConfigs/AlertConfigByNoMeasure/AlertConfigByNoMeasure";
import { AlertConfigByValue } from "../../components/Pages/AlertConfigs/AlertConfigByValue/AlertConfigByValue";
import { SubscribersAlertConfig } from "../../components/Pages/AlertConfigs/AlertConfigSubscribers";
import { AdminCompany } from "../../components/Pages/Administration/AdminCompany/AdminCompany";
import { Patients as AdminPatients } from "../../components/Pages/Administration/Patients/Patients";
import { CreateTreatmentPatient } from "../../components/Pages/Treatments/CreateTreatmentPatient/CreateTreatmentPatient";
import { Nurses } from "../../components/Pages/Administration/Nurses/Nurses";
import { CompanyEdit } from "../../components/Pages/Administration/Company/CompanyEdit";
import { Dashboard } from "../../components/Pages/Dashboard/Dashboard";
import { PatientDetails } from "../../components/Pages/Patients/PatientDetails";
import { processError } from "../errorToast";
import { AlertGroupPage } from "../../components/Pages/Administration/AlertGroups/AlertGroup";
import { AlertConfigTemplates } from "../../components/Pages/Administration/AlertConfigTemplates/AlertConfigTemplates";
import { useTranslation } from "react-i18next";
import { AlertGroupSubscribers } from "../../components/Pages/Administration/AlertGroups/AlertGroupSubscribers";
import { TreatmentsPatient } from "../../components/Pages/Treatments/TreatmentsPatient/TreatmentsPatient";
import { Devices } from "../../components/Pages/Devices/Devices";
import { ExternalReferences } from "../../components/Pages/Administration/ExternalReferences/ExternalReferences";
import { FormsPage } from "../../components/Pages/Administration/Forms/FormsPage";
import { FormsCreatePage } from "../../components/Pages/Administration/Forms/FormsCreatePage";
import { FormsEditPage } from "../../components/Pages/Administration/Forms/FormsEditPage";
import { Camera } from "../../components/Pages/Camera/Camera";
import { ReadDevicePage } from "../../components/Pages/ReadDevice/ReadDevice";
import { AlertConfigByFrequency } from "../../components/Pages/AlertConfigs/AlertConfigByFrequency/AlertConfigByFrequency";
import { Forms } from "../../components/Pages/Forms/Forms";

interface LoggedAppProps {
  redirectPath: string | null;
}

const LoggedApp = (props: LoggedAppProps): React.ReactElement => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isEmbedded = searchParams.get("embedded") === "true";
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { role, useProfile, useCompany, logout, companyId } = useApi();
  const { response: profileResponse } = useProfile(
    true,
    true,
    processError(logout, navigate, t)
  );
  const { response: companyResponse } = useCompany(
    companyId,
    true,
    processError(logout, navigate, t)
  );
  const profile = profileResponse?.data;

  useEffect(() => {
    if (props.redirectPath && props.redirectPath !== "/") {
      navigate(`${props.redirectPath as string}?${searchParams.toString()}`);
    }
  }, [props.redirectPath]);

  useEffect(() => {
    if (profileResponse && profileResponse.additional_data)
      localStorage.setItem(
        "permissions",
        JSON.stringify(profileResponse.additional_data) || JSON.stringify([])
      );
  }, [profileResponse]);

  useEffect(() => {
    if (companyResponse && companyResponse.data)
      localStorage.setItem("logoCache", companyResponse.data.logo || "");
  }, [companyResponse]);

  if (!companyResponse || !profileResponse) return <></>;

  const company = companyResponse.data;
  const isUser = role === Role.user;
  const isNurse = role === Role.nurse;
  const isAdmin = role === Role.admin;
  const isDoctor = role === Role.doctor;
  const isTeam = isDoctor || isNurse || isAdmin;
  const notSubscribed = !!(profile as GetPatientResponseDto).hasSubscription
    ? company?.stripeAccount &&
      !(profile as GetPatientResponseDto).hasSubscription
    : false;
  const notAcceptedLegal = !(profile as GetPatientResponseDto).acceptedContract;
  const notHasPassword = !(profile as GetPatientResponseDto).hasPassword;

  const doctorRoutes = (
    <Routes>
      {isTeam && <Route path="/" element={<Dashboard />} />}
      <Route
        path={BreadcrumbNameMap.Patients.url}
        element={<Patients profile={profile} />}
      />
      <Route
        path={BreadcrumbNameMap.Patient.url}
        element={<PatientDetails />}
      />
      <Route path={BreadcrumbNameMap.Profile.url} element={<Profile />} />
      <Route path="/alerts" element={<Alerts />} />
      <Route
        path={BreadcrumbNameMap.AlertsByPatient.url}
        element={<AlertsByPatient />}
      />
      <Route
        path={BreadcrumbNameMap.Administration.url}
        element={<AdministrationPage />}
      />
      <Route
        path={BreadcrumbNameMap.AdminLocations.url}
        element={<AdminLocationPage />}
      />
      <Route
        path={BreadcrumbNameMap.Locations.url}
        element={<LocationPage />}
      />
      <Route
        path={BreadcrumbNameMap.LocationsUser.url}
        element={<UserLocations />}
      />
      <Route
        path={BreadcrumbNameMap.TrackingTypes.url}
        element={<TrackingTypePage />}
      />
      <Route
        path={BreadcrumbNameMap.GroupPermissionsDoctor.url}
        element={<GroupPermissionListPage role={Role.doctor} />}
      />
      <Route
        path={BreadcrumbNameMap.GroupPermissionsNurse.url}
        element={<GroupPermissionListPage role={Role.nurse} />}
      />
      <Route
        path={BreadcrumbNameMap.GroupPermissionsAdminCompany.url}
        element={<GroupPermissionListPage role={Role.admin} />}
      />
      <Route
        path={BreadcrumbNameMap.GroupPermissionAdd.url}
        element={<GroupPermissionCreatePage />}
      />
      <Route
        path={BreadcrumbNameMap.GroupPermissionEdit.url}
        element={<GroupPermissionEditPage />}
      />
      <Route
        path={BreadcrumbNameMap.Trackings.url}
        element={<TreatmentsDoctorNurse />}
      />
      <Route
        path={BreadcrumbNameMap.CreateTrackingPatient.url}
        element={<CreateTreatmentPatient />}
      />
      <Route
        path={BreadcrumbNameMap.TrackingPatient.url}
        element={<TreatmentsPatientByStaff />}
      />
      <Route
        path={BreadcrumbNameMap.TrackingGraph.url}
        element={<TreatmentsGraph />}
      />
      <Route
        path={BreadcrumbNameMap.TrackingGraph.urlAlt}
        element={<TreatmentsGraph />}
      />
      <Route
        path={BreadcrumbNameMap.AlertConfigByFrequency.url}
        element={<AlertConfigByFrequency />}
      />
      <Route
        path="/measurements/value/:alertId/:actorRole/:actorId"
        element={<AlertsByValueGraph />}
      />
      <Route
        path="/measurements/measure/:alertId/:actorRole/:actorId"
        element={<AlertsByNoMeasureGraph />}
      />
      <Route
        path={BreadcrumbNameMap.AlertConfigByNoMeasure.url}
        element={<AlertConfigByNoMeasure />}
      />
      <Route
        path={BreadcrumbNameMap.AlertConfigByValue.url}
        element={<AlertConfigByValue />}
      />
      <Route path="/recover" element={<ChangePassword />} />
      <Route path="/about" element={<About />} />
      <Route path="/support" element={<Support />} />
      <Route
        path={BreadcrumbNameMap.AlertGroups.url}
        element={<AlertGroupPage profile={profile} />}
      />
      <Route
        path={BreadcrumbNameMap.AlertConfigSubscribers.url}
        element={<SubscribersAlertConfig />}
      />
      <Route
        path={BreadcrumbNameMap.AlertGroupSubscribers.url}
        element={<AlertGroupSubscribers />}
      />
      <Route
        path={BreadcrumbNameMap.AlertConfigTemplates.url}
        element={<AlertConfigTemplates />}
      />
      <Route
        path={BreadcrumbNameMap.ExternalReferences.url}
        element={<ExternalReferences profile={profile} />}
      />
      <Route
        path={BreadcrumbNameMap.AdministrationPatients.url}
        element={<AdminPatients profile={profile} />}
      />
      <Route
        path={BreadcrumbNameMap.AdministrationDoctors.url}
        element={<Doctors />}
      />
      <Route
        path={BreadcrumbNameMap.AdministrationNurses.url}
        element={<Nurses />}
      />
      <Route
        path={BreadcrumbNameMap.AdministrationAdminCompanies.url}
        element={<AdminCompany />}
      />
      <Route path={BreadcrumbNameMap.Forms.url} element={<FormsPage />} />
      <Route
        path={BreadcrumbNameMap.FormsCreate.url}
        element={<FormsCreatePage />}
      />
      <Route
        path={BreadcrumbNameMap.FormsEdit.url}
        element={<FormsEditPage />}
      />
      <Route
        path={BreadcrumbNameMap.ReadDevice.url}
        element={<ReadDevicePage />}
      />
      <Route path="/devices/:deviceId/camera/:userId" element={<Camera />} />
      <Route path="/devices/camera/:userId" element={<Camera />} />
      <Route path="/devices/:userId" element={<Devices />} />
      <Route
        path="/devices/:formId/form/:userId"
        element={<Forms />}
      />
      <Route path="*" element={<Dashboard />} />
    </Routes>
  );

  const nonSubscribedUserRoutes = (
    <Routes>
      <Route path="/profile" element={<Profile />} />
      <Route path="/recover" element={<ChangePassword />} />
      <Route path="/about" element={<About />} />
      <Route path="/support" element={<Support />} />
      <Route path="*" element={<Profile />} />
    </Routes>
  );

  const patientRoutes = (
    <Routes>
      <Route path="/profile" element={<Profile />} />
      <Route path="/trackings" element={<TreatmentsPatient />} />
      <Route path="/devices" element={<Devices />} />
      <Route path="/devices/:deviceId/camera/:userId" element={<Camera />} />
      <Route path="/devices/camera/:userId" element={<Camera />} />
      <Route
        path="/devices/:formId/form/:userId"
        element={<Forms />}
      />
      <Route
        path={BreadcrumbNameMap.TrackingGraph.url}
        element={<TreatmentsGraph />}
      />
      <Route
        path={BreadcrumbNameMap.TrackingGraph.urlAlt}
        element={<TreatmentsGraph />}
      />
      <Route path="/recover" element={<ChangePassword />} />
      <Route path="/about" element={<About />} />
      <Route path="/support" element={<Support />} />
      <Route path="*" element={<Profile />} />
    </Routes>
  );

  const adminRoutes = (
    <Routes>
      <Route path={BreadcrumbNameMap.Profile.url} element={<Profile />} />
      <Route
        path={BreadcrumbNameMap.Administration.url}
        element={<AdministrationPage />}
      />
      <Route
        path={BreadcrumbNameMap.AdminLocations.url}
        element={<AdminLocationPage />}
      />
      <Route
        path={BreadcrumbNameMap.LocationsUser.url}
        element={<UserLocations />}
      />
      {isTeam && (
        <Route
          path={BreadcrumbNameMap.TrackingTypes.url}
          element={<TrackingTypePage />}
        />
      )}
      {isTeam && (
        <Route
          path={BreadcrumbNameMap.AlertGroups.url}
          element={<AlertGroupPage profile={profile} />}
        />
      )}
      {isTeam && (
        <Route
          path={BreadcrumbNameMap.AlertConfigTemplates.url}
          element={<AlertConfigTemplates />}
        />
      )}
      {isTeam && (
        <Route
          path={BreadcrumbNameMap.ExternalReferences.url}
          element={<ExternalReferences profile={profile} />}
        />
      )}
      {isAdmin && (
        <Route
          path={BreadcrumbNameMap.CompanyEdit.url}
          element={<CompanyEdit />}
        />
      )}
      <Route
        path={BreadcrumbNameMap.GroupPermissionsDoctor.url}
        element={<GroupPermissionListPage role={Role.doctor} />}
      />
      <Route
        path={BreadcrumbNameMap.GroupPermissionsNurse.url}
        element={<GroupPermissionListPage role={Role.nurse} />}
      />
      <Route
        path={BreadcrumbNameMap.GroupPermissionsAdminCompany.url}
        element={<GroupPermissionListPage role={Role.admin} />}
      />
      <Route
        path={BreadcrumbNameMap.GroupPermissionAdd.url}
        element={<GroupPermissionCreatePage />}
      />
      <Route
        path={BreadcrumbNameMap.GroupPermissionEdit.url}
        element={<GroupPermissionEditPage />}
      />
      <Route
        path={BreadcrumbNameMap.Trackings.url}
        element={<TreatmentsDoctorNurse />}
      />
      <Route
        path={BreadcrumbNameMap.CreateTrackingPatient.url}
        element={<CreateTreatmentPatient />}
      />
      <Route
        path={BreadcrumbNameMap.TrackingPatient.url}
        element={<TreatmentsPatientByStaff />}
      />
      <Route
        path={BreadcrumbNameMap.TrackingGraph.url}
        element={<TreatmentsGraph />}
      />
      <Route
        path={BreadcrumbNameMap.TrackingGraph.urlAlt}
        element={<TreatmentsGraph />}
      />
      <Route
        path="/measurements/value/:alertId/:actorRole/:actorId"
        element={<AlertsByValueGraph />}
      />
      <Route
        path="/measurements/measure/:alertId/:actorRole/:actorId"
        element={<AlertsByNoMeasureGraph />}
      />
      <Route
        path={BreadcrumbNameMap.AlertConfigByNoMeasure.url}
        element={<AlertConfigByNoMeasure />}
      />
      <Route
        path={BreadcrumbNameMap.AlertConfigByValue.url}
        element={<AlertConfigByValue />}
      />
      <Route
        path={BreadcrumbNameMap.AlertConfigByFrequency.url}
        element={<AlertConfigByFrequency />}
      />
      {/* <Route path={BreadcrumbNameMap.AlertConfigSubscribers.url} element={<Subscribers />} /> */}
      <Route path="/recover" element={<ChangePassword />} />
      <Route path="/about" element={<About />} />
      <Route path="/support" element={<Support />} />
      <Route
        path={BreadcrumbNameMap.AlertConfigByFrequency.url}
        element={<AlertConfigByFrequency />}
      />
      <Route path={BreadcrumbNameMap.Forms.url} element={<FormsPage />} />
      <Route
        path={BreadcrumbNameMap.FormsCreate.url}
        element={<FormsCreatePage />}
      />
      <Route
        path={BreadcrumbNameMap.FormsEdit.url}
        element={<FormsEditPage />}
      />
      {isTeam && (
        <Route
          path={BreadcrumbNameMap.AdministrationPatients.url}
          element={<AdminPatients profile={profile} />}
        />
      )}
      {isTeam && (
        <Route
          path={BreadcrumbNameMap.AdministrationDoctors.url}
          element={<Doctors />}
        />
      )}
      {isTeam && (
        <Route
          path={BreadcrumbNameMap.AdministrationNurses.url}
          element={<Nurses />}
        />
      )}
      {isTeam && (
        <Route
          path={BreadcrumbNameMap.AdministrationAdminCompanies.url}
          element={<AdminCompany />}
        />
      )}
      {role === Role.admin && <Route path="/webhooks" element={<Webhooks />} />}
      <Route path="*" element={<AdministrationPage />} />
    </Routes>
  );

  const routes = () => {
    const management =
      localStorage.getItem("management") === "true" ? true : false;
    const shouldEndRegisterProcess = management
      ? false
      : (isUser && (notHasPassword || notSubscribed || notAcceptedLegal)) ||
        (isTeam && notHasPassword);

    if (shouldEndRegisterProcess) return nonSubscribedUserRoutes;
    if (isAdmin) return adminRoutes;
    if (isDoctor) return doctorRoutes;
    if (isNurse) return doctorRoutes;
    if (isUser) return patientRoutes;

    return isUser ? patientRoutes : doctorRoutes;
  };

  return (
    <Theme
      companyFont={company?.font}
      companyColors={{
        primary: company?.primary,
        secondary: company?.secondary,
      }}
    >
      {isEmbedded ? <></> : <Appbar />}
      {routes()}
    </Theme>
  );
};

export { LoggedApp };
