import React, { ReactElement } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import useStyles from './styles'
import { BreadcrumbDocto, BreadcrumbProps } from '@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto';
import { Link, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';

export interface PageProps {
  title?: string
  breadCrumbProps?: BreadcrumbProps;
  buttons?: React.ReactNode
  footer?: React.ReactNode
  filters?: React.ReactNode
  children: React.ReactNode
}

const Page: React.FC<PageProps> = (props: PageProps): ReactElement => {
  const classes = useStyles()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isEmbedded = searchParams.get("embedded") === "true";
  
  const breadcrumbItemsWithRouterLink = props.breadCrumbProps ? props.breadCrumbProps.breadcrumbItems.map(
    item => item.url ? { ...item, routerLink: Link } : item) : []

  return (
    <Box className={classes.content} display='flex' flexDirection='column'>
      {
        props.breadCrumbProps && !isEmbedded ?
          <Box p={3} display='flex'>

            <Box flexGrow={1}>
              <BreadcrumbDocto breadcrumbItems={breadcrumbItemsWithRouterLink} />
              <Box flexGrow={1}>{props.buttons}</Box>
            </Box>
          </Box>
          :
          props.title !== '' && !props.buttons && !props.filters ?
          <Box p={3} display='flex'>
            <Typography variant='h1'>{props.title}</Typography>
          </Box>
          :
          <Grid container p={3} spacing={1} columns={12} justifyContent="left" alignItems="center" display='flex'>
            <Grid item xs={9} lg={3}>
              <Typography variant='h1'>{props.title}</Typography>
            </Grid>
            <Grid item xs={3} lg={1}>
              <Box>{props.buttons}</Box>
            </Grid>
            <Grid item xs={12} lg={8}>
              {props.filters && <Box>{props.filters}</Box>}
            </Grid>
          </Grid>
      }

      <Box px={3} pb={props.footer ? '100px' : '20px'} flexGrow={1} display='flex' flexDirection='column'>
        {props.children}
      </Box>

      {props.footer && <Box className={classes.footer}>{props.footer}</Box>}
    </Box>
  )
}

export { Page }
