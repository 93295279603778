import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    margin: '0 auto',
    maxWidth: 500,
    paddingTop: 24,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  label:{
    fontWeight: 'bold'
  }
}))
