import { GetAlertConfigIndividualResponseDto } from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigIndividual";
import { GetAlertConfigTemplateResponseDto } from "@doctomatic/sdk/build/dto/Alerts/config/template/AlertConfigTemplate";
import { AlertState, GetAlertUserAckDto } from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { Role } from "@doctomatic/sdk/build/dto/User";
import AlertsDataRow from "../AlertsDataRow";
import { GetPatientResponseDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { GetSignResponseDto } from "@doctomatic/sdk/build/dto/Sign/Signs";
import { GetTreatmentResponseDto } from "@doctomatic/sdk/build/dto/Treatment/Treatments";

export default class AlertsByValueDataRow extends AlertsDataRow {
  value: string;
  sign: GetSignResponseDto;
  device: GetDeviceResponseDto;
  valueText?: string;
  readDeviceId?: number;

  constructor(
    id: number,
    patient: GetPatientResponseDto,
    color: string,
    label: string,
    comments: string,
    created_at: Date,
    ackBy: GetAlertUserAckDto[],
    trackings: GetTreatmentResponseDto[],
    actorId: number,
    actorRole: Role,
    state: AlertState,
    sign: GetSignResponseDto,
    measurement: number,
    onClickCheckbox: (item: any) => void,
    valueText?: string,
    readDeviceId?: number,
    viewModal?: (props: any) => React.ReactElement,
    individualConfig?: GetAlertConfigIndividualResponseDto,
    templateConfig?: GetAlertConfigTemplateResponseDto,
  ) {
    super(
      id,
      patient,
      color,
      label,
      comments,
      created_at,
      ackBy,
      trackings,
      actorId,
      actorRole,
      state,
      onClickCheckbox,
      viewModal,
      individualConfig,
      templateConfig,
    );
    this.sign = sign;
    this.value = measurement + " " + sign?.unit;
    this.device = { ...sign?.device, signs: [], forceTranslateImage: false, companyId: 0, typeName: "" };
    this.valueText = valueText;
    this.readDeviceId = readDeviceId;
  }

}
