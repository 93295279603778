import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Role } from '@doctomatic/sdk/build/dto/User';
import { validate, ValidationError } from 'class-validator';
import { Phone } from '../../../Phone';
import { Alert } from '@mui/material';
import { useApi } from '@doctomatic/sdk/build/Api';
import { useTranslation } from 'react-i18next';
import timezones from 'timezones-list';
import { GetNurseResponseDto, UpdateNurseRequestDto } from '@doctomatic/sdk/build/dto/Actors/Nurse/Nurse';

interface NurseEditProps {
  open: boolean;
  getNurseResponseDto: GetNurseResponseDto;
  onClose: () => void;
  onSave: (nurse: UpdateNurseRequestDto) => void;
}

const useStyles = makeStyles((theme: Theme) => ({ visible: { overflowY: 'visible' } }));

export const NurseEdit = ({ open, getNurseResponseDto, onClose, onSave }: NurseEditProps): React.ReactElement => {
  const { role } = useApi();

  const classes = useStyles();
  const { t } = useTranslation();
  
  const [name, setName] = useState<string|undefined>(getNurseResponseDto.name);
  const [email, setEmail] = useState<string|undefined>(getNurseResponseDto.email);
  const [phone, setPhone] = useState<string|undefined>(getNurseResponseDto.phone);
  const [timezone, setTimezone] = useState<string|undefined>(getNurseResponseDto.timezone);

  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [disableEditFields, setDisableEditFields] = useState(true);

  useEffect(() => {
    const checkErrors = async () => {
      const dto = new UpdateNurseRequestDto();
      dto.email = email;
      dto.name = name;
      dto.phone = phone;
      const errors = await validate(dto);
      setErrors(errors);
    }

    checkErrors();
  }, [open, name, email, phone]);

  useEffect(() => {
    if (role === Role.admin || role === Role.doctor || role === Role.nurse) {
      setDisableEditFields(true);
    }
  }, [role]);

  if (!getNurseResponseDto) return (<></>);
 
  let nurseToEdit: GetNurseResponseDto = getNurseResponseDto;
 
  let alert = '';
  let cameraAlert = '';
  let notificationsAlert = '';

  const nameError = Boolean(errors.find(e => e.property === 'name'));
  const emailError = Boolean(errors.find(e => e.property === 'email'));
  const phoneError = Boolean(errors.find(e => e.property === 'phone'));

  if (!nurseToEdit.hasPassword ) alert = t('UserEditPasswordAlert');
  if (nurseToEdit.role === Role.nurse && !nurseToEdit.cameraPermission) cameraAlert = t('UserCameraPermissionAlert');

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth='sm'
      classes={{paper: classes.visible}}
    >
      <DialogTitle>
        {t('ButtonEdit')} <span>{t('FieldNurse')}</span>
      </DialogTitle>

      <DialogContent className={classes.visible}>
        <TextField
          autoComplete='off'
          error={nameError}
          margin='dense'
          variant='outlined'
          name='name'
          label={t('FieldName')}
          type='text'
          fullWidth
          onChange={(event) => setName(event.target.value)}
          value={name}
          autoFocus
          required
        />
        <TextField
          autoComplete='off'
          error={emailError}
          margin='dense'
          variant='outlined'
          name='email'
          label={t('FieldEmail')}
          type='email'
          fullWidth
          onChange={(event) => setEmail(event.target.value)}
          value={email}
          required
          disabled={disableEditFields}
        />
        <Phone
          error={phoneError}
          onUpdate={(value) => setPhone(value)}
          value={phone ? phone : ''}
          disabled={disableEditFields}
        />
        <FormControl variant="outlined" fullWidth margin='normal' required>
          <InputLabel>{t('FieldTimezone')}</InputLabel>
          <Select
            label={t('FieldTimezone')}
            variant='outlined'
            onChange={(event) => setTimezone(event.target.value as string)}
            value={timezone}
          >
            {timezones.map(t => (
              <MenuItem key={t.tzCode} value={t.tzCode}>
                {t.label}
              </MenuItem>
            ))}
          </Select>  
        </FormControl>
        {
          alert !== '' && (
            <Box mt={2}>
              <Alert severity="warning">{alert}</Alert>
            </Box>
          )
        }
        {
          cameraAlert !== '' && (
            <Box mt={2}>
              <Alert severity="warning">{cameraAlert}</Alert>
            </Box>
          )
        }
        {
          notificationsAlert !== '' && (
            <Box mt={2}>
              <Alert severity="warning">{notificationsAlert}</Alert>
            </Box>
          )
        }
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display='inline-block'>
            <Button onClick={onClose} size='small'>{t('ButtonCancel')}</Button>
          </Box>
          <Button
            disabled={errors.length > 0}
            onClick={() => {
              let dto = new UpdateNurseRequestDto();
              if (nurseToEdit.email !== email) dto.email = email;
              if (nurseToEdit.name !== name) dto.name = name;
              if (nurseToEdit.phone !== phone) dto.phone = phone;
              if (nurseToEdit.timezone !== timezone) dto.timezone = timezone;
              onSave(dto);
            }}
            variant='contained'
            size='small'
          >
            {t('ButtonSave')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
