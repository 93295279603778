import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  UpdateDiseaseRequestDto,
  GetDiseaseResponseDto,
} from "@doctomatic/sdk/build/dto/Diseases";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { processError } from "../../../../App/errorToast";
import { ConfirmationTrackingTypeEdit } from "./ConfirmationTrackingTypeEdit";
import { UpdateFrequenciesTemplateRequestDto } from "@doctomatic/sdk/build/dto/FrequenciesTemplate/FrequenciesTemplate";
import { getDeviceName } from "../../utils";
import { useNavigate } from "react-router-dom";

interface TrackingTypeEditProps {
  open: boolean;
  diseaseToEdit: GetDiseaseResponseDto;
  onClose: () => void;
  onSave: (disease: UpdateDiseaseRequestDto) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  visible: { overflowY: "visible" },
}));

const TrackingTypeEdit = ({
  open,
  diseaseToEdit,
  onClose,
  onSave,
}: TrackingTypeEditProps): React.ReactElement => {
  const { useDevices, logout } = useApi();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { response: allDevicesResponse } = useDevices(
    true,
    processError(logout, navigate, t)
  );
  const allDevices = allDevicesResponse?.data;

  const [name, setName] = useState(diseaseToEdit.name);
  const [stripePriceId, setStripePriceId] = useState(
    diseaseToEdit.stripePriceId
  );
  const [devices, setDevices] = useState<number[]>(
    diseaseToEdit.devices?.map((d: GetDeviceResponseDto) => d.id)
  );
  const [openModalConfirmation, setOpenModalConfirmation] =
    useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
  const initialDevices = diseaseToEdit.devices?.map(
    (d: GetDeviceResponseDto) => d.id
  );

  const classes = useStyles();

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  if (!allDevices) return <></>;
  const handleDeviceChange = async (checked: boolean, deviceId: number) => {
    if (checked) {
      setDevices([...devices, deviceId]);
    } else {
      setDevices(devices.filter((d) => d !== deviceId));
    }
  };

  const handleSave = () => {
    const dto = new UpdateDiseaseRequestDto();
    dto.name = name;
    dto.devices = devices.map((value: number) => {
      const dto: UpdateFrequenciesTemplateRequestDto =
        new UpdateFrequenciesTemplateRequestDto();
      dto.deviceId = value;
      return dto;
    });
    dto.stripePriceId = stripePriceId;
    onSave(dto);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="sm"
        classes={{ paper: classes.visible }}
      >
        <DialogTitle>{t("DiseasesEditTitle")}</DialogTitle>

        <DialogContent className={classes.visible}>
          <TextField
            autoComplete="off"
            margin="dense"
            variant="outlined"
            name="name"
            label={t("FieldName")}
            type="text"
            fullWidth
            onChange={(event) => setName(event.target.value)}
            value={name}
            autoFocus
          />
          <TextField
            margin="dense"
            variant="outlined"
            name="priceId"
            label={t("FieldStripePriceId")}
            type="text"
            fullWidth
            onChange={(e) => setStripePriceId(e.target.value)}
            value={stripePriceId}
            autoFocus
          />
          <FormControl
            component="fieldset"
            style={{
              marginTop: 10,
              marginBottom: 10,
              marginRight: 20,
              marginLeft: 20,
            }}
          >
            <FormGroup>
              {allDevices?.map((d) => (
                <FormControlLabel
                  key={d.id}
                  control={
                    <Checkbox
                      name={getDeviceName(currentLanguage, d, t)}
                      checked={devices.includes(d.id)}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleDeviceChange(event.target.checked, d.id)
                      }
                    />
                  }
                  label={getDeviceName(currentLanguage, d, t)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Box m={2}>
            <Box mr={2} display="inline-block">
              <Button onClick={onClose} size="small">
                {t("ButtonCancel")}
              </Button>
            </Box>
            <Button
              onClick={() => {
                if (
                  devices.length == initialDevices.length &&
                  devices.every((device) => initialDevices.includes(device)) &&
                  diseaseToEdit.name === name &&
                  diseaseToEdit.stripePriceId === stripePriceId
                ) {
                  onClose();
                } else {
                  setOpenModalConfirmation(true);
                }
              }}
              variant="contained"
              size="small"
            >
              {t("ButtonSave")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <ConfirmationTrackingTypeEdit
        handleSave={handleSave}
        openModalConfirmation={openModalConfirmation}
        setOpenModalConfirmation={setOpenModalConfirmation}
        t={t}
      />
    </>
  );
};

export { TrackingTypeEdit };
