import { Box } from "@mui/material";
import {
  AlertConfigBaseForm,
  AlertConfigBaseProps,
} from "./AlertConfigBaseForm";
import {
  AlertConfigByValueBaseForm,
  AlertConfigByValueBaseProps,
} from "./AlertConfigByValueBaseForm";
import { styles } from "./styles";

export interface EditAlertConfigByValueProps
  extends AlertConfigBaseProps,
    AlertConfigByValueBaseProps {}

export const EditAlertConfigByValue = ({
  label,
  handleOnChangeLabel,
  color,
  handleOnChangeColor,
  time,
  setTime,
  timeUnit,
  setTimeUnit,
  condition,
  setCondition,
  alertConfig,
  setAlertConfig,
  signUnit,
  errors,
}: EditAlertConfigByValueProps): React.ReactElement => {
  return (
    <Box style={styles.titleContainer}>
      <AlertConfigBaseForm
        label={label}
        handleOnChangeLabel={handleOnChangeLabel}
        color={color}
        handleOnChangeColor={handleOnChangeColor}
      />
      <AlertConfigByValueBaseForm
        time={time}
        setTime={setTime}
        timeUnit={timeUnit}
        setTimeUnit={setTimeUnit}
        condition={condition}
        setCondition={setCondition}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
        signUnit={signUnit}
        errors={errors}
      />
    </Box>
  );
};
