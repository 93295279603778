import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  CreateFrequencyRequestDto,
  GetFrequencyResponseDto,
  Weekday,
} from "@doctomatic/sdk/build/dto/Frequencies";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useLoading } from "../../Loading/Loading";
import { toast } from "react-toastify";
import { Frequency } from "@doctomatic/components-react/build/Frequencies/Frequency";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import { GetPatientResponseDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { processError } from "../../../App/errorToast";
import "moment-timezone";
import moment from "moment-timezone";
import { getDeviceName } from "../utils";
import { useNavigate } from "react-router-dom";

const minStringToNumbers = (min: string): number[] =>
  min.split(",").map((value: string) => Number(value));

interface Props {
  open: boolean;
  userToFrequencyId: number;
  onClose: () => void;
  afterSave: () => Promise<void>;
}

const UserFrequency = ({
  open,
  userToFrequencyId,
  onClose,
  afterSave,
}: Props): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const week: { [key: string]: string } = {
    [Weekday.Monday]: t("Monday"),
    [Weekday.Tuesday]: t("Tuesday"),
    [Weekday.Wednesday]: t("Wednesday"),
    [Weekday.Thursday]: t("Thursday"),
    [Weekday.Friday]: t("Friday"),
    [Weekday.Saturday]: t("Saturday"),
    [Weekday.Sunday]: t("Sunday"),
  };
  const navigate = useNavigate();
  const { useFrequencies, useProfile, usePatient, logout } = useApi();
  const { setIsLoading } = useLoading();
  const { response: profileResponse } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const profile = profileResponse?.data;
  const { response: patientResponse, mutate } = usePatient(
    userToFrequencyId,
    true
  );
  const userToFrequency = patientResponse?.data;
  const { updateAll } = useFrequencies();
  const [frequencies, setFrequencies] = useState<GetFrequencyResponseDto[]>(
    userToFrequency?.frequencies || []
  );
  const [errors, setErrors] = useState<{ [id: string]: boolean }>({});
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    userToFrequency && setFrequencies(userToFrequency.frequencies);
  }, [userToFrequency]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const devices: GetDeviceResponseDto[] = useMemo<
    GetDeviceResponseDto[]
  >(() => {
    let deviceDtos: GetDeviceResponseDto[] = [];
    if (
      userToFrequency &&
      (userToFrequency as GetPatientResponseDto).treatments
    ) {
      for (const treatment of userToFrequency &&
        (userToFrequency as GetPatientResponseDto).treatments) {
        if (treatment.disease) {
          for (const device of treatment.disease.devices) {
            if (
              !deviceDtos.find((d: GetDeviceResponseDto) => d.id === device.id)
            ) {
              deviceDtos.push(device);
            }
          }
        }
      }
    }
    return deviceDtos;
  }, [userToFrequency]);

  return (
    <Dialog open={open} maxWidth={false}>
      <DialogTitle>
        {t("FrequencyTitle", { name: userToFrequency?.name })}
      </DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table style={{ tableLayout: "fixed", width: "1280px" }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "100px", paddingLeft: 0 }}>
                  {t("TableDevice")}
                </TableCell>
                <TableCell style={{ width: "200px" }}>
                  {t("TableFrequency")}
                </TableCell>
                <TableCell style={{ width: "680px", padding: "16px 8px" }}>
                  {t("TableHours")}
                </TableCell>
                <TableCell style={{ width: "200px", padding: 0 }}>
                  {t("TableNextNotification")}
                </TableCell>
                <TableCell style={{ width: "100px", padding: 0 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.map((device: GetDeviceResponseDto, index: number) => {
                const frequency: GetFrequencyResponseDto | undefined =
                  frequencies.find(
                    (f) => f.device && f.device.id === device.id
                  );

                const initialWeekdays: string[] =
                  frequency?.weekdays && frequency.weekdays !== ""
                    ? frequency.weekdays.split(",")
                    : [];
                const initialMin: number[] =
                  frequency?.min && frequency.min !== ""
                    ? minStringToNumbers(frequency.min)
                    : [0];

                return (
                  <TableRow key={`d${device.id}`}>
                    <TableCell style={{ paddingLeft: 0, height: "81px" }}>
                      {getDeviceName(currentLanguage, device, t)}
                    </TableCell>
                    {frequency ? (
                      <>
                        <Frequency
                          initialWeekdays={initialWeekdays}
                          initialMin={initialMin}
                          week={week}
                          onChange={async (d: string[], m: number[]) => {
                            let freq: GetFrequencyResponseDto =
                              new GetFrequencyResponseDto();
                            freq = {
                              ...frequency,
                              device: { ...device } as GetDeviceResponseDto,
                              weekdays: d.length > 1 ? d.join(",") : d[0] ?? "",
                              min: m.length > 1 ? m.join(",") : String(m[0]),
                            } as GetFrequencyResponseDto;
                            setFrequencies([
                              ...frequencies.filter(
                                (f) => f.device?.id !== device.id
                              ),
                              freq,
                            ]);
                            await mutate();
                          }}
                          onValidate={(val: boolean, msg: string) => {
                            setErrors({ ...errors, [device.id]: !val });
                          }}
                          translator={t}
                        />
                        <TableCell style={{ padding: 0 }}>
                          <Box display="flex">
                            <Tooltip
                              placement="top"
                              title={t("FrequencyDelete") || ""}
                            >
                              <IconButton
                                style={{ padding: "8px" }}
                                onClick={async () => {
                                  setFrequencies(
                                    frequencies.filter(
                                      (f) =>
                                        f.device && f.device.id !== device.id
                                    )
                                  );
                                  const { [device.id]: _, ...newErrors } =
                                    errors;
                                  setErrors(newErrors);
                                  await mutate();
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell style={{ padding: "16px 8px" }}>
                          {frequency.id ? (
                            <Tooltip
                              title={
                                <React.Fragment>
                                  <p>
                                    {moment(frequency.nextNotificationAt)
                                      .tz(userToFrequency?.timezone || "")
                                      .format("DD/MM/yyyy HH:mm")}{" "}
                                    ({userToFrequency?.timezone})
                                  </p>
                                  {profile &&
                                    userToFrequency?.timezone !==
                                      profile.timezone && (
                                      <p>
                                        {moment(
                                          frequency.nextNotificationAt
                                        ).format("DD/MM/yyyy HH:mm")}{" "}
                                        ({profile.timezone})
                                      </p>
                                    )}
                                  <p>
                                    {moment(frequency.nextNotificationAt)
                                      .utc()
                                      .format("DD/MM/yyyy HH:mm")}{" "}
                                    (UTC)
                                  </p>
                                </React.Fragment>
                              }
                            >
                              <p>
                                {moment(frequency.nextNotificationAt)
                                  .tz(userToFrequency?.timezone || "")
                                  .format("DD/MM/yyyy HH:mm")}
                              </p>
                            </Tooltip>
                          ) : (
                            <p>-</p>
                          )}
                        </TableCell>
                      </>
                    ) : (
                      <TableCell colSpan={6}>
                        <Button
                          variant="text"
                          startIcon={<AddCircleIcon />}
                          onClick={async () => {
                            let f = new GetFrequencyResponseDto();
                            f.device = device;
                            f.weekdays = "";
                            f.min = "0";
                            setFrequencies([...frequencies, f]);
                            await mutate();
                          }}
                        >
                          {t("FrequencyAdd")}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button
              onClick={async () => {
                await mutate();
                onClose();
              }}
              size="small"
            >
              {t("ButtonCancel")}
            </Button>
          </Box>
          <Button
            disabled={Object.values(errors).includes(true)}
            variant="contained"
            size="small"
            onClick={async () => {
              setIsLoading(true);
              try {
                userToFrequency &&
                  (await updateAll(
                    userToFrequency.id,
                    frequencies.map((f) => {
                      const dto = new CreateFrequencyRequestDto();
                      dto.deviceId = f.device?.id || 0;
                      dto.weekdays = f.weekdays;
                      dto.min = f.min;
                      return dto;
                    })
                  ));
                toast.success(`${t("FrequencySaved")}`);
              } catch (err: any) {
                toast.error(
                  `${t("FrequencyError")}: ${
                    err.response?.data?.message || err.message
                  }`
                );
              }
              await mutate();
              await afterSave();
              setIsLoading(false);
            }}
          >
            {t("ButtonSave")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { UserFrequency };
