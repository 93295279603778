import React from 'react';
// Import React to prevent compiler errors
import { ViewAlertsCell } from '@doctomatic/components-react/build/Alerts/ViewAlertsCell';
import { TextWordsQuantityCell } from '@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell';
import { GridColumns, GridValueGetterParams } from '@mui/x-data-grid';
import AlertsByNoMeasureDataRow from './AlertsByNoMeasureDataRow';
import { Checkbox } from '@mui/material';
import { BreadcrumbNameMap } from '../../../../breadcrumbMap';
import { Link, NavLink } from 'react-router-dom';
import { ElementGridStringEdit } from '@doctomatic/components-react/build/ElementColumn/ElementGridStringEdit';
import { sortText } from '../../../../App/sortFunctions';
import { getDeviceName, toLocaleString } from '../../utils';
import { Launch } from '@mui/icons-material';
import { AlertTypeAlert } from '@doctomatic/sdk/build/dto/Alerts/IAlerts';

export const AlertsByNoMeasureGridColumns = (
  t: (text: string) => string,
  showExternalId: boolean | undefined,
  timezone: string,
  currentLanguage: string,
  showModal?: (item: any) => void,
  showStateModal?: (id: number, item: any) => void,
  onSaveComments?: (
    alertId: number,
    comment: string | undefined,
    type?: string
  ) => Promise<boolean>
): GridColumns<AlertsByNoMeasureDataRow> => {
  //TODO add viewModals
  const gridColumns: any[] = [
    {
      field: "color",
      headerName: t("Level"),
      width: 75,
      align: "center",
      valueGetter: (params: GridValueGetterParams) => params.row.color,
      sortable: false,
      renderCell: (params: { row: AlertsByNoMeasureDataRow }) => {
        return (
          <ViewAlertsCell
            alerts={[{ id: params.row.id, color: params.row.color }]}
            row={undefined}
            viewModal={() => <p></p>}
          />
        );
      },
    },
    {
      field: "state",
      headerName: t("AlertState"),
      width: 120,
      align: "center",
      valueGetter: (params: GridValueGetterParams) => params.row.state,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: AlertsByNoMeasureDataRow }) => {
        const alertStateCapitalized =
          params.row.state[0].toUpperCase() +
          params.row.state.substr(1).toLowerCase();
        return (
          <TextWordsQuantityCell
            onClickModal={() =>
              showStateModal!(params.row.id, params.row.state)
            }
            item={params.row}
            words={[t(alertStateCapitalized)]}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "comments",
      headerName: t("Comments"),
      width: 95,
      align: "center",
      valueGetter: (params: GridValueGetterParams) => params.row.comments,
      sortable: false,
      renderCell: (params: { row: AlertsByNoMeasureDataRow }) => {
        return (
          <ElementGridStringEdit
            textCancel={t("Cancel")}
            textSave={t("Save")}
            textLabelField={t("Comments")}
            itemToAddCommentId={params.row.id}
            text={params.row.comments}
            alertType={AlertTypeAlert.alert_by_no_measure}
            onSave={
              onSaveComments
                ? onSaveComments
                : async (
                    alertId: number,
                    comment: string | undefined,
                    type?: string
                  ): Promise<boolean> => {
                    return true;
                  }
            }
            onClose={() => {}}
          />
        );
      },
    },
    {
      field: "patient.name",
      headerName: t("Patient"),
      width: 250,
      valueGetter: (params: GridValueGetterParams) => params.row.patient.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: AlertsByNoMeasureDataRow }) => {
        return (
          <TextWordsQuantityCell
            item={params.row}
            words={[params.row.patient.name]}
            page={BreadcrumbNameMap.Patient.url.replace(
              ":patientId",
              params.row.patient.id.toString()
            )}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "created_at",
      headerName: t("Date"),
      width: 150,
      valueGetter: (params: GridValueGetterParams) => params.row.created_at,
      sortComparator: (v1: any, v2: any) =>
        new Date(v2!).getTime() - new Date(v1!).getTime(),
      renderCell: (params: { row: AlertsByNoMeasureDataRow }) => {
        return <p>{toLocaleString(params.row.created_at)}</p>;
      },
    },
    {
      field: "typeAlert",
      headerName: t("HeaderLabel"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.label,
      sortable: false,
      renderCell: (params: { row: AlertsByNoMeasureDataRow }) => {
        const alertConfigUrl =
          BreadcrumbNameMap.AlertConfigByNoMeasure.url.replace(
            ":patientId",
            params.row.patient.id.toString()
          );
        const content: JSX.Element = (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {params.row.individualConfig && params.row.individualConfig.id ? (
              <NavLink
                to={alertConfigUrl.concat(
                  `?alertConfigId=${params.row.individualConfig.id}`
                )}
              >
                <Launch />
              </NavLink>
            ) : params.row.templateConfig && params.row.templateConfig.id ? (
              <NavLink
                to={alertConfigUrl.concat(
                  `?alertConfigId=${params.row.templateConfig.id}`
                )}
              >
                <Launch />
              </NavLink>
            ) : (
              "--"
            )}
            <p style={{ marginLeft: 5 }}></p>
            <p>
              {params.row.device?.typeName === "Form" &&
              (params.row.label === "Yes" || params.row.label === "No")
                ? t(params.row.label)
                : params.row.label}
            </p>
          </div>
        );
        return content;
      },
    },
    {
      field: "device.name",
      headerName: t("HeaderDevice"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.device?.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: AlertsByNoMeasureDataRow }) => {
        return <p>{getDeviceName(currentLanguage, params.row.device, t)}</p>;
      },
    },
    {
      field: "trackings",
      headerName: t("HeaderTracking"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        const words = params.row.trackings.map(
          (tracking: any) => tracking.name
        );
        return words.join(", ");
      },
      sortable: false,
      renderCell: (params: { row: AlertsByNoMeasureDataRow }) => {
        const trackingsName = params.row.trackings.map(
          (tracking) => tracking.name
        );
        return (
          <TextWordsQuantityCell
            tooltipContent={{ title: t("Trackings"), lines: trackingsName }}
            item={params.row}
            words={trackingsName}
            page={BreadcrumbNameMap.Trackings.url}
            routerLink={Link}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "ackBy",
      headerName: t("HeaderAckBy"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        const texts =
          params.row.ackBy && params.row.ackBy.map((ack: any) => ack.user.name);
        if (texts.length === 0) texts.push("---");
        return texts.join(", ");
      },
      sortable: false,
      renderCell: (params: { row: AlertsByNoMeasureDataRow }) => {
        const texts =
          params.row.ackBy && params.row.ackBy.map((ack) => ack.user.name);
        if (texts.length === 0) texts.push("---");

        return (
          <TextWordsQuantityCell
            routerLink={Link}
            item={params.row}
            words={texts}
            onClickModal={() => showModal!(params.row)}
            tooltipContent={{ title: t("AckBy"), lines: texts }}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "active",
      headerName: t("ConfigAlert"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.individualConfig
          ? params.row.individualConfig.active
            ? t("Active")
            : t("Deactive")
          : t("Active"),
      sortable: false,
      renderCell: (params: { row: AlertsByNoMeasureDataRow }) => {
        const active = params.row.individualConfig
          ? params.row.individualConfig.active
          : params.row.templateConfig?.active;
        return (
          <TextWordsQuantityCell
            routerLink={Link}
            item={params.row}
            words={[active ? t("Active") : t("Deactive")]}
            page={BreadcrumbNameMap.AlertConfigByNoMeasure.url.replace(
              ":patientId",
              params.row.patient.id.toString()
            )}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "setAck",
      headerName: t("HeaderActions"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.checkbox,
      sortable: false,
      renderCell: (params: { row: AlertsByNoMeasureDataRow }) => (
        <Checkbox
          checked={params.row.checkbox}
          disabled={params.row.checkbox}
          onChange={() => {
            params.row.onClickCheckbox(params.row);
          }}
        />
      ),
    },
  ];

  showExternalId &&
    gridColumns.unshift({
      field: "externalId",
      headerName: t("ExternalId"),
      width: 200,
      align: "left",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.patient.externalId ? params.row.patient.externalId : "--",
      sortable: false,
      renderCell: (params: { row: AlertsByNoMeasureDataRow }) => (
        <p>
          {params.row.patient.externalId ? params.row.patient.externalId : "--"}
        </p>
      ),
    });

  return gridColumns;
};
