import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { validate, ValidationError } from 'class-validator'
import { useTranslation } from 'react-i18next'
import { CreateLocationRequestDto, ItemLocation } from '@doctomatic/sdk/build/dto/Location/Location';
import { SelectModal } from '@doctomatic/components-react/build/SelectModal/SelectModal'
import SelectLocationDataRow from '../../../Modals/SelectLocationModal/SelectLocationDataRow';
import { SelectGridColumns, SelectGridColumnsProps } from '@doctomatic/components-react/build/SelectModal/SelectGridColumns';

interface Props {
  locations: ItemLocation[] | undefined;
  open: boolean;
  onClose: () => void;
  onSave: (user: CreateLocationRequestDto) => void;
}

const useStyles = makeStyles((theme: Theme) => ({ visible: { overflowY: 'visible' } }))

const AdminLocationCreateModal = ({ open, onClose, onSave, locations }: Props): React.ReactElement => {
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [parentSelected, setParentSelected] = useState<ItemLocation | null>(null);
  const [openSelectLocationModal, setOpenSelectLocationModal] = useState(false);
  const [errors, setErrors] = useState<ValidationError[]>([])
  const classes = useStyles()

  const handleClose = (setOpen: Dispatch<SetStateAction<boolean>>) => setOpen(false);

  /* Modal selection of location */
  const selectedRow = (dataRow: SelectLocationDataRow) => {
    setParentSelected(dataRow);
  }

  const selectLocationGridColumnsByLibraryProps: SelectGridColumnsProps<SelectLocationDataRow> = {
    columns: [{
      field: 'name',
      headerName: t('Name'),
      width: 250,
      align: 'left',
    }],
    selectEvent: selectedRow,
    handleClose: () => handleClose(setOpenSelectLocationModal),
    textButton: t('Select')
  }

  const getSelectModal = (items: ItemLocation[] | undefined) => {
    if (!items) return <></>;
    
    return <SelectModal<SelectLocationDataRow>
      breadcrumbItems={[{ name: t('Locations'), url: "" }, { name: t('Selection'), url: "" }]}
      permissions={[]}
      open={openSelectLocationModal}
      handleClose={() => handleClose(setOpenSelectLocationModal)}
      item={undefined}
      columns={SelectGridColumns<SelectLocationDataRow>(selectLocationGridColumnsByLibraryProps)}
      rows={items} />
  }
  /* End Modal selection of location */

  useEffect(() => {
    setName('')
    setDescription('')
    setParentSelected(null)
  }, [open])

  useEffect(() => {
    const checkErrors = async () => {
      const dto = new CreateLocationRequestDto()
      dto.name = name
      dto.description = description
      dto.parentId = parentSelected ? parentSelected.id : undefined;
      const errors = await validate(dto)
      setErrors(errors)
    }
    checkErrors()
  }, [open, name, description, parentSelected])

  return (

    <Dialog open={open} fullWidth={true} maxWidth='sm' classes={{ paper: classes.visible }}>
      <DialogTitle>
        {t('ButtonCreate')}
      </DialogTitle>

      <DialogContent className={classes.visible}>
        <TextField inputProps={
          { readOnly: true, }
        } margin='dense' variant='outlined' name='name' fullWidth helperText={t('FieldParent')}
          onClick={() => setOpenSelectLocationModal(true)}
          type='text' value={parentSelected?.name} />

        <TextField margin='dense' variant='outlined' name='name' label={t('FieldName')} type='text' fullWidth onChange={(e) => setName(e.target.value)} value={name} autoFocus required />
        <TextField multiline rows={4} maxRows={Infinity} margin='dense' variant='outlined' name='description' label={t('FieldDescription')} type='text' fullWidth onChange={(e) => setDescription(e.target.value)} value={description} />

        {getSelectModal(locations)}
      </DialogContent>
      <DialogActions>
        <Box m={2}>
          <Box mr={2} display='inline-block'>
            <Button onClick={onClose} size='small'>{t('ButtonCancel')}</Button>
          </Box>
          <Button disabled={errors.length > 0} onClick={() => {
            const dto = new CreateLocationRequestDto();
            dto.name = name;
            dto.description = description;
            dto.parentId = parentSelected ? parentSelected.id : undefined;
            onSave(dto);
          }} variant='contained' size='small'>{t('ButtonSave')}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export { AdminLocationCreateModal }


