import React from 'react';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
var ITEM_HEIGHT = 48;
var ITEM_PADDING_TOP = 8;
var MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function MultipleSelect(_a) {
    var placeholder = _a.placeholder, options = _a.options, selectedValues = _a.selectedValues, setSelectedValues = _a.setSelectedValues;
    return (React.createElement("div", null,
        React.createElement(FormControl, { sx: { width: '100%' } },
            React.createElement(InputLabel, { id: "demo-multiple-name-label" }, placeholder),
            React.createElement(Select, { labelId: "demo-multiple-name-label", id: "demo-multiple-name", multiple: true, value: selectedValues, onChange: function (_a) {
                    var value = _a.target.value;
                    setSelectedValues(typeof value === 'string' ? value.split(',') : value);
                }, input: React.createElement(OutlinedInput, { label: placeholder }), renderValue: function (selected) { return selected.join(', '); }, MenuProps: MenuProps }, options.map(function (option) { return (React.createElement(MenuItem, { key: option, value: option },
                React.createElement(Checkbox, { checked: selectedValues.indexOf(option) > -1 }),
                React.createElement(ListItemText, { primary: option }))); })))));
}
