/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Modal,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { CustomSelect } from "@doctomatic/components-react/build/AlertConfig/CustomSelect";
import { CustomSwitch } from "@doctomatic/components-react/build/AlertConfig/CustomSwitch";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";
import { ModalBaseProps } from "../../../../Modals/modalBaseProps";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { IUseAlertConfigsByFrequencyIndividual } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByFrequency";
import { CustomTextInput } from "@doctomatic/components-react/build/AlertConfig/CustomTextInput";
import { toast } from "react-toastify";
import {
  ItemAlertConfigByFrequencyIndividualDto,
  AddAlertConfigByFrequencyIndividualRequestDto,
  UpdateAlertConfigByFrequencyIndividualRequestDto,
} from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByFrequencyIndividual";
import { processError } from "../../../../../App/errorToast";
import {
  getWeekFormatted,
  minStringToNumbers,
  minToHHmm,
} from "../../../../../utils/frequencies";
import { FrequencyFormatted } from "./AddAlertConfigByFrequencyModal";
import { INotification } from "@doctomatic/sdk/build/dto/Alerts/group/IAlertGroup";
import { useNavigate } from "react-router-dom";

export interface UpdateAlertConfigByFrequencyModalProps extends ModalBaseProps {
  patientId: number;
  alertConfigByFrequencyIndividual: ItemAlertConfigByFrequencyIndividualDto;
  permissions: Array<string>;
  messengerChannel: string;
}

export const UpdateAlertConfigByFrequencyModal = ({
  patientId,
  open,
  alertConfigByFrequencyIndividual,
  handleClose,
  messengerChannel,
}: UpdateAlertConfigByFrequencyModalProps): React.ReactElement => {
  const initNotification = JSON.parse(
    alertConfigByFrequencyIndividual.notification
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { useAlertConfigsByFrequency, usePatient, id, logout }: IProvideApi =
    useApi();
  const { patch, add }: IUseAlertConfigsByFrequencyIndividual =
    useAlertConfigsByFrequency(false, processError(logout, navigate));

  const initialValue: ItemAlertConfigByFrequencyIndividualDto =
    alertConfigByFrequencyIndividual;
  const [alertConfig, setAlertConfig] =
    useState<ItemAlertConfigByFrequencyIndividualDto>(initialValue);
  const { response: patientResponse } = usePatient(patientId, true);
  const frequencyUser = patientResponse?.data;
  const [frequencies, setFrequencies] = useState<FrequencyFormatted[]>([]);
  const [notification, setNotification] = useState<INotification>({
    sms: initNotification.sms,
    email: initNotification.email,
    pushNotification: initNotification.pushNotification,
  });

  const subscribersIncludesLoggedUser =
    alertConfigByFrequencyIndividual.subscribers &&
    alertConfigByFrequencyIndividual.subscribers.filter(
      (subscriber) => subscriber.user && subscriber.user.id === id
    ).length === 1;

  const [subscribe, setSubscribe] = useState<boolean>(
    subscribersIncludesLoggedUser
  );

  useEffect(() => {
    if (frequencyUser) {
      const frequenciesFormatted = frequencyUser.frequencies.map(
        (frequency) => {
          return {
            id: frequency.id,
            value: {
              weekdays: frequency.weekdays,
              min: frequency.min,
              deviceName: frequency.device ? frequency.device.name : "",
            },
          };
        }
      );
      setFrequencies(frequenciesFormatted);
    }
  }, [frequencyUser, open, patientId]);

  useEffect(() => {
    handleAlertConfig(notification, "notification");
  }, [notification]);

  // TODO
  const colors: string[] = ["red", "yellow", "green", "blue", "orange"];

  const handleAlertConfig = useCallback(
    (value: any, field: string): void => {
      setAlertConfig({ ...alertConfig, [field]: value });
    },
    [alertConfig]
  );

  const handleNotification = (value: boolean, field: string) => {
    setNotification({ ...notification, [field]: value });
  };

  const handleFormSubmit = async (): Promise<void> => {
    if (!alertConfig.customAlertConfigTemplate) {
      //es una alertconfig individual sin relación con ninguna alertconfig Template
      const dto: UpdateAlertConfigByFrequencyIndividualRequestDto =
        new UpdateAlertConfigByFrequencyIndividualRequestDto();
      Object.assign(dto, alertConfig);
      dto.subscribe = subscribe;
      try {
        if (alertConfig && alertConfig.id) {
          await patch(alertConfig.id, dto);
          handleClose();
          setAlertConfig(initialValue);
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        return;
      }
    }
    if (
      alertConfig.customAlertConfigTemplate &&
      alertConfig.customAlertConfigTemplate.id === alertConfig.id
    ) {
      //Es una alert Config individual emulada de una alertconfig template, creamos una individual usando de base la template
      //TODO: en el caso de ser igual descartar la creación.
      try {
        const dto: AddAlertConfigByFrequencyIndividualRequestDto =
          new AddAlertConfigByFrequencyIndividualRequestDto();
        Object.assign(dto, alertConfig);
        dto.patientId = patientId;
        dto.customAlertConfigTemplateId =
          alertConfig.customAlertConfigTemplate?.id;
        dto.subscribe = subscribe;

        await add(dto);
        handleClose();
        setAlertConfig(initialValue);
        return;
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        return;
      }
    }
    if (
      alertConfig.customAlertConfigTemplate &&
      alertConfig.customAlertConfigTemplate.id !== alertConfig.id
    ) {
      //Es una alert Config individual emulada que customiza los valores de la alert config Template
      const dto: UpdateAlertConfigByFrequencyIndividualRequestDto =
        new UpdateAlertConfigByFrequencyIndividualRequestDto();
      Object.assign(dto, alertConfig);
      dto.subscribe = subscribe;
      try {
        if (alertConfig && alertConfig.id) {
          await patch(alertConfig.id, dto);
          handleClose();
          setAlertConfig(initialValue);
          handleClose();
          setAlertConfig(initialValue);
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        return;
      }
    }
  };

  const colorMenuItems: JSX.Element[] = colors.map(
    (c: string, index: number) => (
      <MenuItem key={index} value={c}>
        <Box
          component="div"
          style={{
            backgroundColor: c,
            width: 20,
            height: 20,
          }}
        />
      </MenuItem>
    )
  );

  const handleOnChangeLabel = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleAlertConfig(e.target.value, "label");
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          <Grid container spacing={1} columns={12} xs={12}>
            <Grid item xs={6}>
              <CustomTextInput
                value={alertConfig.label}
                onChange={handleOnChangeLabel}
                required={true}
                helperText={t("FieldLabel")}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                value={alertConfig.color}
                onChange={(e: SelectChangeEvent) => {
                  handleAlertConfig(e.target.value, "color");
                }}
                menuItems={colorMenuItems}
                helperText={`${t("FieldLevel")}`}
                required={true}
                minWidth={"100%"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} columns={12} xs={12}>
            <Grid item xs={12}>
              <CustomSelect
                helperText={`${t("Frequency")}`}
                minWidth={"100%"}
                autoWidth={false}
                value={alertConfig.frequencyId?.toString()}
                onChange={(e: SelectChangeEvent) => {
                  handleAlertConfig(e.target.value, "frequencyId");
                }}
                sx={{
                  width: "10%",
                }}
                menuItems={frequencies.map(
                  (f: FrequencyFormatted, index: number) => {
                    const weekdays = f.value.weekdays.split(",");
                    const weekdaysFormatted = weekdays
                      .map((weekday) => getWeekFormatted(weekday, t))
                      .join(", ");

                    const hoursFormatted = minStringToNumbers(f.value.min)
                      .map((item) => minToHHmm(item))
                      .join(", ");
                    return (
                      <MenuItem
                        key={index}
                        value={f.id}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          whiteSpace: "unset",
                          wordBreak: "keep-all",
                          borderBottom:
                            index === frequencies.length - 1
                              ? undefined
                              : "2px solid lightgrey",
                        }}
                      >
                        <p style={{ margin: 4 }}>{f.value.deviceName}</p>
                        <p
                          style={{
                            margin: 4,
                          }}
                        >
                          {weekdaysFormatted}
                        </p>
                        <p style={{ margin: 4 }}>{hoursFormatted}</p>
                      </MenuItem>
                    );
                  }
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} columns={12} xs={12}>
            <Grid item xs={6}>
              <TextField
                type="number"
                label={t("FieldTimeout")}
                value={alertConfig.timeout}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => handleAlertConfig(Number(e.target.value), "timeout")}
                sx={{ m: 1, minWidth: "100%" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                label={t("FieldTimeToValidate")}
                value={alertConfig.timeToValidate}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) =>
                  handleAlertConfig(Number(e.target.value), "timeToValidate")
                }
                sx={{ m: 1, minWidth: "100%" }}
              />
            </Grid>
          </Grid>

          <Box style={styles.switchesContainer}>
            <Grid container spacing={1} columns={12} xs={12}>
              <Grid item xs={3}>
                <CustomSwitch
                  checked={notification.pushNotification}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => handleNotification(checked, "pushNotification")}
                  helperText={`${t("FieldPushNotifyPatient")}*`}
                  required={true}
                  fontSize={14}
                  minWidth={"100%"}
                />
              </Grid>
              {JSON.parse(messengerChannel).sms && (
                <Grid item xs={3}>
                  <CustomSwitch
                    checked={notification.sms}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => handleNotification(checked, "sms")}
                    helperText={`${t("FieldSMSNotifyPatient")}*`}
                    required={true}
                    fontSize={14}
                    minWidth={"100%"}
                  />
                </Grid>
              )}
              {JSON.parse(messengerChannel).email && (
                <Grid item xs={3}>
                  <CustomSwitch
                    checked={notification.email}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => handleNotification(checked, "email")}
                    helperText={`${t("FieldEmailNofityPatient")}*`}
                    required={true}
                    fontSize={14}
                    minWidth={"100%"}
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <CustomSwitch
                  checked={subscribe}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => setSubscribe(checked)}
                  helperText={`${t("FieldSubscribe")}`}
                  fontSize={14}
                  minWidth={"100%"}
                />
              </Grid>
            </Grid>
          </Box>

          <Box style={styles.btnSubmit}>
            <Button variant="contained" onClick={handleFormSubmit}>
              {t("UpdateAlert")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
