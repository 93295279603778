import React from "react";
import { useTranslation } from "react-i18next";
import { FlexLayoutGridProps } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { ViewModalProps } from "@doctomatic/components-react/build/ViewModalProps";
import { useApi } from "@doctomatic/sdk/build/Api";
import { SelectModal } from "@doctomatic/components-react/build/SelectModal/SelectModal";
import {
  SelectGridColumns,
  SelectGridColumnsProps,
} from "@doctomatic/components-react/build/SelectModal/SelectGridColumns";
import SelectLocationDataRow from "../../Modals/SelectLocationModal/SelectLocationDataRow";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router-dom";

export interface AssignLocationModalProps
  extends ViewModalProps,
    FlexLayoutGridProps<SelectLocationDataRow> {
  handleAssign: (id: number) => void;
}

export const AssignLocationModal = (
  props: AssignLocationModalProps
): React.ReactElement => {
  const navigate = useNavigate();
  const { useLocations, logout } = useApi();
  const { t } = useTranslation();
  const { response } = useLocations(true, processError(logout, navigate, t));
  const originalRows = response?.data ?? [];

  const selectLocationGridColumnsByLibraryProps: SelectGridColumnsProps<SelectLocationDataRow> =
    {
      columns: [
        {
          field: "name",
          headerName: t("Name"),
          width: 250,
          align: "left",
        },
      ],
      selectEvent: (row) => props.handleAssign(row.id),
      handleClose: () => props.handleClose(),
      textButton: t("Select"),
    };

  return (
    <SelectModal<SelectLocationDataRow>
      breadcrumbItems={[
        { name: t("Locations"), url: "" },
        { name: t("Selection"), url: "" },
      ]}
      permissions={[]}
      open={props.open}
      handleClose={() => props.handleClose()}
      item={undefined}
      columns={SelectGridColumns<SelectLocationDataRow>(
        selectLocationGridColumnsByLibraryProps
      )}
      rows={originalRows}
    />
  );
};
