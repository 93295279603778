import React from "react";
import { Box } from "@mui/material";
import { Measurements } from "../Measurements";
var styles = {
    content: {
        backgroundColor: "#FFFFFF",
        flexGrow: 1,
    },
    box: {
        width: "100%",
        color: "#3e4247",
        margin: 0,
        fontSize: "0.875rem",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 400,
        lineHeight: 1.43,
        backgroundColor: "#fafafa",
        height: "50vh",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    measurements: {
        maxHeight: "100%",
        height: "300px",
        width: "100%",
    },
};
export var GraphAlert = function (_a) {
    var device = _a.device, measurements = _a.measurements, t = _a.t, language = _a.language, getUrl = _a.getUrl, onSaveMeasurement = _a.onSaveMeasurement, editMeasurementsPermissions = _a.editMeasurementsPermissions, deleteMeasurementsPermissions = _a.deleteMeasurementsPermissions, onDeleteOneMeasurement = _a.onDeleteOneMeasurement, onDeleteManyMeasurements = _a.onDeleteManyMeasurements;
    var key = "graphAlert";
    return (React.createElement(Box, { style: styles.box },
        React.createElement(Box, { style: styles.content, display: "flex", flexDirection: "column" },
            React.createElement(Box, { display: "flex", style: styles.measurements },
                React.createElement(Measurements, { key: key, measurements: measurements, device: device, 
                    // TODO
                    limits: [], t: t, language: language, getUrl: getUrl, onSaveMeasurement: onSaveMeasurement, editMeasurementsPermissions: editMeasurementsPermissions, deleteMeasurementsPermissions: deleteMeasurementsPermissions, onDeleteOneMeasurement: onDeleteOneMeasurement, onDeleteManyMeasurements: onDeleteManyMeasurements })))));
};
