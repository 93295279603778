import { useTranslation } from "react-i18next";
import { Box, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { TimeInput } from "@doctomatic/components-react/build/AlertConfig/TimeInput";
import { Sign } from "@doctomatic/components-react/build/Graphs/models";
import { styles } from "./styles";
import { CustomCheckboxNotDisabled } from "@doctomatic/components-react/build/Checkbox/CustomCheckboxNotDisabled";
import { TimeUnit } from "./alertConfig.interface";

export interface AlertConfigByNoMeasureBaseProps {
  time: number;
  setTime: (value: number) => void;
  timeUnit: string;
  setTimeUnit: (value: string) => void;
  signs: Sign[];
  requestedSigns: any;
  setRequestedSigns: (value: any) => void;
}
export const AlertConfigByNoMeasureBaseForm = ({
  time,
  setTime,
  timeUnit,
  setTimeUnit,
  signs,
  requestedSigns,
  setRequestedSigns,
}: AlertConfigByNoMeasureBaseProps): React.ReactElement => {
  const { t } = useTranslation();
  const timeUnits: TimeUnit[] = [
    { text: t("hours"), value: "h" },
    { text: t("days"), value: "d" },
  ];

  const timeUnitMenuItems: JSX.Element[] = timeUnits.map(
    (t: TimeUnit, index: number) => (
      <MenuItem key={index} value={t.value}>
        {t.text}
      </MenuItem>
    )
  );

  const handleRequestedSign = (signName: string) => {
    setRequestedSigns({
      ...requestedSigns,
      [signName]: !requestedSigns[signName],
    });
  };
  return (
    // Max time
    // hours or days
    // Required signs if device.signs.length > 1

    <>
      <TimeInput
        time={time}
        onChangeTime={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => setTime(parseInt(e.target.value))}
        timeUnit={timeUnit}
        onChangeTimeUnit={(e: SelectChangeEvent) => setTimeUnit(e.target.value)}
        menuItems={timeUnitMenuItems}
        helperText={`${t("FieldMaxTimeBetween")}`}
        required={true}
      />

      {signs && signs.length > 1 && (
        <Box style={styles.signsContainer}>
          <Typography>{t("SelectRequiredSigns")}</Typography>
          <Box style={styles.checkboxContainer}>
            {signs.map((s: Sign) => {
              return (
                <Box style={styles.checkboxItem}>
                  <CustomCheckboxNotDisabled
                    defaultValue={requestedSigns[s.name]}
                    onChange={() => handleRequestedSign(s.name)}
                  />
                  <Typography>{t(s.name)}</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};
