import React, { useRef, useState, useEffect } from "react";
import {
  Modal,
  Box,
  IconButton,
  styled,
  Button,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useApi } from "@doctomatic/sdk/build/Api";
import { processError } from "../../../App/errorToast";
import { useLocation, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import {
  Form,
  FormResponse,
  Question,
  ResponseOfQuestionFactory,
  T,
} from "@doctomatic/sdk/build/dto/ReadForm/types/ReadFormTypes";
import QuestionForm from "../../QuestionForm/QuestionForm";
import { ConfigError, ResponseApi } from "@doctomatic/sdk/build/global";
import { CreateQuestionImageResponseDto } from "@doctomatic/sdk/build/dto/ResponseQuestionImage";
import {
  CreateReadFormRequestDto,
  CreateReadFormResponseDto,
} from "@doctomatic/sdk/build/dto/ReadForm/ReadFormDto";
import { IResponseOfQuestionImage } from "@doctomatic/sdk/build/dto/ReadForm/types/IReadFormTypes";
import { toast } from "react-toastify";
import { ColoredSwitch } from "../Camera/CameraCore";
import QuizIcon from "@mui/icons-material/Quiz";
import QrCodeIcon from "@mui/icons-material/QrCode";
import QRCodeCanvas, { ErrorLevel } from "../../QRCodes/QRCodeCanvas";

interface IParams {
  userId: string;
  formId: string;
}

type TypeAddQuestionImageFunc = (
  signId: number,
  formData: FormData,
  patientId?: number | undefined
) => Promise<ResponseApi<CreateQuestionImageResponseDto>>;
type TypeAddReadFormFunc = (
  readForm: CreateReadFormRequestDto
) => Promise<ResponseApi<CreateReadFormResponseDto>>;

export const Forms = (): React.ReactElement => {
  const { userId, formId }: IParams = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const {
    logout,
    useDevices,
    useReadForm,
    useQuestionImage,
    useCompany,
    companyId,
    lang,
    id: loggedId,
    token,
    readDeviceDescription,
  } = useApi();
  const { response: company } = useCompany(companyId, true);
  const { getDevice } = useDevices(false, processError(logout, navigate, t));
  const { add: addReadForm } = useReadForm(false, ConfigError.onError);
  const { create: addImageOfQuestionImage } = useQuestionImage(
    false,
    ConfigError.onError
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const islogout = searchParams.get("logout") === "true";
  const [bgColor, setBgColor] = useState<string>("");
  const [buttonColor, setButtonColor] = useState<string>("");
  const [device, setDevice] = useState<GetDeviceResponseDto>();
  const [form, setForm] = useState<FormResponse>();
  const [responses, setResponses] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [buttonNextEnable, setButtonNextEnable] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [showQR, setShowQR] = useState(false);

  // Función para manejar la selección de respuestas
  const handleResponseChange = (questionId: number, response: any) => {
    if (questionId === 0) {
      setResponses([]);
    }

    if (response.typeQuestion === "image") {
      response.url = `capturedPhoto-${questionId}`;
    }

    if (response.typeQuestion === "device") {
      try {
        const readDeviceId = localStorage.getItem(`readDeviceId-${questionId}`);
        response.readDeviceId = readDeviceId;
      } catch (error) {
        console.error(error);
      }
    }

    setResponses((prevResponses: any) => ({
      ...prevResponses,
      [questionId]: response,
    }));
  };

  const base64ToBlob = (base64: string, contentType: string = ""): Blob => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };

  const getPictureFromLocalStorage = async (id: string) => {
    const value = localStorage.getItem(id.toString());
    if (!value) return undefined;
    const result = base64ToBlob(value, "application/json");
    return result;
  };

  const sendImages = async (
    patientId: number,
    form: FormResponse,
    addImageOfQuestionImage: TypeAddQuestionImageFunc
  ): Promise<FormResponse> => {
    const questionsWithImage = form.responses.filter(
      (e) => e.typeQuestion === "image"
    );
    await Promise.all(
      questionsWithImage.map(async (response) => {
        const imageObj = await getPictureFromLocalStorage(
          (response as IResponseOfQuestionImage).url
        );
        if (imageObj) {
          const file = new File([imageObj], `file.json`, {
            type: "application/json",
          });

          let formData = new FormData();
          formData.append("file", file);
          const responseAdd = await addImageOfQuestionImage(
            response.signId,
            formData,
            patientId
          );
          (response as IResponseOfQuestionImage).url = responseAdd.data?.Key!;
          return responseAdd;
        }
      })
    );
    return form;
  };

  const sendForm = async (
    patientId: number,
    form: FormResponse,
    addReadForm: TypeAddReadFormFunc
  ) => {
    let createReadFormRequestDto = new CreateReadFormRequestDto(
      form,
      description
    );
    createReadFormRequestDto.formResponse = form;
    createReadFormRequestDto.formResponse.patientId = patientId;
    let response = await addReadForm(createReadFormRequestDto);
    if (response.success) {
      toast.info(t("OkSendingReadForm"));
    } else {
      toast.error(t("ErrorSendingReadForm"));
    }
  };

  const getDataAndSendForm = async () => {
    let formWithUrlImages = await sendImages(
      parseInt(userId),
      form as FormResponse,
      addImageOfQuestionImage as unknown as TypeAddQuestionImageFunc
    );
    await sendForm(parseInt(userId), formWithUrlImages, addReadForm);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("management");
    navigate(`/devices`);
    logout();
  };

  const onClose = () => {
    if (islogout) {
      handleLogout();
    } else {
      let path = "/devices";
      let userIdPath = +loggedId !== +userId ? `/${userId}` : "";
      if (searchParams) {
        navigate(`${path}${userIdPath}?${searchParams.toString()}`);
      } else {
        navigate(`${path}${userIdPath}`);
      }
    }
  };

  // Función para avanzar a la siguiente página
  const onNextPage = () => {
    setCurrentPage((prevPage) => {
      if (
        form?.responses !== undefined &&
        prevPage + 1 >= form.responses.length
      ) {
        // Send form
        getDataAndSendForm();
        onClose();
        setCurrentPage(0);
        return 0;
      } else {
        return prevPage + 1;
      }
    });
  };

  // Función para retroceder a la página anterior
  const onPreviousPage = () => {
    setCurrentPage((prevPage) => {
      if (prevPage - 1 === -1) {
        onClose();
        setCurrentPage(0);
      }
      return prevPage - 1;
    });
  };

  // Función para preguntar si realmente se desea salir del formulario
  const handleDeleteTestForm = () => {
    onClose();
    setCurrentPage(0);
    handleModalClose();
  };

  const handleDeleteConfirmation = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (company?.data?.buttonColor) {
      setButtonColor(company?.data?.buttonColor);
    }

    if (company?.data?.parametersUrl) {
      const param = JSON.parse(company?.data?.parametersUrl);

      if (param?.buttonColor) {
        setButtonColor(param?.buttonColor);
      }

      if (param?.bgColor) {
        setBgColor(param?.bgColor);
      }
    }
  }, [company]);

  useEffect(() => {
    const getDeviceSelected = async () => {
      if (formId) {
        try {
          const device = await getDevice(parseInt(formId));
          setDevice(device.data);

          if (device.additional_data) {
            const formJSON = device.additional_data;
            const form: Form = JSON.parse(formJSON);
            const formResponse: FormResponse = {
              ...form,
              responses: form.questions.map((q: Question) =>
                ResponseOfQuestionFactory.Create(q)
              ),
            };

            setForm(formResponse);
          }
        } catch (error) {
          const device = await getDevice(parseInt(formId));
          setDevice(device.data);

          if (device.additional_data) {
            const deviceForm = device.additional_data;
            let stringToJson = deviceForm.replace(
              /(\w+:)|(\w+ :)/g,
              function (s: any) {
                return '"' + s.substring(0, s.length - 1) + '":';
              }
            );
            stringToJson = stringToJson.replace(/'/g, '"');
            stringToJson = stringToJson.replace(/,\s*([\]}])/g, "$1");
            const form: Form = JSON.parse(stringToJson);
            const formResponse: FormResponse = {
              ...form,
              responses: form.questions.map((q: Question) =>
                ResponseOfQuestionFactory.Create(q)
              ),
            };

            setForm(formResponse);
          }
        }
      }
    };

    getDeviceSelected();
  }, [formId]);

  const translation = new T([lang]);

  const currentHost =
    process.env.REACT_APP_URL === "" || process.env.REACT_APP_URL === undefined
      ? `${window.location.protocol}//${window.location.hostname}`
      : process.env.REACT_APP_URL;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          overflow: "auto",
          position: "relative",
          padding: "16px",
          boxSizing: "border-box",
          backgroundColor: bgColor ? bgColor : "",
        }}
      >
        <IconButton
          onClick={handleDeleteConfirmation}
          color="inherit"
          style={{ color: "black" }}
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
        {device && (
          <h1 style={{ color: "black", textAlign: "center" }}>
            {form?.formTitle ? translation.i18n(form.formTitle) : device.name}
          </h1>
        )}

        {/* QR Code Switch */}
        <div
          style={{
            marginBlockStart: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <QuizIcon sx={{ fontSize: 30 }} />
          <ColoredSwitch
            sx={{ transform: "scale(1.8)", marginX: 2 }}
            checked={showQR}
            name={"QR"}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => setShowQR(checked)}
          />
          <QrCodeIcon sx={{ fontSize: 30 }} />
        </div>

        {showQR ? (
          <QRCodeCanvas
            QRString={currentHost + location.pathname + `?token=${token}`}
            options={{ width: 250, errorCorrectionLevel: ErrorLevel.low }}
          />
        ) : (
          <QuestionForm
            form={form}
            currentPage={currentPage}
            userId={parseInt(userId)}
            buttonNextEnable={buttonNextEnable}
            companyColor={buttonColor}
            setButtonNextEnable={setButtonNextEnable}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
            handleResponseChange={handleResponseChange}
            description={description}
            setDescription={setDescription}
            showDescription={readDeviceDescription}
          />
        )}

        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="delete-confirmation-modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              maxWidth: 400,
              outline: "none",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              id="delete-confirmation-modal"
            >
              {t("DeleteTestForm")}
            </Typography>
            <Typography style={{ marginBlock: "20px" }}>
              {t("DeleteTestFormExplanation")}
            </Typography>
            <Button
              variant="contained"
              onClick={handleDeleteTestForm}
              style={{
                color: "#000000",
                backgroundColor: "#C4C4C4",
                margin: "4px",
              }}
            >
              {t("ButtonConfirm")}
            </Button>
            <Button
              variant="contained"
              onClick={handleModalClose}
              style={{
                color: "#000000",
                backgroundColor: "#008000",
                marginLeft: "1em",
                margin: "4px",
              }}
            >
              {t("ButtonCancel")}
            </Button>
          </Box>
        </Modal>
        <StyledCanvas ref={canvasRef} />
      </Box>
    </>
  );
};

const StyledCanvas = styled("canvas")`
  display: none;
`;
