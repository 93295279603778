import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { validate, ValidationError } from 'class-validator'
import { useTranslation } from 'react-i18next'
import { ItemLocation, UpdateLocationRequestDto } from '@doctomatic/sdk/build/dto/Location/Location';

interface Props {
  open: boolean,
  locationToEdit?: ItemLocation;
  onClose: () => void
  onEdit: (locationId: number, updateLocationRequestDto: UpdateLocationRequestDto) => void
}

const useStyles = makeStyles((theme: Theme) => ({ visible: { overflowY: 'visible' } }))

const AdminLocationEditModal = ({ open, locationToEdit, onClose, onEdit }: Props): React.ReactElement => {

  const { t } = useTranslation()
  const [name, setName] = useState(locationToEdit?.name)
  const [description, setDescription] = useState(locationToEdit?.description)
  const [errors, setErrors] = useState<ValidationError[]>([])
  const classes = useStyles()

  useEffect(() => {
    setName(locationToEdit?.name)
    setDescription(locationToEdit?.description)
  }, [open, locationToEdit?.name, locationToEdit?.description])

  useEffect(() => {
    const checkErrors = async () => {
      const dto = new UpdateLocationRequestDto()
      dto.name = name
      dto.description = description
      const errors = await validate(dto)
      setErrors(errors)
    }
    checkErrors()
  }, [open, name, description])

  return (

    <Dialog open={open} fullWidth={true} maxWidth='sm' classes={{ paper: classes.visible }}>
      <DialogTitle>
        {t('ButtonEdit')}
      </DialogTitle>

      <DialogContent className={classes.visible}>
        <TextField margin='dense' variant='outlined' name='name' label={t('FieldParent')} type='text' fullWidth value={locationToEdit?.parent?.name ?? ''} autoFocus disabled />
        <TextField margin='dense' variant='outlined' name='name' label={t('FieldName')} type='text' fullWidth onChange={(e) => setName(e.target.value)} value={name || ''} autoFocus />
        <TextField multiline rows={4} margin='dense' variant='outlined' name='description' label={t('FieldDescription')} type='text' fullWidth onChange={(e) => setDescription(e.target.value)} value={description || ''} />
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display='inline-block'>
            <Button onClick={onClose} size='small'>{t('ButtonCancel')}</Button>
          </Box>
          <Button disabled={errors.length > 0} onClick={() => {
            const dto = new UpdateLocationRequestDto();
            if (locationToEdit?.name !== name) dto.name = name
            if (locationToEdit?.description !== description) dto.description = description
            onEdit(locationToEdit!.id, dto)
            onClose();
          }} variant='contained' size='small'>{t('ButtonSave')}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export { AdminLocationEditModal }


