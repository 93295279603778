import { Trans } from "react-i18next";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { useLoading } from "../../Loading/Loading";
import { toast } from "react-toastify";
import { Page } from "../Page";
import useStyles from "./styles";
import { DefaultForm } from "./Forms/DefaultForm";
import { UserForm } from "./Forms/UserForm";
import { CreditCardForm } from "./Forms/CreditCardForm";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { DocumentType } from "@doctomatic/sdk/build/dto/Legal/Legal";
import ReactMarkdown from "react-markdown";
import { Link, useNavigate } from "react-router-dom";
import logoIos from "../../../assets/ios-store-logo.png";
import logoAndroid from "../../../assets/android-store-logo.png";
import {
  GetPatientResponseDto,
  PatientOnboardingOptions,
} from "@doctomatic/sdk/build/dto/Patient/Patient";
import { processError } from "../../../App/errorToast";
import { GetCompanyResponseDto } from "@doctomatic/sdk/build/dto/Company";
import { UserFormOnlyPassword } from "./Forms/UserFormOnlyPassword";

const Profile = (): React.ReactElement => {
  const navigate = useNavigate();
  const iosPath = process.env.REACT_APP_IOS_STORE_URL;
  const androidPath = process.env.REACT_APP_ANDROID_STORE_URL;
  const introductionVideo = process.env.REACT_APP_INTRODUCTION_VIDEO_URL;
  const { useProfile, useCompany, useLegal, role, logout, companyId } =
    useApi();
  const { t } = useTranslation();
  const { response: profileResponse } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const { response: companyResponse } = useCompany(
    companyId,
    true,
    processError(logout, navigate, t)
  );
  const { render, accept } = useLegal();
  const classes = useStyles();
  const [consent, setConsent] = useState("");
  const [constentAccepted, setConsentAccepted] = useState(false);
  const { setIsLoading } = useLoading();
  const [title, setTitle] = useState("");
  let profile = profileResponse?.data;
  let profilePatient: GetPatientResponseDto = profile as GetPatientResponseDto;
  const company = companyResponse?.data;
  if (profile?.role === Role.user)
    profilePatient = profile as GetPatientResponseDto;

  const isInConsentSetupStep = (
    role: Role,
    profile: GetPatientResponseDto | undefined,
    acceptsConsent: boolean
  ): boolean => {
    return (
      role === Role.user &&
      !!profile &&
      !profile.acceptedConsent &&
      !acceptsConsent
    );
  };

  useEffect(() => {
    const getConsent = async (id: number) => {
      let { data: markdown } = await render(DocumentType.consent, id);
      markdown && setConsent(markdown.md);
    };

    const checkSubscription: boolean =
      profile?.role === Role.user &&
      ((profilePatient.mustHaveSubscription &&
        profilePatient.hasSubscription) ||
        (!profilePatient.mustHaveSubscription &&
          !profilePatient.hasSubscription));

    if (isInConsentSetupStep(role, profilePatient, constentAccepted)) {
      getConsent(profile!.id);
      setTitle(t("HeaderConsent"));
    }

    if (
      profile &&
      role === Role.user &&
      profilePatient.hasPassword &&
      checkSubscription &&
      profilePatient.acceptedContract &&
      profilePatient.acceptedConsent
    )
      setTitle(t("HeaderSettings"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, role, constentAccepted, profilePatient]);

  if (!profile || !company) return <>Loading...</>;

  const isStripeNeeded = (
    role: Role,
    profile: GetPatientResponseDto | undefined,
    company: GetCompanyResponseDto | undefined
  ): boolean => {
    return (
      (role === Role.user &&
        !profile?.hasSubscription &&
        profile?.mustHaveSubscription &&
        company?.stripeAccount !== undefined &&
        Boolean(company?.stripePriceId)) ??
      false
    );
  };

  const doctomaticStripeAccount = isStripeNeeded(role, profilePatient, company)
    ? loadStripe(process.env.REACT_APP_STRIPE_KEY || "", {
        stripeAccount: company.stripeAccount,
      })
    : null;

  const isInUserSetupStep = (
    profile: GetPatientResponseDto | undefined
  ): boolean => {
    return profile ? profile?.hasPassword === false : true;
  };

  const isInPaymentSetupStep = (
    role: Role,
    profile: GetPatientResponseDto | undefined,
    doctomaticStripeAccount: Promise<Stripe | null> | null
  ): boolean => {
    return (
      role === Role.user &&
      profile?.hasSubscription === false &&
      profile?.mustHaveSubscription &&
      doctomaticStripeAccount !== null
    );
  };

  const isInDownloadStep = (role: Role, constentAccepted: boolean) => {
    return role === Role.user && constentAccepted;
  };

  const setUserInfo = (
    <Box className={classes.container}>
      <Typography variant="h6" style={{ marginBottom: 20 }}>
        {role === Role.user
          ? t("Greeting", { name: profile.name, company_name: company.name })
          : t("GreetingDoctor", { name: profile.name })}
      </Typography>
      <UserForm profile={profile} />
    </Box>
  );

  const setUserPassword = (
    <Box className={classes.container}>
      <Typography variant="h6" style={{ marginBottom: 20 }}>
        {role === Role.user
          ? t("GreetingOnlyPass", {
              name: profile.name,
              company_name: company.name,
            })
          : t("GreetingDoctor", { name: profile.name })}
      </Typography>
      <UserFormOnlyPassword />
    </Box>
  );

  const setCreditCard = (
    <Elements stripe={doctomaticStripeAccount}>
      <Box className={classes.container}>
        <Typography>
          <Trans
            i18nKey="PaymentTitle"
            values={{ company_name: company.name }}
            components={{ 1: <strong /> }}
          />
        </Typography>
        <Box mt={3}>
          <CreditCardForm />
        </Box>
      </Box>
    </Elements>
  );

  const setProfile = (
    <Box display="flex">
      <DefaultForm profile={profile} />
    </Box>
  );

  const acceptConsent = (
    <Box>
      <Box style={{ height: "82vh", paddingRight: 0, overflowY: "scroll" }}>
        <ReactMarkdown children={consent} />
      </Box>
      <Box my={3} display="flex" justifyContent="center">
        <Button
          onClick={async () => {
            setIsLoading(true);
            try {
              await accept(DocumentType.consent);
              setConsentAccepted(true);
            } catch (err: any) {
              toast.error(
                toast.error(err.response?.data?.message || err.message)
              );
            }
            setIsLoading(false);
          }}
          variant="contained"
          size="small"
          style={{ textTransform: "none", fontSize: "20px" }}
        >
          {t("LegalAcceptInformedConsent")}
        </Button>
      </Box>
    </Box>
  );

  const downloadApp = (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: "80%",
      }}
    >
      <Box
        style={{
          marginBottom: 56,
          paddingRight: 16,
          paddingLeft: 16,
          textAlign: "center",
        }}
      >
        <Typography style={{ marginTop: 24 }}>
          {t("DownloadAppPageFirstText")}
        </Typography>
        <Typography style={{ marginTop: 24, marginBottom: 24 }}>
          {t("DownloadAppPageSecondText")}
        </Typography>
        {introductionVideo && (
          <Link to={{ pathname: introductionVideo }} target="_blank">
            {t("DownloadAppPageThirdText")}
          </Link>
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        <Link to={{ pathname: iosPath }} target="_blank">
          <img src={logoIos} style={{ width: 200 }} alt="iOS" />
        </Link>
        <Link to={{ pathname: androidPath }} target="_blank">
          <img src={logoAndroid} style={{ width: 200 }} alt="Android" />
        </Link>
      </Box>
    </Box>
  );

  const content = () => {
    const isSimpleOnboarding =
      profilePatient.onBoardingReq ===
      PatientOnboardingOptions.SimpleOnboarding;

    if (
      ((company.onBoardingReq > 2 && isSimpleOnboarding) ||
        company.onBoardingReq < 3) &&
      profilePatient.role === Role.user
    ) {
      return !profile?.hasPassword ? setUserPassword : setProfile;
    }

    if (isInUserSetupStep(profilePatient)) return setUserInfo;
    if (isInPaymentSetupStep(role, profilePatient, doctomaticStripeAccount))
      return setCreditCard;
    if (isInConsentSetupStep(role, profilePatient, constentAccepted))
      return acceptConsent;
    if (isInDownloadStep(role, constentAccepted)) return downloadApp;
    return setProfile;
  };

  return <Page title={title}>{content()}</Page>;
};

export { Profile };
