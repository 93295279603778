import { Button, Modal, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

interface CameraPermissionModalProps {
  onOpen: boolean;
  onRequestPermission: () => void;
  onClose: () => void;
}

const CameraPermissionModal: React.FC<CameraPermissionModalProps> = ({
  onOpen,
  onRequestPermission,
  onClose,
}) => {

  const { t } = useTranslation();

  useEffect(() => {
    if (onOpen) {
      onRequestPermission();
    }
  }, [onOpen]);

  const handleRequestPermission = () => {
    onRequestPermission();
  };

  return (
    <Modal open={onOpen} onClose={onClose}>
      <div style={styles.modalContainer}>
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <Typography variant="h5">{t('RequestPermissionTitle')}</Typography>
            <br />
            <Typography variant="body1">{t('RequestPermissionModal')}</Typography>
            <div style={styles.modalButtons}>
              <Button onClick={handleRequestPermission} style={styles.button}>
                {t('RequestPermissionButton')}
              </Button>
              <Button onClick={onClose} style={styles.button}>
                {t('ButtonClose')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CameraPermissionModal;

const styles: { [key: string]: CSSProperties } = {
  modalContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
  },
  modal: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 4,
    zIndex: 10000,
  },
  modalContent: {
    textAlign: 'center',
  },
  modalButtons: {
    marginTop: 20,
  },
  button: {
    color: 'white',
    backgroundColor: 'black',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    margin: '0px 8px',
  }
};
