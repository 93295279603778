
import { GridColumns } from "@mui/x-data-grid";
import { IPermissionDto } from '@doctomatic/sdk/build/dto/GroupPermission/IGroupPermission';

export const GroupPermissionModalGridColumns = (
  columnNameName: string,
  t: (label: string) => string,
): GridColumns<IPermissionDto> => {

  return [
    {
      field: 'name',
      headerName: columnNameName,
      width: 400,
      align: 'left',
      renderCell: (params: { row: IPermissionDto }) => {
        const permission = params.row.name.split('.');
        if(permission[1] === 'user_permission') 
          return t(permission[0].charAt(0).toUpperCase() + permission[0].slice(1)
                 + permission[1].charAt(0).toUpperCase() + permission[1].slice(1))
        return `${t(permission[0].charAt(0).toUpperCase() + permission[0].slice(1))} 
                ${t(permission[1].charAt(0).toUpperCase() + permission[1].slice(1))}
                `
      }
    },
  ]
}