import React from "react";
import {
  FlexLayoutGrid,
  FlexLayoutGridProps,
} from "@doctomatic/components-react/build/DataGrid/DataGrid";
import TreatmentsDataRow from "./TreatmentsDataRow";
import { GridCallbackDetails, GridSelectionModel } from "@mui/x-data-grid";

interface TreatmentsListProps extends FlexLayoutGridProps<TreatmentsDataRow> {
  onSelectionModelChangeFunc?: (
    selectionModel: GridSelectionModel,
    details: GridCallbackDetails<any>
  ) => void;
  checkBox: boolean;
  treatmentsPaginationInfo?: any;
  isLoading?: boolean;
  onPageChange?: (newPageNumber: number) => void;
  onPageSizeChange?: (newPageSize: number) => void;
}

export const TreatmentsList = ({
  columns,
  rows,
  language,
  onSelectionModelChangeFunc,
  checkBox,
  treatmentsPaginationInfo,
  isLoading,
  onPageChange,
  onPageSizeChange
}: TreatmentsListProps): React.ReactElement => (
  <FlexLayoutGrid
    language={language}
    columns={columns}
    rows={rows}
    onPageChange={onPageChange}
    onPageSizeChange={onPageSizeChange}
    pagination={treatmentsPaginationInfo}
    isLoading={isLoading}
    checkboxSelection={checkBox}
    onSelectionModelChange={onSelectionModelChangeFunc}
  />
);
