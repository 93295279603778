/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { styled, useMediaQuery } from "@mui/material";
import { CreateImageResponseDto } from "@doctomatic/sdk/build/dto/Images";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { Theme } from "@mui/material/styles";
import { Player } from "@lottiefiles/react-lottie-player";
import Skeleton from "react-loading-skeleton";
import { ImageConfirmation } from "./ImageConfirmation";

type Props = {
  isLoading: boolean;
  device: GetDeviceResponseDto | undefined;
  createImageResponseDto: CreateImageResponseDto | null;
  setCreateImageResponseDto: (value: CreateImageResponseDto | null) => void;
  bgColor?: string;
  parametersUrl?: string;
  onNextPage?: () => void;
  saveReadDeviceIdToLocalStorage?: (readDeviceId: number) => void;
};

export const Processing = ({
  isLoading,
  device,
  createImageResponseDto,
  setCreateImageResponseDto,
  bgColor,
  parametersUrl,
  onNextPage,
  saveReadDeviceIdToLocalStorage,
}: Props): React.ReactElement => {
  const { userId }: any = useParams();
  const { t } = useTranslation();
  const [photoTaken, setPhotoTaken] = useState<boolean>(false);
  const [photoData, setPhotoData] = useState<string | null>(null);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    const photo = localStorage.getItem("capturedPhoto");
    setPhotoData(photo);
    setPhotoTaken(true);
  }, []);

  const loadingComponent = device ? (
    <div
      style={{
        position: "relative",
        width: "100%",
        marginTop: 20,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        marginBottom: 30,
        paddingInline: 20,
      }}
    >
      <p
        style={{
          fontSize: 20,
          marginInline: 20,
          textAlign: "center",
          paddingBlock: 10,
          marginBottom: 30,
          marginTop: 20,
          paddingTop: 20,
        }}
      >
        {t(device.name)} {t("DeviceDetected")}
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <StyledSkeleton height={30} width={150} />
        <StyledSkeleton height={30} width={150} />
        <StyledSkeleton height={30} width={150} />
        <StyledSkeleton height={30} width={150} />
        <StyledSkeleton height={30} width={150} />
      </div>
      <div
        style={{
          marginTop: 30,
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          display: "flex",
        }}
      >
        <StyledSkeleton width={120} height={50} />
        <StyledSkeleton width={120} height={50} />
      </div>
    </div>
  ) : (
    <div
      style={{
        position: "relative",
        width: "100%",
        marginTop: 20,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        marginBottom: 30,
        paddingInline: 20,
      }}
    >
      <p
        style={{
          fontSize: 26,
          marginInline: 20,
          textAlign: "center",
          paddingBlock: 10,
          marginBottom: 30,
        }}
      >
        {t("DetectingDevice")}
      </p>
    </div>
  );

  const loadedComponent = createImageResponseDto && device && (
    <ImageConfirmation
      createImageResponseDto={createImageResponseDto}
      device={device}
      patientId={userId}
      setPhotoTaken={setPhotoTaken}
      setCreateImageResponseDto={setCreateImageResponseDto}
      parametersUrl={parametersUrl}
      onNextPage={onNextPage}
      saveReadDeviceIdToLocalStorage={saveReadDeviceIdToLocalStorage}
    />
  );

  const imageComponent = (
    <div
      style={{
        position: "relative",
        justifyContent: "center",
        display: "flex",
      }}
    >
      {isLoading && (
        <Player
          src={require("../../../assets/lottie/processing.json")}
          className="player"
          loop
          autoplay
          style={{
            width: "75%",
            position: "relative",
            aspectRatio: 1,
            zIndex: 100,
          }}
        />
      )}
      {photoTaken && (
        <StyledImage
          src={photoData || ""}
          alt="Captured"
          style={{
            ...(isLoading
              ? {
                  width: "75%",
                  position: "absolute",
                  top: "0%",
                  left: "13%",
                }
              : { width: "50%" }),
          }}
        />
      )}
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: bgColor ? bgColor : "#edeaea",
        height: "94vh",
        maxHeight: "100vh",
        overflowY: "hidden",
      }}
    >
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          marginTop: 20,
        }}
      >
        {device && (
          <h1
            style={{
              color: "black",
              justifyContent: "center",
              display: "flex",
              backgroundColor: "white",
              borderRadius: 15,
              paddingInline: 20,
              maxWidth: "70%",
            }}
          >
            {t(device.name)}
          </h1>
        )}
      </div>

      <div
        style={{
          height: "85%",
          display: "flex",
          ...(isMobile && {
            width: "100%",
          }),
          ...(!isMobile && {
            paddingInline: "35%",
          }),
          flexDirection: "column",
        }}
      >
        {imageComponent}
        {isLoading ? loadingComponent : loadedComponent}
      </div>
    </div>
  );
};

// styles material ui mode

const StyledImage = styled("img")`
  height: auto;
  z-index: 1;
`;

const StyledSkeleton = styled(Skeleton)`
  margin-top: 10px;
  border-radius: 15px;
  background-color: lightgray;
`;
