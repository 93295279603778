import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import AdminLocationDataRow from './AdminLocationDataRow';
import { createColumnActionAdminLocation, PopoverAdminLocationConfig } from "./popoverRowAdminLocation";
import { HtmlTooltipLines } from "@doctomatic/components-react/build/Tooltips/HtmlTooltip";

export const AdminLocationGridColumns = (t: (text: string) => string,
  popoverConf?: PopoverAdminLocationConfig,

): GridColumns<AdminLocationDataRow> => {
  let gridColumns: GridColumns<AdminLocationDataRow> = [{
    field: 'name',
    headerName: t('Name'),
    width: 220,
    align: 'left'
  },
  {
    field: 'description',
    headerName: t('HeaderColumnDescription'),
    width: 220,
    align: 'left',
  },
  {
    field: 'quantityPatients',
    headerName: t('HeaderColumnQuantityPatient'),
    width: 200,
    align: 'left',
  },
  {
    field: 'quantityLocationChildren',
    headerName: t('HeaderColumnQuantityLocationsChildren'),
    width: 200,
    align: 'left',
    valueGetter: (params: GridValueGetterParams) => params.row.children?.length,
    sortComparator: (v1: any, v2: any) => v1!.toString().localeCompare(v2!.toString()),
    renderCell: (params) => {
      return HtmlTooltipLines(t('Sublocations'), params.row.children?.map(x => x.name), <p>{params.row.children?.length}</p>);
    }
  }];

  if (popoverConf) gridColumns.push(...createColumnActionAdminLocation(t, popoverConf))

  return gridColumns;
};