import { Box, Button, FormControlLabel, FormGroup, ListItem, Switch, } from "@mui/material";
import React, { useState } from "react";
import { Measurements } from "../Measurements";
import { GraphTreatmentListItemEcg } from "./Ecg/GraphTreatmenListItemEcg";
var styles = {
    listItem: {
        alignItems: "flex-start",
        flexDirection: "column",
    },
    measurements: {
        maxHeight: "100%",
        width: "100%",
        maxWidth: "1200px",
        paddingRight: "24px",
    },
    ecg: {
        maxHeight: "100%",
        height: "100%",
        width: "80%",
        padding: "24px",
        maxWidth: "1200px",
    },
    formGroup: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    },
};
export var GraphTreatmentListItem = function (_a) {
    var measurements = _a.measurements, device = _a.device, t = _a.t, language = _a.language, start = _a.start, end = _a.end, onExport = _a.onExport, getUrl = _a.getUrl, onSaveMeasurement = _a.onSaveMeasurement, editMeasurementsPermissions = _a.editMeasurementsPermissions, deleteMeasurementsPermissions = _a.deleteMeasurementsPermissions, onDeleteOneMeasurement = _a.onDeleteOneMeasurement, onDeleteManyMeasurements = _a.onDeleteManyMeasurements, files = _a.files, getPdf = _a.getPdf, pagination = _a.pagination, onPageChangeFiles = _a.onPageChangeFiles, onPageSizeChangeFiles = _a.onPageSizeChangeFiles;
    var _b = useState(true), checked = _b[0], setChecked = _b[1];
    var handleChange = function (event) {
        setChecked(event.target.checked);
    };
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    return (React.createElement(ListItem, { disablePadding: true, style: styles.listItem },
        React.createElement(Box, { display: "flex", flexGrow: 1, width: "100%" },
            React.createElement(FormGroup, { sx: styles.formGroup },
                React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: checked, onChange: handleChange, inputProps: { "aria-label": "controlled" } }), label: t(device.name) }),
                device.name !== "ECG" && (React.createElement(Box, { display: "flex", flexDirection: "row" },
                    React.createElement(Button, { variant: "contained", size: "small", onClick: function () { return setOpen(true); }, style: { marginRight: "10px", marginBottom: "10px" } }, t("NumericValuesBtn")),
                    React.createElement(Button, { variant: "contained", size: "small", style: { marginBottom: "10px" }, onClick: function () { return onExport(measurements, device.signs, device); } }, t("ExportMeasurementsTitle")))))),
        checked ? (React.createElement(React.Fragment, null, device.name !== "ECG" ? (React.createElement(Box, { display: "flex", flexGrow: 1, style: styles.measurements },
            React.createElement(Measurements, { measurements: measurements, device: device, t: t, language: language, 
                // TODO
                limits: [], start: start, end: end, open: open, handleClose: function () { return setOpen(false); }, getUrl: getUrl, onSaveMeasurement: onSaveMeasurement, editMeasurementsPermissions: editMeasurementsPermissions, deleteMeasurementsPermissions: deleteMeasurementsPermissions, onDeleteOneMeasurement: onDeleteOneMeasurement, onDeleteManyMeasurements: onDeleteManyMeasurements }))) : (React.createElement(Box, { display: "flex", flexGrow: 1, style: styles.ecg },
            React.createElement(GraphTreatmentListItemEcg, { files: files, getPdf: getPdf, t: t, language: language, pagination: pagination, onPageChangeFiles: onPageChangeFiles, onPageSizeChangeFiles: onPageSizeChangeFiles }))))) : (React.createElement(React.Fragment, null))));
};
