/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Modal,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { AlertConfigByFrequency } from "../alertConfigByFrequency.interface";
import { CustomSelect } from "@doctomatic/components-react/build/AlertConfig/CustomSelect";
import { CustomSwitch } from "@doctomatic/components-react/build/AlertConfig/CustomSwitch";
import { useTranslation } from "react-i18next";
import { ModalBaseProps } from "../../../../Modals/modalBaseProps";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { IUseAlertConfigsByFrequencyIndividual } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByFrequency";
import { processError } from "../../../../../App/errorToast";
import { toast } from "react-toastify";
import { AddAlertConfigByFrequencyIndividualRequestDto } from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByFrequencyIndividual";
import { CustomTextInput } from "@doctomatic/components-react/build/AlertConfig/CustomTextInput";
import { styles } from "./styles";
import {
  getWeekFormatted,
  minStringToNumbers,
  minToHHmm,
} from "../../../../../utils/frequencies";
import { INotification } from "@doctomatic/sdk/build/dto/Alerts/group/IAlertGroup";
import { useNavigate } from "react-router-dom";

export interface AddAlertConfigByFrequencyModalProps extends ModalBaseProps {
  patientId: number;
  permissions: Array<string>;
  messengerChannel: string;
}

export type FrequencyFormatted = {
  id: number;
  value: {
    weekdays: string;
    min: string;
    deviceName: string;
  };
};

const TIMEOUT_DEFAULT_VALUE = 5;
const TIME_TO_VALIDATE_DEFAULT_VALUE = 15;

export const AddAlertConfigByFrequencyModal = ({
  patientId,
  open,
  handleClose,
  messengerChannel,
}: AddAlertConfigByFrequencyModalProps): React.ReactElement => {
  const colors: string[] = ["red", "yellow", "green", "blue", "orange"];
  const colorMenuItems: JSX.Element[] = colors.map(
    (c: string, index: number) => (
      <MenuItem key={index} value={c}>
        <Box
          component="div"
          style={{
            backgroundColor: c,
            width: 20,
            height: 20,
          }}
        />
      </MenuItem>
    )
  );
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { useAlertConfigsByFrequency, usePatient, logout }: IProvideApi =
    useApi();
  const { add }: IUseAlertConfigsByFrequencyIndividual =
    useAlertConfigsByFrequency(false, processError(logout, navigate, t));
  const { response: patientResponse } = usePatient(patientId, true);
  const frequencyUser = patientResponse?.data;
  const [frequencies, setFrequencies] = useState<FrequencyFormatted[]>([]);
  const [notification, setNotification] = useState<INotification>({
    sms: false,
    email: true,
    pushNotification: true,
  });
  const initialValue: AlertConfigByFrequency = {
    id: undefined,
    patientId: patientId,
    notification: { sms: false, email: true, pushNotification: true },
    color: colors[0],
    label: undefined,
    timeout: TIMEOUT_DEFAULT_VALUE,
    timeToValidate: TIME_TO_VALIDATE_DEFAULT_VALUE,
    frequencyId: 0,
  };
  const [alertConfig, setAlertConfig] =
    useState<AlertConfigByFrequency>(initialValue);

  useEffect(() => {
    if (frequencyUser) {
      const frequenciesFormatted = frequencyUser.frequencies.map(
        (frequency) => {
          return {
            id: frequency.id,
            value: {
              weekdays: frequency.weekdays,
              min: frequency.min,
              deviceName: frequency.device ? frequency.device.name : "",
            },
          };
        }
      );
      setFrequencies(frequenciesFormatted);
    }
  }, [frequencyUser, open, patientId]);

  useEffect(() => {
    setNotification(alertConfig.notification);
  }, [alertConfig.notification]);

  useEffect(() => {
    handleAlertConfig(notification, "notification");
  }, [notification]);

  const [subscribe, setSubscribe] = useState<boolean>(true);

  const handleAlertConfig = useCallback(
    (value: any, field: string): void => {
      setAlertConfig({ ...alertConfig, [field]: value });
    },
    [alertConfig]
  );

  const handleNotification = (value: boolean, field: string) => {
    setNotification({ ...notification, [field]: value });
  };

  const handleFormSubmit = async (): Promise<void> => {
    const dto: AddAlertConfigByFrequencyIndividualRequestDto =
      new AddAlertConfigByFrequencyIndividualRequestDto();
    Object.assign(dto, alertConfig);

    dto.subscribe = subscribe;
    try {
      await add(dto);
      handleClose();
      setAlertConfig(initialValue);
    } catch (error: any) {
      toast.error(error.response?.data?.message ?? error.message);
    }
  };

  const handleOnChangeLabel = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleAlertConfig(e.target.value, "label");
    setAlertConfig({ ...alertConfig, label: e.target.value });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          <Grid container spacing={2} columns={12} xs={12}>
            <Grid item xs={6}>
              <CustomTextInput
                value={alertConfig.label}
                onChange={handleOnChangeLabel}
                required={true}
                helperText={t("FieldLabel")}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                value={alertConfig.color}
                onChange={(e: SelectChangeEvent) => {
                  handleAlertConfig(e.target.value, "color");
                }}
                menuItems={colorMenuItems}
                helperText={`${t("FieldLevel")}`}
                required={true}
                minWidth={"100%"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={12} xs={12}>
            <Grid item xs={12}>
              <CustomSelect
                helperText={`${t("Frequency")}`}
                required={true}
                minWidth={"100%"}
                autoWidth={false}
                value={alertConfig.frequencyId.toString()}
                onChange={(e: SelectChangeEvent) => {
                  handleAlertConfig(e.target.value, "frequencyId");
                }}
                sx={{
                  width: "10%",
                }}
                menuItems={frequencies.map(
                  (f: FrequencyFormatted, index: number) => {
                    const weekdays = f.value.weekdays.split(",");
                    const weekdaysFormatted = weekdays
                      .map((weekday) => getWeekFormatted(weekday, t))
                      .join(", ");

                    const hoursFormatted = minStringToNumbers(f.value.min)
                      .map((item) => minToHHmm(item))
                      .join(", ");
                    return (
                      <MenuItem
                        key={index}
                        value={f.id}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          whiteSpace: "unset",
                          wordBreak: "keep-all",
                          borderBottom:
                            index === frequencies.length - 1
                              ? undefined
                              : "2px solid lightgrey",
                        }}
                      >
                        <p style={{ margin: 4 }}>{f.value.deviceName}</p>
                        <p
                          style={{
                            margin: 4,
                          }}
                        >
                          {weekdaysFormatted}
                        </p>
                        <p style={{ margin: 4 }}>{hoursFormatted}</p>
                      </MenuItem>
                    );
                  }
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={12} xs={12}>
            <Grid item xs={6}>
              <TextField
                type="number"
                label={t("Timeout")}
                value={alertConfig.timeout}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => handleAlertConfig(Number(e.target.value), "timeout")}
                sx={{ m: 1, minWidth: "100%" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                label={t("TimeToValidate")}
                value={alertConfig.timeToValidate}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) =>
                  handleAlertConfig(Number(e.target.value), "timeToValidate")
                }
                sx={{ m: 1, minWidth: "100%" }}
              />
            </Grid>
          </Grid>

          <Box style={styles.switchesContainer}>
            <Grid container spacing={2} columns={12} xs={12}>
              <Grid item xs={3}>
                <CustomSwitch
                  checked={alertConfig.notification.pushNotification}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => handleNotification(checked, "pushNotification")}
                  helperText={`${t("FieldPushNotifyPatient")}*`}
                  required={true}
                  fontSize={14}
                  minWidth={"100%"}
                />
              </Grid>
              {JSON.parse(messengerChannel).sms && (
                <Grid item xs={3}>
                  <CustomSwitch
                    checked={alertConfig.notification.sms}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => handleNotification(checked, "sms")}
                    helperText={`${t("FieldSMSNotifyPatient")}*`}
                    required={true}
                    fontSize={14}
                    minWidth={"100%"}
                  />
                </Grid>
              )}
              {JSON.parse(messengerChannel).email && (
                <Grid item xs={3}>
                  <CustomSwitch
                    checked={alertConfig.notification.email}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => handleNotification(checked, "email")}
                    helperText={`${t("FieldEmailNofityPatient")}*`}
                    required={true}
                    fontSize={14}
                    minWidth={"100%"}
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <CustomSwitch
                  checked={subscribe}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => setSubscribe(checked)}
                  helperText={`${t("FieldSubscribe")}`}
                  fontSize={14}
                  minWidth={"100%"}
                />
              </Grid>
            </Grid>
          </Box>

          <Box style={styles.btnSubmit}>
            <Button variant="contained" onClick={handleFormSubmit}>
              {t("CreateAlert")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
