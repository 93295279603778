import React, { useEffect, useState } from "react";
import { Box, List, Typography } from "@mui/material";
import { GraphTreatmentListItem } from "./GraphTreatmentListItem";
import { useWindowSize } from "../../utils";
var styles = {
    content: {
        backgroundColor: "#FFFFFF",
        flexGrow: 1,
    },
    box: {
        width: "100%",
        color: "#3e4247",
        margin: 0,
        fontSize: "0.875rem",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 400,
        lineHeight: 1.43,
        backgroundColor: "#fafafa",
        maxHeight: "80vh",
        height: "100%",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    list: {
        overflowY: "scroll",
        height: "100%",
        maxHeight: 600,
        width: "100%",
        maxWidth: "100%",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
};
export var GraphTreatment = function (_a) {
    var devices = _a.devices, measurements = _a.measurements, t = _a.t, language = _a.language, start = _a.start, end = _a.end, onExport = _a.onExport, getUrl = _a.getUrl, onSaveMeasurement = _a.onSaveMeasurement, editMeasurementsPermissions = _a.editMeasurementsPermissions, deleteMeasurementsPermissions = _a.deleteMeasurementsPermissions, onDeleteOneMeasurement = _a.onDeleteOneMeasurement, onDeleteManyMeasurements = _a.onDeleteManyMeasurements, files = _a.files, getPdf = _a.getPdf, pagination = _a.pagination, onPageChangeFiles = _a.onPageChangeFiles, onPageSizeChangeFiles = _a.onPageSizeChangeFiles;
    var _b = useState(false), isMobile = _b[0], setIsMobile = _b[1];
    var windowSize = useWindowSize();
    useEffect(function () {
        setIsMobile(windowSize.width <= 600);
    }, [windowSize]);
    return (React.createElement(Box, { style: styles.box },
        React.createElement(Box, { style: styles.content, display: "flex", flexDirection: "column" },
            React.createElement(Box, { px: isMobile ? 0 : 3, pb: 0, flexGrow: 1, display: "flex", flexDirection: "column" },
                React.createElement(Box, { display: "flex", flexDirection: "column", height: "100%" },
                    React.createElement(List, { style: styles.list }, devices !== undefined && devices.length > 0 ? (devices.map(function (device, index) { return (React.createElement(GraphTreatmentListItem, { t: t, key: index, measurements: measurements, device: device, start: start, end: end, onExport: onExport, language: language, getUrl: getUrl, onSaveMeasurement: onSaveMeasurement, editMeasurementsPermissions: editMeasurementsPermissions, deleteMeasurementsPermissions: deleteMeasurementsPermissions, onDeleteOneMeasurement: onDeleteOneMeasurement, onDeleteManyMeasurements: onDeleteManyMeasurements, files: files, getPdf: getPdf, pagination: pagination, onPageChangeFiles: onPageChangeFiles, onPageSizeChangeFiles: onPageSizeChangeFiles })); })) : (React.createElement(Typography, null, t("DevicesNotFound")))))))));
};
