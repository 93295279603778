import { useTranslation } from "react-i18next";
import { CustomTextInput } from "@doctomatic/components-react/build/AlertConfig/CustomTextInput";
import { Box, MenuItem, SelectChangeEvent } from "@mui/material";
import { CustomSelect } from "@doctomatic/components-react/build/AlertConfig/CustomSelect";

export const colors: string[] = ["red", "yellow", "green", "blue", "orange"];
export interface AlertConfigBaseProps {
  label: string;
  handleOnChangeLabel: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  color: string;
  handleOnChangeColor: (e: SelectChangeEvent) => void;
}
export const AlertConfigBaseForm = ({
  label,
  handleOnChangeLabel,
  color,
  handleOnChangeColor,
}: AlertConfigBaseProps): React.ReactElement => {
  const { t } = useTranslation();

  const colorMenuItems: JSX.Element[] = colors.map(
    (c: string, index: number) => (
      <MenuItem key={index} value={c}>
        <Box
          component="div"
          style={{
            backgroundColor: c,
            width: 20,
            height: 20,
          }}
        />
      </MenuItem>
    )
  );

  return (
    // Label
    // Level
    <>
      <CustomTextInput
        value={label}
        onChange={handleOnChangeLabel}
        required={true}
        helperText={t("FieldLabel")}
      />
      <CustomSelect
        value={color}
        onChange={handleOnChangeColor}
        menuItems={colorMenuItems}
        helperText={`${t("FieldLevel")}`}
        required={true}
      />
    </>
  );
};
