
import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { Switch } from '@mui/material';
import { ChangeEvent } from 'react';
import { IGroupPermissionUserDto, IPermissionDto } from '@doctomatic/sdk/build/dto/GroupPermission/IGroupPermission';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const GroupPermissionListGridColumns = (
  onChangeSwitch: (row: IGroupPermissionUserDto, event: ChangeEvent<HTMLInputElement>) => void,
  t: (label: string) => string,
  viewGroupPermissions?: (permissions: IPermissionDto[]) => void,
): GridColumns<IGroupPermissionUserDto> => {


  return [
    {
      field: 'name',
      headerName: t('ColumnHeaderGroupPermisssion'),
      width: 200,
      align: 'left',
      renderCell: (params: { row: IGroupPermissionUserDto }) => {
        return t(params.row.name.charAt(0).toUpperCase() + params.row.name.slice(1))
      }
    },
    {
      field: 'iconEdit',
      headerName: t('Select'),
      width: 100,
      valueGetter: (params: GridValueGetterParams) => params.row.userGroupPermissionId ? true : false,
      renderCell: (params) => {
        return (<Switch checked={params.row.userGroupPermissionId ? true : false}
          onChange={(event) => { onChangeSwitch(params.row, event) }}
          inputProps={{ 'aria-label': 'controlled' }} />)
      }
    },
    {
      field: 'permissions',
      headerName: t('View'),
      headerAlign: 'center',
      align: 'center',
      width: 100,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (<VisibilityIcon sx={{
          cursor: 'pointer',
        }} onClick={() => viewGroupPermissions!(params.row.permissions)} />)
      }
    }

  ]
}