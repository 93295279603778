import { GridColumns } from "@mui/x-data-grid";
import FormDataRow from "./FormDataRow";
import { ItemForm } from "@doctomatic/sdk/build/dto/Form/Form";

export const FormGridColumns = (
  t: (text: string) => string,
  actions: (form: ItemForm) => JSX.Element
): GridColumns<FormDataRow> => {
  let gridColumns: GridColumns<FormDataRow> = [
    {
      field: "name",
      headerName: t("Name"),
      width: 220,
      align: "left",
    },
    {
      field: "description",
      headerName: t("HeaderColumnDescription"),
      width: 220,
      align: "left",
    },
    {
      field: "actions",
      headerName: t("Actions"),
      width: 100,
      filterable: false,
      sortable: false,
      renderCell: (params) => actions(params.row),
    },
  ];

  return gridColumns;
};
