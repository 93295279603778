import React from "react";
import useStyles from "./styles";
import { Box, Button, Typography, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./errorPage.css";
import { useNavigate } from "react-router-dom";

const ErrorPage = (): React.ReactElement => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleReturn = async () => {
    navigate("/", { replace: true });
    navigate(0);
  };

  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Box sx={{ width: "100%" }}>
          <Typography variant="h1" component="div" gutterBottom>
            {t("ErrorPageTitle")}
          </Typography>
          <Typography variant="h2" component="div" gutterBottom>
            {t("ErrorPageSubtitle")}
          </Typography>

          <Button size="small" variant="contained" onClick={handleReturn}>
            {t("ErrorPageHomeButton")}
          </Button>
        </Box>
      </div>
    </Container>
  );
};

export { ErrorPage };
