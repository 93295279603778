import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BreadcrumbDocto } from "../BreadcrumbDocto/BreadcrumbDocto";
import { FlexLayoutGrid } from "../DataGrid/DataGrid";
export var SelectMultipleModal = function (props) {
    var _a;
    var _b = useState(props.rows), rows = _b[0], setRows = _b[1];
    var style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
    };
    var buttonStyle = {
        margin: 16,
        width: '10%'
    };
    useEffect(function () {
        setRows(props.rows);
    }, [props.rows]);
    return (React.createElement("div", null,
        React.createElement(Modal, { open: props.open, onClose: props.handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", id: (_a = props.id) !== null && _a !== void 0 ? _a : undefined },
            React.createElement(Box, { sx: style },
                React.createElement(BreadcrumbDocto, { breadcrumbItems: props.breadcrumbItems }),
                React.createElement(FlexLayoutGrid, { columns: props.columns, rows: rows, onPageChange: props.onPageChange, onPageSizeChange: props.onPageSizeChange, pagination: props.paginationInfo, isLoading: props.isLoading, onSelectionModelChange: props.onSelectionModelChange, sortBy: props.sortBy, onSortChange: props.onSortChange }),
                React.createElement(Button, { style: buttonStyle, size: 'small', variant: 'contained', onClick: props.handleOnButtonClick }, props.buttonText)))));
};
