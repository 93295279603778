import React from "react";
import { FormControl, InputLabel, Select, } from "@mui/material";
import { styles } from "./styles";
export var CustomSelect = function (_a) {
    var value = _a.value, onChange = _a.onChange, menuItems = _a.menuItems, helperText = _a.helperText, _b = _a.required, required = _b === void 0 ? false : _b, _c = _a.autoWidth, autoWidth = _c === void 0 ? true : _c, _d = _a.minWidth, minWidth = _d === void 0 ? 120 : _d, sx = _a.sx;
    return (React.createElement(FormControl, { required: required, sx: { m: 1, minWidth: minWidth } },
        helperText !== null && helperText !== undefined && (React.createElement(InputLabel, { style: styles.formHelperText, variant: "outlined", id: "custom-select-label" }, helperText)),
        React.createElement(Select, { value: value, onChange: onChange, autoWidth: autoWidth, labelId: "custom-select-label", label: helperText, MenuProps: {
                PaperProps: {
                    sx: sx,
                },
            } }, menuItems)));
};
