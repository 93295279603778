import { IGetAlertConfigIndividualResponseDto } from "@doctomatic/sdk/build/dto/Alerts/config/individual/IAlertConfigIndividual";
import {
  AlertState,
  IGetAlertUserAckDto,
} from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import { IGetPatientResponseDto } from "@doctomatic/sdk/build/dto/Patient/IPatient";
import {} from "@doctomatic/sdk/build/dto/Treatment/ITreatments";
import { Role } from "@doctomatic/sdk/build/dto/User";
import AlertsDataRow from "../AlertsDataRow";

export default class AlertsByFrequencyDataRow extends AlertsDataRow {
  frequencyId: number;
  constructor(
    id: number,
    patient: IGetPatientResponseDto,
    color: string,
    label: string,
    comments: string,
    created_at: Date,
    ackBy: IGetAlertUserAckDto[],
    actorId: number,
    actorRole: Role,
    state: AlertState,
    onClickCheckbox: (item: any) => void,
    individualConfig: IGetAlertConfigIndividualResponseDto,
    frequencyId: number,
    viewModal?: (props: any) => React.ReactElement
  ) {
    super(
      id,
      patient,
      color,
      label,
      comments,
      created_at,
      ackBy,
      [],
      actorId,
      actorRole,
      state,
      onClickCheckbox,
      viewModal,
      individualConfig,
      undefined
    );
    this.frequencyId = frequencyId;
  }
}
