import { Theme } from "@mui/material/styles";
import { useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { processError } from "../../../../App/errorToast";
import { IUseExternalReferencesByUserId } from "@doctomatic/sdk/build/modules/ExternalReferences/ExternalReferencesByUserId";
import {
  ExternalReferenceGridColumns,
  ExternalReferenceFromList,
} from "./ExternalReferenceGridColumns";
import { GridColumns } from "@mui/x-data-grid";
import { AddExternalReferenceModal } from "./AddExternalReferenceModal";
import { EditExternalReferenceModal } from "./EditExternalReferenceModal";
import {
  GetExternalReferenceResponseDto,
  UpdateExternalReferenceRequestDto,
} from "@doctomatic/sdk/build/dto/ExternalReferences/ExternalReferences";
import ExternalReferenceDataRow from "./ExternalReferenceDataRow";
import { AddCircle } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import ConfirmModal from "@doctomatic/components-react/build/ConfirmModal/ConfirmModal";
import { useLoading } from "../../../Loading/Loading";
import { useNavigate } from "react-router-dom";

interface Props {
  open: boolean;
  userId?: number;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  visible: { overflowY: "visible" },
}));

const ExternalReferenceModal = ({
  open,
  userId,
  onClose,
}: Props): React.ReactElement => {
  const { useExternalReferencesByUserId, logout, companyId } = useApi();
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const { t, i18n } = useTranslation();

  // declaration of hooks to handle pagination
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  // Store the pagination
  // Update pagination when page or pageSize changes
  const pagination = useMemo(() => {
    return {
      page: page,
      limit: pageSize,
    };
  }, [page, pageSize]);

  const {
    response: externalReferencesResponse,
    add,
    patch,
    del,
  }: IUseExternalReferencesByUserId = useExternalReferencesByUserId(
    userId ?? 0,
    pagination,
    true,
    processError(logout, navigate, t)
  );
  const externalReferencePaginationInfo = externalReferencesResponse?.meta;

  const classes = useStyles();
  const externalReferences = externalReferencesResponse?.data;
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [externalReferenceToEdit, setExternalReferenceToEdit] =
    useState<ExternalReferenceFromList>();
  const [externalReferenceToDelete, setExternalReferenceToDelete] =
    useState<ExternalReferenceFromList | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = useState<string>("");

  if (userId === undefined || !externalReferences) return <></>;

  const externalReferencesDataRows: ExternalReferenceDataRow[] =
    externalReferences?.map(
      (externalReference: GetExternalReferenceResponseDto) =>
        ({
          id: externalReference.id,
          source: externalReference.source,
          reference: externalReference.reference,
          userId: externalReference.userId,
          companyId: companyId,
        } as ExternalReferenceDataRow)
    ) || [];

  const onClickEdit = (externalReference: ExternalReferenceFromList) => {
    setIsOpenEditModal(true);
    setExternalReferenceToEdit(externalReference);
  };

  const handleSaveEdit = async (
    externalReference: UpdateExternalReferenceRequestDto
  ) => {
    if (externalReferenceToEdit) {
      try {
        await patch(externalReferenceToEdit.id, externalReference);
        setIsOpenEditModal(false);
      } catch (err: any) {
        toast.error(
          `${t("ErrorEdit")}: ${err.response?.data?.message || err.message}`
        );
      }
    }
  };

  const actions = (externalReference: ExternalReferenceFromList) => {
    const onClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setAnchorEl(event.currentTarget);
      setPopoverId(`m${externalReference.id}`);
    };

    const onClose = () => {
      setAnchorEl(null);
      setPopoverId("");
    };

    return (
      <>
        <IconButton onClick={onClick}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={`m${externalReference.id}`}
          open={popoverId === `m${externalReference.id}`}
          anchorEl={anchorEl}
          onClose={onClose}
        >
          <List>
            <ListItem
              button
              onClick={() => {
                onClose();
                onClickEdit(externalReference);
              }}
            >
              <ListItemIcon>
                <EditOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={`${t("ButtonEdit")} ${t("ExternalReference")}`}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                onClose();
                setExternalReferenceToDelete(externalReference);
              }}
            >
              <ListItemIcon>
                <DeleteOutlineIcon />
              </ListItemIcon>
              <ListItemText
                primary={`${t("ButtonDelete")} ${t("ExternalReference")}`}
              />
            </ListItem>
          </List>
        </Popover>
      </>
    );
  };

  const externalReferenceGridColumns: GridColumns<ExternalReferenceDataRow> =
    ExternalReferenceGridColumns(t, actions);

  const confirmDelete = externalReferenceToDelete && (
    <ConfirmModal
      open={Boolean(externalReferenceToDelete)}
      content={
        <Trans
          i18nKey="UserListDeleteConfirm"
          values={{ user: t("Source"), name: externalReferenceToDelete.source }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonDelete")}
      onCancel={() => setExternalReferenceToDelete(null)}
      onClose={() => setExternalReferenceToDelete(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await del(externalReferenceToDelete.id);
        } catch (err: any) {
          toast.error(
            `${t("ErrorDelete")}: ${err.response?.data?.message || err.message}`
          );
        }
        setExternalReferenceToDelete(null);
        setIsLoading(false);
      }}
    />
  );

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      classes={{ paper: classes.visible }}
    >
      <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
        {`${t("ExternalReferences")}`}
        <IconButton onClick={() => setIsOpenAddModal(true)}>
          <AddCircle color="primary" fontSize="large" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.visible}>
        <FlexLayoutGrid
          language={i18n.language}
          columns={externalReferenceGridColumns}
          rows={externalReferencesDataRows}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          pagination={externalReferencePaginationInfo}
        ></FlexLayoutGrid>
        {isOpenAddModal && (
          <AddExternalReferenceModal
            open={isOpenAddModal}
            userId={userId}
            onClose={() => setIsOpenAddModal(false)}
            onSave={async (externalReference) => {
              try {
                await add(externalReference);
                setIsOpenAddModal(false);
              } catch (err: any) {
                toast.error(
                  `${t("ErrorCreate")}: ${
                    err.response?.data?.message || err.message
                  }`
                );
              }
            }}
          />
        )}
        {isOpenEditModal && externalReferenceToEdit && (
          <EditExternalReferenceModal
            open={isOpenEditModal}
            externalReferenceToEdit={externalReferenceToEdit}
            onClose={() => setIsOpenEditModal(false)}
            onSave={handleSaveEdit}
          />
        )}
        {confirmDelete}
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={onClose} variant="contained" size="small">
              {t("ButtonClose")}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { ExternalReferenceModal };
