import i18n from 'i18next';
import { en, es, fr, ca, pt_PT, pt_BR, it } from './locales'
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const resources = {
  en: { translation: en },
  es: { translation: es },
  fr: { translation: fr },
  ca: { translation: ca },
  pt_PT: { translation: pt_PT },
  pt_BR: { translation: pt_BR },
  it: { translation: it },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources,
    fallbackLng: 'es',
    supportedLngs: ['en', 'es', 'ca', 'pt_PT', 'pt_BR', 'fr', 'it'],
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupQuerystring: 'lng',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage']
    }
  });