import React, { useEffect, useMemo, useState } from "react";
import { Page } from "../../Page";
import { toast } from "react-toastify";
import { useLoading } from "../../../Loading/Loading";

import { Box, IconButton } from "@mui/material";

import { useApi, IProvideApi } from "@doctomatic/sdk/build/Api";

import { IUseAlertGroups } from "@doctomatic/sdk/build/modules/AlertGroup/AlertGroup";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { AlertGroupList } from "./AlertGroupList";
import { AlertGroupCreate } from "./AlertGroupCreate";
import { GetActorResponseDto } from "@doctomatic/sdk/build/modules/Profile";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { AlertGroupEdit } from "./AlertGroupEdit";
import {
  AddAlertGroupRequestDto,
  GetAlertGroupResponseDto,
  UpdateAlertGroupRequestDto,
} from "@doctomatic/sdk/build/dto/Alerts/group/AlertGroup";
import ConfirmModal from "@doctomatic/components-react/build/ConfirmModal/ConfirmModal";
import { AddCircle } from "@mui/icons-material";
import { GridSortItem } from "@mui/x-data-grid";
import { ConfigError } from "@doctomatic/sdk/build/global";

const INITIAL_PAGINATION = { page: 1, limit: 10, sortBy: [] };

interface IAlertGroupsProps {
  profile: GetActorResponseDto | undefined;
}

const AlertGroupPage = ({ profile }: IAlertGroupsProps): React.ReactElement => {
  const getRole = (role: Role): "doctors" | "nurses" | "admin_company" => {
    if (role === Role.doctor) return "doctors";
    if (role === Role.nurse) return "nurses";
    if (role === Role.admin) return "admin_company";
    return "doctors";
  };

  const {
    useAlertGroups,
    usePatientsByActor,
    role,
    useAlertConfigsByValueTemplate,
    useAlertConfigsByNoMeasureTemplate,
    useCompany,
    companyId,
  }: IProvideApi = useApi();
  const { t } = useTranslation();

  // declaration of hooks to handle pagination
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [sort, setSort] = useState<GridSortItem[]>([]);

  const { response: patientsResponse } = usePatientsByActor(
    profile?.id ?? 0,
    getRole(role),
    pagination,
    "",
    [],
    undefined,
    undefined,
    true
  );

  const { response: company } = useCompany(
    companyId,
    true,
    ConfigError.onError
  );

  const { alertConfigsByValue: alertConfigByValueResponse } =
    useAlertConfigsByValueTemplate(pagination, true);
  const { response: alertConfigByNoMeasureResponse } =
    useAlertConfigsByNoMeasureTemplate(pagination, true);
  const patients = patientsResponse?.data;
  const patientsPaginationInfo = patientsResponse?.meta;

  const alertConfigByValue = alertConfigByValueResponse?.data;
  const paginationByValueInfo = alertConfigByValueResponse?.meta;
  const alertConfigByNoMeasure = alertConfigByNoMeasureResponse?.data;
  const paginationByNoMeasureInfo = alertConfigByNoMeasureResponse?.meta;

  // // declaration of hooks to handle pagination of alert group
  const [pageSizeGroup, setPageSizeGroup] = useState<number>(10);
  const [pageGroup, setPageGroup] = useState<number>(1);

  // Store the pagination
  // Update pagination when page or pageSize changes
  const paginationGroup = useMemo(() => {
      return {
        page: pageGroup,
        limit: pageSizeGroup,
      }
  }, [pageGroup, pageSizeGroup]);
  

  const { alertGroups: alertGroupsResponse, add, del, update, mutate }: IUseAlertGroups = useAlertGroups(paginationGroup);
  const { setIsLoading } = useLoading();

  const alertGroups: GetAlertGroupResponseDto[] | undefined = alertGroupsResponse?.data;
  
  const alertGroupPaginationInfo = alertGroupsResponse?.meta;

  const [alertGroupFormOpen, setAlertGroupFormOpen] = useState<boolean>(false);
  const [alertGroupToDelete, setAlertGroupToDelete] =
    useState<GetAlertGroupResponseDto | null>(null);
  const [alertGroupToEdit, setAlertGroupToEdit] =
    useState<GetAlertGroupResponseDto | null>(null);

  useEffect(() => {
    setLoading(true);
    setPagination({ page: page, limit: pageSize, sortBy: sort });
  }, [page, pageSize, sort]);

  useEffect(() => {
    setLoading(false);
  }, [patients]);

  const title = t("AlertGroupsTitle");

  const buttons = (
    <Box display="flex" justifyContent="flex-end">
      <IconButton onClick={() => setAlertGroupFormOpen(true)}>
        <AddCircle color="primary" fontSize="large" />
      </IconButton>
    </Box>
  );

  const confirmDelete = alertGroupToDelete && (
    <ConfirmModal
      open={Boolean(alertGroupToDelete)}
      content={
        <Trans
          i18nKey="AlertGroupsDeleteConfirm"
          values={{ name: alertGroupToDelete.name }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonDelete")}
      onCancel={() => setAlertGroupToDelete(null)}
      onClose={() => setAlertGroupToDelete(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await del(alertGroupToDelete.id);
          await mutate();
        } catch (err: any) {
          toast.error(
            `${t("ErrorDelete")}: ${err.response?.data?.message || err.message}`
          );
        }
        setAlertGroupToDelete(null);
        setIsLoading(false);
      }}
    />
  );

  const alertGroupCreateForm = alertGroupFormOpen && (
    <AlertGroupCreate
      patientsByActor={patients}
      open={alertGroupFormOpen}
      onClose={() => setAlertGroupFormOpen(false)}
      onSave={async (dto: AddAlertGroupRequestDto) => {
        setIsLoading(true);
        try {
          await add(dto);
          await mutate();
        } catch (err: any) {
          toast.error(
            `${t("ErrorCreate")}: ${err.response?.data?.message || err.message}`
          );
        }
        setAlertGroupFormOpen(false);
        setIsLoading(false);
      }}
      alertConfigsByValue={alertConfigByValue}
      alertConfigsByNoMeasure={alertConfigByNoMeasure}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      paginationInfo={patientsPaginationInfo}
      paginationInfoByValue={paginationByValueInfo}
      paginationInfoByNoMeasure={paginationByNoMeasureInfo}
      sortBy={sort}
      onSortChange={setSort}
      messengerChannel={company?.data?.messengerChannel as string}
    />
  );

  const alertGroupEditForm = alertGroupToEdit && (
    <AlertGroupEdit
      alertGroup={alertGroupToEdit}
      patientsByActor={patients}
      open={Boolean(alertGroupToEdit)}
      onClose={() => setAlertGroupToEdit(null)}
      onSave={async (dto: UpdateAlertGroupRequestDto) => {
        setIsLoading(true);
        try {
          await update(alertGroupToEdit.id, dto);
          await mutate();
        } catch (err: any) {
          toast.error(
            `${t("ErrorCreate")}: ${err.response?.data?.message || err.message}`
          );
        }
        setAlertGroupToEdit(null);
        setIsLoading(false);
      }}
      alertConfigsByValue={alertConfigByValue}
      alertConfigsByNoMeasure={alertConfigByNoMeasure}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      paginationInfo={patientsPaginationInfo}
      paginationInfoByValue={paginationByValueInfo}
      paginationInfoByNoMeasure={paginationByNoMeasureInfo}
      messengerChannel={company?.data?.messengerChannel as string}
    />
  );

  const getBreadCrumbProps = (): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        { url: "", name: t(BreadcrumbNameMap.AlertGroups.name) },
      ],
    } as BreadcrumbProps;
  };

  return (
    <Page
      title={title}
      buttons={buttons}
      breadCrumbProps={getBreadCrumbProps()}
    >
      <Box
        style={{
          marginLeft: "10%",
          marginRight: "10%",
        }}
      >
        <AlertGroupList
          onDelete={setAlertGroupToDelete}
          onEdit={setAlertGroupToEdit}
          userId={profile?.id ?? 0}
          alertGroups={alertGroups}
          setPage={setPageGroup}
          setPageSize={setPageSizeGroup}
          alertGroupPaginationInfo={alertGroupPaginationInfo}
        />
      </Box>
      {alertGroupCreateForm}
      {confirmDelete}
      {alertGroupEditForm}
    </Page>
  );
};

export { AlertGroupPage };
