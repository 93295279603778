import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Page } from "../../Page";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import {
  Device,
  Measurement,
  Sign,
} from "@doctomatic/components-react/build/Graphs/models";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { DateRangePicker } from "@doctomatic/components-react/build/Graphs/DateRangePicker";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { GetAlertByValueResponseDto } from "@doctomatic/sdk/build/dto/Alerts/Alerts";
import { GraphAlert } from "@doctomatic/components-react/build/Graphs/GraphAlert/GraphAlert";
import { IUseMeasurementsByAlert } from "@doctomatic/sdk/build/modules/MeasurementsByAlert";
import { IUseAlertByValue } from "@doctomatic/sdk/build/modules/AlertByValue";
import { processError } from "../../../../App/errorToast";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { ResponseApi, ResponseApiList } from "@doctomatic/sdk/build/global";
import {
  GetMeasurementResponseDto,
  UpdateMeasurementRequestDto,
  UpdateMeasurementResponseDto,
} from "@doctomatic/sdk/build/dto/Measurements/Measurements";
import { IUseImages } from "@doctomatic/sdk/build/modules/Images";
import { PermissionDto } from "@doctomatic/sdk/build/dto/GroupPermission/GroupPermission";
import { IUseMeasurements } from "@doctomatic/sdk/build/modules/Measurements";
import { useLoading } from "../../../Loading/Loading";
import { toast } from "react-toastify";
import { getSignName } from "../../utils";

interface AlertsByValueGraphParams {
  alertId: string;
  actorId: string;
  actorRole: Role;
}

export const AlertsByValueGraph = (): React.ReactElement => {
  const navigate = useNavigate();
  const { alertId, actorId, actorRole }: AlertsByValueGraphParams =
    useParams<AlertsByValueGraphParams>();
  const { t, i18n } = useTranslation();
  const {
    useAlertByValue,
    useMeasurementsByAlert,
    logout,
    useImages,
    useMeasurements,
  }: IProvideApi = useApi();
  const { response: alertResponse }: IUseAlertByValue = useAlertByValue(
    +alertId,
    +actorId,
    actorRole,
    true,
    processError(logout, navigate, t)
  );
  const { updateMany, del, delByReadDevice }: IUseMeasurements =
    useMeasurements(
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      processError(logout, navigate, t)
    );
  const { setIsLoading } = useLoading();
  const alert = alertResponse?.data;
  console.log(alert);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [device, setDevice] = useState<Device>();
  const [measurementsResponse, setMeasurementsResponse] =
    useState<ResponseApiList<GetMeasurementResponseDto>>();
  const { getUrl }: IUseImages = useImages(
    false,
    processError(logout, navigate, t)
  );
  const [permissions, setPermissions] = useState<PermissionDto[]>([]);
  const [editMeasurementsPermissions, setEditMeasurementsPermissions] =
    useState<boolean>(false);
  const [deleteMeasurementsPermissions, setDeleteMeasurementsPermissions] =
    useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  const { findAll }: IUseMeasurementsByAlert = useMeasurementsByAlert(
    alert?.patient.id ?? 0,
    device?.id ?? 0,
    dateRange[0] === null
      ? undefined
      : moment(dateRange[0]).format("yyyy-MM-DD"),
    dateRange[1] === null
      ? undefined
      : moment(dateRange[1]).format("yyyy-MM-DD"),
    false,
    processError(logout, navigate, t)
  );

  const onSaveMeasurement = async (measurements: any[]) => {
    let updateResponse: undefined | ResponseApi<UpdateMeasurementResponseDto[]>;
    setIsLoading(true);
    try {
      const updateMeasurements: UpdateMeasurementRequestDto[] = [];
      measurements.forEach((measurement) => {
        const updateMeasurement = new UpdateMeasurementRequestDto();
        Object.assign(updateMeasurement, measurement);
        updateMeasurements.push(updateMeasurement);
      });
      updateResponse = await updateMany(updateMeasurements);
      if (updateResponse.success === true) {
        toast.success(t("UpdateMeasurementSuccess"));
      }
    } catch (err) {
      toast.error(`${t("UpdateMeasurementError")}`);
    }
    setIsLoading(false);
    return updateResponse;
  };

  const onDeleteOneMeasurement = async (measurementId: number) => {
    setIsLoading(true);
    try {
      const delResponse = await del(measurementId);
      if (delResponse.success === true) {
        toast.success(t("DeleteMeasurementSuccess"));
      }
    } catch (err) {
      toast.error(`${t("DeleteMeasurementError")}`);
    }
    setIsLoading(false);
  };

  const onDeleteManyMeasurements = async (readDeviceId: number) => {
    setIsLoading(true);
    try {
      const delResponse = await delByReadDevice(readDeviceId);
      if (delResponse.success === true) {
        toast.success(t("DeleteManyMeasurementsSuccess"));
      }
    } catch (err) {
      toast.error(`${t("DeleteManyMeasurementsError")}`);
    }
    setIsLoading(false);
  };

  const findAllMeasurements = async (
    alert: GetAlertByValueResponseDto | undefined,
    device: Device | undefined,
    dateRange: [Date | null, Date | null]
  ) => {
    if (alert?.patient?.id && device?.id) {
      const date1 =
        dateRange[0] === null
          ? undefined
          : moment(dateRange[0]).format("yyyy-MM-DD");

      const date2 =
        dateRange[1] === null
          ? undefined
          : moment(dateRange[1]).format("yyyy-MM-DD");

      const response: ResponseApiList<GetMeasurementResponseDto> =
        await findAll(alert.patient.id, device.id, date1, date2);
      if (response && response.success === true && response.data!) {
        setMeasurementsResponse(response);
      }
    }
  };

  /**
   * Will generate a set of dates based on the alert's measurement creation date
   * If not present, will use the alert creation date.
   * @param alert
   * @returns minDate, maxDate
   */
  const getMeasurementsDateRange = (alert: GetAlertByValueResponseDto) => {
    // Amount of days to take as range
    const amountOfDays = 14;
    let baseDate: Date;

    if (alert.measurement.createdAt) {
      baseDate = new Date(alert.measurement.createdAt);
    } else {
      baseDate = new Date(alert.created_at);
    }

    const minDate = new Date(baseDate);
    const maxDate = new Date(baseDate);

    minDate.setDate(minDate.getDate() - amountOfDays);
    maxDate.setDate(maxDate.getDate() + amountOfDays);

    return { minDate, maxDate };
  };

  const measurements =
    measurementsResponse?.data.map((m: any) => {
      const newMeasure: Measurement = {
        id: m.id,
        value: m.value,
        createdAt: m.createdAt,
        signId: m.signId,
        patientId: alert?.patient.id ?? 0,
        readDeviceId: m.readDeviceId,
      };
      return newMeasure;
    }) ?? [];

  useEffect(() => {
    const cache = localStorage.getItem("permissions");
    if (cache) setPermissions(JSON.parse(cache));
  }, []);

  useEffect(() => {
    findAllMeasurements(alert, device, dateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, device]);

  useEffect(() => {
    if (alert) {
      const sign: Sign = {
        id: alert.sign.id,
        name: getSignName(currentLanguage, alert.sign, t),
        unit: alert.sign.unit,
        decimals: alert.sign.decimals,
        max: alert.sign.max,
        min: alert.sign.min,
        device: alert.sign.device,
        typeSign: alert.sign.typeSign,
      };

      const device: Device = {
        id: alert.sign.device.id,
        name: alert.sign.device.name,
        signs: [sign],
      };

      // Obtain dates
      const { minDate, maxDate } = getMeasurementsDateRange(alert);

      setDevice(device);
      setDateRange([minDate, maxDate]);
    }
  }, [alert, alert?.sign, currentLanguage]);

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      if (
        permissions.find((p) => {
          return p.key === "update.measurement";
        })
      ) {
        setEditMeasurementsPermissions(true);
      } else {
        setEditMeasurementsPermissions(false);
      }
      if (
        permissions.find((p) => {
          return p.key === "delete.measurement";
        })
      ) {
        setDeleteMeasurementsPermissions(true);
      } else {
        setDeleteMeasurementsPermissions(false);
      }
    }
  }, [permissions]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const getBreadCrumbProps = (
    alert: GetAlertByValueResponseDto | undefined
  ): BreadcrumbProps =>
    ({
      breadcrumbItems: [
        {
          url: `/patients/${alert?.patient.id}`,
          name: alert?.patient.name ?? "",
        },
        { url: "/alerts?tab=0", name: t("AlertsTitle") },
        { url: "", name: alert?.label ?? "" },
      ],
    } as BreadcrumbProps);

  const filters = (
    <Box display="flex" justifyContent="flex-end" height={"100%"}>
      <DateRangePicker
        dateRangePicked={dateRange}
        setDateRange={setDateRange}
        t={t}
      />
    </Box>
  );

  if (!alert || !device) return <></>;

  return (
    <Page
      title=""
      buttons={filters}
      breadCrumbProps={getBreadCrumbProps(alert)}
    >
      <GraphAlert
        measurements={measurements}
        dateRangePicked={dateRange}
        device={device}
        t={t}
        language={i18n.language}
        getUrl={getUrl}
        onSaveMeasurement={onSaveMeasurement}
        editMeasurementsPermissions={editMeasurementsPermissions}
        deleteMeasurementsPermissions={deleteMeasurementsPermissions}
        onDeleteOneMeasurement={onDeleteOneMeasurement}
        onDeleteManyMeasurements={onDeleteManyMeasurements}
      />
    </Page>
  );
};
