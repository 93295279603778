
import { darken, lighten, SxProps, Theme } from '@mui/material/styles';

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

export  function DoctomaticStylingRowsGrid():SxProps<Theme> {
 
  return {
        height: 800,
        maxHeight: '100%',
        width: '100%',
        '& .super-app-theme--Open': {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.info.main, theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
          },
        },
        '& .super-app-theme--Filled': {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.success.main, theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
              ),
          },
        },
        '& .super-app-theme--PartiallyFilled': {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
              ),
          },
        },
        '& .super-app-theme--Rejected': {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.error.main, theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
          },
        },
        '& .no-actived-alert-config': {
          bgcolor: (theme) =>
            getBackgroundColor('#D3D3D3', theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor('#D3D3D3', theme.palette.mode),
          },
        },
      }
   
}
