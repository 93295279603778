import React, { useEffect, useState } from "react";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { IGetTreatmentResponseDto } from "@doctomatic/sdk/build/dto/Treatment/ITreatments";
import { GridColumns } from "@mui/x-data-grid";
import TreatmentsDataRow from "../TreatmentsDataRow";
import {
  TreatmentsDoctorNurseGridColumns,
  TreatmentsDoctorNurseGridColumnsProps,
} from "./TreatmentsDoctorNurseGridColumns";
import { toast } from "react-toastify";
import { processError } from "../../../../App/errorToast";
import { IUseTreatmentsByActor } from "@doctomatic/sdk/build/modules/Treatments/TreatmentsByActor";
import { Treatments } from "../Treatments";
import ConfirmModal from "@doctomatic/components-react/build/ConfirmModal/ConfirmModal";
import { DialogTitle, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const TreatmentsDoctorNurse = (): React.ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    useProfile,
    useTreatmentsByActor,
    useCompany,
    logout,
    role,
    companyId,
    timezone,
  } = useApi();
  const { response: profile } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const { response: companyResponse } = useCompany(companyId);
  const company = companyResponse?.data;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedTracking, setSelectedTracking] = useState<TreatmentsDataRow>();
  const [showEnded, setShowEnded] = useState<boolean>(false);
  const [totalTreatments, setTotalTreatments] = useState<number>(0);

  // Declaration of hooks to handle pagination
  const [lastTreatments, setLastTreatments] = useState<any>([]);
  const [pageSizeTreatments, setPageSizeTreatments] = useState<number>(10);
  const [pageTreatments, setPageTreatments] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationValue, setPaginationValue] = useState<any>({
    page: pageTreatments,
    limit: pageSizeTreatments,
  });

  const {
    response: treatmentsByActorResponse,
    end,
    reopen,
    mutate,
  }: IUseTreatmentsByActor = useTreatmentsByActor(
    profile?.data?.id ?? 0,
    role,
    showEnded,
    Boolean(profile?.data?.id),
    paginationValue,
    processError(logout, navigate, t)
  );

  const treatments = treatmentsByActorResponse?.data;
  const treatmentsPaginationInfo = treatmentsByActorResponse?.meta;

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(`m${item.id}`);
  };

  const handleConfirm = (item: TreatmentsDataRow) => {
    setSelectedTracking(item);
    const haveEndDateButIsOpen = item.end && item.end >= new Date();
    if (haveEndDateButIsOpen) {
      setOpen(true);
    } else {
      onEndOrReopen(item);
    }
  };

  const onEndOrReopen = async (item: TreatmentsDataRow) => {
    try {
      if (item.end) {
        await reopen(item.id);
      } else {
        await end(item.id);
      }
      await mutate();
    } catch (err: any) {
      toast.error(
        `${t("ErrorExportTreatmentMeasurements")}: ${
          err.response?.data?.message || err.message
        }`
      );
    }
  };

  const onClose = () => {
    setAnchorEl(null);
    setPopoverId("");
  };

  const treatmentsGridColumns: GridColumns<TreatmentsDataRow> =
    TreatmentsDoctorNurseGridColumns({
      t,
      timezone,
      showExternalId: company?.showExternalId,
      popoverConf: {
        onClick,
        onClose,
        anchorEl,
        popoverId,
        onEndOrReopen: handleConfirm,
      },
    } as TreatmentsDoctorNurseGridColumnsProps);

  const treatmentsDataRows: TreatmentsDataRow[] = treatments
    ? treatments.map(
        (treatment: IGetTreatmentResponseDto, index: number) =>
          ({
            id: treatment.id,
            patient: {
              id: treatment.patient.id,
              name: treatment.patient.name,
              email: "",
              deletedAt: undefined,
              externalId: treatment.patient?.externalId,
            },
            name: treatment.name,
            start: new Date(
              treatment.start_at ? treatment.start_at : treatment.createdAt
            ),
            end: treatment.closed_at
              ? new Date(treatment.closed_at)
              : undefined,
            lastMeasurement: treatment.lastMeasurement
              ? new Date(treatment.lastMeasurement)
              : undefined,
            alert: treatment.alert ? new Date(treatment.alert) : undefined,
          } as TreatmentsDataRow)
      )
    : [];

  const getBreadCrumbProps = (actorName: string): BreadcrumbProps =>
    ({
      breadcrumbItems: [
        { url: "/", name: actorName },
        { url: "", name: t("TreatmentsTitle") },
      ],
    } as BreadcrumbProps);

  let actorName = profile?.data?.name ? profile!.data!.name : "";

  const confirmModalContent = (
    <>
      <DialogTitle id="confirm-dialog">
        {t("ApplyFrequenciesTitle")}
      </DialogTitle>
      <DialogContent>{t("ApplyFrequenciesContent")}</DialogContent>
    </>
  );

  useEffect(() => {
    if (treatments) {
      setLastTreatments(treatments);
    }
  }, [treatments, treatmentsPaginationInfo]);

  useEffect(() => {
    setLoading(true);
    setPaginationValue({ page: pageTreatments, limit: pageSizeTreatments });
  }, [pageTreatments, pageSizeTreatments]);

  useEffect(() => {
    setLoading(false);
  }, [lastTreatments]);

  useEffect(() => {
    setTotalTreatments(treatmentsPaginationInfo?.totalItems || 0);
  }, [treatmentsPaginationInfo]);

  return (
    <>
      <Treatments
        breadcrumbs={getBreadCrumbProps(actorName)}
        treatmentsDataRows={treatmentsDataRows}
        treatmentsGridColumns={treatmentsGridColumns}
        treatments={lastTreatments}
        treatmentsPaginationInfo={treatmentsPaginationInfo}
        totalTreatments={totalTreatments}
        isLoading={loading}
        onPageChange={setPageTreatments}
        onPageSizeChange={setPageSizeTreatments}
        showEnded={showEnded}
        setShowEnded={setShowEnded}
      />
      <ConfirmModal
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
        onCancel={() => {
          setOpen(!open);
        }}
        onConfirm={async () => {
          await end(selectedTracking?.id || 0);
          await mutate();
          setOpen(!open);
        }}
        content={confirmModalContent}
        cancelText={t("ButtonNo")}
        confirmText={t("ButtonYes")}
      />
    </>
  );
};
