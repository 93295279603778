import { Device, Sign } from "@doctomatic/components-react/build/Graphs/models";
import { Box, Button, Modal, SelectChangeEvent } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";
import { ModalBaseProps } from "../../../../../Modals/modalBaseProps";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { IUseDevices } from "@doctomatic/sdk/build/modules/Devices";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { SignDto } from "@doctomatic/sdk/build/dto/Sign/Signs";
import { AlertType } from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import { processError } from "../../../../../../App/errorToast";
import { toast } from "react-toastify";
import { AddAlertConfigByValueTemplateRequestDto } from "@doctomatic/sdk/build/dto/Alerts/config/template/AlertConfigByValueTemplate";
import { alertTypeMap } from "../../../../AlertConfigs/utils";
import { AddAlertConfigByValue } from "../../../../../AlertsConfig/AddAlertConfigByValueForm";
import { getDeviceName, getSignName } from "../../../../utils";
import { useNavigate } from "react-router-dom";

interface TimeUnit {
  text: string;
  value: string;
}

interface Props extends ModalBaseProps {
  open: boolean;
  onSave: (
    alertGroup: AddAlertConfigByValueTemplateRequestDto
  ) => Promise<void>;
  currentLanguage: string;
}

export const AddAlertConfigByValueModal = (
  props: Props
): React.ReactElement => {
  const { t } = useTranslation();
  const { useDevices, logout }: IProvideApi = useApi();
  const navigate = useNavigate();
  const { response: responseApiDevices }: IUseDevices = useDevices(
    true,
    processError(logout, navigate)
  );

  const [devices, setDevices] = useState<Device[]>([]);

  // TODO
  const colors: string[] = ["red", "yellow", "green", "blue", "orange"];
  const timeUnits: TimeUnit[] = [
    { text: t("hours"), value: "h" },
    { text: t("days"), value: "d" },
  ];
  // TODO: AlertType time_absolute and time_relative
  const conditions: string[][] = alertTypeMap;

  const checkDevicesWithSigns: boolean =
    devices &&
    devices.length > 0 &&
    devices[0].signs &&
    devices[0].signs.length > 0;

  const initialValue: AddAlertConfigByValueTemplateRequestDto = {
    color: colors[0],
    signId: checkDevicesWithSigns ? devices[0].signs[0].id : 0,
    min: undefined,
    max: undefined,
    alert_type: AlertType.min,
    range_value: undefined,
    range_time: undefined,
    label: "",
  };

  const [alertConfig, setAlertConfig] = useState(initialValue);
  const [deviceId, setDeviceId] = useState<number>(
    devices && devices.length > 0 ? devices[0].id : 0
  );
  const [signs, setSigns] = useState<Sign[]>(
    devices && devices.length > 0 ? devices[0].signs : []
  );
  const [signUnit, setSignUnit] = useState<string>(
    devices &&
      devices.length > 0 &&
      devices[0].signs &&
      devices[0].signs.length > 0
      ? devices[0].signs[0].unit
      : ""
  );
  const [condition, setCondition] = useState<string>(conditions[0][0]);
  const [time, setTime] = useState<number>();
  const [timeUnit, setTimeUnit] = useState<string>(timeUnits[0].value);

  const isTime: boolean =
    condition === "time_absolute" || condition === "time_relative";

  const handleAlertConfig = useCallback(
    (value: any, field: string): void => {
      setAlertConfig({ ...alertConfig, [field]: value });
    },
    [alertConfig]
  );

  const initialErrors = {
    min: false,
    max: false,
    out_of_bounds: false,
    range_value: false,
    range_time: false,
  };
  const [errors, setErrors] = useState<any>(initialErrors);

  const checkErrors = (
    dto: AddAlertConfigByValueTemplateRequestDto
  ): boolean => {
    let validationErrors = initialErrors;
    if (dto.alert_type === "min") {
      validationErrors.min = dto.min === undefined || dto.min === null;
    } else if (dto.alert_type === "max") {
      validationErrors.max = dto.max === undefined || dto.max === null;
    } else if (AlertType.get_and_lt === dto.alert_type) {
      // check min is defined
      validationErrors.min = dto.min === undefined || dto.min === null;
      // check max is defined
      validationErrors.max = dto.max === undefined || dto.max === null;

      // check min is lower than max
      if (dto.min && dto.max) {
        validationErrors.out_of_bounds = Number(dto.max) < Number(dto.min);
      }
    } else if (
      dto.alert_type === "range_absolute" ||
      dto.alert_type === "range_relative"
    ) {
      validationErrors.range_value =
        dto.range_value === undefined || dto.range_value === null;
    } else if (
      dto.alert_type === "time_absolute" ||
      dto.alert_type === "time_relative"
    ) {
      validationErrors.range_value =
        dto.range_value === undefined || dto.range_value === null;
      validationErrors.range_time =
        dto.range_time === undefined || dto.range_time === null;
    }
    setErrors(validationErrors);

    return Object.values(validationErrors).some((value: boolean) => !!value);
  };

  useEffect(() => {
    let fieldNames: any = {
      min: "FieldMin",
      max: "FieldMax",
      out_of_bounds: "FieldGETLT",
      range_value: "FieldRangeValue",
      range_time: "FieldRangeTime",
    };

    const error: string | undefined = Object.keys(errors).find(
      (key: string) => errors[key] === true
    );
    if (error)
      toast.error(
        t("ErrorRequiredField", { field_name: t(fieldNames[error]) })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleFormSubmit = async (): Promise<void> => {
    const dto: AddAlertConfigByValueTemplateRequestDto =
      new AddAlertConfigByValueTemplateRequestDto();
    Object.assign(dto, alertConfig);
    if (!checkErrors(dto)) {
      await props.onSave(dto);
    }
  };

  const handleOnChangeLabel = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAlertConfig({ ...alertConfig, label: e.target.value });
  };

  useEffect(() => {
    if (
      responseApiDevices &&
      responseApiDevices.success === true &&
      responseApiDevices.data!
    ) {
      const devices: Device[] =
        responseApiDevices?.data?.map((device: GetDeviceResponseDto) => {
          const signs: Sign[] = device.signs?.map(
            (sign: SignDto) =>
              ({
                id: sign.id,
                decimals: sign.decimals,
                max: sign.defaultMax,
                min: sign.defaultMin,
                name: getSignName(props.currentLanguage, sign, t),
                unit: sign.unit,
              } as Sign)
          );
          return {
            id: device.id,
            name: getDeviceName(props.currentLanguage, device, t),
            signs: signs,
          } as Device;
        }) ?? [];
      setDevices(devices);
    }
  }, [responseApiDevices]);

  useEffect(() => {
    if (devices && devices.length > 0) setDeviceId(devices[0].id);
  }, [devices]);

  useEffect(() => {
    if (devices && devices.length > 0) {
      const device: Device | undefined = devices.find(
        (d: Device) => d.id === deviceId
      );
      if (device !== undefined) {
        setSigns(device.signs);
        if (condition === "min" || condition === "max") {
          handleAlertConfig(alertConfig.min, "min");
          handleAlertConfig(alertConfig.max, "max");
        }
      }
    }
  }, [devices, deviceId, condition, alertConfig]);

  useEffect(() => {
    if (signs && signs.length > 0) {
      handleAlertConfig(signs[0].id, "signId");
    }
  }, [signs]);

  useEffect(() => {
    if (alertConfig && alertConfig.signId) {
      const sign: Sign | undefined = signs.find(
        (s: Sign) => s.id === alertConfig.signId
      );
      if (sign !== undefined) {
        const signUnit =
          sign.unit === "point"
            ? `1 = ${t("Yes")} / 0 = ${t("No")}`
            : sign.unit;
        setSignUnit(signUnit);
        if (condition === "min" || condition === "max") {
          handleAlertConfig(alertConfig.min, "min");
          handleAlertConfig(alertConfig.max, "max");
        }
      }
    }
  }, [alertConfig, signs, condition]);

  useEffect(() => {
    if (isTime) {
      handleAlertConfig(`${time}${timeUnit}`, "range_time");
    }
  }, [time, timeUnit, isTime, condition]);

  const handleOnColorChange = (e: SelectChangeEvent) => {
    handleAlertConfig(e.target.value, "color");
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          <AddAlertConfigByValue
            deviceId={deviceId}
            setDeviceId={setDeviceId}
            devices={devices}
            signs={signs}
            label={alertConfig.label ?? ""}
            handleOnChangeLabel={handleOnChangeLabel}
            color={alertConfig.color}
            handleOnChangeColor={handleOnColorChange}
            time={time}
            setTime={setTime}
            timeUnit={timeUnit}
            setTimeUnit={setTimeUnit}
            condition={condition}
            setCondition={setCondition}
            alertConfig={alertConfig}
            setAlertConfig={setAlertConfig}
            signUnit={signUnit}
            errors={errors}
          />
          <Box style={styles.btnSubmit}>
            <Button variant="contained" onClick={handleFormSubmit}>
              {t("CreateAlert")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
