var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { format } from "date-fns";
import { FilterOperator } from "./advanced-search.enum";
import { Filter } from "./column.filter";
export function porcessDatePickerChange(payload) {
    if (!payload.activeAdvancedSearch) {
        return payload.filters;
    }
    if (payload.newValue) {
        // compute array of dates to filter between
        var selectedDate = new Date(payload.newValue);
        var today = new Date();
        var formatDate = format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss'.000Z'");
        var todayFormated = format(today, "yyyy-MM-dd'T'HH:mm:ss'.000Z'");
        // generate array of dates
        var values = today.getMilliseconds() > selectedDate.getMilliseconds() ? [formatDate, todayFormated] : [todayFormated, formatDate];
        var exists = payload.filters.some(function (filter) { return filter.column === payload.column; });
        var newFilters = __spreadArray([], payload.filters, true);
        var filter = void 0;
        if (exists) {
            // find filter
            var indexOfExisting_1 = -1;
            payload.filters.forEach(function (value, index) {
                if (value.column === payload.column) {
                    indexOfExisting_1 = index;
                    return;
                }
            });
            // get filter
            filter = payload.filters[indexOfExisting_1];
            // remove filter from existings
            newFilters.splice(indexOfExisting_1, 1);
            filter.values = values;
        }
        else {
            filter = new Filter(payload.column, FilterOperator.BTW, values);
        }
        // add filter
        newFilters.push(filter);
        return newFilters;
    }
    else {
        var exists = payload.filters.some(function (f) { return f.column === 'created_at'; });
        if (exists) {
            var newFilters = __spreadArray([], payload.filters, true);
            // find filter
            var indexOfExisting_2 = -1;
            payload.filters.forEach(function (value, index) {
                if (value.column === payload.column) {
                    indexOfExisting_2 = index;
                    return;
                }
            });
            // remove filter from existings
            newFilters.splice(indexOfExisting_2, 1);
            return newFilters;
        }
        return payload.filters;
    }
}
export function processCheckboxChange(payload) {
    if (!payload.activeAdvancedSearch) {
        return payload.filters;
    }
    var selectedValue = payload.newValue;
    var selectedFilter = payload.column;
    // was the filter selected?
    var isSelected = payload.selected;
    var filter;
    var newFilters = __spreadArray([], payload.filters, true);
    // if is selected
    if (isSelected) {
        // check if the filter was already selected - means, it's adding more values to the column
        var contained = payload.filters.some(function (filter) { return filter.column === selectedFilter; });
        // exists
        if (contained) {
            var indexOf_1 = -1;
            // search index of filter
            payload.filters.forEach(function (filter, index) {
                if (filter.column === selectedFilter) {
                    indexOf_1 = index;
                    return;
                }
            });
            // Obtain the filter
            filter = payload.filters[indexOf_1];
            // add the new value
            filter.values.push(selectedValue);
            // remove it from the new filters
            newFilters.splice(indexOf_1, 1);
        }
        else {
            // create the new filter
            filter = new Filter(selectedFilter, FilterOperator.IN, [selectedValue]);
        }
        // add the computed filter to the list
        newFilters.push(filter);
    }
    else {
        var indexOfElementToRemove_1 = -1;
        // search index of filter
        payload.filters.forEach(function (filter, index) {
            if (filter.column === selectedFilter) {
                indexOfElementToRemove_1 = index;
                return;
            }
        });
        // obtain the filter
        filter = payload.filters[indexOfElementToRemove_1];
        // remove the whole filter
        newFilters.splice(indexOfElementToRemove_1, 1);
        // if this filter have more than one value selected
        if (filter.values.length > 1) {
            // remove the unselected value
            var indexOfValue_1 = -1;
            filter.values.forEach(function (filter, index) {
                if (filter === selectedFilter) {
                    indexOfValue_1 = index;
                    return;
                }
            });
            filter.values.splice(indexOfValue_1, 1);
            newFilters.push(filter);
        }
    }
    return newFilters;
}
