import React, { useEffect, useState } from "react";
import { Page } from "../Page";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { BreadcrumbNameMap } from "../../../breadcrumbMap";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { processError } from "../../../App/errorToast";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { Grid, Typography } from "@mui/material";
import {
  getDeviceName,
  getQuestionName,
  getSignName,
  toLocaleDateString,
} from "../utils";
import { ImageEditModal } from "@doctomatic/components-react/build/ImagesViews/ImageEditModal";
import { UpdateMeasurementRequestDto } from "@doctomatic/sdk/build/dto/Measurements/Measurements";
import { useLoading } from "../../Loading/Loading";
import { toast } from "react-toastify";
import { IUseMeasurements } from "@doctomatic/sdk/build/modules/Measurements";
import { ImageViewModal } from "@doctomatic/components-react/build/ImagesViews/ImageViewModal";
import {
  BasicMeasurement,
  Device,
} from "@doctomatic/components-react/build/Graphs/models";
import ReadDeviceForm from "@doctomatic/components-react/build/ReadDevice/ReadDeviceForm";
import { PermissionDto } from "@doctomatic/sdk/build/dto/GroupPermission/GroupPermission";
import { Role } from "@doctomatic/sdk/build/dto/User";

export interface ImageItem {
  url: string | undefined;
  device: Device;
  measurements: BasicMeasurement[];
  signs: string | undefined;
}

const ReadDevicePage = (): React.ReactElement => {
  const { readDeviceId }: any = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setIsLoading } = useLoading();
  const {
    useReadDevice,
    useMeasurements,
    useDevices,
    useImages,
    logout,
  }: IProvideApi = useApi();
  const { response: readDeviceResponse, mutate } = useReadDevice(
    +readDeviceId,
    undefined,
    undefined,
    true,
    processError(logout, navigate, t)
  );
  const readDevice = readDeviceResponse?.data;
  const patient = readDevice?.user;
  const takeBy = readDevice?.takeBy;
  const {
    updateMany,
    del,
    delByReadDevice,
    findAllByReadDevice,
  }: IUseMeasurements = useMeasurements(
    undefined,
    undefined,
    undefined,
    undefined,
    false,
    processError(logout, navigate, t)
  );
  const { getDevice } = useDevices(false, processError(logout, navigate, t));
  const { getUrl } = useImages(false, processError(logout, navigate, t));

  const [permissions, setPermissions] = useState<PermissionDto[]>([]);
  const [editMeasurementsPermissions, setEditMeasurementsPermissions] =
    useState<boolean>(false);
  const [deleteMeasurementsPermissions, setDeleteMeasurementsPermissions] =
    useState<boolean>(false);
  const [measurementToDelete, setMeasurementToDelete] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [readDeviceToDelete, setReadDeviceToDelete] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [imageItem, setImageItem] = useState<ImageItem | undefined>();
  const [additionalData, setAdditionalData] = useState<string>("");
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
  const [signs, setSigns] = useState<string>("");

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    let cache = localStorage.getItem("permissions");
    if (cache) setPermissions(JSON.parse(cache));
  }, []);

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      if (
        permissions.find((p) => {
          return p.key === "update.measurement";
        })
      ) {
        setEditMeasurementsPermissions(true);
      } else {
        setEditMeasurementsPermissions(false);
      }
      if (
        permissions.find((p) => {
          return p.key === "delete.measurement";
        })
      ) {
        setDeleteMeasurementsPermissions(true);
      } else {
        setDeleteMeasurementsPermissions(false);
      }
    }
  }, [permissions]);

  useEffect(() => {
    const measurementsAndDeviceByReadDevice = async (readDeviceId: number) => {
      if (readDevice) {
        const measurementResponse = await findAllByReadDevice(readDeviceId);
        const deviceResponse = await getDevice(readDevice?.deviceId);
        const additionalData =
          deviceResponse.data?.typeName === "Form"
            ? deviceResponse.additional_data
            : "";
        setAdditionalData(additionalData);

        let signs;
        if (additionalData) {
          const data = JSON.parse(additionalData);
          const questions = data.questions;
          signs = deviceResponse.data?.signs
            .map((sign, index) => {
              const signName = getSignName(currentLanguage, sign, t);
              if (signName.includes("question")) {
                return getName(questions, index, signName);
              } else {
                return signName;
              }
            })
            .join(", ");
        } else {
          signs = deviceResponse.data?.signs
            .map((sign) => getSignName(currentLanguage, sign, t))
            .join(", ");
        }
        const imageUrlResponse =
          deviceResponse.data?.typeName === "Form"
            ? ""
            : (await getUrl(readDeviceId)).data;
        setImageItem({
          url: imageUrlResponse,
          device: deviceResponse?.data as Device,
          measurements: measurementResponse?.data as BasicMeasurement[],
          signs: signs,
        });
        if (signs) {
          setSigns(signs);
        }
      }
    };
    measurementsAndDeviceByReadDevice(+readDeviceId);
  }, [i18n.language, readDevice]);

  const getName = (questions: any, index: number, name: string) => {
    if (questions) {
      return getQuestionName(currentLanguage, questions[index].text, t, name);
    }
    return name;
  };

  const getBreadCrumbProps = (actorName: string): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        { url: "", name: actorName },
        {
          url: BreadcrumbNameMap.Patients.url,
          name: t(BreadcrumbNameMap.Patients.name),
        },
        { url: "", name: patient?.name },
        { url: "", name: readDeviceId },
      ],
    } as BreadcrumbProps;
  };

  const onSaveMeasurement = async (measurements: any[]) => {
    setIsLoading(true);
    try {
      let updateMeasurements: UpdateMeasurementRequestDto[] = [];
      measurements.forEach((measurement) => {
        let updateMeasurement = new UpdateMeasurementRequestDto();
        Object.assign(updateMeasurement, measurement);
        updateMeasurement.measurementId = measurement.id;
        updateMeasurements.push(updateMeasurement);
      });
      const updateResponse = await updateMany(updateMeasurements);
      if (updateResponse.success === true) {
        toast.success(t("UpdateMeasurementSuccess"));
        if (imageItem) {
          const measurementResponse = await findAllByReadDevice(readDeviceId);
          setImageItem({
            ...imageItem,
            measurements: measurementResponse.data as BasicMeasurement[],
          });
        }
      }

      await mutate();
    } catch (err) {
      toast.error(`${t("UpdateMeasurementError")}`);
    }
    setIsLoading(false);
  };

  const onDeleteOneMeasurement = async (measurementId: number) => {
    setIsLoading(true);
    try {
      const delResponse = await del(measurementId);
      if (delResponse.success === true && imageItem) {
        toast.success(t("DeleteMeasurementSuccess"));
        const toUpdate = imageItem.measurements.findIndex(
          (m) => m.id === measurementId
        );
        let newList = [...imageItem.measurements];
        newList.splice(toUpdate, 1);
        setImageItem({ ...imageItem, measurements: newList });
        await mutate();
      }
    } catch (err) {
      toast.error(`${t("DeleteMeasurementError")}`);
    }
    setIsLoading(false);
  };

  const onDeleteManyMeasurements = async (readDeviceId: number) => {
    setIsLoading(true);
    try {
      if (imageItem) {
        const delResponse = await delByReadDevice(readDeviceId);

        /**
         * We reset measurements list since we deleted all the measurements.
         */
        if (delResponse.success === true) {
          toast.success(t("DeleteManyMeasurementsSuccess"));
          setImageItem({ ...imageItem, measurements: [] });
        }
      }
    } catch (err) {
      toast.error(`${t("DeleteManyMeasurementsError")}`);
    }
    setIsLoading(false);
  };

  const onChangeMeasurements = (event: any, signId: number) => {
    if (imageItem) {
      const toUpdate = imageItem.measurements.findIndex(
        (m) => m.signId === signId
      );
      let newList = [...imageItem.measurements];
      if (toUpdate !== -1) {
        newList[toUpdate].value = +event.target.value;
      } else {
        const newMeasurement = new BasicMeasurement(
          0,
          +event.target.value,
          new Date(),
          signId,
          +readDeviceId
        );
        newList.push(newMeasurement);
      }
      setImageItem({ ...imageItem, measurements: newList });
    }
  };

  const handleError = () => {
    setError(true);
  };

  return (
    <Page breadCrumbProps={getBreadCrumbProps("User1")}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Page title={t("ReadingDetails")}>
            <Typography variant="subtitle1" paddingTop={3}>
              <b>{t("Patient")}:</b> {patient?.name ? patient?.name : "--"}
            </Typography>
            <Typography variant="subtitle1" paddingTop={5}>
              <b>{t("ReadDeviceTakeBy")}:</b> {takeBy?.name}{" "}
              {takeBy?.role === Role.user
                ? ""
                : `- ${
                    takeBy?.role &&
                    t(
                      takeBy.role.charAt(0).toUpperCase() + takeBy.role.slice(1)
                    )
                  }`}{" "}
            </Typography>
            <Typography variant="subtitle1" paddingTop={5}>
              <b>{t("Date")}:</b>{" "}
              {toLocaleDateString(readDevice?.created_at ?? "")}
            </Typography>
            {imageItem?.device?.typeName !== "Form" && (
              <>
                <Typography variant="subtitle1" paddingTop={5}>
                  <b>{t("Device")}:</b>{" "}
                  {imageItem?.device?.name
                    ? getDeviceName(currentLanguage, imageItem?.device, t)
                    : "--"}
                </Typography>
                <Typography variant="subtitle1" paddingTop={5}>
                  <b>{t("Sign")}:</b> {signs}
                </Typography>
              </>
            )}
          </Page>
        </Grid>
        {imageItem?.device?.typeName === "Form" && (
          <Grid item xs={12} lg={6}>
            <Typography variant="subtitle1" paddingTop={12} paddingLeft={3}>
              <b>{t("Device")}:</b>{" "}
              {imageItem?.device?.name
                ? getDeviceName(currentLanguage, imageItem?.device, t)
                : "--"}
            </Typography>
            <Typography variant="subtitle1" paddingTop={5} paddingLeft={3}>
              <b>{t("Sign")}:</b> {signs}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} lg={6}>
          <Page>
            {imageItem && imageItem.device.typeName !== "Form" ? (
              !editMeasurementsPermissions && !deleteMeasurementsPermissions ? (
                <ImageViewModal
                  imageUrl={imageItem.url as string}
                  altText={""}
                  open={false}
                  handleClose={() => null}
                  t={t}
                  error={error}
                  handleError={handleError}
                  isModal={false}
                />
              ) : (
                <ImageEditModal
                  imageUrl={imageItem.url as string}
                  altText={""}
                  open={true}
                  handleClose={() => null}
                  t={t}
                  error={error}
                  handleError={handleError}
                  measurements={imageItem.measurements}
                  onSave={onSaveMeasurement}
                  editPermissions={true}
                  deletePermissions={true}
                  onDeleteOne={onDeleteOneMeasurement}
                  onDeleteMany={onDeleteManyMeasurements}
                  onChange={onChangeMeasurements}
                  device={imageItem.device}
                  readDeviceId={+readDeviceId}
                  measurementToDelete={measurementToDelete}
                  setMeasurementToDelete={setMeasurementToDelete}
                  readDeviceToDelete={readDeviceToDelete}
                  setReadDeviceToDelete={setReadDeviceToDelete}
                  isModal={false}
                  currentLanguage={currentLanguage}
                />
              )
            ) : (
              <></>
            )}
          </Page>
        </Grid>
      </Grid>
      {imageItem && imageItem.device.typeName === "Form" && (
        <ReadDeviceForm
          measurements={imageItem?.measurements as BasicMeasurement[]}
          t={t}
          language={currentLanguage}
          createdAt={toLocaleDateString(readDevice?.created_at ?? "")}
          getUrl={getUrl}
          onSaveMeasurement={onSaveMeasurement}
          editMeasurementsPermissions
          deleteMeasurementsPermissions
          onDeleteOneMeasurement={onDeleteOneMeasurement}
          onDeleteManyMeasurements={onDeleteManyMeasurements}
          currentLanguage={currentLanguage}
          additionalData={additionalData}
          setSigns={setSigns}
        />
      )}
    </Page>
  );
};

export { ReadDevicePage };
