import { Trans } from "react-i18next";
import React, { useState } from "react";
import { Page } from "../Page";
import { toast } from "react-toastify";
import { WebhookList } from "./WebhookList";
import { WebhookCreate } from "./WebhookCreate";
import { useLoading } from "../../Loading/Loading";
import { GetWebhookResponseDto } from "@doctomatic/sdk/build/dto/Webhooks";
import { Box, Button, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useApi } from "@doctomatic/sdk/build/Api";
import { processError } from "../../../App/errorToast";
import ConfirmModal from "@doctomatic/components-react/build/ConfirmModal/ConfirmModal";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  total: {
    marginRight: 16,
    padding: "4px 0",
    lineHeight: 1.75,
    fontSize: 16,
  },
  deleted: {
    textTransform: "none",
    color: theme.palette.text.primary,
    fontWeight: 400,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "white",
    },
  },
  showDeletedLabel: {
    lineHeight: 1.75,
    fontSize: 16,
  },
}));

const Webhooks = (): React.ReactElement => {
  const { useWebhooks, logout } = useApi();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    response: webhooksResponse,
    remove,
    add,
  } = useWebhooks(true, processError(logout, navigate, t));
  const { setIsLoading } = useLoading();
  const classes = useStyles();
  const [isWebhookFormOpen, setIsWebhookFormOpen] = useState<boolean>(false);
  const [webhookToDelete, setWebhookToDelete] =
    useState<GetWebhookResponseDto | null>(null);
  const title = t("HeaderPatients");
  const webhooks = webhooksResponse?.data;
  if (!webhooks) return <></>;

  const buttons = webhooks && webhooks.length > 0 && (
    <Box display="flex" justifyContent="flex-end">
      <Button
        size="small"
        variant="contained"
        onClick={() => setIsWebhookFormOpen(true)}
      >
        {t("ButtonCreate")}
      </Button>
    </Box>
  );

  const footer = webhooks && webhooks.length > 0 && (
    <Box my={2} display="flex" justifyContent="flex-end">
      <Typography className={classes.total}>
        {t("WebhookListTotal")}: {webhooks && webhooks.length}
      </Typography>
    </Box>
  );

  const confirmDelete = webhookToDelete && (
    <ConfirmModal
      open={Boolean(webhookToDelete)}
      content={
        <Trans
          i18nKey="WebhookListDeleteConfirm"
          values={{ webhook: "Webhook", name: webhookToDelete.id }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonDelete")}
      onCancel={() => setWebhookToDelete(null)}
      onClose={() => setWebhookToDelete(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await remove(webhookToDelete.id.toString());
        } catch (err: any) {
          toast.error(
            `${t("ErrorDelete")}: ${err.response?.data?.message || err.message}`
          );
        }
        setWebhookToDelete(null);
        setIsLoading(false);
      }}
    />
  );

  const webhookList = (
    <WebhookList
      onCreate={() => setIsWebhookFormOpen(true)}
      onDelete={setWebhookToDelete}
    />
  );

  const webhookCreateForm = (
    <WebhookCreate
      open={isWebhookFormOpen}
      onClose={() => setIsWebhookFormOpen(false)}
      onSave={async (webhook) => {
        setIsLoading(true);
        try {
          await add(webhook);
        } catch (err: any) {
          toast.error(
            `${t("ErrorCreate")}: ${err.response?.data?.message || err.message}`
          );
        }
        setIsWebhookFormOpen(false);
        setIsLoading(false);
      }}
    />
  );

  return (
    <Page title={title} buttons={buttons} footer={footer}>
      {webhookList}
      {webhookCreateForm}
      {confirmDelete}
    </Page>
  );
};

export { Webhooks };
