var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Box, Typography, Select, MenuItem, IconButton, TableCell, Tooltip, Chip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Time } from './Time';
var styles = {
    select: {
        // padding: '10px 14px',
        fontSize: '14px',
        minWidth: '77px'
    },
    error: {
        bottom: 0,
        marginBottom: 2,
        fontSize: '12px',
        color: '#FF526D',
    },
    iconButton: {
        padding: '20px',
        height: '20px',
        width: '20px'
    },
};
function removeItem(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}
var MAX_FREQUENCY_MIN = 96;
var Frequency = function (_a) {
    var initialWeekdays = _a.initialWeekdays, initialMin = _a.initialMin, week = _a.week, onChange = _a.onChange, onValidate = _a.onValidate, t = _a.translator;
    var paddingWithError = '20px 0px 0px 0px';
    var normalPadding = '0px 8px';
    var _b = useState(initialWeekdays), weekdays = _b[0], setWeekdays = _b[1];
    var _c = useState(initialMin), min = _c[0], setMin = _c[1];
    var _d = useState([]), areMinsValid = _d[0], setAreMinsValid = _d[1];
    var minToHHmm = function (min) { return "".concat(Math.floor(min / 60)).padStart(2, '0') + ':' + "".concat(min % 60).padStart(2, '0'); };
    var handleDays = function (event) {
        setWeekdays(event.target.value
        // .sort((a: , b: number) => a - b)
        );
    };
    var renderSelect = function (selected) { return (selected.length === 0
        ? (React.createElement("em", null, t('WeekdaysSelectPlaceholder')))
        : (React.createElement(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 } }, selected.map(function (value) { return (React.createElement(Chip, { key: value, label: week[value] })); })))); };
    useEffect(function () {
        var minsValid = Array.from(min, function (v, k) { return v !== null && (k === 0 || v > min[k - 1]); });
        setAreMinsValid(minsValid);
        onValidate(minsValid.every(Boolean), t('FrequencyInvalidError'));
        onChange(weekdays, min);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weekdays, min]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TableCell, null,
            React.createElement(Box, { display: 'flex', alignItems: 'center' },
                React.createElement(Select, { style: styles.select, variant: 'outlined', name: 'days', displayEmpty: true, onChange: function (event) { return handleDays(event); }, value: weekdays, multiple: true, renderValue: function (value) { return renderSelect(value); } },
                    React.createElement(MenuItem, { disabled: true, value: undefined },
                        React.createElement("em", null, t('WeekdaysSelectPlaceholder'))),
                    Object.entries(week).map(function (_a, index) {
                        var key = _a[0], value = _a[1];
                        return (React.createElement(MenuItem, { key: "".concat(index).concat(key), value: key }, t(value)));
                    })))),
        React.createElement(TableCell, { style: { display: 'flex', flexWrap: 'wrap', alignItems: 'center' } },
            min.map(function (m, index, array) {
                if (index === 0)
                    return (React.createElement(Box, { style: { paddingRight: '16px' }, key: index },
                        React.createElement(Time, { min: min[0], onChange: function (newMin) {
                                var newMins = __spreadArray([], min, true);
                                newMins[0] = newMin;
                                setMin(newMins);
                            } })));
                return (React.createElement(Box, { style: { display: 'flex', alignItems: 'center', flex: '0 0 30%', flexDirection: 'row', padding: !areMinsValid[index] ? paddingWithError : normalPadding }, key: index },
                    React.createElement(Box, { style: { display: 'flex', flexDirection: 'column' } },
                        React.createElement(Box, { style: { display: 'flex', flexDirection: 'row', alignItems: 'center' } },
                            React.createElement(Time, { min: m, onChange: function (time) {
                                    var newMins = __spreadArray([], min, true);
                                    newMins[index] = time;
                                    setMin(newMins);
                                } }),
                            m !== null && index === array.length - 1 && (React.createElement(Tooltip, { placement: 'top', title: t('FrequencyDeleteHour') || '' },
                                React.createElement(IconButton, { onClick: function () {
                                        var newMins = __spreadArray([], min, true);
                                        newMins = removeItem(newMins, m);
                                        setMin(newMins);
                                    }, style: styles.iconButton },
                                    React.createElement(CancelIcon, null))))),
                        !areMinsValid[index] && (React.createElement(Box, null,
                            React.createElement(Typography, { style: styles.error }, t('FrequencyInvalidError', { hour: minToHHmm(min[index - 1]) })))))));
            }),
            min.length < MAX_FREQUENCY_MIN && (React.createElement(Tooltip, { placement: 'top', title: t('FrequencyAddHour') || '' },
                React.createElement(IconButton, { onClick: function () {
                        var newMins = __spreadArray([], min, true);
                        newMins[min.length] = 0;
                        setMin(newMins);
                    }, style: styles.iconButton },
                    React.createElement(AddCircleIcon, null)))))));
};
export { Frequency };
