/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";

const listZoom = [1, 1.5, 2.5, 3.5, 5];
interface Props {
  indexZoom: number | undefined;
  setIndexZoom: (indexZoom: number) => void;
  setZoom: (zoom: number) => void;
  deviceId: string | undefined;
}

export const Zoom = ({ indexZoom, setIndexZoom, setZoom, deviceId }: Props) => {
  const setZoomLocalStorage = async (zoom: number, deviceId: number) => {
    localStorage.setItem(`@zoom-${deviceId}`, zoom.toString());
  };

  const getZoomLocalStorage = async (deviceId: string | undefined) => {
    const indexZoom = localStorage.getItem(`@zoom-${deviceId}`);
    if (indexZoom) {
      setIndexZoom(parseInt(indexZoom));
    } else {
      setIndexZoom(0);
    }
  };

  const getXZoom = () => {
    if (indexZoom === 0) {
      return "x1";
    }
    if (indexZoom === 1) {
      return "x2";
    }
    if (indexZoom === 2) {
      return "x5";
    }
    if (indexZoom === 3) {
      return "x7";
    }
    if (indexZoom === 4) {
      return "x10";
    }
  };

  const handlePlusZoom = () => {
    const newIndexZoom = (indexZoom as number) + 1;
    if (newIndexZoom > listZoom.length - 1) {
      return;
    }
    setIndexZoom(newIndexZoom);
    setZoom(listZoom[newIndexZoom]);
  };

  const handleMinusZoom = () => {
    if (indexZoom === 0) {
      return;
    }
    const newIndexZoom = (indexZoom as number) - 1;
    setIndexZoom(newIndexZoom);
    setZoom(listZoom[newIndexZoom]);
  };

  useEffect(() => {
    if (indexZoom !== undefined) {
      if (deviceId) {
        setZoomLocalStorage(indexZoom as number, parseInt(deviceId));
      }
      setZoom(listZoom[indexZoom as number]);
    }
  }, [indexZoom, deviceId]);

  useEffect(() => {
    setZoom(listZoom[indexZoom as number]);
  }, [listZoom, indexZoom]);

  useEffect(() => {
    getZoomLocalStorage(deviceId);
  }, [deviceId]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton
        onClick={handleMinusZoom}
        color="inherit"
        disabled={indexZoom === 0}
        size="medium"
        style={{ color: "white" }}
        sx={{
          borderRadius: 50,
          backgroundColor: "gray",
          color: "white",
          ":hover": {
            backgroundColor: "gray",
            color: "black",
          },
          ":disabled": {
            backgroundColor: "#80808082",
            color: "white",
          },
        }}
      >
        <RemoveIcon />
      </IconButton>
      <h3 style={{ color: "black", marginInline: 10, marginBlock: 0 }}>
        {getXZoom()}
      </h3>
      <IconButton
        onClick={handlePlusZoom}
        color="inherit"
        disabled={indexZoom === listZoom.length - 1}
        style={{ color: "white" }}
        size="medium"
        sx={{
          borderRadius: 50,
          backgroundColor: "gray",
          color: "white",
          ":hover": {
            backgroundColor: "gray",
            color: "black",
          },
          ":disabled": {
            backgroundColor: "#80808082",
            color: "white",
          },
        }}
      >
        <AddIcon />
      </IconButton>
    </div>
  );
};
