import React, { ReactElement, useState } from "react";
import {
  FormResponse,
  ResponseOfQuestionMultiRadio,
  ResponseOfQuestionRadio,
  T,
} from "@doctomatic/sdk/build/dto/ReadForm/types/ReadFormTypes";
import {
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  styled,
  Alert,
  IconButton,
} from "@mui/material";
import { useApi } from "@doctomatic/sdk/build/Api";
import {
  IOption,
  IResponseOfQuestionNumeric,
  IResponseOfQuestionRadio,
  IResponseOfQuestionText,
} from "@doctomatic/sdk/build/dto/ReadForm/types/IReadFormTypes";
import RadioGroupForm from "./RadioGroupForm";
import { _UUID } from "@doctomatic/sdk/build/dto/ReadForm/types/IReadFormTypes";
import ImageForm from "./ImageForm";
import { useTranslation } from "react-i18next";
import MultiSelectForm from "./MultiSelectForm";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { DescriptionModal } from "../Description/DescriptionModal";
import DeviceForm from "./DeviceForm";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

interface QuestionFormProps {
  form: FormResponse | undefined;
  currentPage: number;
  userId: number;
  buttonNextEnable: boolean;
  companyColor?: string | undefined;
  setButtonNextEnable: (value: boolean) => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
  handleResponseChange: (questionId: number, response: any) => void;
  description: string | undefined;
  setDescription(value: string | undefined): void;
  showDescription: boolean;
}

export interface RadioItem {
  id: _UUID;
  value: number;
  textValue: string;
  color: string;
  label: string;
  isValueEditable: boolean;
  isTextValueEditable: boolean;
  checked: boolean;
}

const QuestionForm: React.FC<QuestionFormProps> = ({
  form,
  currentPage,
  userId,
  buttonNextEnable,
  companyColor,
  setButtonNextEnable,
  onNextPage,
  onPreviousPage,
  handleResponseChange,
  description,
  setDescription,
  showDescription,
}) => {
  const { t } = useTranslation();
  const { lang } = useApi();
  const [valueNumeric, setValueNumeric] = useState("");
  const [openDescription, setOpenDescription] = useState<boolean>(false);

  const renderQuestionWithRadio = (
    questionIndex: number,
    form: FormResponse
  ): ReactElement => {
    const handleValueChange = (
      optionId: string,
      value: number,
      valueText: string
    ): void => {
      if (valueText !== "") {
        setButtonNextEnable(true);
        const question = form.responses[
          questionIndex
        ] as ResponseOfQuestionRadio;
        question.valueText = valueText;
        question.value = value;
        question.id = optionId;
      }
    };

    const translation = new T([lang]);

    return (
      <RadioGroupForm
        isQuestionForm={true}
        items={form.responses[questionIndex]?.options
          .sort((e: IOption) => e.order)
          .map((e: IOption) => {
            const hasValue =
              (form.responses[questionIndex] as IResponseOfQuestionRadio).id !=
                "" &&
              (form.responses[questionIndex] as IResponseOfQuestionRadio)
                .valueText != "";

            hasValue && setButtonNextEnable(true);

            return {
              id: e.id,
              textValue: e.textValue,
              label: translation.i18n(e.text),
              color: e.backgroundColor ? e.backgroundColor : "green",
              value: e.value,
              isValueEditable: e.isValueEditable,
              isTextValueEditable: e.isTextValueEditable,
              checked:
                (form.responses[questionIndex] as IResponseOfQuestionRadio)
                  .value == undefined || !hasValue
                  ? undefined
                  : e.value ==
                    (form.responses[questionIndex] as IResponseOfQuestionRadio)
                      .value,
            } as RadioItem;
          })}
        onValueChange={handleValueChange}
      />
    );
  };

  const renderQuestionWithMultiRadio = (
    questionIndex: number,
    form: FormResponse
  ): ReactElement => {
    const handleValueChange = (
      optionId: string[],
      value: number[],
      valueText: string[]
    ): void => {
      if (valueText.length > 0) {
        setButtonNextEnable(true);
        const question = form.responses[
          questionIndex
        ] as ResponseOfQuestionMultiRadio;
        question.valueText = valueText;
        question.value = value;
        question.id = optionId;
      }
    };

    const translation = new T([lang]);

    return (
      <MultiSelectForm
        isQuestionForm={true}
        items={form.responses[questionIndex]?.options
          .sort((e: IOption) => e.order)
          .map((e: IOption) => {
            const hasValue =
              (form.responses[questionIndex] as ResponseOfQuestionMultiRadio).id
                .length > 0 &&
              (form.responses[questionIndex] as ResponseOfQuestionMultiRadio)
                .value.length > 0;

            hasValue && setButtonNextEnable(true);
            return {
              id: e.id,
              textValue: e.textValue,
              label: translation.i18n(e.text),
              color: e.backgroundColor ? e.backgroundColor : "green",
              value: e.value,
              isValueEditable: e.isValueEditable,
              isTextValueEditable: e.isTextValueEditable,
              checked:
                (form.responses[questionIndex] as ResponseOfQuestionMultiRadio)
                  .value == undefined || !hasValue
                  ? undefined
                  : (
                      form.responses[
                        questionIndex
                      ] as ResponseOfQuestionMultiRadio
                    ).value.includes(e.value),
            } as RadioItem;
          })}
        questionIndex={questionIndex}
        onValueChange={handleValueChange}
      />
    );
  };

  const renderQuestionsWithText = (
    questionIndex: number,
    form: FormResponse
  ) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (value !== "") {
        const question = form.responses[
          questionIndex
        ] as IResponseOfQuestionText;
        question.response = value;
        handleResponseChange(questionIndex, form);
        setButtonNextEnable(true);
      }
    };

    return (
      <TextField
        style={{
          marginBlock: "15px",
          width: "100%",
        }}
        onChange={handleInputChange}
        value={
          (form.responses[questionIndex] as IResponseOfQuestionText).response
        }
        variant="outlined"
        multiline
      />
    );
  };

  const renderQuestionsWithNumeric = (
    questionIndex: number,
    form: FormResponse
  ) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const alertError = document.getElementById("alert-error");
      if (value !== "") {
        const isNumberValidRegExp = /^(?:\d{1,6}\.\d{1,4}|\d{1,6})$/;
        const valueWithDotSymbol = value.replace(",", ".");
        if (isNumberValidRegExp.test(valueWithDotSymbol)) {
          if (alertError) {
            alertError.style.display = "none";
          }
          const question = form.responses[
            questionIndex
          ] as IResponseOfQuestionNumeric;
          question.response = +valueWithDotSymbol;
          handleResponseChange(questionIndex, form);
          setButtonNextEnable(true);
        } else {
          if (alertError) {
            alertError.style.display = "inline-flex";
            alertError.style.marginBottom = "15px";
          }
          setButtonNextEnable(false);
        }
        setValueNumeric(value);
      } else {
        if (alertError) {
          alertError.style.display = "none";
        }
        setValueNumeric("");
        setButtonNextEnable(false);
      }
    };

    return (
      <>
        <TextField
          style={{
            marginBlock: "15px",
            width: "100%",
          }}
          type="number"
          value={valueNumeric}
          onChange={handleInputChange}
          variant="outlined"
          multiline
        />
        <Alert id="alert-error" style={{ display: "none" }} severity="error">
          {t("OnlyNumber")}
        </Alert>
      </>
    );
  };

  const renderQuestionsWithTime = (
    questionIndex: number,
    form: FormResponse
  ) => {
    const handleInputChange = (event: Dayjs | null) => {
      const value = event ? event.format("HH:mm") : "";
      if (value !== "") {
        const question = form.responses[
          questionIndex
        ] as IResponseOfQuestionText;
        question.response = value;
        handleResponseChange(questionIndex, form);
        setButtonNextEnable(true);
      }
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimeField
          style={{
            marginBlock: "15px",
            width: "50%",
          }}
          label="HH:mm"
          format="HH:mm"
          variant="outlined"
          onChange={(newValue) => handleInputChange(newValue as Dayjs)}
        />
      </LocalizationProvider>
    );
  };

  const renderQuestion = (questionIndex: number, form: FormResponse) => {
    switch (form.responses[questionIndex!].typeQuestion) {
      case "radio": {
        return renderQuestionWithRadio(questionIndex, form);
      }
      case "multiRadio": {
        return renderQuestionWithMultiRadio(questionIndex, form);
      }
      case "text": {
        return renderQuestionsWithText(questionIndex, form);
      }
      case "number": {
        return renderQuestionsWithNumeric(questionIndex, form);
      }
      case "image": {
        return <></>;
      }
      case "device": {
        return <></>;
      }
      case "time": {
        return renderQuestionsWithTime(questionIndex, form);
      }
      default: {
        return renderQuestionWithRadio(questionIndex, form);
      }
    }
  };

  function getContrastColor(backgroundColor: string | undefined) {
    if (!backgroundColor) return "#000";

    // Convert the hexadecimal color code to RGB format
    const hexColor = backgroundColor.replace("#", "");
    const red = parseInt(hexColor.substring(0, 2), 16);
    const green = parseInt(hexColor.substring(2, 4), 16);
    const blue = parseInt(hexColor.substring(4, 6), 16);

    // Calculate the relative luminance of the color
    const relativeLuminance =
      (red * 0.299 + green * 0.587 + blue * 0.114) / 255;

    // If the relative luminance is less than 0.5, return white as the text color, otherwise return black
    return relativeLuminance < 0.5 ? "#fff" : "#000";
  }

  const handleDescriptionModal = (text: string | undefined) => {
    if (text && text.length === 0) {
      setDescription(undefined);
    } else {
      setDescription(text);
    }
    setOpenDescription(false);
  };

  if (!form) return <></>;

  const translation = new T([lang]);
  const totalPages = form.responses.length;

  return (
    <div>
      <DescriptionModal
        open={openDescription}
        onSave={handleDescriptionModal}
        onClose={() => setOpenDescription(false)}
        description={description}
      />
      {form.responses[currentPage]?.typeQuestion === "image" ? (
        <ImageForm
          userId={userId}
          form={form}
          questionIndex={currentPage}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          handleResponseChange={handleResponseChange}
          setOpenDescription={setOpenDescription}
          showDescription={showDescription}
        />
      ) : form.responses[currentPage]?.typeQuestion === "device" ? (
        <DeviceForm
          userId={userId}
          form={form}
          questionIndex={currentPage}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          handleResponseChange={handleResponseChange}
          key={currentPage}
          setOpenDescription={setOpenDescription}
        />
      ) : (
        <StyledCard>
          <CardContent>
            <div style={{ flexDirection: "row-reverse", display: "flex" }}>
              <div
                style={
                  companyColor
                    ? {
                        backgroundColor: companyColor,
                        color: getContrastColor(companyColor),
                        paddingInline: 15,
                        paddingBlock: 5,
                        borderRadius: 10,
                      }
                    : {
                        color: "#000000",
                        backgroundColor: "#b8e0ba",
                        paddingInline: 15,
                        paddingBlock: 5,
                        borderRadius: 10,
                      }
                }
              >
                {currentPage + 1} / {totalPages}
              </div>
            </div>
            <StyleScroll>
              <div
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "flex-start",
                  textAlign: "center",
                  padding: "2em",
                  paddingBottom: "0em",
                }}
              >
                <Typography variant="h6">
                  {translation.i18n(form.responses[currentPage]?.text)}
                </Typography>
                {renderQuestion(currentPage, form)}

                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {onPreviousPage && (
                    <Button
                      variant="contained"
                      disabled={currentPage <= 0}
                      onClick={() => {
                        onPreviousPage();
                        setButtonNextEnable(true);
                      }}
                      style={{ color: "#000000", backgroundColor: "#C4C4C4" }}
                    >
                      {t("ButtonPrevious")}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    onClick={() => {
                      onNextPage();
                      setButtonNextEnable(false);
                      handleResponseChange(
                        currentPage,
                        form.responses[currentPage]
                      );
                    }}
                    style={
                      companyColor && companyColor !== "null"
                        ? {
                            backgroundColor: companyColor,
                            color: getContrastColor(companyColor),
                          }
                        : { color: "#000000", backgroundColor: "#b8e0ba" }
                    }
                    disabled={!buttonNextEnable}
                  >
                    {t("ButtonNext")}
                  </Button>
                </div>
                {showDescription && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 5,
                    }}
                  >
                    <IconButton
                      color="inherit"
                      edge="start"
                      onClick={() => setOpenDescription(true)}
                    >
                      <AddCommentIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            </StyleScroll>
          </CardContent>
        </StyledCard>
      )}
    </div>
  );
};

export default QuestionForm;

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: "40em",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    minWidth: "80%",
  },
}));

const StyleScroll = styled("div")(({ theme }) => ({
  maxHeight: "900px",
  overflowY: "auto",
  [theme.breakpoints.down("md")]: {
    maxHeight: "450px",
  },
}));
