import React from 'react'
import { DialogContent, DialogTitle } from '@mui/material'
import ConfirmModal from '@doctomatic/components-react/build/ConfirmModal/ConfirmModal';

interface ConfirmationTrackingTypeEditProps {
    handleSave: () => void
    openModalConfirmation: boolean
    setOpenModalConfirmation: React.Dispatch<React.SetStateAction<boolean>>
    t: (label: string) => string
}

const ConfirmationTrackingTypeEdit = ({ handleSave, openModalConfirmation, setOpenModalConfirmation, t }: ConfirmationTrackingTypeEditProps): React.ReactElement => {

    const confirmModalContent = (
        <>
            <DialogTitle id="confirm-dialog">{t('EditTrackingTypeConfirmationTitle')}</DialogTitle>
            <DialogContent>{t('EditTrackingTypeConfirmationModal')}</DialogContent>
        </>
    )

    return (
        <ConfirmModal
            open={openModalConfirmation}
            onClose={() => {
                setOpenModalConfirmation(!openModalConfirmation);
            }}
            onConfirm={async () => {
                handleSave();
            }}
            content={confirmModalContent}
            cancelText={t('ButtonNo')}
            confirmText={t('ButtonYes')}
            onCancel={() => {
                setOpenModalConfirmation(!openModalConfirmation);
            }}
        />
    )
}

export { ConfirmationTrackingTypeEdit }


