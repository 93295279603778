import React from "react";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

import { Page } from "../../Page";
import useStyles from "./styles";
import { PasswordForm } from "../Forms/PasswordForm";
import { useTranslation } from "react-i18next";

const ChangePassword = (): React.ReactElement => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page>
      <Box className={classes.container}>
        <Typography>{t("ChangePasswordTitle")}</Typography>
        <PasswordForm
          onClose={() => navigate("/")}
          onSave={() => navigate("/")}
        />
      </Box>
    </Page>
  );
};

export { ChangePassword };
