import { Box, MenuItem, SelectChangeEvent } from "@mui/material";
import {
  AlertConfigBaseForm,
  AlertConfigBaseProps,
} from "./AlertConfigBaseForm";
import {
  AlertConfigByValueBaseForm,
  AlertConfigByValueBaseProps,
} from "./AlertConfigByValueBaseForm";
import { Device, Sign } from "@doctomatic/components-react/build/Graphs/models";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "@doctomatic/components-react/build/AlertConfig/CustomSelect";
import { useCallback } from "react";
import { styles } from "./styles";

export interface AddAlertConfigByValueProps
  extends AlertConfigBaseProps,
    AlertConfigByValueBaseProps {
  deviceId: number;
  setDeviceId: (value: number) => void;
  devices: Device[];
  signs: Sign[];
}

export const AddAlertConfigByValue = ({
  deviceId,
  setDeviceId,
  devices,
  signs,
  label,
  handleOnChangeLabel,
  color,
  handleOnChangeColor,
  time,
  setTime,
  timeUnit,
  setTimeUnit,
  condition,
  setCondition,
  alertConfig,
  setAlertConfig,
  signUnit,
  errors,
}: AddAlertConfigByValueProps): React.ReactElement => {
  const { t } = useTranslation();
  const deviceMenuItems: JSX.Element[] = devices.map(
    (d: Device, index: number) => (
      <MenuItem key={index} value={d.id.toString()}>
        {t(d.name)}
      </MenuItem>
    )
  );
  const signMenuItems: JSX.Element[] =
    signs &&
    signs.map((s: Sign, index: number) => (
      <MenuItem key={index} value={s.id.toString()}>
        {t(s.name)}
      </MenuItem>
    ));
  const handleDevideId = (e: SelectChangeEvent): void => {
    setDeviceId(Number(e.target.value));
  };

  const handleAlertConfig = useCallback(
    (value: any, field: string): void => {
      setAlertConfig({ ...alertConfig, [field]: value });
    },
    [alertConfig]
  );

  return (
    <Box style={styles.titleContainer}>
      <AlertConfigBaseForm
        label={label}
        handleOnChangeLabel={handleOnChangeLabel}
        color={color}
        handleOnChangeColor={handleOnChangeColor}
      />
      <CustomSelect
        value={deviceId.toString()}
        onChange={(e: SelectChangeEvent) => handleDevideId(e)}
        menuItems={deviceMenuItems}
        helperText={`${t("FieldDevice")}`}
        required={true}
        minWidth={150}
      />
      <CustomSelect
        value={alertConfig.signId.toString()}
        onChange={(e: SelectChangeEvent) =>
          handleAlertConfig(Number(e.target.value), "signId")
        }
        menuItems={signMenuItems}
        helperText={`${t("FieldSign")}`}
        required={true}
        minWidth={150}
      />
      <AlertConfigByValueBaseForm
        time={time}
        setTime={setTime}
        timeUnit={timeUnit}
        setTimeUnit={setTimeUnit}
        condition={condition}
        setCondition={setCondition}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
        signUnit={signUnit}
        errors={errors}
      />
    </Box>
  );
};
