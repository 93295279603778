import React, { useState, useEffect, ChangeEvent } from "react";
import { Page } from "../../../Page";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { BreadcrumbNameMap } from "../../../../../breadcrumbMap";
import { IPermissionDto } from "@doctomatic/sdk/build/dto/GroupPermission/IGroupPermission";
import { GroupPermissionEditDataRow } from "../GroupPermissionEdit/GroupPermissionEditDataRow";
import { GroupPermissionEditGridColumns } from "../GroupPermissionEdit/GroupPermissionEditDataGridColumns";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { useNavigate } from "react-router-dom";

const GroupPermissionEditPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [permisionList, setPermissionList] = useState<IPermissionDto[]>();

  useEffect(() => {
    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (!permisionList) {
      getAllPermissions();
    }
  }, [permisionList]);

  const getBreadCrumbProps = (): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        {
          url: BreadcrumbNameMap.GroupPermissionAdd.url,
          name: t(BreadcrumbNameMap.GroupPermissionAdd.name),
        },
        { url: "", name: t(BreadcrumbNameMap.GroupPermissionEdit.name) },
      ],
    } as BreadcrumbProps;
  };

  const getAllPermissions = () => {
    let result = new Array<IPermissionDto>();
    result.push({ id: 1, name: "permission1", key: "key1" });
    result.push({ id: 2, name: "permission2", key: "key2" });
    result.push({ id: 3, name: "permission3", key: "key3" });
    setPermissionList(result);
  };
  if (!permisionList) return <></>;

  const permissionsSelectedDataRow: GroupPermissionEditDataRow[] =
    permisionList.map((permission: IPermissionDto) => {
      const permissionSelectedDataRow: GroupPermissionEditDataRow = {
        id: permission.id,
        name: permission.name,
        permissions: [],
      };
      return permissionSelectedDataRow;
    });

  /* Aux functions */
  const onChangeSwitchPermission = (
    id: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    // console.log(event.currentTarget.checked)
  };

  const editGroupPermission = () => {
    let result_ok = true;
    if (result_ok) navigate(BreadcrumbNameMap.GroupPermissionAdd.url);
  };

  return (
    <Page title={""} breadCrumbProps={getBreadCrumbProps()}>
      <Box style={{ padding: "0%" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              style={{ width: "100%" }}
              focused
              required
              id="outlined-required"
              label={t("Name of new group of permissions")}
            />
          </Grid>
          <Grid item xs={7}>
            <FlexLayoutGrid
              language={i18n.language}
              columns={GroupPermissionEditGridColumns(
                "",
                "",
                onChangeSwitchPermission
              )}
              rows={permissionsSelectedDataRow}
            ></FlexLayoutGrid>
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" onClick={() => editGroupPermission()}>
              {t("ButtonCreate")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export { GroupPermissionEditPage };
