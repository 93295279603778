import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import TreatmentsDataRow from "../TreatmentsDataRow";
import { sortText } from "../../../../App/sortFunctions";
import { TreatmentsGridColumns, TreatmentsGridColumnsProps } from "../TreatmentsGridColumns";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { Link } from "react-router-dom";

export interface TreatmentsDoctorNurseGridColumnsProps extends TreatmentsGridColumnsProps{
  showExternalId?: boolean | undefined;
}

export const TreatmentsDoctorNurseGridColumns = ({
  t,
  timezone,
  popoverConf,
  showExternalId
}: TreatmentsDoctorNurseGridColumnsProps): GridColumns<TreatmentsDataRow> => {
  let columns = TreatmentsGridColumns({
    t,
    timezone,
    popoverConf
  }) as GridColumns<TreatmentsDataRow>
  columns.unshift(
    {
      field: 'patient',
      headerName: t('PatientNameColumn'),
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => params.row.patient.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: TreatmentsDataRow }) => (
        <TextWordsQuantityCell 
          routerLink={Link}
          page={BreadcrumbNameMap.Patient.url.replace(":patientId", params.row.patient.id.toString())}
          item={params.row}
          words={[params.row.patient.name]}
        ></TextWordsQuantityCell>
      )
    }
  )
  showExternalId && columns.unshift({
    field: 'externalId',
    headerName: t('ExternalId'),
    width: 200,
    align: 'left',
    valueGetter: (params: GridValueGetterParams) => params.row.patient.externalId ? params.row.patient.externalId : '--',
    sortComparator: (v1: string, v2: string) => sortText(v1, v2),
    renderCell: (params: { row: TreatmentsDataRow }) => {
      return <p>{params.row.patient.externalId ? params.row.patient.externalId : '--'}</p>
    }
  });

  return columns;
};
