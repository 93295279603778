import React, { useState } from 'react';
import { Link, IconButton, List, ListItem, ListItemIcon, ListItemText, SxProps } from '@mui/material';
import { Link as NavLink } from 'react-router-dom';
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Popover from '@mui/material/Popover';
import { Theme } from '@mui/material/styles';
import { Role } from '@doctomatic/sdk/build/dto/User';
import { useApi } from '@doctomatic/sdk/build/Api';
import { useTranslation } from 'react-i18next';
import { FlexLayoutGrid } from '@doctomatic/components-react/build/DataGrid/DataGrid';
import { PatientListGridColumns } from './PatientListDataGridColumns';
import { DoctomaticStylingRowsGrid } from '../../../Theme/ThemeDataGrid';
import { GridRowClassNameParams } from '@mui/x-data-grid';
import { ItemPatientListExtendedResponseDto } from '@doctomatic/sdk/build/dto/Patient/Patient';
import PatientDataRow from './PatientDataRow';

interface Props {
  patients: ItemPatientListExtendedResponseDto[];
  showDeleted: boolean;
  onDelete: (patient: ItemPatientListExtendedResponseDto) => void;
  onRestore: (patient: ItemPatientListExtendedResponseDto) => void;
  onEdit: (id: number) => void;
  onSendSms: (doctor: ItemPatientListExtendedResponseDto) => void;
  onSendEmail: React.Dispatch<React.SetStateAction< { id: number; name: string; email?: string } | null >>;
}

export const PatientList = ({
  patients, onDelete, onRestore, onEdit, onSendSms, onSendEmail
}: Props): React.ReactElement => {
  const { role, useCompany, companyId } = useApi();
  const { response: companyResponse } = useCompany(companyId);
  const company = companyResponse?.data;
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = useState<string>('');
  const iamAdmin = role === Role.admin;
  const iamDoctor = role === Role.doctor;
  const iamPatient = role === Role.user;
  const iamTeam = iamPatient || iamAdmin || iamDoctor;

  const actions = (patient: ItemPatientListExtendedResponseDto) => {
    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget)
      setPopoverId(`m${patient.id}`)
    }

    const onClose = () => {
      setAnchorEl(null)
      setPopoverId('')
    }

    return (
      <>
        <IconButton onClick={onClick}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={`m${patient.id}`}
          open={popoverId === `m${patient.id}`}
          anchorEl={anchorEl}
          onClose={onClose}
        >
          <List>
            { iamTeam && !patient.deletedAt && 
              <ListItem button>
                <Link
                  style={{ textDecoration: 'none' }}
                  component={NavLink}
                  to={
                    BreadcrumbNameMap.LocationsUser.url
                      .replace(":role", 'patients')
                      .replace(":actorId", patient.id.toString())
                  }
                >
                  {t('PatientListLocations')}
                </Link>
              </ListItem>
            }
            { iamTeam && !patient.deletedAt && 
              <ListItem 
                button
                onClick={() => {
                onClose();
                onEdit(patient.id);
              }}
              >
                <ListItemIcon><EditOutlinedIcon /></ListItemIcon>
                <ListItemText primary={`${t('ButtonEdit')} ${t('FieldPatient')}`} />
              </ListItem>
            }
            { iamTeam && !patient.deletedAt && 
              <ListItem disabled={!patient.email} button onClick={() => { onClose(); onSendSms(patient); }}>
                <ListItemIcon><TextsmsOutlinedIcon /></ListItemIcon>
                <ListItemText primary={t('UserListSendSMS')} />
              </ListItem>
            }
            { iamTeam && !patient.deletedAt && 
              <ListItem disabled={!patient.email} button onClick={() => { onClose(); onSendEmail( {id: patient.id, name: patient.name, email: patient.email}); }}>
                <ListItemIcon><MailOutlineIcon /></ListItemIcon>
                <ListItemText primary={t('UserListSendEmail')} />
              </ListItem>
            }
            {
              !patient.deletedAt && (
                <>
                  <ListItem button onClick={() => { onClose(); onDelete(patient); }}>
                    <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
                    <ListItemText primary={`${t('ButtonDelete')} ${t('FieldPatient')}`} />
                  </ListItem>
                </>
              )
            }
            {
              iamTeam && patient.deletedAt && (
                <ListItem button onClick={() => { onClose(); onRestore(patient); }}>
                  <ListItemIcon><RestoreFromTrashOutlinedIcon /></ListItemIcon>
                  <ListItemText primary={`${t('ButtonRestore')} ${t('FieldPatient')}`} />
                </ListItem>
              )
            }
          </List>
        </Popover>
      </>
    );
  }

  if (!patients) return (<></>);

  const datagridColumns = PatientListGridColumns(company?.showExternalId, t, actions);

  const setRowClassName = (
    params: GridRowClassNameParams<PatientDataRow>
  ): string => (
    params.row.deletedAt ? 'super-app-theme--Rejected' : ''
  );

  const patientsDataRow: PatientDataRow[] = patients?.map((patient: ItemPatientListExtendedResponseDto) => {
    const patientDataRow: PatientDataRow = {
      id: patient.id,
      name: patient.name,
      email: patient.email,
      locations: patient.location_user.map((location_user: { location: { name: string; }; }) => { return location_user.location.name }),
      deletedAt: patient.deletedAt,
      externalId: patient.externalId,
      phone: patient.phone
    }
    return patientDataRow;
  }) || [];

  return (
    <>
      <FlexLayoutGrid
        language={i18n.language}
        columns={datagridColumns}
        rows={patientsDataRow}
        setRowClassName={setRowClassName}
        sx={DoctomaticStylingRowsGrid() as SxProps<Theme>}
      />
    </>
  );
}
