import React, { useEffect, useState } from "react";
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
export var ModeActivefilter;
(function (ModeActivefilter) {
    ModeActivefilter["active"] = "active";
    ModeActivefilter["noActive"] = "noActive";
    ModeActivefilter["all"] = "all";
})(ModeActivefilter || (ModeActivefilter = {}));
var ActiveAlertFilter = function (_a) {
    var mode = _a.mode, setMode = _a.setMode, t = _a.t;
    var _b = useState(false), isMobile = _b[0], setIsMobile = _b[1];
    var handleChange = function (event) {
        // filter by no active, active or all
        setMode(event.target.value);
    };
    useEffect(function () {
        setIsMobile(window.innerWidth <= 768);
    }, [window.innerWidth]);
    return (React.createElement(Box, { display: 'flex', justifyContent: isMobile ? 'center' : 'end' },
        React.createElement(RadioGroup, { row: true, name: "filter", value: mode, onChange: handleChange },
            React.createElement(FormControlLabel, { value: ModeActivefilter.all, control: React.createElement(Radio, null), label: React.createElement(Box, { sx: { textAlign: 'center' } }, t('allMode')), labelPlacement: "top" }),
            React.createElement(FormControlLabel, { value: ModeActivefilter.active, control: React.createElement(Radio, null), label: React.createElement(Box, { sx: { textAlign: 'center' } }, t('activeMode')), labelPlacement: "top" }),
            React.createElement(FormControlLabel, { value: ModeActivefilter.noActive, control: React.createElement(Radio, null), label: React.createElement(Box, { sx: { textAlign: 'center' } }, t('noActiveMode')), labelPlacement: "top" }))));
};
export { ActiveAlertFilter };
