import React, { ReactElement, useEffect } from 'react'

import { createTheme, adaptV4Theme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/700.css'

let colors = {
  black: '#3e4247',
  white: '#FFFFFF',
  primary: '#4bae51',
  secondary: '#8d36a4',
  grey: '#B5B5B5',
  error: '#FF526D',
  info: '#00A9FF'
}

interface Props {
  companyColors?: object,
  companyFont?: string | undefined
  children: React.ReactNode,
}

const Theme : React.FC<Props> = ({ companyColors, children, companyFont }: Props): ReactElement => {

  let font = companyFont ? companyFont : 'Montserrat, sans-serif' 

  useEffect(() => {
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${font}`;
    link.rel = 'stylesheet';

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [font]);

  if (companyColors) colors = { ...colors, ...companyColors }

  const theme = createTheme(adaptV4Theme({
    typography: {
      fontFamily: font,
      h1: { fontSize: '20px', lineHeight: '24px', fontWeight: 500 }
    },
    palette: {
      common: { black: colors.black, white: colors.white },
      text: { primary: colors.black },
      primary: { main: colors.primary },
      secondary: { main: colors.secondary },
      error: { main: colors.error },
      info: { main: colors.info }
    },
    props: {
      MuiButton: { color: 'secondary' },
      MuiAppBar: { color: 'primary' }
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: { color: colors.white }
      },
      MuiButton: {
        root: {
          transition: 'color .01s',
        },
        textPrimary: { color: colors.secondary },
      },
      MuiInputBase: {
        input: {
          backgroundColor: colors.white,
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset"
          }
        }
      },
      MuiCircularProgress: {
        colorPrimary: { color: colors.white }
      },
      
    }
  }))

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export { Theme }
