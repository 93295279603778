var ColumnFilter = /** @class */ (function () {
    function ColumnFilter(column, typeFilter, typeQuery, values, label, helpText) {
        this.column = column;
        this.typeFilter = typeFilter;
        this.typeQuery = typeQuery;
        this.values = values;
        this.label = label;
        this.helpText = helpText;
    }
    return ColumnFilter;
}());
export { ColumnFilter };
var ColumnValue = /** @class */ (function () {
    function ColumnValue(newValue, label) {
        this.value = newValue;
        this.label = label;
    }
    return ColumnValue;
}());
export { ColumnValue };
var SearchBoxProp = /** @class */ (function () {
    function SearchBoxProp(labelTranslationKey, placeholderTranslationKey) {
        this.labelTranslationKey = labelTranslationKey;
        this.placeholderTranslationKey = placeholderTranslationKey;
    }
    return SearchBoxProp;
}());
export { SearchBoxProp };
var Filter = /** @class */ (function () {
    function Filter(column, typeQuery, values) {
        this.column = column;
        this.typeQuery = typeQuery;
        this.values = values;
    }
    return Filter;
}());
export { Filter };
