import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import {
  Select,
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
} from "@mui/material";
import { CreateWebhookRequestDto } from "@doctomatic/sdk/build/dto/Webhooks";
import { validate, ValidationError } from "class-validator";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router-dom";

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (webhook: CreateWebhookRequestDto) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  visible: { overflowY: "visible" },
}));

const WebhookCreate = ({
  open,
  onClose,
  onSave,
}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { useProfile, logout } = useApi();
  const { t } = useTranslation();
  const { response: profile } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const [endpoint, setEndpoint] = useState("");
  const actions = ["CREATE", "EDIT", "DELETE"];
  const [action, setAction] = useState(actions[0]);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const classes = useStyles();

  useEffect(() => {
    setEndpoint("");
  }, [open, profile]);

  useEffect(() => {
    const checkErrors = async () => {
      const dto = new CreateWebhookRequestDto();
      dto.endpoint = endpoint;

      let errors = await validate(dto);
      setErrors(errors);
    };

    checkErrors();
  }, [open, endpoint]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.visible }}
    >
      <DialogContent className={classes.visible}>
        <TextField
          margin="dense"
          variant="outlined"
          name="endpoint"
          label={t("FieldEndpoint")}
          type="text"
          fullWidth
          onChange={(e) => setEndpoint(e.target.value)}
          value={endpoint}
          autoFocus
          required
        />
        <Box
          style={{
            display: "flex",
            marginTop: 12,
            justifyContent: "space-between",
          }}
        >
          <Select
            labelId="device"
            id="device-select"
            label={t("FieldDevice")}
            onChange={(event) => setAction(event.target.value as string)}
            value={action}
          >
            {actions.map((action: string, index) => (
              <MenuItem key={`d${index}`} value={action}>
                {t(action)}
              </MenuItem>
            ))}
          </Select>
          <Select
            labelId="device"
            id="device-select"
            label={t("FieldDevice")}
            onChange={(event) => setAction(event.target.value as string)}
            value={action}
          >
            {actions.map((action: string, index) => (
              <MenuItem key={`d${index}`} value={action}>
                {t(action)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={onClose} size="small">
              {t("ButtonCancel")}
            </Button>
          </Box>
          <Button
            disabled={errors.length > 0}
            onClick={() => {
              const dto = new CreateWebhookRequestDto();
              dto.endpoint = endpoint;
              onSave(dto);
            }}
            variant="contained"
            size="small"
          >
            {t("ButtonSave")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { WebhookCreate };
