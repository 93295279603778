import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { BreadcrumbNameMap } from "../../../breadcrumbMap";
import { LocationsList } from "./LocationsList";
import { Page } from "../Page";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { IUseProfile } from "@doctomatic/sdk/build/modules/Profile";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router-dom";

const LocationPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { useProfile, logout }: IProvideApi = useApi();
  const { response: profile }: IUseProfile = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );

  const getBreadCrumbProps = (actorName: string): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        { url: "", name: actorName },
        { url: "", name: t(BreadcrumbNameMap.Locations.name) },
        { url: "", name: t("List") },
      ],
    } as BreadcrumbProps;
  };

  const actorName = profile?.data?.name ? profile!.data!.name : "";

  return (
    <Page title={""} breadCrumbProps={getBreadCrumbProps(actorName)}>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        {profile?.data ? (
          <LocationsList actorId={profile.data.id} role={profile.data.role!} />
        ) : (
          <></>
        )}
      </Box>
    </Page>
  );
};

export { LocationPage };
