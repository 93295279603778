import { Box, TextField, InputAdornment, IconButton, Checkbox, Divider, FormControlLabel, FormLabel, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { ColumnValue } from "./column.filter";
import { useTranslation } from "react-i18next";
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import HelpIcon from '@mui/icons-material/Help';
import { useEffect } from "react";
import { porcessDatePickerChange, processCheckboxChange } from "./utils";
import { format } from "date-fns/esm";
import { TypeFilter } from "./advanced-search.enum";
import DatePicker from 'react-datepicker';
import i18next from 'i18next';
import { customDatePicker } from "./date-picker.utils";
export var AdvancedSearch = function (_a) {
    var searchBoxProp = _a.searchBoxProp, filterElements = _a.filterElements, handleNewSimpleSearch = _a.handleNewSimpleSearch, handleNewFilterSearch = _a.handleNewFilterSearch;
    // Declarations
    var t = useTranslation().t;
    var RangePicker = DatePicker.RangePicker;
    /////////////////////////////////////////////////////////
    //////////     Declaration of state hooks    ////////////
    /////////////////////////////////////////////////////////
    var _b = useState(''), search = _b[0], setSearch = _b[1];
    var _c = useState(false), activeAdvancedSearch = _c[0], setActiveAdvancedSearch = _c[1];
    var _d = useState([]), filters = _d[0], setFilters = _d[1];
    /////////////////////////////////////////////////////////
    /////////     Declaration of effects hooks    ///////////
    /////////////////////////////////////////////////////////
    // This effect will push the new search string everytime it changes
    useEffect(function () {
        handleNewSimpleSearch(search);
    }, [search]);
    useEffect(function () {
        if (filters) {
            handleNewFilterSearch(filters);
        }
    }, [filters]);
    /////////////////////////////////////////////////////////
    ////////////     Declaration of handlers    /////////////
    /////////////////////////////////////////////////////////
    var handleAlertsClearSearch = function () {
        setSearch('');
        handleNewSimpleSearch('');
    };
    var toggleAdvancedSearch = function () {
        // Toggle advanced search visibility
        setActiveAdvancedSearch(!activeAdvancedSearch);
    };
    var handleNewSearch = function (event) {
        var searchValue = event.target.value;
        setSearch(searchValue);
    };
    // Handle selected state
    var handleCheckboxChange = function (event) {
        var column = event.target.id;
        var value = event.target.name;
        var selected = event.target.checked;
        var payload = { activeAdvancedSearch: activeAdvancedSearch, column: column, newValue: value, selected: selected, filters: filters };
        var newFilters = processCheckboxChange(payload);
        setFilters(newFilters);
    };
    var handleDatePickerChange = function (newValue, columnName) {
        var payload = { activeAdvancedSearch: activeAdvancedSearch, column: columnName, newValue: newValue, filters: filters };
        if (newValue) {
            var formatDate = format(newValue, 'yyyy-MM-dd');
            payload.newValue = formatDate;
        }
        var newFilters = porcessDatePickerChange(payload);
        setFilters(newFilters);
    };
    /////////////////////////////////////////////////////////
    /////     Declaration of HTML template mappers    ///////
    /////////////////////////////////////////////////////////
    var helpTooltipTemplate = function (helpText) {
        return (React.createElement(Tooltip, { title: helpText },
            React.createElement(HelpIcon, null)));
    };
    var labelTemplate = function (columnFilter) {
        return (React.createElement(FormLabel, { sx: { marginTop: '0px', display: 'flex', alignItems: 'center' }, component: "legend" },
            columnFilter.label,
            " ",
            columnFilter.helpText ? helpTooltipTemplate(columnFilter.helpText) : React.createElement(React.Fragment, null)));
    };
    var checkboxFilterTemplateMapper = function (columnFilter, index) {
        return (React.createElement("div", { key: columnFilter.label },
            labelTemplate(columnFilter),
            columnFilter.values.map(function (columnValue) {
                return React.createElement(FormControlLabel, { key: index, control: React.createElement(Checkbox, { id: columnFilter.column.toString(), onChange: handleCheckboxChange, name: columnValue.value ? columnValue.value : 'Missing key' }), label: columnValue.label });
            }),
            React.createElement(Divider, { light: true })));
    };
    var dateFilterTemplateMapper = function (columnFilter, index) {
        var dateValue = new ColumnValue(null, null);
        columnFilter.values.push(dateValue);
        var _a = useState(null), selectedValue = _a[0], setSelectedValue = _a[1];
        return (React.createElement("div", { key: index },
            labelTemplate(columnFilter),
            React.createElement(Box, { sx: customDatePicker },
                React.createElement(RangePicker, { selected: selectedValue, locale: i18next.language, onChange: function (date) { handleDatePickerChange(date, columnFilter.column); setSelectedValue(date); }, showMonthDropdown: true, showYearDropdown: true, dropdownMode: "select", isClearable: true, maxDate: new Date() })),
            React.createElement("br", null),
            React.createElement(Divider, { light: true })));
    };
    /////////////////////////////////////////////////////////
    /////////     Declaration of HTML elements    ///////////
    /////////////////////////////////////////////////////////
    var searchBox = React.createElement(Box, { sx: { display: 'flex', alignItems: 'center', width: '100%' } },
        React.createElement(TextField, { margin: 'dense', variant: 'outlined', fullWidth: true, label: t(searchBoxProp.labelTranslationKey), placeholder: t(searchBoxProp.placeholderTranslationKey), style: { width: '100%' }, type: 'text', InputProps: {
                startAdornment: (filterElements && React.createElement(InputAdornment, { position: "start" },
                    React.createElement(IconButton, { edge: 'end', onClick: toggleAdvancedSearch },
                        React.createElement(FilterAltIcon, null)))),
                endAdornment: React.createElement(InputAdornment, { position: "end" },
                    React.createElement(IconButton, { edge: "end", onClick: handleAlertsClearSearch },
                        React.createElement(ClearIcon, null)))
            }, value: search, onChange: handleNewSearch }));
    var advancedSearch = React.createElement(React.Fragment, null, filterElements ? React.createElement("div", null,
        React.createElement(Box, null, filterElements.map(function (columnFilter, index) {
            // Since there are only two types of columns
            // if-else statement will do the work
            // but when +3 types, please add a switch case
            // to increase both readability and performance
            // See more: https://dev.to/sumusiriwardana/if-else-or-switch-case-which-one-to-pick-4p3h
            if (TypeFilter.CHECKBOX === columnFilter.typeFilter) {
                return checkboxFilterTemplateMapper(columnFilter, index);
            }
            else if (TypeFilter.DATE === columnFilter.typeFilter) {
                return dateFilterTemplateMapper(columnFilter, index);
            }
            else {
                return React.createElement(React.Fragment, { key: index });
            }
        })),
        React.createElement("br", null)) : React.createElement(React.Fragment, null));
    return React.createElement(React.Fragment, null,
        searchBox,
        !activeAdvancedSearch ? React.createElement(React.Fragment, null) : advancedSearch);
};
