import { GridColumns, GridValueGetterParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import LastAlertsByNoMeasureByPatientDataRow from './LastAlertsByNoMeasureByPatientDataRow';
import { AlertBox } from '@doctomatic/components-react/build/Alerts/AlertBox';
import { TextWordsQuantityCell } from '@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell';
import { Link } from 'react-router-dom';
import { GetAlertUserAckDto } from '@doctomatic/sdk/build/dto/Alerts/IAlerts';
import { ElementGridStringEdit } from '@doctomatic/components-react/build/ElementColumn/ElementGridStringEdit';
import { sortText } from '../../../../../App/sortFunctions';
import { toLocaleString } from '../../../utils';

export const LastAlertsByNoMeasureByPatientGridColumns = (
  timezone: string,
  showModal?: (item: any) => void,
  showStateModal?: (id: number, item: any) => void,
  setAlertComments?: (id: number, comments: string | undefined) => Promise<boolean>
): GridColumns<LastAlertsByNoMeasureByPatientDataRow> => {
  const { t } = useTranslation();
  const gridColumns: any[] = [];
  gridColumns.push(...[
    {
      field: 'color',
      headerName: t('Level'),
      width: 50,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => params.row.color,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: LastAlertsByNoMeasureByPatientDataRow }) => <AlertBox
        id={params.row.id}
        color={params.row.color}
      ></AlertBox>
    },
    {
      field: 'state',
      headerName: t('AlertState'),
      width: 75,
      align: 'center',
      valueGetter: (params: GridValueGetterParams) => params.row.state,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: LastAlertsByNoMeasureByPatientDataRow }) => {
        const alertStateCapitalized = params.row.state[0].toUpperCase() + params.row.state.substr(1).toLowerCase();
        return (<TextWordsQuantityCell onClickModal={() => showStateModal!(params.row.id, params.row.state)}
          item={params.row}
          words={[t(alertStateCapitalized)]}
        ></TextWordsQuantityCell>);
      },
    },
    {
      field: 'comments',
      headerName: t('Comments'),
      width: 95,
      align: 'center',
      valueGetter: (params: GridValueGetterParams) => params.row.comments,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: LastAlertsByNoMeasureByPatientDataRow }) => {
        return <ElementGridStringEdit
          textCancel={t('Cancel')}
          textSave={t('Save')}
          textLabelField={t("Comments")}
          itemToAddCommentId={params.row.id}
          text={params.row.comments}
          onSave={setAlertComments
            ? setAlertComments
            : async (alertId: number, comment: string | undefined): Promise<boolean> => { return true }}
          onClose={() => { }}
        />
      },
    },
    {
      field: 'label',
      headerName: t('AlertName'),
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => params.row.label,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: LastAlertsByNoMeasureByPatientDataRow }) => <p>{params.row.label}</p>
    },
    {
      field: 'configAlert',
      headerName: t('ConfigAlert'),
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        const configs = [];
        params.row.email && configs.push('email');
        params.row.sms && configs.push('SMS');
        return configs.join(',');
      },
      sortable: false,
      renderCell: (params: { row: LastAlertsByNoMeasureByPatientDataRow }) => {
        const configs: string[] = [];
        params.row.email && configs.push('email');
        params.row.sms && configs.push('SMS');
        return configs.join(',');
      }
    },
    {
      field: 'createdAt',
      headerName: t('CreatedAt'),
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => params.row.created_at,
      sortComparator: (v1: any, v2: any) => new Date(v2!).getTime() - new Date(v1!).getTime(),
      renderCell: (params: { row: LastAlertsByNoMeasureByPatientDataRow }) => (<p>{toLocaleString(new Date(params.row.created_at))}</p>)
    },
    {
      field: 'ackBy',
      headerName: t('HeaderAckBy'),
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        const texts =
          params.row.ackBy && params.row.ackBy.map((ack: GetAlertUserAckDto) => ack.user.name);
        if (texts.length === 0) texts.push('---');
        return texts.join(', ');
      },
      sortable: false,
      renderCell: (params: { row: LastAlertsByNoMeasureByPatientDataRow }) => {
        const texts =
          params.row.ackBy && params.row.ackBy.map((ack: GetAlertUserAckDto) => ack.user.name);
        if (texts.length === 0) texts.push('---');
        return (
          <TextWordsQuantityCell routerLink={Link}
            item={params.row}
            words={texts}
            onClickModal={() => showModal!(params.row)}
          ></TextWordsQuantityCell>
        );
      },
    },
  ]);
  return gridColumns;
};
