import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { validate, ValidationError } from "class-validator";
import { useApi } from "@doctomatic/sdk/build/Api";
import { Phone } from "../../Phone";
import { useTranslation } from "react-i18next";
import { SelectModal } from "@doctomatic/components-react/build/SelectModal/SelectModal";
import {
  SelectGridColumns,
  SelectGridColumnsProps,
} from "@doctomatic/components-react/build/SelectModal/SelectGridColumns";
import SelectConsentDoctorDataRow from "../../Modals/SelectConsentDoctorModal/SelectConsentDoctorDataRow";
import { ItemList } from "@doctomatic/sdk/build/dto/IItemList";
import { AddPatientRequest } from "./patient.class";
import { processError } from "../../../App/errorToast";
import { AddPatientRequestDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { GetDoctorResponseDto } from "@doctomatic/sdk/build/dto/Actors/Doctor/Doctor";
import SelectLocationDataRow from "../../Modals/SelectLocationModal/SelectLocationDataRow";
import { ItemLocation } from "@doctomatic/sdk/build/dto/Location/Location";
import { LanguageSelector } from "@doctomatic/components-react/build/LanguageSelector/LanguageSelector";
import { PatientOnboardingOptions } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { useNavigate } from "react-router-dom";

interface ICreateForm {
  name: string;
  email: string;
  language: string;
  phone: string;
  externalId?: string;
  birthYear?: number;
  activatePatient?: boolean;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (user: AddPatientRequestDto) => void;
  needsSubscription: boolean;
  setNeedsSubscription: React.Dispatch<React.SetStateAction<boolean>>;
  completeOnboarding: boolean;
  setCompleteOnboarding: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  visible: { overflowY: "visible" },
}));

const PatientCreate = ({
  open,
  onClose,
  onSave,
  needsSubscription,
  setNeedsSubscription,
  completeOnboarding,
  setCompleteOnboarding,
}: Props): React.ReactElement => {
  const getRole = (role: Role): "doctors" | "nurses" => {
    if (role === Role.doctor) return "doctors";
    if (role === Role.nurse) return "nurses";
    return "doctors";
  };

  const navigate = useNavigate();
  const {
    useProfile,
    useConsentDoctors,
    useCompany,
    useCompanySubscriptions,
    useActorLocations,
    logout,
    companyId,
    role,
    id,
  } = useApi();
  const { t } = useTranslation();
  const { response: profileResponse } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const profile = profileResponse?.data;
  const { response: companySubscriptionsResponse } = useCompanySubscriptions(
    companyId,
    true,
    processError(logout, navigate, t)
  );
  const { response: companyResponse } = useCompany(
    companyId,
    true,
    processError(logout, navigate, t)
  );
  const { response: consentDoctorResponse } = useConsentDoctors(
    true,
    processError(logout, navigate, t)
  );

  const { response: locationsResponse } = useActorLocations(
    id,
    getRole(role),
    true,
    processError(logout, navigate, t)
  );
  const consentDoctors = consentDoctorResponse?.data;
  const companySubscriptions = companySubscriptionsResponse?.data;
  const company = companyResponse?.data;
  const actorLocations = locationsResponse?.data;
  const companyHasSubscription = companySubscriptions?.companyHasSubscription;
  const subscribedPatients = companySubscriptions?.subscribedPatients;
  const maxPatientsWithoutSubscription =
    companySubscriptions?.maxPatientsWithoutSubscription;
  const currentYear = new Date().getFullYear();
  const [createForm, setCreateForm] = useState<ICreateForm>({
    name: "",
    email: "",
    phone: "",
    language: profile?.language || "en",
    externalId: undefined,
    birthYear: undefined,
    activatePatient: undefined,
  });
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [consentDoctorSelected, setConsentDoctorSelected] =
    useState<ItemList | null>(null);
  const [openSelectConsentDoctorModal, setOpenSelectConsentDoctorModal] =
    useState(false);

  const [locationSelected, setLocationSelected] = useState<ItemLocation | null>(
    null
  );
  const [openSelectLocationModal, setOpenSelectLocationModal] = useState(false);
  const [timezone, setTimezone] = useState("");

  const classes = useStyles();

  useEffect(() => {
    if (consentDoctors && consentDoctors.length >= 1) {
      setConsentDoctorSelected(consentDoctors[0]);
    }
  }, [consentDoctors]);

  useEffect(() => {
    if (actorLocations && actorLocations.length >= 1) {
      if (profile && profile.role === Role.doctor) {
        let doctorProfile = profile as GetDoctorResponseDto;
        if (doctorProfile.ownLocation) {
          setLocationSelected(
            doctorProfile.ownLocation as unknown as ItemLocation
          );
        } else {
          setLocationSelected(actorLocations[0]);
        }
      } else {
        setLocationSelected(actorLocations[0]);
      }
    }
  }, [actorLocations]);

  useEffect(() => {
    const getTimezone = () => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setTimezone(timezone);
    };

    getTimezone();
  }, []);

  const handleClose = (setOpen: Dispatch<SetStateAction<boolean>>) =>
    setOpen(false);

  /* Modal selection of consent doctor */
  const selectedRow = (dataRow: SelectConsentDoctorDataRow) => {
    setConsentDoctorSelected(dataRow);
  };

  const selectConsentDoctorGridColumnsByLibraryProps: SelectGridColumnsProps<SelectConsentDoctorDataRow> =
    {
      columns: [
        {
          field: "name",
          headerName: t("Name"),
          width: 250,
          align: "left",
        },
      ],
      selectEvent: selectedRow,
      handleClose: () => handleClose(setOpenSelectConsentDoctorModal),
      textButton: t("Select"),
    };

  const getSelectConsentDoctorModal = (items: ItemList[] | undefined) => {
    if (!items) return <></>;

    return (
      <SelectModal<SelectConsentDoctorDataRow>
        breadcrumbItems={[
          { name: t("Doctors"), url: "" },
          { name: t("Selection"), url: "" },
        ]}
        permissions={[]}
        open={openSelectConsentDoctorModal}
        handleClose={() => handleClose(setOpenSelectConsentDoctorModal)}
        item={undefined}
        columns={SelectGridColumns<SelectConsentDoctorDataRow>(
          selectConsentDoctorGridColumnsByLibraryProps
        )}
        rows={items}
      />
    );
  };
  /* End Modal selection of consent doctor */

  /* Modal selection of location */
  const selectedLocationRow = (dataRow: SelectLocationDataRow) => {
    setLocationSelected(dataRow);
  };

  const selectLocationGridColumnsByLibraryProps: SelectGridColumnsProps<SelectLocationDataRow> =
    {
      columns: [
        {
          field: "name",
          headerName: t("Name"),
          width: 250,
          align: "left",
        },
      ],
      selectEvent: selectedLocationRow,
      handleClose: () => handleClose(setOpenSelectLocationModal),
      textButton: t("Select"),
    };

  const getSelectLocationModal = (items: ItemLocation[] | undefined) => {
    if (!items) return <></>;

    return (
      <SelectModal<SelectLocationDataRow>
        breadcrumbItems={[
          { name: t("Locations"), url: "" },
          { name: t("Selection"), url: "" },
        ]}
        permissions={[]}
        open={openSelectLocationModal}
        handleClose={() => handleClose(setOpenSelectLocationModal)}
        item={undefined}
        columns={SelectGridColumns<SelectLocationDataRow>(
          selectLocationGridColumnsByLibraryProps
        )}
        rows={items}
      />
    );
  };
  /* End Modal selection of location */

  const checkErrors = async (dto: AddPatientRequest): Promise<boolean> => {
    let errors: ValidationError[] = await validate(dto);
    if (consentDoctors && consentDoctors.length >= 1 && !dto.consentDoctorId) {
      let validationError: ValidationError = new ValidationError();
      validationError.property = "consentDoctor";
      errors.push(validationError);
    }
    if (actorLocations && actorLocations.length >= 1 && !dto.locationId) {
      let validationError: ValidationError = new ValidationError();
      validationError.property = "location";
      errors.push(validationError);
    }
    if (dto.birthYear && dto.birthYear > currentYear) {
      let validationError: ValidationError = new ValidationError();
      validationError.property = "birthYear";
      errors.push(validationError);
    }
    setErrors(errors);
    return errors.length === 0;
  };

  const getError = (name: string): ValidationError | undefined => {
    return errors.find((v: ValidationError) => v.property === name);
  };

  const getHelperText = (name: string, message: string): string => {
    return getError(name) ? message : "";
  };

  const handleSelectLanguage = (e: SelectChangeEvent<string>) => {
    setCreateForm({ ...createForm, language: e.target.value });
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.visible }}
    >
      <DialogTitle>{`${t("ButtonCreate")} ${t("FieldPatient")}`}</DialogTitle>

      <DialogContent className={classes.visible}>
        <TextField
          margin="dense"
          variant="outlined"
          name="name"
          label={t("FieldName")}
          type="text"
          fullWidth
          onChange={(e) =>
            setCreateForm({ ...createForm, name: e.target.value })
          }
          value={createForm.name}
          autoFocus
          required
          helperText={getHelperText(
            "name",
            t("ErrorRequiredField", { field_name: t("FieldName") })
          )}
          error={!!getError("name")}
        />
        <TextField
          margin="dense"
          variant="outlined"
          name="email"
          label={t("FieldEmail")}
          type="email"
          fullWidth
          onChange={(e) =>
            setCreateForm({ ...createForm, email: e.target.value })
          }
          value={createForm.email}
          required
          helperText={getHelperText(
            "email",
            t("ErrorRequiredField", { field_name: t("FieldEmail") })
          )}
          error={!!getError("email")}
        />

        <LanguageSelector
          value={createForm.language}
          onChange={handleSelectLanguage}
          style={{ marginTop: "8px", marginBottom: "10px" }}
          id="language"
          fullWidth
          t={t}
        />

        <Phone
          onUpdate={(value) => setCreateForm({ ...createForm, phone: value })}
          value={createForm.phone}
          helperText={getHelperText(
            "phone",
            t("ErrorRequiredField", { field_name: t("FieldPhone") })
          )}
          error={!!getError("phone")}
        />
        <TextField
          margin="dense"
          variant="outlined"
          name="birthYear"
          InputProps={{ inputProps: { min: 1900, max: currentYear } }}
          label={t("FieldBirthYear")}
          type="number"
          fullWidth
          onChange={(e) =>
            setCreateForm({ ...createForm, birthYear: Number(e.target.value) })
          }
          value={createForm.birthYear}
          helperText={getHelperText(
            "birthYear",
            t("ErrorBirthYearField", {
              fieldName: t("FieldBirthYear"),
              currentYear,
            })
          )}
          error={!!getError("birthYear")}
        />
        {consentDoctors && consentDoctors.length >= 1 && (
          <TextField
            inputProps={{ readOnly: true }}
            margin="dense"
            variant="outlined"
            name="consentDoctor"
            fullWidth
            placeholder={t("FieldConsentDoctor")}
            label={t("FieldConsentDoctor")}
            onClick={() => setOpenSelectConsentDoctorModal(true)}
            type="text"
            value={consentDoctorSelected?.name}
            required
            helperText={getHelperText(
              "consentDoctor",
              t("ErrorRequiredField", { field_name: t("FieldConsentDoctor") })
            )}
            error={!!getError("consentDoctor")}
          />
        )}
        {actorLocations && actorLocations.length >= 1 && (
          <TextField
            inputProps={{ readOnly: true }}
            margin="dense"
            variant="outlined"
            name="location"
            fullWidth
            placeholder={t("FieldLocation")}
            label={t("FieldLocation")}
            onClick={() => setOpenSelectLocationModal(true)}
            type="text"
            value={locationSelected?.name}
            required
            helperText={getHelperText(
              "location",
              t("ErrorRequiredField", { field_name: t("FieldLocation") })
            )}
            error={!!getError("location")}
          />
        )}
        <TextField
          margin="dense"
          variant="outlined"
          name="externalId"
          label={t("FieldExternalId")}
          type="text"
          fullWidth
          onChange={(e) =>
            setCreateForm({ ...createForm, externalId: e.target.value })
          }
          value={createForm.externalId}
        />
        {companyHasSubscription && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  name={t("FieldNeedsSubscription")}
                  checked={needsSubscription}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => setNeedsSubscription(checked)}
                  // Disable needs subscription checkbox if max patients
                  // without subscription have been created
                  disabled={
                    subscribedPatients && maxPatientsWithoutSubscription
                      ? subscribedPatients >= maxPatientsWithoutSubscription
                      : false
                  }
                />
              }
              label={t("FieldNeedsSubscription")}
            />
            <Typography>
              {subscribedPatients}/{maxPatientsWithoutSubscription}{" "}
              {t("PatientsWithoutSubscription")}
            </Typography>{" "}
          </>
        )}
        {Boolean(company?.onBoardingReq && company?.onBoardingReq > 2) && (
          <FormControlLabel
            control={
              <Checkbox
                name={t("FieldCompleteOnboarding")}
                checked={completeOnboarding}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => setCompleteOnboarding(checked)}
                // Disable needs subscription checkbox if max patients
                // without subscription have been created
                disabled={
                  subscribedPatients && maxPatientsWithoutSubscription
                    ? subscribedPatients >= maxPatientsWithoutSubscription
                    : false
                }
              />
            }
            label={t("FieldCompleteOnboarding")}
          />
        )}
        {getSelectConsentDoctorModal(consentDoctors)}
        {getSelectLocationModal(actorLocations)}
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={onClose} size="small">
              {t("ButtonCancel")}
            </Button>
          </Box>
          <Button
            onClick={async () => {
              const dto = new AddPatientRequest();
              dto.email = createForm.email;
              dto.name = createForm.name;
              dto.phone = createForm.phone;
              dto.birthYear = createForm.birthYear;
              dto.externalId = createForm.externalId;
              dto.language = createForm.language;
              dto.timezone = timezone;
              dto.consentDoctorId = consentDoctorSelected
                ? consentDoctorSelected.id
                : undefined;
              dto.locationId = locationSelected
                ? locationSelected.id
                : undefined;
              dto.subscription = needsSubscription;
              dto.activatePatient = createForm.activatePatient;
              dto.onBoardingReq = completeOnboarding
                ? PatientOnboardingOptions.CompleteOnboarding
                : PatientOnboardingOptions.SimpleOnboarding;
              const valid: boolean = await checkErrors(dto);
              if (valid) onSave(dto);
            }}
            variant="contained"
            size="small"
          >
            {t("ButtonSave")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { PatientCreate };
