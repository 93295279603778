import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { PersonOutline } from '@mui/icons-material';
var styles = {
    subscribersModalContainerStyles: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        outline: 'none',
        padding: 4,
        paddingTop: 1,
        paddingBottom: 1
    },
    subscriberContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginRight: 5
    }
};
export default function AlertSubscribersModal(props) {
    return (React.createElement("div", null,
        React.createElement(Modal, { open: props.open, onClose: props.handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
            React.createElement(Box, { sx: styles.subscribersModalContainerStyles },
                React.createElement(Box, { sx: {
                        display: 'flex',
                        justifyContent: 'center',
                        borderBottom: 1,
                        marginBottom: 1
                    } },
                    React.createElement("h2", { style: {
                            marginLeft: 16,
                            marginRight: 16,
                            marginBottom: 0
                        } }, "Alerta atendida")),
                React.createElement(Box, { sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    } }, props.subscribers && props.subscribers.map(function (subscriber, index) {
                    return (React.createElement(Box, { key: index, sx: {
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between'
                        } },
                        React.createElement(Box, { sx: styles.subscriberContainer },
                            React.createElement(PersonOutline, { sx: { color: 'red' } }),
                            React.createElement("p", { style: { textAlign: 'left', marginLeft: 5 } }, subscriber.name)),
                        React.createElement("p", { style: { textAlign: 'right', paddingLeft: '5pt' } }, subscriber.date)));
                }))))));
}
