import { MenuItem, Select } from '@mui/material';
import React from 'react';
export var LanguageSelector = function (_a) {
    var value = _a.value, onChange = _a.onChange, style = _a.style, id = _a.id, fullWidth = _a.fullWidth, t = _a.t;
    return (React.createElement(Select, { id: id, value: value, onChange: function (e) {
            return onChange(e);
        }, style: style, fullWidth: fullWidth ? true : false },
        React.createElement(MenuItem, { value: "en" }, t("LanguageEnglish")),
        React.createElement(MenuItem, { value: "es" }, t("LanguageSpanish")),
        React.createElement(MenuItem, { value: "ca" }, t("LanguageCatalan")),
        React.createElement(MenuItem, { value: 'pt_BR' }, t('LanguagePortugueseBrazilian'))));
};
