import { Grid, IconButton, SelectChangeEvent, Tooltip } from '@mui/material'
import { styles } from './styles'
import { CustomSelect } from "@doctomatic/components-react/build/AlertConfig/CustomSelect";
import { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';

interface Props {
    value: string;
    menuItems: JSX.Element[];
    onChange: (value: string) => void;
}

export default function ConditionsTooltip({ value, menuItems, onChange }: Props) {
    const { t } = useTranslation();
    const [condition, setCondition] = useState(value);

    const handleCondition = (value: string) => {
        setCondition(value)
        onChange(value)
    }

    return (
        <div style={styles.conditionsTooltipContainer}>
            <Grid container spacing={1} columns={12} xs={12}>
                <Grid item xs={11}>
                    <CustomSelect
                        value={condition}
                        onChange={(e: SelectChangeEvent) => handleCondition(e.target.value)}
                        menuItems={menuItems}
                        helperText={`${t('FieldCondition')}`}
                        required={true}
                        minWidth={'100%'}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Tooltip
                        style={styles.tooltip}
                        title=
                        {
                            <ul>
                                <li>{t('FieldConditionMax')}</li>
                                <li>{t('FieldConditionMin')}</li>
                                <li>{t('FieldConditionGETLT')}:{t('DefinitionGETLT')}</li>
                                <li>{t('FieldConditionGTLET')}:{t('DefinitionGETLT')}</li>
                                <li>{t('FieldRangeValue')}: {t('DefinitionAbsoluteRange')}</li>
                                <li>{t('FieldConditionRangeRelative')}: {t('DefinitionPercentageRange')}</li>
                                <li>{t('FiledConditionTimeAbsolute')}: {t('DefinitionAbsoluteTime')}</li>
                                <li>{t('FieldConditionTimeRelative')}: {t('DefinitionRelativeTime')}</li>
                            </ul>
                        }
                    >
                        <IconButton><InfoIcon /></IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    )
}
