import { TextWordsQuantityCell } from '@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell';
import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import PatientDataRow from "./PatientDataRow";
import { IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link as NavLink } from 'react-router-dom';
import { BreadcrumbNameMap } from "../../../breadcrumbMap";
import { PatientActionsPopover } from "./PatientActionsPopover";
import { sortText } from '../../../App/sortFunctions';

export interface PopoverConfig {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => void,
  onEdit: (id: number) => void,
  onFrequency: (id: number) => void,
  onDelete: (id: number, name: string) => void,
  onRestore: (id: number, name: string) => void,
  onSendSms: (id: number, name: string, phone: string) => void,
  onSendEmail: (id: number, name: string, email: string) => void,
  onClose: () => void,
  anchorEl: HTMLElement | null,
  popoverId: string
}

export const PatientGridColumns = (
  showExternalId: boolean = false,
  t: (text: string) => string,
  popoverConf?: PopoverConfig,
): GridColumns<PatientDataRow> => {

  const openPopover = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
    if (popoverConf?.onClick) {
      popoverConf?.onClick(event, item);
    }
  }

  const gridColumns: GridColumns<PatientDataRow> = [
    {
      field: 'name',
      headerName: t('Name'),
      width: 250,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => params.row.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: PatientDataRow }) => {
        const userPath = BreadcrumbNameMap.Patient.url.replace(":patientId", params.row.id.toString());
        return <TextWordsQuantityCell item={params.row} words={[params.row.name]} page={userPath}
          routerLink={NavLink} />
      }
    },
    {
      field: 'locations',
      headerName: t('Locations'),
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        const words: string[] = params.row.locations;
        return words.join(', ');
      },
      sortable: false,
      renderCell: (params: { row: PatientDataRow }) => {
        const userLocationUrl = BreadcrumbNameMap.LocationsUser.url
          .replace(":role", 'patients')
          .replace(":actorId", params.row.id.toString());
        if(params.row.deletedAt) return(<></>)
        return (<TextWordsQuantityCell
          tooltipContent={{ title: t('Locations'), lines: params.row.locations }}
          item={params.row} words={params.row.locations} page={userLocationUrl}
          routerLink={NavLink}></TextWordsQuantityCell>);
      }
    },
    {
      field: 'tracking',
      headerName: t('Trackings'),
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        const words: string[] = params.row.tracking;
        return words.join(', ');
      },
      sortable: false,
      renderCell: (params: { row: PatientDataRow }) => {
        const userTrackingsUrl = BreadcrumbNameMap.TrackingPatient.url.replace(":patientId", params.row.id.toString());
        if(params.row.deletedAt) return(<></>)
        return (<TextWordsQuantityCell
          tooltipContent={{ title: t('Trackings'), lines: params.row.tracking }}
          item={params.row} words={params.row.tracking}
          page={userTrackingsUrl}
          routerLink={NavLink}></TextWordsQuantityCell>)
      }
    },
    {
      field: 'gender',
      headerName: t('Gender'),
      width: 150,
      valueGetter: (params: GridValueGetterParams) => params.row.gender,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: PatientDataRow }) => {
        const genderCapitalized = params.row.gender && params.row.gender[0].toUpperCase() + params.row.gender.slice(1);
        return <p>{t(genderCapitalized ?? '')}</p>
      }
    },
    {
      field: 'birthYear',
      headerName: t('FieldBirthYear'),
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.birthYear,
      renderCell: (params: { row: PatientDataRow }) => {
        return <p>{params.row.birthYear}</p>
      }
    },
    {
      field: "actions",
      headerName: t('Actions'),
      filterable: false,
      sortable: false,
      renderCell: (params: { row: PatientDataRow }) => {
        return <>
          <IconButton onClick={(event) => openPopover(event, params.row)}><MoreVertIcon /></IconButton>
          <PatientActionsPopover row={params.row} t={t} popoverConf={popoverConf}></PatientActionsPopover>
        </>
      }
    },
  ]

  showExternalId && gridColumns.unshift({
    field: 'externalId',
    headerName: t('ExternalId'),
    width: 170,
    align: 'left',
    renderCell: (params: { row: PatientDataRow }) => <p>{params.row.externalId ? params.row.externalId : '--'}</p>
  });

  return gridColumns;
}