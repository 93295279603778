import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { NavLink } from 'react-router-dom'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import { useApi } from '@doctomatic/sdk/build/Api'
import useStyles from './styles'
import { CircularProgress, Link } from '@mui/material'
import { RecoverPasswordRequestDto } from '@doctomatic/sdk/build/dto/Mail'
import { validate, ValidationError } from 'class-validator'
import { useTranslation } from 'react-i18next'

const ForgotPassword = (): React.ReactElement => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState<ValidationError[]>([])
  const [showSuccess, setShowSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const { useMail } = useApi()
  const { sendRecoverPasswordEmail } = useMail()
  const { t } = useTranslation();

  useEffect(() => {
    const checkErrors = async () => {
      const dto = new RecoverPasswordRequestDto()
      dto.email = email
      const errors = await validate(dto)
      setErrors(errors)
    }

    checkErrors()
  }, [email])

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setLoading(true)
    try {
      const dto = new RecoverPasswordRequestDto()
      dto.email = email
      await sendRecoverPasswordEmail(dto)
      setShowSuccess(true)
    } catch (err:any) {
      toast.error(err.response?.data?.message || err.message)
    }
    setLoading(false)
  }

  const success = (
    <>
      <Typography style={{marginTop: 40, marginBottom: 20, fontSize: 36}}>{t('ForgotPasswordSuccessTitle')}</Typography>
      <Typography style={{marginBottom: 20}}>{t('ForgotPasswordSuccessText')}</Typography>
      <Link component={NavLink} to="/">{t('ButtonBack')}</Link>
    </>
  )

  const form = (
    <form id='login' className={classes.form} noValidate onSubmit={handleSubmit}>
      <Typography style={{marginTop: 20}}>{t('ForgotPasswordText')}</Typography>
      <Typography style={{marginTop: 20}}>{t('ForgotPasswordText2')}</Typography>
      <TextField variant='outlined' margin='normal' required fullWidth id='email' label={t('FieldEmail')} name='email' autoComplete='email' autoFocus onChange={(event) => setEmail(event.target.value)} value={email} />
      <Button disabled={errors.length > 0 || loading} id='button' type='submit' fullWidth variant='contained' className={classes.submit}>
        { loading ? <CircularProgress /> : t('ButtonSendRecoverPasswordEmail') }
      </Button>
    </form>
  )

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography style={{marginBottom: 20}} variant='h1'>{t('ForgotPasswordTitle')}</Typography>
        { showSuccess ? success : form }
      </div>
    </Container>
  )
}

export { ForgotPassword }