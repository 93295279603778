import { toast } from "react-toastify";
import { JwtMalformedError } from "@doctomatic/sdk/build/exceptions/JwtMalformedError";

export const processError =
  (logout: any, history: any, t?: (text: string) => string) =>
  async (err: any): Promise<void> => {
    if (!t) t = (text: string) => text;
    if (err instanceof JwtMalformedError) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("permissions");
      localStorage.removeItem("management");
      logout();
      history.push("/");
      toast.success(err.message);
    } else {
      if (err && err.response) {
        if (err.response.status === 403) {
          toast.info(t("ForbiddenErrorToast"));
        } else if (err.response.status === 401) {
          toast.error(t("SessionExpired"));
        } else {
          let message = "";
          if (err.response.status) message = `${err.response.status}:`;
          if (err.response.data && err.response.data.message)
            message += ` ${err.response.data.message}`;
          toast.error(message);
        }
      } else {
        console.error("Error without object");
        toast.warning("Error without object");
      }
    }
  };
