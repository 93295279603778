import { SxProps, Theme } from "@mui/material";
import React from 'react';

export const styles: {
  container: SxProps<Theme>
  titleContainer: React.CSSProperties,
  btnSubmit: React.CSSProperties,
  signsContainer: React.CSSProperties,
  checkboxContainer: React.CSSProperties,
  checkboxItem: React.CSSProperties,
} = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '100%',
    width: 'auto',
    minWidth: '350px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    outline: 'none',
    padding: 2,
    borderRadius: 2
  },
  titleContainer: {
    padding: '0.75rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: 'minmax(100px, auto)'
  },
  btnSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  signsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 16
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  checkboxItem: {
    display: 'flex',
    alignItems: 'center'
  }
};