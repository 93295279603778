import { ReactElement, useState } from "react";
import { Page } from "../Page";
import { toast } from "react-toastify";
import { PatientsList } from "../Patients/PatientsList";
import { useLoading } from "../../Loading/Loading";
import { Box, Grid, IconButton, Switch, Typography } from "@mui/material";
import { useApi } from "@doctomatic/sdk/build/Api";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { AddPatientRequestDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { PatientCreate } from "./PatientCreate";
import { IUsePatientsByActor } from "@doctomatic/sdk/build/modules/PatientsByActor";
import { GetActorResponseDto } from "@doctomatic/sdk/build/modules/Profile";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { processError } from "../../../App/errorToast";
import { useEffect } from "react";
import { AddCircle } from "@mui/icons-material";
import { GridSortItem } from "@mui/x-data-grid";
import { Filter } from "@doctomatic/sdk/build/utils/pagination/Filter";
import { FilterOperator } from "@doctomatic/sdk/build/utils/pagination/FilterOperator.enum";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdvancedSearch } from "@doctomatic/components-react/build/AdvancedSearch/AdvancedSearch";
import MultipleSelect from "@doctomatic/components-react/build/MultipleSelect/MultipleSelect";
import {
  ISearchBoxProp,
  SearchBoxProp,
} from "@doctomatic/components-react/build/AdvancedSearch/column.filter";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  total: {
    marginRight: 16,
    padding: "4px 0",
    lineHeight: 1.75,
    fontSize: 16,
  },
}));

const INITIAL_PAGINATION = { page: 1, limit: 10, sortBy: [] };
const GENDERS = ["Male", "Female"];

interface IPatientsProps {
  profile: GetActorResponseDto | undefined;
}

const Patients = ({ profile }: IPatientsProps): ReactElement => {
  const getRole = (role: Role): "doctors" | "nurses" => {
    if (role === Role.doctor) return "doctors";
    if (role === Role.nurse) return "nurses";
    return "doctors";
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { usePatientsByActor, useCompany, companyId, role, logout } = useApi();
  const { response: companyResponse } = useCompany(companyId, true);
  const company = companyResponse?.data;
  const { setIsLoading } = useLoading();
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const classes = useStyles();
  const title = t("HeaderPatients");
  const [isPatientFormOpen, setIsPatientFormOpen] = useState<boolean>(false);
  const [needsSubscription, setNeedsSubscription] = useState<boolean>(true);
  const [completeOnboarding, setCompleteOnboarding] = useState<boolean>(
    company && company.onBoardingReq === 4 ? true : false
  );

  const [filters, setFilters] = useState<Filter[]>([]);
  const [search, setSearch] = useState<string>("");
  const [searchToFind, setSearchToFind] = useState<string>("");

  // declaration of hooks to handle pagination
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [sort, setSort] = useState<GridSortItem[]>([]);
  const [selectedGender, setSelectedGender] = useState<string[]>([]);
  const [date, setDate] = useState(null);

  const {
    response: patientsResponse,
    add,
    patch,
    del,
    restore,
    mutate,
  }: IUsePatientsByActor = usePatientsByActor(
    profile?.id ?? 0,
    getRole(role),
    pagination,
    searchToFind,
    filters,
    showDeleted,
    true,
    true,
    processError(logout, navigate, t)
  );
  const patients = patientsResponse?.data;
  const patientsPaginationInfo = patientsResponse?.meta;

  // Handle if company can display external ID
  const [showExternalId, setShowExternalId] = useState<boolean>(false);

  // If companyResponse changes, value updated
  useEffect(() => {
    if (company && company?.showExternalId) {
      setShowExternalId(true);
    }
  }, [companyResponse, company]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchToFind(search);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    setLoading(true);
    setPagination({ page: page, limit: pageSize, sortBy: sort });
  }, [page, pageSize, sort]);

  useEffect(() => {
    setLoading(false);
  }, [patients]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPagination(INITIAL_PAGINATION);
      setSearchToFind(search);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    setPagination(INITIAL_PAGINATION);

    const genderNames: string[] = GENDERS
      ? GENDERS.map((gender: string) => gender)
      : [];
    const newSelectedGenders = genderNames.filter((gender) =>
      selectedGender.includes(t(gender))
    );

    const updatedFilters = [...filters];

    // Encontrar el índice del objeto agregado por este useEffect
    const index = updatedFilters.findIndex(
      (filter) => filter.column === "gender"
    );

    if (index !== -1) {
      // Reemplazar el objeto existente con el nuevo
      newSelectedGenders.length === 0
        ? updatedFilters.splice(index, 1)
        : updatedFilters.splice(index, 1, {
            column: "gender",
            typeQuery: FilterOperator.IN,
            values: newSelectedGenders.map((gender) => gender.toLowerCase()), // Convertir a minúscula aquí
          });
      setFilters(updatedFilters);
    } else {
      newSelectedGenders.length > 0 &&
        setFilters([
          ...filters,
          {
            column: "gender",
            typeQuery: FilterOperator.IN,
            values: newSelectedGenders.map((gender) => gender.toLowerCase()), // Convertir a minúscula aquí
          },
        ]);
    }
  }, [selectedGender]);

  useEffect(() => {
    setPagination(INITIAL_PAGINATION);

    const updatedFilters = [...filters];

    // Encontrar el índice del objeto agregado por este useEffect
    const index = updatedFilters.findIndex(
      (filter) => filter.column === "birthYear"
    );

    if (index !== -1) {
      // Reemplazar el objeto existente con el nuevo
      date
        ? updatedFilters.splice(index, 1, {
            column: "birthYear",
            typeQuery: FilterOperator.LTE,
            values: [moment(date).endOf("day").format("YYYY-MM-DD HH:mm:ss")],
          })
        : updatedFilters.splice(index, 1);

      setFilters(updatedFilters);
    } else {
      date &&
        setFilters([
          ...filters,
          {
            column: "birthYear",
            typeQuery: FilterOperator.LTE,
            values: [moment(date).endOf("day").format("YYYY-MM-DD HH:mm:ss")],
          },
        ]);
    }
  }, [date]);

  const buttons = (
    <Box display="flex" justifyContent="flex-end">
      <IconButton onClick={() => setIsPatientFormOpen(true)}>
        <AddCircle color="primary" fontSize="large" />
      </IconButton>
    </Box>
  );

  const footer = patients && patients.length > 0 && (
    <Box my={2} display="flex">
      <Switch
        checked={showDeleted}
        onChange={async () => {
          setShowDeleted(!showDeleted);
          await mutate();
        }}
      />
      <Typography className={classes.total}>{t("ShowAllCheckBox")}</Typography>
    </Box>
  );

  const patientCreateForm = isPatientFormOpen && (
    <PatientCreate
      open={isPatientFormOpen}
      onClose={() => setIsPatientFormOpen(false)}
      onSave={async (user: AddPatientRequestDto) => {
        setIsLoading(true);
        try {
          user.subscription = needsSubscription;
          await add(user);
          await mutate();
          setIsPatientFormOpen(false);
        } catch (err: any) {
          toast.error(
            `${t("ErrorCreate")}: ${err.response?.data?.message || err.message}`
          );
        }
        setIsLoading(false);
      }}
      needsSubscription={needsSubscription}
      setNeedsSubscription={setNeedsSubscription}
      completeOnboarding={completeOnboarding}
      setCompleteOnboarding={setCompleteOnboarding}
    />
  );

  const getBreadCrumbProps = (actorName: string): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        { url: "", name: actorName },
        { url: "", name: t("Patients") },
      ],
    } as BreadcrumbProps;
  };

  const searchBoxProp: ISearchBoxProp = new SearchBoxProp(
    t("AlertSearch"),
    t("AlertSearchPlaceholder")
  );

  const handleSearch = (newSearch: string) => {
    setSearch(newSearch);
  };

  const advancedSearchComponent = (
    <AdvancedSearch
      searchBoxProp={searchBoxProp}
      handleNewSimpleSearch={handleSearch}
      handleNewFilterSearch={() => null}
    ></AdvancedSearch>
  );

  const gendersSelect = (
    <MultipleSelect
      placeholder={"Gender"}
      options={GENDERS.map((gender) => t(gender))}
      selectedValues={selectedGender}
      setSelectedValues={setSelectedGender}
    />
  );

  const datePicker = (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          defaultValue={null}
          label={t("TableBirthYear")}
          value={date}
          disableFuture
          onChange={(newValue) => {
            console.log({ newValue });
            setDate(newValue ?? null);
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );

  const patientList = (
    <>
      {loading || !profile ? (
        <TableSkeleton numRows={10} widthCard={"100%"} />
      ) : (
        <></>
      )}
      <PatientsList
        patients={patients}
        profile={profile}
        showExternalId={showExternalId}
        showDeleted={showDeleted}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        paginationInfo={patientsPaginationInfo}
        sortBy={sort}
        onSortChange={setSort}
        patch={patch}
        del={del}
        restore={restore}
        mutate={mutate}
        isLoading={loading}
      />
    </>
  );

  const actorName = profile?.name ? profile!.name : "";

  return (
    <Page
      title={title}
      buttons={buttons}
      footer={footer}
      breadCrumbProps={getBreadCrumbProps(actorName)}
    >
      <Grid container spacing={2} marginBottom={4}>
        <Grid item xs={12} md={4}>
          <div>{advancedSearchComponent}</div>
        </Grid>
        <Grid item xs={12} md={4} style={{ marginTop: "8px" }}>
          <div>{gendersSelect}</div>
        </Grid>
        <Grid item xs={12} md={4}>
          {datePicker}
        </Grid>
      </Grid>
      {patientList}
      {patientCreateForm}
    </Page>
  );
};

export { Patients };
