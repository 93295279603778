var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { styles } from "../ImagesViews/styles";
import CloseIcon from '@mui/icons-material/Close';
export var CustomGraphTooltip = function (_a) {
    var active = _a.active, payload = _a.payload, label = _a.label, getUrl = _a.getUrl, t = _a.t, chartRef = _a.chartRef;
    var _b = useState(''), url = _b[0], setUrl = _b[1];
    var _c = useState(false), error = _c[0], setError = _c[1];
    var handleError = function () {
        setError(true);
    };
    var handleClose = function () {
        chartRef.current.setState({
            dismissed: true,
            dismissedAtCoordinate: { x: 0, y: 0 },
            isTooltipActive: false
        });
    };
    useEffect(function () {
        var fetchUrl = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(active && payload && payload.length)) return [3 /*break*/, 2];
                        if (!(payload[0].payload.deviceTypeName !== "Form")) return [3 /*break*/, 2];
                        return [4 /*yield*/, getUrl(payload[0].payload.readDeviceId)];
                    case 1:
                        response = _a.sent();
                        if (response && response.data) {
                            setUrl(response.data);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        if (active && payload && payload.length)
            fetchUrl();
    }, [active, payload]);
    if (active && payload && payload.length) {
        return (React.createElement("div", { className: "custom-tooltip", style: { backgroundColor: "white", borderRadius: '10px', border: "2px solid" }, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement("div", { style: { display: "flex", alignItems: 'flex-start', justifyContent: 'space-between' } },
                React.createElement("p", { className: "intro", style: { margin: 10, fontWeight: 500 } }, "".concat(label)),
                React.createElement(IconButton, { onClick: function () { return handleClose(); } },
                    React.createElement(CloseIcon, null))),
            payload.map(function (element) { return React.createElement("p", { className: "label", style: { margin: 10 } },
                " ",
                React.createElement("span", { style: { color: "".concat(element.color), fontWeight: 600 } }, "".concat(element.name)),
                " : ",
                React.createElement("span", { style: { color: "".concat(element.color), fontWeight: 300 } }, "".concat(element.value))); }),
            url.length !== 0 ?
                (error ? (React.createElement(Box, null,
                    React.createElement(Typography, { style: styles.error }, t("ImageNotAvailableError")))) : (React.createElement("img", { style: { margin: 10, width: 200, height: 200 }, src: url, alt: "", onError: handleError })))
                : null));
    }
    return React.createElement(React.Fragment, null);
};
