import { Trans } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Page } from "../../Page";
import { toast } from "react-toastify";
import { AdminCompanyList } from "./AdminCompanyList";
import { AdminCompanyCreate } from "./AdminCompanyCreate";
import { AdminCompanyEdit } from "./AdminCompanyEdit";
import { useLoading } from "../../../Loading/Loading";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { useApi } from "@doctomatic/sdk/build/Api";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import {
  AddAdminCompanyResponseDto,
  GetAdminCompanyResponseDto,
  ItemAdminCompanyListDto,
} from "@doctomatic/sdk/build/dto/Actors/AdminCompany/AdminCompany";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { processError } from "../../../../App/errorToast";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import ConfirmModal from "@doctomatic/components-react/build/ConfirmModal/ConfirmModal";
import { ConfirmationRegisterModal } from "@doctomatic/components-react/build/ConfirmationRegisterModal/ConfirmationRegisterModal";
import { AddCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  total: {
    marginRight: 16,
    padding: "4px 0",
    lineHeight: 1.75,
    fontSize: 16,
  },
  deleted: {
    textTransform: "none",
    color: theme.palette.text.primary,
    fontWeight: 400,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "white",
    },
  },
  showDeletedLabel: {
    lineHeight: 1.75,
    fontSize: 16,
  },
}));

const AdminCompany = (): React.ReactElement => {
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { useSMS, useMail, useAdminCompanies, logout } = useApi();
  const {
    response: responseGetAllAdminCompany,
    add,
    patch,
    restore,
    del,
    get,
  } = useAdminCompanies(showDeleted, true, processError(logout, navigate, t));
  const { setIsLoading } = useLoading();
  const { sendRegistrationEmail } = useMail();
  const { sendRegistrationSMS } = useSMS();
  const classes = useStyles();
  const [quantityDeleted, setQuantityDeleted] = useState<number>(0);
  const [isUserFormOpen, setIsUserFormOpen] = useState<boolean>(false);
  const [adminCompanyToDelete, setUserToDelete] =
    useState<ItemAdminCompanyListDto | null>(null);
  const [adminCompanyToRestore, setUserToRestore] =
    useState<ItemAdminCompanyListDto | null>(null);
  const [adminCompanyToEdit, setUserToEdit] =
    useState<ItemAdminCompanyListDto | null>(null);
  const [adminCompanyToEditDto, setUserToEditDto] =
    useState<GetAdminCompanyResponseDto | null>(null);
  const [adminCompanyToSendSMS, setUserToSendSMS] = useState<{
    id: number;
    name: string;
    phone?: string;
  } | null>(null);
  const [adminCompanyToSendEmail, setAdminCompanyToSendEmail] = useState<{
    id: number;
    name: string;
    email?: string;
  } | null>(null);

  useEffect(() => {
    const getAdminCompanyDto = async (id: number) => {
      let response = await get(id);
      response && response.data && setUserToEditDto(response.data);
    };
    if (adminCompanyToEdit !== null) getAdminCompanyDto(adminCompanyToEdit.id);
    // eslint-disable-next-line
  }, [adminCompanyToEdit]);

  const title = t("HeaderAdminCompanys");

  let adminCompanies: ItemAdminCompanyListDto[];

  if (!responseGetAllAdminCompany) return <></>;
  adminCompanies = responseGetAllAdminCompany.data;
  if (!adminCompanies) return <></>;

  const buttons = adminCompanies.length > 0 && (
    <Box display="flex" justifyContent="flex-end">
      <IconButton onClick={() => setIsUserFormOpen(true)}>
        <AddCircle color="primary" fontSize="large" />
      </IconButton>
    </Box>
  );

  const footer = adminCompanies.length > 0 && (
    <Box my={2} display="flex" justifyContent="flex-end">
      <Typography style={{ padding: "0 9px" }} className={classes.total}>
        {t("UserListTotal")}: {adminCompanies.length}{" "}
      </Typography>
      {showDeleted && (
        <Typography style={{ padding: "0 9px" }} className={classes.total}>
          {t("UserListDeleted")} : {quantityDeleted}{" "}
        </Typography>
      )}
      <FormControlLabel
        control={
          <Checkbox
            style={{ padding: "0 9px " }}
            checked={showDeleted}
            onChange={() => setShowDeleted(!showDeleted)}
          />
        }
        label={
          <Typography className={classes.showDeletedLabel}>
            {t("UserListShowDeleted")}
          </Typography>
        }
      />
    </Box>
  );

  const confirmRestore = adminCompanyToRestore && (
    <ConfirmModal
      open={Boolean(adminCompanyToRestore)}
      content={
        <Trans
          i18nKey="UserListRestoreConfirm"
          values={{
            user: t("FieldAdminCompany"),
            name: adminCompanyToRestore.name,
          }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonRestore")}
      onCancel={() => setUserToRestore(null)}
      onClose={() => setUserToRestore(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await restore(adminCompanyToRestore.id);
          adminCompanyToRestore.deletedAt = undefined;
          setUserToRestore(adminCompanyToRestore);
        } catch (err: any) {
          toast.error(
            `${t("UserListRestoreError")}: ${
              err.response?.data?.message || err.message
            }`
          );
        }
        setUserToRestore(null);
        setIsLoading(false);
      }}
    />
  );

  const confirmDelete = adminCompanyToDelete && (
    <ConfirmModal
      open={Boolean(adminCompanyToDelete)}
      content={
        <Trans
          i18nKey="UserListDeleteConfirm"
          // values={{ user: adminCompanyToDelete.role === Role.adminCompany ? t('FieldAdminCompany') : t('FieldPatient'), name: adminCompanyToDelete.name }}
          values={{
            user: t("FieldAdminCompany"),
            name: adminCompanyToDelete.name,
          }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonDelete")}
      onCancel={() => setUserToDelete(null)}
      onClose={() => setUserToDelete(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await del(adminCompanyToDelete.id);
        } catch (err: any) {
          toast.error(
            `${t("ErrorDelete")}: ${err.response?.data?.message || err.message}`
          );
        }
        setUserToDelete(null);
        setIsLoading(false);
      }}
    />
  );

  const handleSendEmail = async () => {
    const { data } = await sendRegistrationEmail(
      adminCompanyToSendEmail?.id as number
    );
    toast.info(data?.message);
  };

  const handleSendSms = async () => {
    try {
      const { data } = await sendRegistrationSMS(
        adminCompanyToSendSMS?.id as number
      );
      if (process.env.REACT_APP_SHOW_MSG_SMS_CONSOLE === "true") {
        console.log(data?.message);
        toast.info(
          "Mira en la consola. Copia el enlace de On Boarding y pégalo en una nueva ventana en modo incógnito (sin token en localStorage)."
        );
        toast.info(data?.message);
      }
    } catch (err: any) {
      toast.error(
        `${t("UserListSMSError")}: ${
          err.response?.data?.message || err.message
        }`
      );
    }
  };

  const adminCompanyList = (
    <AdminCompanyList
      showDeleted={showDeleted}
      onDelete={setUserToDelete}
      onRestore={setUserToRestore}
      onEdit={setUserToEdit}
      onSendSms={setUserToSendSMS}
      onSendEmail={setAdminCompanyToSendEmail}
      quantityDeleted={(quantity) => setQuantityDeleted(quantity)}
    />
  );

  const adminCompanyCreateForm = isUserFormOpen && (
    <AdminCompanyCreate
      open={isUserFormOpen}
      onClose={() => setIsUserFormOpen(false)}
      onSave={async (user) => {
        setIsLoading(true);
        try {
          const response: ResponseApi<AddAdminCompanyResponseDto> = await add(
            user
          );
          setIsUserFormOpen(false);
          if (response && response.success === true && response.data!) {
            toast.info(`${t("RemindAssignPermissions")} ${response.data.name}`);
          }
        } catch (err: any) {
          toast.error(
            `${t("ErrorCreate")}: ${err.response?.data?.message || err.message}`
          );
        }
        setIsLoading(false);
      }}
    />
  );

  const adminCompanyEditForm = adminCompanyToEditDto && (
    <AdminCompanyEdit
      open={true}
      getAdminCompanyResponseDto={adminCompanyToEditDto}
      onClose={() => {
        setUserToEditDto(null);
        setUserToEdit(null);
      }}
      onSave={async (adminCompany) => {
        setIsLoading(true);
        try {
          await patch(adminCompanyToEditDto.id, adminCompany);
          setUserToEditDto(null);
          setUserToEdit(null);
        } catch (err: any) {
          toast.error(
            `${t("ErrorEdit")}: ${err.response?.data?.message || err.message}`
          );
        }
        setIsLoading(false);
      }}
    />
  );

  const getBreadCrumbProps = (): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        {
          url: "",
          name: t(BreadcrumbNameMap.AdministrationAdminCompanies.name),
        },
      ],
    } as BreadcrumbProps;
  };

  return (
    <Page
      title={title}
      buttons={buttons}
      footer={footer}
      breadCrumbProps={getBreadCrumbProps()}
    >
      {adminCompanyList}
      {adminCompanyCreateForm}
      {adminCompanyEditForm}
      {confirmDelete}
      {confirmRestore}
      {adminCompanyToSendEmail && (
        <ConfirmationRegisterModal
          userToSend={adminCompanyToSendEmail}
          setUserToSend={setAdminCompanyToSendEmail}
          setIsLoading={setIsLoading}
          t={t}
          handleSend={handleSendEmail}
          keyConfirmation={"UserListEmailConfirm"}
          keyError={"UserListEmailError"}
        />
      )}
      {adminCompanyToSendSMS && (
        <ConfirmationRegisterModal
          userToSend={adminCompanyToSendSMS}
          setUserToSend={setUserToSendSMS}
          setIsLoading={setIsLoading}
          t={t}
          handleSend={handleSendSms}
          keyConfirmation={"UserListSMSConfirm"}
          keyError={"UserListSmsError"}
        />
      )}
    </Page>
  );
};

export { AdminCompany };
