import { IAddPatientRequestDto } from "@doctomatic/sdk/build/dto/Patient/IPatient";
import { PatientOnboardingOptions } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { IsEmail, IsNotEmpty, IsOptional, Length } from "class-validator";

export class AddPatientRequest implements IAddPatientRequestDto {
  @IsEmail()
  @Length(2, 254)
  @IsNotEmpty()
  email = "";

  @Length(2, 50)
  @IsNotEmpty()
  name = "";

  @Length(9, 50)
  @IsNotEmpty()
  phone = "";

  @IsNotEmpty()
  onBoardingReq: PatientOnboardingOptions =
    PatientOnboardingOptions.CompleteOnboarding;

  @IsOptional()
  language?: string;

  @IsOptional()
  timezone?: string;

  @IsOptional()
  birthYear?: number;

  @IsOptional()
  externalId?: string;

  @IsOptional()
  consentDoctorId?: number;

  subscription = false;

  @IsOptional()
  locationId?: number;

  @IsOptional()
  activatePatient?: boolean;
}
