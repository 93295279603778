import React, { useEffect, useState, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Button } from "@mui/material";
import { getDeviceName } from "../utils";
import { IItemDeviceList } from "@doctomatic/sdk/build/dto/IItemList";
import Close from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const formTypeName = "Form";
const read6MWTName = "ReadSixMWT";

interface Props {
  loading: boolean;
  devices: IItemDeviceList[];
  displayOnlyDevices: boolean;
  autoRead: boolean;
  handleOnClickDevice: (id: number, typeName: string) => void;
  handleOnClickAutoRead?: () => void;
  buttonColor?: string;
  bgColor?: string;
  fromAutoRead?: boolean;
}

export const DevicesList = ({
  loading,
  devices,
  displayOnlyDevices,
  autoRead,
  handleOnClickDevice,
  handleOnClickAutoRead,
  buttonColor,
  bgColor,
  fromAutoRead,
}: Props): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
  const [isWeb, setIsWeb] = useState<boolean>(window.innerWidth >= 768);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (displayOnlyDevices === true) {
      devices.forEach((d) => {
        if (d.typeName === formTypeName) {
          d.visible = false;
        }
      });
    }
  }, [displayOnlyDevices]);

  useEffect(() => {
    const handleResize = () => {
      setIsWeb(window.innerWidth >= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [devices]);

  function getContrastColor(backgroundColor: string | undefined) {
    if (!backgroundColor) return "#000";

    // Convert the hexadecimal color code to RGB format
    const hexColor = backgroundColor.replace("#", "");
    const red = parseInt(hexColor.substring(0, 2), 16);
    const green = parseInt(hexColor.substring(2, 4), 16);
    const blue = parseInt(hexColor.substring(4, 6), 16);

    // Calculate the relative luminance of the color
    const relativeLuminance =
      (red * 0.299 + green * 0.587 + blue * 0.114) / 255;

    // If the relative luminance is less than 0.5, return white as the text color, otherwise return black
    return relativeLuminance < 0.5 ? "#fff" : "#000";
  }

  const emptyDevicesMessage = (
    <div style={styles.emptyDevices}>
      <h2>{t("EmptyDevicesMessage")}</h2>
    </div>
  );

  const cannotFindDeviceMessage = (
    <>
      <div
        style={{
          position: "absolute",
          top: 80,
          left: 20,
        }}
      >
        <Button
          variant="outlined"
          color="inherit"
          style={{
            marginRight: "auto !important",
            marginBottom: "10px !important",
          }}
          onClick={() => {
            navigate(`/devices`);
          }}
        >
          <Close />
        </Button>
      </div>
      <div style={styles.cannotFindDevice}>
        <h2
          dangerouslySetInnerHTML={{
            __html: t("DeviceNotDetected"),
          }}
        ></h2>
      </div>
    </>
  );
  const devicesLoadingDisplay = (
    <>
      {[...Array(9)].map((_, index) => (
        <div
          key={index}
          style={isWeb ? styles.buttonItemWeb : styles.buttonItem}
        >
          {isWeb ? (
            <Skeleton height={100} />
          ) : (
            <Skeleton height={130} width={350} />
          )}
        </div>
      ))}
    </>
  );

  const devicesListDisplay = () => {
    if (devices.length === 0) {
      return emptyDevicesMessage;
    } else {
      return (
        <>
          {autoRead && handleOnClickAutoRead && (
            <div style={isWeb ? styles.buttonItemWeb : styles.buttonItem}>
              <Button
                onClick={() => handleOnClickAutoRead()}
                style={{
                  ...styles.button,
                  backgroundColor: "#D8CDF2",
                  padding: isWeb ? "0px 50px" : "50px 50px",
                }}
              >
                {t("ReadAutomatically")}
              </Button>
            </div>
          )}
          {devices.map((item, index) => {
            let formName = "";
            if (item.form) {
              let found = false;
              try {
                const parsed = JSON.parse(item.form);

                parsed.deviceName.forEach((element: any) => {
                  if (element.lang === i18n.language) {
                    formName = element.text;
                    found = true;
                  }
                });

                if (!found) {
                  parsed.formTitle.forEach((element: any) => {
                    if (element.lang === "es") {
                      formName = element.text;
                      found = true;
                    }
                  });
                }

                if (!found) {
                  formName = parsed.formName;
                }
              } catch (error) {}
            }

            return (
              item.name !== read6MWTName &&
              (item.visible === true ||
                item.visible === undefined ||
                fromAutoRead) && (
                <div
                  key={index}
                  style={isWeb ? styles.buttonItemWeb : styles.buttonItem}
                >
                  <Button
                    onClick={() => handleOnClickDevice(item.id, item.typeName)}
                    style={
                      buttonColor
                        ? {
                            ...styles.button,
                            backgroundColor: buttonColor,
                            color: getContrastColor(buttonColor),
                            padding: isWeb ? "0px 50px" : "50px 50px",
                          }
                        : {
                            ...styles.button,
                            padding: isWeb ? "0px 50px" : "50px 50px",
                          }
                    }
                  >
                    {item.typeName === formTypeName && formName !== ""
                      ? t(formName)
                      : getDeviceName(currentLanguage, item, t)}
                  </Button>
                </div>
              )
            );
          })}
        </>
      );
    }
  };

  return (
    <div style={{ height: "100%", backgroundColor: bgColor ? bgColor : "" }}>
      <div style={isWeb ? styles.containerWeb : styles.container}>
        {fromAutoRead && !autoRead && cannotFindDeviceMessage}

        <div style={isWeb ? styles.buttonListWeb : styles.buttonList}>
          {loading ? devicesLoadingDisplay : devicesListDisplay()}
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  containerWeb: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  buttonList: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "50px 0px",
    margin: 0,
    listStyleType: "none",
  },
  buttonItem: {
    margin: "10px 0px",
    width: "100%",
  },
  button: {
    width: "100%",
    fontSize: "1rem",
    cursor: "pointer",
    borderRadius: "5px",
    backgroundColor: "#b8e0ba",
    boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.15)",
    border: "none",
    marginTop: "5%",
    height: "70%",
    color: "#000000",
  },
  buttonListWeb: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridAutoRows: "20vh",
    gap: "10px",
    padding: "50px 0px",
    margin: 0,
    width: "80%",
    listStyleType: "none",
  },
  buttonItemWeb: {
    margin: "0px 10px",
    height: "100%",
  },
  emptyDevices: {
    position: "fixed",
    top: "15%",
    left: "0%",
    width: "100%",
    textAlign: "center",
    padding: 10,
  },
  cannotFindDevice: {
    marginTop: 50,
    width: "100%",
    textAlign: "center",
    padding: 10,
  },
};
