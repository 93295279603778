import { ItemLocation } from "@doctomatic/sdk/build/dto/Location/Location";
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Popover } from "@mui/material";
import AdminLocationDataRow from './AdminLocationDataRow';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from "react";
import { GridColumns } from "@mui/x-data-grid";

export interface PopoverAdminLocationConfig {
    handleClickPopover: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => void;
    showEditModal: (id: number, location: ItemLocation) => void,
    onDelete: (id: number) => Promise<void>,
    handleClosePopover: () => void;
    anchorEl: HTMLElement | null,
    popoverId: string
}

export function createColumnActionAdminLocation(t: (text: string) => string,
    popoverConf: PopoverAdminLocationConfig): GridColumns<AdminLocationDataRow> {
    return [{
        field: 'Actions',
        headerName: t('Actions'),
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
            return <>
                <IconButton onClick={(event) => popoverConf?.handleClickPopover(event, params.row)}><MoreVertIcon /></IconButton>
                <Popover
                    id={`m${params.row.id}`}
                    open={popoverConf?.popoverId === `m${params.row.id}`}
                    anchorEl={popoverConf?.anchorEl}
                    onClose={popoverConf?.handleClosePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <List>
                        <ListItem button onClick={() => {
                            popoverConf?.handleClosePopover();
                            const location: ItemLocation = {
                                id: params.row.id,
                                name: params.row.name,
                                quantityPatients: 0,
                                description: params.row.description,
                                children: params.row.children ?? [],
                                parent: params.row.parent
                            };
                            popoverConf?.showEditModal(location.id, location)
                        }}>
                            <ListItemIcon><EditOutlinedIcon /></ListItemIcon>
                            <ListItemText primary={t('ButtonEdit')} />
                        </ListItem>
                        <ListItem button onClick={() => {
                            popoverConf?.handleClosePopover();
                            popoverConf?.onDelete(params.row.id)
                        }}>
                            <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
                            <ListItemText primary={t('LocationListDelete')} />
                        </ListItem>
                    </List>
                </Popover>
            </>
        }
    }]
}