import React from 'react';

export const styles: {
  conditionsTooltipContainer: React.CSSProperties,
  tooltip: React.CSSProperties,
} = {
  conditionsTooltipContainer: {
    display: 'flex'
  },
  tooltip: {
    height: 'fit-content'
  }
};