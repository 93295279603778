
import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { TextWordsQuantityCell } from '@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell';
import { BreadcrumbNameMap } from '../../../../breadcrumbMap';
import { ItemAdminCompanyListDto } from '@doctomatic/sdk/build/dto/Actors/AdminCompany/AdminCompany';
import { Link } from 'react-router-dom';

export const AdminCompanyListGridColumns = (
  t: (text: string) => string,
  onEdit: (user: ItemAdminCompanyListDto) => void,
  onDelete: (user: ItemAdminCompanyListDto) => void,
  onRestore: (user: ItemAdminCompanyListDto) => void,
  actions: (user: ItemAdminCompanyListDto) => JSX.Element): GridColumns<ItemAdminCompanyListDto> => {

  return [
    {
      field: 'name',
      headerName: t('HeaderName'),
      width: 200,
      align: 'left',
    },
    {
      field: 'groupPermissions',
      headerName: t('HeaderGroupPermissions'),
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        let strings: string[];
        if (params.row.groupPermissions && params.row.groupPermissions.length > 0)
          strings = params.row.groupPermissions.map((x: any) => x.name);
        else
          strings = [t('Go_to_groupPermissions')];
        return strings.join(', ');
      },
      sortable: false,
      renderCell: (params) => {
        let strings: string[];
        if (params.row.groupPermissions && params.row.groupPermissions.length > 0)
          strings = params.row.groupPermissions.map((x) => x.name);
        else
          strings = [t('Go_to_groupPermissions')];

        return <TextWordsQuantityCell item={params.row} routerLink={Link}
          page={BreadcrumbNameMap.GroupPermissionsAdminCompany.url.replace(':actorId', params.row.id.toString())}
          words={strings} />
      }
    },
    {
      field: 'permissions',
      headerName: t('HeaderPermissions'),
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        let strings: string[];
        if (params.row.permissions && params.row.permissions.length > 0)
          strings = params.row.permissions.map((x: any) => x.name);
        else
          strings = [t('Go_to_permissions')];
        return strings.join(', ');
      },
      sortable: false,
      renderCell: (params) => {
        let strings: string[];
        if (params.row.permissions && params.row.permissions.length > 0)
          strings = params.row.permissions.map((x) => x.name);
        else
          strings = [t('Go_to_permissions')];

        return <TextWordsQuantityCell item={params.row} routerLink={Link}
          page={BreadcrumbNameMap.GroupPermissionsAdminCompany.url.replace(':actorId', params.row.id.toString())}
          words={strings}  ></TextWordsQuantityCell>
      }
    },
    {
      field: 'actions',
      headerName: t('Actions'),
      width: 100,
      filterable: false,
      sortable: false,
      renderCell: (params) => actions(params.row)
    },
  ]
}