import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import TreatmentsDataRow from "./TreatmentsDataRow";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useLocation } from "react-router-dom";
import { BreadcrumbNameMap } from "../../../breadcrumbMap";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { sortText } from "../../../App/sortFunctions";
import { toLocaleDateString } from "../utils";

export interface PopoverConfig {
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => void;
  onEndOrReopen: (item: TreatmentsDataRow) => void;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  popoverId: string;
}

export interface TreatmentsGridColumnsProps {
  t: (label: string) => string;
  timezone: string;
  popoverConf?: PopoverConfig;
}

export const TreatmentsGridColumns = ({
  t,
  timezone,
  popoverConf,
}: TreatmentsGridColumnsProps): GridColumns<TreatmentsDataRow> => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const openPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    if (popoverConf?.onClick) {
      popoverConf?.onClick(event, item);
    }
  };

  let columns: GridColumns<TreatmentsDataRow> = [
    {
      field: "name",
      headerName: t("NameColumn"),
      width: 200,
      align: "left",
      valueGetter: (params: GridValueGetterParams) => params.row.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => {
        return <p>{params.row.name}</p>;
      },
    },
    {
      field: "lastMeasurement",
      headerName: t("LastMeasurementColumn"),
      minWidth: 150,
      maxWidth: 170,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Link
          to={
            searchParams
              ? `${BreadcrumbNameMap.TrackingGraph.url
                  .replace(":trackingId", params.row.id.toString())
                  .replace(
                    ":patientId",
                    params.row.patient?.id.toString()
                  )}?${searchParams.toString()}`
              : `${BreadcrumbNameMap.TrackingGraph.url
                  .replace(":trackingId", params.row.id.toString())
                  .replace(":patientId", params.row.patient?.id.toString())}`
          }
          style={{ textDecoration: "none" }}
        >
          <IconButton color="default">
            <QueryStatsIcon />
          </IconButton>
        </Link>
      ),
    },
    {
      field: "start",
      headerName: t("StartColumn"),
      width: 100,
      valueGetter: (params: GridValueGetterParams) => params.row.start,
      sortComparator: (v1: any, v2: any) =>
        new Date(v2!).getTime() - new Date(v1!).getTime(),
      renderCell: (params) => {
        return <p>{toLocaleDateString(params.row.start)}</p>;
      },
    },
    {
      field: "end",
      headerName: t("EndColumn"),
      width: 100,
      valueGetter: (params: GridValueGetterParams) => params.row.end,
      sortComparator: (v1: any, v2: any) =>
        new Date(v2!).getTime() - new Date(v1!).getTime(),
      renderCell: (params) => {
        return (
          <p>{params.row.end ? toLocaleDateString(params.row.end) : "--"}</p>
        );
      },
    },
  ];

  if (popoverConf) {
    columns.push({
      field: "actions",
      headerName: t("ActionsColumn"),
      width: 200,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => openPopover(event, params.row)}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            id={`m${params.row.id}`}
            open={popoverConf?.popoverId === `m${params.row.id}`}
            anchorEl={popoverConf?.anchorEl}
            onClose={popoverConf?.onClose}
          >
            <List>
              <ListItem
                button
                onClick={() => {
                  popoverConf?.onClose();
                  popoverConf?.onEndOrReopen(params.row);
                }}
              >
                <ListItemText
                  primary={
                    params.row.end && params.row.end < new Date()
                      ? t("ReopenTreatmentTitle")
                      : t("EndTreatmentTitle")
                  }
                />
              </ListItem>
            </List>
          </Popover>
        </>
      ),
    });
  }
  return columns;
};
