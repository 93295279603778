import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { TextWordsQuantityCell } from '@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell';
import { BreadcrumbNameMap } from '../../../../breadcrumbMap';
import { ItemDoctorListDto } from '@doctomatic/sdk/build/dto/Actors/Doctor/Doctor';
import { Link } from 'react-router-dom';

export const DoctorListGridColumns = (
  t: (text: string) => string,
  onEdit: (user: ItemDoctorListDto) => void,
  onDelete: (user: ItemDoctorListDto) => void,
  onRestore: (user: ItemDoctorListDto) => void,
  actions: (user: ItemDoctorListDto) => JSX.Element): GridColumns<ItemDoctorListDto> => {

  return [
    {
      field: 'name',
      headerName: t('HeaderName'),
      width: 200,
      align: 'left',
    },
    // TODO: this Page does not exist yet
    // {
    //   field: 'trakings',
    //   headerName: t('HeaderTrackingTypes'),
    //   width: 200,
    //   align: 'left',
    //   valueGetter: (params: GridValueGetterParams) => {
    //     let strings: string[];
    //     if (params.row.trackings && params.row.trackings.length > 0)
    //       strings = params.row.trackings.map((x: any) => x.name);
    //     else
    //       strings = [t('Go_to_trankings')];
    //     return strings.join(', ');
    //   },
    //   sortable: false,
    //   renderCell: (params) => {
    //     let strings: string[];
    //     if (params.row.trackings && params.row.trackings.length > 0)
    //       strings = params.row.trackings.map((x) => x.name);
    //     else
    //       strings = [t('Go_to_trankings')];
    //     return <TextWordsQuantityCell item={params.row} page={'/tracking'} routerLink={Link}
    //       words={strings}  ></TextWordsQuantityCell>

    //   }
    // },
    {
      field: 'locations',
      headerName: t('HeaderLocations'),
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        let strings: string[];
        if (params.row.locations && params.row.locations.length > 0)
          strings = params.row.locations.map((x: any) => x.name);
        else
          strings = [t('GoToLocations')];
        return strings.join(', ');
      },
      sortable: false,
      renderCell: (params) => {
        let strings: string[];
        if (params.row.locations && params.row.locations.length > 0)
          strings = params.row.locations.map((x) => x.name);
        else
          strings = [t('GoToLocations')];
        return <TextWordsQuantityCell item={params.row} routerLink={Link}
          page={BreadcrumbNameMap.LocationsUser.url
            .replace(':role', 'doctors')
            .replace(':actorId', params.row.id.toString())}
          words={strings}  ></TextWordsQuantityCell>
      }
    },
    {
      field: 'groupPermissions',
      headerName: t('HeaderGroupPermissions'),
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        let strings: string[];
        if (params.row.groupPermissions && params.row.groupPermissions.length > 0)
          strings = params.row.groupPermissions.map((x: any) => x.name);
        else
          strings = [t('Go_to_groupPermissions')];
        return strings.join(', ');
      },
      sortable: false,
      renderCell: (params) => {
        let strings: string[];
        if (params.row.groupPermissions && params.row.groupPermissions.length > 0)
          strings = params.row.groupPermissions.map((x) => x.name);
        else
          strings = [t('Go_to_groupPermissions')];

        return <TextWordsQuantityCell item={params.row} routerLink={Link}
          page={BreadcrumbNameMap.GroupPermissionsDoctor.url.replace(':actorId', params.row.id.toString())}
          words={strings} />
      }
    },
    {
      field: 'permissions',
      headerName: t('HeaderPermissions'),
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        let strings: string[];
        if (params.row.permissions && params.row.permissions.length > 0)
          strings = params.row.permissions.map((x: any) => x.name);
        else
          strings = [t('Go_to_permissions')];
        return strings.join(', ');
      },
      sortable: false,
      renderCell: (params) => {
        let strings: string[];
        if (params.row.permissions && params.row.permissions.length > 0)
          strings = params.row.permissions.map((x) => x.name);
        else
          strings = [t('Go_to_permissions')];

        return <TextWordsQuantityCell item={params.row} routerLink={Link}
          page={BreadcrumbNameMap.GroupPermissionsDoctor.url.replace(':actorId', params.row.id.toString())}
          words={strings}  ></TextWordsQuantityCell>
      }
    },
    //TODO: a la espera definitiva de borrado
    /*
    {
      field: 'iconEdit',
      
      headerName: '',
      width: 100,
      renderCell: (params) => {
        return (
          <Tooltip title={t('ButtonEdit') || ''}>
            <IconButton aria-label="edit" onClick={() => onEdit(params.row)}>
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>)
      }
    },
    {
      field: 'iconDelete',
      headerName: '',
      width: 100,
      renderCell: (params) => {
        if (params.row.isDeleted)
        return (
          <Tooltip title={t('ButtonRestore') || ''}>
            <IconButton aria-label="delete" onClick={() => onRestore(params.row)}>
              <RestoreFromTrashIcon />
            </IconButton>
          </Tooltip>)
        else
        return (
          <Tooltip title={t('ButtonDelete') || ''}>
            <IconButton aria-label="delete" onClick={() => onDelete(params.row)}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>)
      }
    },*/
    {
      field: 'actions',
      headerName: t('Actions'),
      width: 100,
      filterable: false,
      sortable: false,
      renderCell: (params) => actions(params.row)
    },
  ]
}