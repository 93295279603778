import IconButton from '@mui/material/IconButton';
import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { GetDiseaseResponseDto } from '@doctomatic/sdk/build/dto/Diseases';
import { TextWordsQuantityCell } from '@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell';
import { Checkbox, Tooltip } from '@mui/material';
import { GetDeviceResponseDto } from '@doctomatic/sdk/build/dto/Devices';
import { getDeviceName } from '../../utils';

export const TrackingTypeListGridColumns = (
  t: (text: string) => string,
  onEdit: (disease: GetDiseaseResponseDto) => void,
  onDelete: (disease: GetDiseaseResponseDto) => void,
  onFrequenciesTemplate: (disease: GetDiseaseResponseDto) => void,
  onClickCheckbox: (disease: GetDiseaseResponseDto) => void,
  currentLanguage: string
): GridColumns<GetDiseaseResponseDto> => {

  return [
    {
      field: 'name',
      headerName: t('HeaderName'),
      width: 200,
      align: 'left',
    },
    {
      field: 'devices',
      headerName: t('Devices'),
      width: 200,
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        const words: string[] = params.row.devices?.map((d: any) => d) ?? [];
        return words.join(', ');
      },
      sortable: false,
      renderCell: (params) => {
        if (params.row.devices) {
          return (
            <TextWordsQuantityCell
              item={params.row}
              words={params.row.devices?.map((d: GetDeviceResponseDto) => getDeviceName(currentLanguage, d, t))}
              onClickModal={() => onEdit(params.row)}
            ></TextWordsQuantityCell>
          );
        } else {
          return (<></>);
        }
      }
    },
    {
      field: 'iconEdit',
      headerName: t('FrequenciesTemplates'),
      width: 170,
      align: 'center',
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={t('ButtonEdit') || ''}>
            <IconButton aria-label="edit" onClick={() => onFrequenciesTemplate(params.row)}>
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
        );
      }
    },
    {
      field: 'forceFrequenciesCreation',
      headerName: t('ForceFrequenciesCreationCheckBox'),
      width: 220,
      align: 'center',
      valueGetter: (params: GridValueGetterParams) => params.row.forceFrequenciesCreation,
      renderCell: (params: { row: GetDiseaseResponseDto }) => (
        <Checkbox
          checked={params.row.forceFrequenciesCreation}
          onChange={() => {
            onClickCheckbox(params.row);
          }}
        />
      ),
    },
    {
      field: 'iconDelete',
      headerName: '',
      width: 100,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={t('ButtonDelete') || ''}>
            <IconButton aria-label="delete" onClick={() => onDelete(params.row)}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>)
      }
    }
  ]
}