import { Weekday } from "@doctomatic/sdk/build/dto/Frequencies";

export const minStringToNumbers = (min: string): number[] =>
  min.split(",").map((value: string) => Number(value));

export const minToHHmm = (min: number): string =>
  `${Math.floor(min / 60)}`.padStart(2, "0") +
  ":" +
  `${min % 60}`.padStart(2, "0");

export const getWeekFormatted = (weekday: string, t: (text: string) => string) => {
  const week: { [key: string]: string } = {
    [Weekday.Monday]: t("Monday"),
    [Weekday.Tuesday]: t("Tuesday"),
    [Weekday.Wednesday]: t("Wednesday"),
    [Weekday.Thursday]: t("Thursday"),
    [Weekday.Friday]: t("Friday"),
    [Weekday.Saturday]: t("Saturday"),
    [Weekday.Sunday]: t("Sunday"),
  };
  return week[weekday];
};
