import { useEffect, useState } from "react";
import { FormResponse } from "@doctomatic/sdk/build/dto/ReadForm/types/ReadFormTypes";
import { Modal } from "@mui/material";
import { CameraCore } from "../Pages/Camera/CameraCore";
import { CreateImageResponseDto } from "@doctomatic/sdk/build/dto/Images";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { processError } from "../../App/errorToast";
import FormData from "form-data";
import { IItemDeviceList } from "@doctomatic/sdk/build/dto/IItemList";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { GetTypeDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { DevicesList } from "../Pages/Devices/DevicesList";

const formTypeName = "Form";
const read6MWTName = "ReadSixMWT";
const MIN_CONFIDENCE = 0.95;

interface DeviceFormProps {
  userId: number;
  form: FormResponse;
  questionIndex: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  handleResponseChange: (questionId: number, response: any) => void;
  setOpenDescription: (status: boolean) => void;
}

export default function DeviceForm({
  userId,
  form,
  questionIndex,
  onNextPage,
  onPreviousPage,
  handleResponseChange,
  setOpenDescription,
}: DeviceFormProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { logout, useImages, useDevices, usePatient, useCompany, companyId } =
    useApi();

  const { create } = useImages(false, processError(logout, navigate, t));
  const { response: company } = useCompany(companyId, true);
  const { getDeviceType } = useDevices(
    false,
    processError(logout, navigate, t)
  );
  const { findOneWithDevicesAndMeasurements } = usePatient(
    +userId,
    false,
    processError(logout, navigate, t)
  );

  const [isProcessing, setIsProcessing] = useState(false);
  const [deviceId, setDeviceId] = useState<string | undefined>();
  const [displayDevices, setDisplayDevices] = useState(false);
  const [buttonColor, setButtonColor] = useState<string>("");
  const [devices, setDevices] = useState<IItemDeviceList[]>([]);
  const [devicesLoading, setDevicesLoading] = useState(false);

  // Processing image
  const [createImageResponseDto, setCreateImageResponseDto] =
    useState<CreateImageResponseDto | null>(null);
  const [takenPhoto, setTakenPhoto] = useState<Blob | undefined>();

  const fetchDevices = async () => {
    setDevicesLoading(true);
    // Get devices by user
    const userResponse = await findOneWithDevicesAndMeasurements();
    if (userResponse?.data) {
      const parsedDevices: IItemDeviceList[] =
        userResponse.data.treatmentDevices
          .map((td) => {
            return td.device;
          })
          .filter(
            (x) => x.typeName !== formTypeName && x.name !== read6MWTName
          );
      setDevices(parsedDevices);
    }
    setDevicesLoading(false);
  };

  useEffect(() => {
    fetchDevices();
  }, [userId]);

  useEffect(() => {
    if (form.questions[questionIndex].deviceId !== -1) {
      setDeviceId(form.questions[questionIndex].deviceId?.toString());
    }
  }, [form]);

  useEffect(() => {
    if (company?.data?.buttonColor) {
      setButtonColor(company?.data?.buttonColor);
    }
  }, [company]);

  const onTakePhoto = async (photo: Blob) => {
    setTakenPhoto(photo);
    if (deviceId) {
      await handleGetMeasurements(photo, +deviceId);
    } else {
      await handleGetDevice(photo);
    }
  };

  const handleGetMeasurements = async (photo: Blob, deviceId: number) => {
    //TO BE REPLACED WITH CreateImageResponseDto ON 4.0.0
    let formData = new FormData();
    formData.append("file", photo, `${Date.now().toString()}.jpg`);
    const sendImageResponse = await create(deviceId, formData, userId);

    //TO BE REPLACED WITH .data ON 4.0.0
    let data = "";
    if (sendImageResponse && sendImageResponse.additional_data) {
      const proposedMeasurements =
        sendImageResponse.additional_data.proposedMeasurements;
      data = proposedMeasurements ? proposedMeasurements.join(",") : "";
    }
    setCreateImageResponseDto(
      sendImageResponse?.additional_data
        ? sendImageResponse.additional_data
        : null
    );
    window.parent.postMessage(data, "*");
  };

  const handleNoDeviceProposed = async () => {
    // Get devices by user
    setDeviceId(devices[0].id.toString());
    // Display devices list
    setDisplayDevices(true);
  };

  const handleGetDevice = async (photo: Blob) => {
    let formData = new FormData();
    formData.append("file", photo, `${Date.now().toString()}.jpg`);
    const proposedDevicesResponse: ResponseApi<GetTypeDeviceResponseDto[]> =
      await getDeviceType(formData);
    if (
      proposedDevicesResponse?.data &&
      proposedDevicesResponse.data.length > 0
    ) {
      const firstProposed = proposedDevicesResponse.data[0];
      if (firstProposed.confidence > MIN_CONFIDENCE) {
        const device = devices.find((x) => x.name === firstProposed.name);
        if (device) {
          const id = device.id;
          setDeviceId(id.toString());
          await handleGetMeasurements(photo, +id);
        } else {
          await handleNoDeviceProposed();
        }
      } else {
        await handleNoDeviceProposed();
      }
    } else {
      await handleNoDeviceProposed();
    }
  };

  const handleSelectedDevice = async (id: number) => {
    if (id) {
      setDeviceId(id.toString());
      setDisplayDevices(false);
      if (takenPhoto && deviceId) {
        setIsProcessing(true);
        await handleGetMeasurements(takenPhoto, +deviceId);
      }
      setIsProcessing(false);
    }
  };

  const saveReadDeviceIdToLocalStorage = async (readDeviceId: number) => {
    try {
      localStorage.setItem(
        `readDeviceId-${questionIndex}`,
        readDeviceId.toString()
      );
      handleResponseChange(questionIndex, form.responses[questionIndex]);
    } catch (error) {
      console.error(error);
    }
  };

  const devicesSelector = devices && devices.length > 0 && (
    <DevicesList
      loading={devicesLoading}
      devices={devices}
      displayOnlyDevices={true}
      autoRead={false}
      handleOnClickDevice={handleSelectedDevice}
      buttonColor={buttonColor}
      fromAutoRead={true}
    />
  );

  return (
    <>
      <Modal
        open={true}
        onClose={onPreviousPage}
        disableScrollLock={true}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgb(237, 234, 234)",
            height: "100%",
          },
          "& .MuiPaper-root": {
            maxHeight: "100vh",
            overflowY: "hidden",
          },
        }}
        key={questionIndex}
      >
        <>
          {displayDevices ? (
            devicesSelector
          ) : (
            <CameraCore
              userId={userId.toString()}
              deviceId={deviceId?.toString()}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
              onTakePhoto={onTakePhoto}
              createImageResponseDto={createImageResponseDto}
              setCreateImageResponseDto={setCreateImageResponseDto}
              takenPhoto={takenPhoto}
              showSwitch={false}
              onClose={onPreviousPage}
              onNextPage={onNextPage}
              saveReadDeviceIdToLocalStorage={saveReadDeviceIdToLocalStorage}
              key={questionIndex}
              setOpenDescription={setOpenDescription}
            />
          )}
        </>
      </Modal>
    </>
  );
}
