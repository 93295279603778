import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexLayoutGrid } from '@doctomatic/components-react/build/DataGrid/DataGrid'
import { ItemLocation } from '@doctomatic/sdk/build/dto/Location/Location';
import AdminLocationDataRow from './AdminLocationDataRow';
import { AdminLocationGridColumns } from './AdminLocationGridColumns';
import { PopoverAdminLocationConfig } from './popoverRowAdminLocation';

export interface AdminLocationsListProps {
  locations?: ItemLocation[];
  showEditModal: (locationId: number, location: ItemLocation) => void;
  deleteLocation: (id: number) => Promise<void>;
}

const AdminLocationsList = (props: AdminLocationsListProps): React.ReactElement => {
  const { t, i18n } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = React.useState<string>('');

  const handleClickPopover2 = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(`m${item.id}`);
  };

  const handleClosePopover2 = () => {
    setAnchorEl(null);
    setPopoverId('');
  };

  const poperConfig: PopoverAdminLocationConfig = {
    handleClickPopover: handleClickPopover2,
    handleClosePopover: handleClosePopover2,
    anchorEl: anchorEl,
    popoverId: popoverId,
    showEditModal: props.showEditModal,
    onDelete: props.deleteLocation
  };

  const adminLocationsGridColumns2 = AdminLocationGridColumns(t, poperConfig);

  const locationsDataRow: AdminLocationDataRow[] = props.locations || [];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '90%' }}>
      <FlexLayoutGrid
        language={i18n.language}
        columns={adminLocationsGridColumns2}
        rows={locationsDataRow}
      ></FlexLayoutGrid>
    </div>
  )
}

export { AdminLocationsList }