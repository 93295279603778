import { ReactElement, useState } from "react";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { GetActorResponseDto } from "@doctomatic/sdk/build/modules/Profile";
import { processError } from "../../../../App/errorToast";
import { Page } from "../../Page";
import ExternalReferencesList from "./ExternalReferencesList";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { Box } from "@mui/material";
import { DropdownSelect } from "./DropdownSelect";
import { ExternalReferenceModal } from "./ExternalReferenceModal";
import { AdvancedSearch } from "@doctomatic/components-react/build/AdvancedSearch/AdvancedSearch";
import {
  ISearchBoxProp,
  SearchBoxProp,
} from "@doctomatic/components-react/build/AdvancedSearch/column.filter";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { useEffect } from "react";
import { IUseCompanyActorsPaginated } from "@doctomatic/sdk/build/modules/CompanyActors/CompanyActorsPaginated";
import { useNavigate } from "react-router-dom";

interface IPatientsProps {
  profile: GetActorResponseDto | undefined;
}

const roleEndpoints: any = Object.freeze({
  All: undefined,
  Patients: Role.user,
  Nurses: Role.nurse,
  Doctors: Role.doctor,
  Administrators: Role.admin,
});

const ExternalReferences = ({ profile }: IPatientsProps): ReactElement => {
  const roles = ["All", "Patients", "Nurses", "Doctors", "Administrators"];
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<any>({
    page: 1,
    limit: 10,
    sortBy: [],
  });

  const { useCompanyActorsPaginated, companyId, logout } = useApi();
  const [search, setSearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchToFind, setSearchToFind] = useState<string>("");

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const [selectedRole, setSelectedRole] = useState<string>("All");
  const [selectedRoleEndpoint, setSelectedRoleEndpoint] = useState<
    Role | undefined
  >();
  const { response: actorsResponse }: IUseCompanyActorsPaginated =
    useCompanyActorsPaginated(
      companyId,
      pagination,
      searchToFind,
      selectedRoleEndpoint,
      processError(logout, navigate, t)
    );
  const [isActorFormOpen, setIsActorFormOpen] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const actorsAndPatients = actorsResponse ? actorsResponse.data : [];
  const actorsPaginationInfo = actorsResponse?.meta;

  const queryParameters = new URLSearchParams(window.location.search);
  const patientId = queryParameters.get("patientId") ?? undefined;

  useEffect(() => {
    setIsLoading(true);
    const found = roles.find((role) => t(role) === selectedRole);
    setSelectedRoleEndpoint(found ? roleEndpoints[found] : undefined);
  }, [selectedRole]);

  useEffect(() => {
    setPagination({ page: page, limit: pageSize, sortBy: [] });
  }, [page, pageSize]);

  useEffect(() => {
    setIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      setSearchToFind(search);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    setIsLoading(false);
  }, [actorsAndPatients]);

  useEffect(() => {
    if (patientId) {
      setSelectedUserId(+patientId);
      setIsActorFormOpen(true);
    }
  }, [patientId]);

  const actorsList = (
    <ExternalReferencesList
      actors={actorsAndPatients}
      isLoading={isLoading}
      setSelectedUserId={(userId: number) => {
        setSelectedUserId(userId);
        setIsActorFormOpen(true);
      }}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      pagination={actorsPaginationInfo}
    />
  );

  const externalReferencesModal = isActorFormOpen && (
    <ExternalReferenceModal
      open={isActorFormOpen}
      userId={selectedUserId}
      onClose={() => setIsActorFormOpen(false)}
    />
  );

  const getBreadCrumbProps = (): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        { url: "", name: t(BreadcrumbNameMap.ExternalReferences.name) },
      ],
    } as BreadcrumbProps;
  };

  const searchBoxProp: ISearchBoxProp = new SearchBoxProp(
    t("Search"),
    t("PatientSearchPlaceholder")
  );

  const handleSearch = (newSearch: string) => {
    setSearch(newSearch);
  };

  const buttons = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems={"center"}
      height={"100%"}
    >
      <div style={{ flex: 1, maxWidth: "50%" }}>
        <AdvancedSearch
          searchBoxProp={searchBoxProp}
          handleNewSimpleSearch={handleSearch}
          handleNewFilterSearch={() => null}
        />
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginLeft: 16 }}
      >
        <DropdownSelect<string>
          selectedItem={t(selectedRole)}
          setSelectedItem={(item: string) => {
            setSelectedRole(item);
          }}
          items={roles.map((role: string) => t(role))}
          title={t("Roles")}
        />
      </div>
    </Box>
  );

  return (
    <Page buttons={buttons} breadCrumbProps={getBreadCrumbProps()}>
      {profile ? actorsList : <></>}
      {externalReferencesModal}
    </Page>
  );
};

export { ExternalReferences };
