import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import React from "react";
import { styles } from "./styles";
import { ImageDisplay } from "./ImageDisplay";
import DownloadIcon from '@mui/icons-material/Download';
export var ImageViewModal = function (_a) {
    var imageUrl = _a.imageUrl, altText = _a.altText, open = _a.open, handleClose = _a.handleClose, t = _a.t, error = _a.error, handleError = _a.handleError, _b = _a.isModal, isModal = _b === void 0 ? true : _b;
    var handleDownload = function () {
        var link = document.createElement("a");
        link.href = imageUrl;
        link.download = "imagen.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    var ImageContainer = (React.createElement(Box, { sx: isModal ? styles.containerModal : styles.container },
        React.createElement(ImageDisplay, { imageUrl: imageUrl, altText: altText, t: t, error: error, handleError: handleError }),
        React.createElement(Box, { style: styles.btnClose },
            isModal &&
                React.createElement(Button, { variant: "contained", onClick: handleClose, style: styles.botBtn }, t("CloseImage")),
            React.createElement(Button, { sx: styles.dwnBtn, variant: "contained", size: "small", onClick: handleDownload, title: t('DownloadButton'), disabled: imageUrl.length === 0 || error },
                React.createElement(DownloadIcon, null)))));
    return (React.createElement("div", null, isModal ?
        (React.createElement(Modal, { open: open, onClose: handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, ImageContainer))
        : React.createElement(React.Fragment, null, ImageContainer)));
};
