import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { validate, ValidationError } from "class-validator";
import { useApi, IProvideApi } from "@doctomatic/sdk/build/Api";
import { Phone } from "../../../Phone";
import { useTranslation } from "react-i18next";
import { AddNurseRequestDto } from "@doctomatic/sdk/build/dto/Actors/Nurse/Nurse";
import { processError } from "../../../../App/errorToast";
import { useNavigate } from "react-router-dom";

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (user: AddNurseRequestDto) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  visible: { overflowY: "visible" },
}));

export const NurseCreate = ({
  open,
  onClose,
  onSave,
}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { useProfile, useNurses, logout }: IProvideApi = useApi();
  const { t } = useTranslation();
  const { response: profileResponse } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const { response: responseGetAllNurse } = useNurses(
    false,
    undefined,
    true,
    processError(logout, navigate, t)
  );

  const classes = useStyles();
  const profile = profileResponse?.data;
  const currentYear = new Date().getFullYear() - 30;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [birthYear, setBirthYear] = useState(currentYear);
  const [role, setRole] = useState<Role>(Role.nurse);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [timezone, setTimezone] = useState("");

  useEffect(() => {
    setName("");
    setEmail("");
    setPhone("");
    setBirthYear(currentYear);
    setRole(Role.nurse);
  }, [open, profile, currentYear]);

  useEffect(() => {
    const checkErrors = async () => {
      const dto = new AddNurseRequestDto();
      dto.email = email;
      dto.name = name;
      dto.phone = phone;
      dto.birthYear = birthYear;

      let errors = await validate(dto);
      setErrors(errors);
    };

    checkErrors();
  }, [open, name, email, phone, birthYear, role]);

  useEffect(() => {
    const getTimezone = () => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setTimezone(timezone);
    };

    getTimezone();
  }, []);

  if (!responseGetAllNurse) return <></>;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.visible }}
    >
      <DialogTitle>{t("ButtonCreate")}</DialogTitle>

      <DialogContent className={classes.visible}>
        <TextField
          margin="dense"
          variant="outlined"
          name="name"
          label={t("FieldName")}
          type="text"
          fullWidth
          onChange={(e) => setName(e.target.value)}
          value={name}
          autoFocus
          required
        />
        <TextField
          margin="dense"
          variant="outlined"
          name="email"
          label={t("FieldEmail")}
          type="email"
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
        <Phone onUpdate={(value) => setPhone(value)} value={phone} />
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={onClose} size="small">
              {t("ButtonCancel")}
            </Button>
          </Box>
          <Button
            disabled={errors.length > 0}
            onClick={() => {
              const dto = new AddNurseRequestDto();
              dto.email = email;
              dto.name = name;
              dto.phone = phone;
              dto.birthYear = birthYear;
              dto.language = profile?.language || "en";
              dto.timezone = timezone;
              onSave(dto);
            }}
            variant="contained"
            size="small"
          >
            {t("ButtonSave")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
