import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
var modalContainerStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    outline: 'none',
    padding: 4,
    paddingTop: 1,
    paddingBottom: 1,
};
export default function AlertStatesModal(props) {
    return (React.createElement("div", null,
        React.createElement(Modal, { open: props.open, onClose: props.handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
            React.createElement(Box, { sx: modalContainerStyles },
                React.createElement(Box, { sx: {
                        display: 'flex',
                        justifyContent: 'center',
                        borderBottom: 1,
                        marginBottom: 1,
                        borderRadius: 2
                    } },
                    React.createElement("h2", { style: {
                            marginLeft: 16,
                            marginRight: 16,
                            marginBottom: 0
                        } }, props.t('SelectAlertState'))),
                React.createElement(Box, { sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    } }, props.states && props.states.map(function (state, index) {
                    return (React.createElement(Box, { key: index, sx: {
                            display: 'flex',
                            alignItems: 'center'
                        } },
                        React.createElement(Button, { style: { margin: 2 }, disabled: props.currentState.state === state, onClick: function () { return props.handleClose(state); } }, props.t(state))));
                }))))));
}
