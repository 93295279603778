export var styles = {
    tab: {
        textTransform: 'none',
        height: 'auto'
    },
    tabContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignSelf: 'flex-start',
        width: 'auto',
        height: 'auto',
        paddingBlock: '5px',
    }
};
