import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
    marginRight: theme.spacing(3),
    '&.active': {
      textDecoration: 'underline'
    }
  },
  homeLink: {
    color: 'inherit',
    textDecoration: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  label: {
    textDecoration: 'none',
    boxShadow: 'none',
    color: theme.palette.common.white
  },
  menuLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&.active': {
      textDecoration: 'underline'
    }
  },
  linkMobile: {
    color: 'inherit',
    textDecoration: 'none',
    '&.active': {
      textDecoration: 'underline'
    },
  },
  menuButtonMobile: {
    marginBlock: '3px !important',
    width: '100%' 
  },
  closeButton: {
    marginRight: 'auto !important',
    marginBottom: '10px !important'
  },
}))
