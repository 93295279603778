import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/".concat(pdfjs.version, "/pdf.worker.min.js");
export var ViewPdfModal = function (_a) {
    var open = _a.open, setOpen = _a.setOpen, pdfLink = _a.pdfLink, t = _a.t;
    var _b = useState(null), numPages = _b[0], setNumPages = _b[1];
    var _c = useState(1), currentPage = _c[0], setCurrentPage = _c[1];
    var handleDocumentLoadSuccess = function (_a) {
        var numPages = _a.numPages;
        setNumPages(numPages);
    };
    var handlePrevPage = function () {
        setCurrentPage(function (prevPage) { return prevPage - 1; });
    };
    var handleNextPage = function () {
        setCurrentPage(function (prevPage) { return prevPage + 1; });
    };
    var handleCloseModal = function () {
        setOpen(false);
        setCurrentPage(1);
    };
    var modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '90%',
        maxWidth: '90%',
        width: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        outline: 'none',
        overflow: 'auto',
    };
    return (React.createElement(Modal, { open: open, onClose: handleCloseModal, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
        React.createElement(Box, { sx: modalStyle },
            React.createElement("div", { style: { display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '10px' } },
                React.createElement(Button, { onClick: handlePrevPage, disabled: currentPage <= 1, style: { marginRight: '10px' } }, t('ButtonPrevious')),
                React.createElement("span", { style: { margin: '0 10px' } },
                    t('Page'),
                    " ",
                    currentPage,
                    "/",
                    numPages || '-/-'),
                React.createElement(Button, { onClick: handleNextPage, disabled: numPages != null && currentPage >= numPages || !numPages, style: { marginLeft: '10px' } }, t('ButtonNext'))),
            React.createElement(Document, { file: pdfLink, onLoadSuccess: handleDocumentLoadSuccess },
                React.createElement(Page, { pageNumber: currentPage, width: window.innerWidth * 0.9, renderTextLayer: false, renderAnnotationLayer: false, onRenderSuccess: function () {
                        if (numPages !== null && currentPage > numPages) {
                            setCurrentPage(numPages);
                        }
                    } })),
            React.createElement("div", { style: { display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '10px' } },
                React.createElement(Button, { onClick: handlePrevPage, disabled: currentPage <= 1, style: { marginRight: '10px' } }, t('ButtonPrevious')),
                React.createElement("span", { style: { margin: '0 10px' } },
                    t('Page'),
                    " ",
                    currentPage,
                    "/",
                    numPages || '-/-'),
                React.createElement(Button, { onClick: handleNextPage, disabled: numPages != null && currentPage >= numPages || !numPages, style: { marginLeft: '10px' } }, t('ButtonNext'))))));
};
