export const drawLogo = (
  ctx: CanvasRenderingContext2D,
  logoImg: string,
  logoWidth: number,
  logoHeight: number,
  centerX: number,
  centerY: number,
  colorLight: string,
  margin: number
) => {
  const img = new Image();
  img.src = logoImg;
  img.onload = () => {
    const cornerRadius = 10;

    // Mejorar la calidad del escalado
    ctx.imageSmoothingEnabled = true; // Activar suavizado de imagen
    ctx.imageSmoothingQuality = "high"; // Usar alta calidad de suavizado

    // Dibujar fondo blanco con bordes redondeados
    ctx.fillStyle = colorLight;
    ctx.beginPath();
    ctx.moveTo(centerX - margin + cornerRadius, centerY - margin);
    ctx.lineTo(centerX + logoWidth + margin - cornerRadius, centerY - margin);
    ctx.quadraticCurveTo(
      centerX + logoWidth + margin,
      centerY - margin,
      centerX + logoWidth + margin,
      centerY - margin + cornerRadius
    );
    ctx.lineTo(
      centerX + logoWidth + margin,
      centerY + logoHeight + margin - cornerRadius
    );
    ctx.quadraticCurveTo(
      centerX + logoWidth + margin,
      centerY + logoHeight + margin,
      centerX + logoWidth + margin - cornerRadius,
      centerY + logoHeight + margin
    );
    ctx.lineTo(centerX - margin + cornerRadius, centerY + logoHeight + margin);
    ctx.quadraticCurveTo(
      centerX - margin,
      centerY + logoHeight + margin,
      centerX - margin,
      centerY + logoHeight + margin - cornerRadius
    );
    ctx.lineTo(centerX - margin, centerY - margin + cornerRadius);
    ctx.quadraticCurveTo(
      centerX - margin,
      centerY - margin,
      centerX - margin + cornerRadius,
      centerY - margin
    );
    ctx.closePath();
    ctx.fill();

    // Dibujar logo en el centro del QR
    ctx.drawImage(
      img,
      Math.round(centerX),
      Math.round(centerY),
      Math.round(logoWidth),
      Math.round(logoHeight)
    );
  };
};
