import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Close from "@mui/icons-material/Close";
import logo from "../../assets/logo.png";
import { useApi, ILoginData } from "@doctomatic/sdk/build/Api";
import useStyles from "./styles";
import {
  Box,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
  SelectChangeEvent,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { About } from "../Pages/About";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { LanguageSelector } from "@doctomatic/components-react/build/LanguageSelector/LanguageSelector";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <Button
          onClick={onClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </Button>
      ) : null}
    </DialogTitle>
  );
};

interface LocationState {
  from: {
    pathname: string;
  };
}

const Login = (): React.ReactElement => {
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const navigate = useNavigate();
  const { token, login } = useApi();
  const [open, setOpen] = useState(false);
  const location = useLocation<LocationState>();
  const [userData, setUserData] = useState<ILoginData>({
    email: "",
    password: "",
    super: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const logoCache = localStorage.getItem("logoCache");

  const { from } = location.state || { from: { pathname: "/" } };

  const [imageError, setImageError] = useState(false);

  const handleLogoError = () => {
    setImageError(true);
  };

  useEffect(() => {
    if (token) navigate("/");
  }, [token, navigate]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      let { accessToken: token, refreshToken } = await login(userData);
      localStorage.setItem("token", token || "");
      localStorage.setItem("refreshToken", refreshToken || "");
      navigate(from, { replace: true });
    } catch (err: any) {
      toast.error(i18n.t("LoginErrorTitle") + ": " + i18n.t("LoginErrorText"));
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  const handleSelectLanguage = (e: SelectChangeEvent<string>) => {
    i18n.changeLanguage(e.target.value as string);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          height: "100vh",
        }}
      >
        <div className={classes.paper}>
          <img
            src={logoCache && !imageError ? logoCache : logo}
            onError={handleLogoError}
            style={{ width: 200, marginBottom: 20 }}
            alt="logo"
          />
          <form
            id="login"
            className={classes.form}
            noValidate
            onSubmit={handleSubmit}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("FieldEmail")}
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("FieldPassword")}
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        background: "none",
                        border: "none",
                        padding: 0,
                        marginTop: 5,
                      }}
                    >
                      {!showPassword ? (
                        <IoEyeOff size={20} color="gray" />
                      ) : (
                        <IoEye size={20} color="gray" />
                      )}
                    </button>
                  </InputAdornment>
                ),
                style: { borderRadius: 0 },
              }}
            />

            <Button
              id="button"
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              {t("ButtonEnter")}
            </Button>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <Link component={NavLink} to="/forgot">
                {t("ButtonForgotPassword")}
              </Link>
              <LanguageSelector
                value={i18n.language}
                onChange={handleSelectLanguage}
                t={t}
              />
            </Box>
          </form>
        </div>

        <Dialog
          fullScreen={true}
          maxWidth={false}
          fullWidth={true}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          />
          <DialogContent style={{ maxHeight: "100%", height: "100rem" }}>
            <About />
          </DialogContent>
        </Dialog>
        <Box display="flex" justifyContent="center">
          <Button
            variant="text"
            style={{
              textTransform: "none",
              fontSize: "1.25rem",
              textAlign: "center",
            }}
            onClick={handleOpen}
          >
            {t("HeaderAbout")}
          </Button>
        </Box>
      </div>
    </Container>
  );
};

export { Login };
