import React, { useEffect } from "react";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useNavigate } from "react-router";
import { processError } from "../../../App/errorToast";
import { useTranslation } from "react-i18next";
import { IUseUrlRedirect } from "@doctomatic/sdk/build/modules/UrlRedirect";
import { useParams } from "react-router-dom";

const Redirect = (): React.ReactElement => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { useUrlRedirect, logout } = useApi();
  const { response }: IUseUrlRedirect = useUrlRedirect(
    id as string,
    true,
    processError(logout, navigate, t)
  );

  useEffect(() => {
    if (response && response?.data?.url) {
      const currentOrigin = window.location.origin;
      const url = `${currentOrigin}/${response?.data?.url}`;

      window.location.href = url;
    }
  }, [response]);

  return <></>;
};

export { Redirect };
