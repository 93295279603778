import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import {
  Box, Button, TextField, Dialog, DialogActions, DialogContent,
  DialogTitle, FormControl, InputLabel, Select, MenuItem
} from '@mui/material'
import { Role } from '@doctomatic/sdk/build/dto/User'
import { validate, ValidationError } from 'class-validator'
import { Phone } from '../../../Phone'
import { useApi } from '@doctomatic/sdk/build/Api'
import { useTranslation } from 'react-i18next'
import timezones from 'timezones-list';
import { GetAdminCompanyResponseDto, UpdateAdminCompanyRequestDto } from '@doctomatic/sdk/build/dto/Actors/AdminCompany/AdminCompany';

interface AdminCompanyEditProps {
  open: boolean,
  getAdminCompanyResponseDto: GetAdminCompanyResponseDto,
  onClose: () => void
  onSave: (adminCompany: UpdateAdminCompanyRequestDto) => void
}

const useStyles = makeStyles((theme: Theme) => ({ visible: { overflowY: 'visible' } }))

const AdminCompanyEdit = ({ open, getAdminCompanyResponseDto, onClose, onSave }: AdminCompanyEditProps): React.ReactElement => {
  const { role } = useApi()
  const { t } = useTranslation()
  const [name, setName] = useState<string | undefined>(getAdminCompanyResponseDto.name)
  const [email, setEmail] = useState<string | undefined>(getAdminCompanyResponseDto.email)
  const [phone, setPhone] = useState<string | undefined>(getAdminCompanyResponseDto.phone)
  const [timezone, setTimezone] = useState<string | undefined>(getAdminCompanyResponseDto.timezone)
  const [errors, setErrors] = useState<ValidationError[]>([])
  const [disableEditFields, setDisableEditFields] = useState(true)
  const classes = useStyles()

  useEffect(() => {
    const checkErrors = async () => {
      const dto = new UpdateAdminCompanyRequestDto()
      dto.email = email;
      dto.name = name;
      dto.phone = phone;
      const errors = await validate(dto);
      setErrors(errors)
    }
    checkErrors()
  }, [open, name, email, phone])

  useEffect(() => {
    if (role === Role.admin || role === Role.nurse) {
      setDisableEditFields(true);
    }
  }, [role])

  if (!getAdminCompanyResponseDto) return <></>

  let adminCompanyToEdit: GetAdminCompanyResponseDto = getAdminCompanyResponseDto;
  const nameError = Boolean(errors.find(e => e.property === 'name'))
  const emailError = Boolean(errors.find(e => e.property === 'email'))
  const phoneError = Boolean(errors.find(e => e.property === 'phone'))

  return (
    <Dialog open={open} fullWidth={true} maxWidth='sm' classes={{ paper: classes.visible }}>
      <DialogTitle>
        {t('ButtonEdit')} <span>{t('FieldAdminCompany')}</span>
      </DialogTitle>
      <DialogContent className={classes.visible}>
        <TextField autoComplete='off' error={nameError} margin='dense' variant='outlined' name='name' label={t('FieldName')} type='text' fullWidth onChange={(event) => setName(event.target.value)} value={name} autoFocus required />
        <TextField autoComplete='off' error={emailError} margin='dense' variant='outlined' name='email' label={t('FieldEmail')} type='email' fullWidth onChange={(event) => setEmail(event.target.value)} value={email} required disabled={disableEditFields} />
        <Phone error={phoneError} onUpdate={(value) => setPhone(value)} value={phone ? phone : ''} disabled={disableEditFields} />
        <FormControl variant="outlined" fullWidth margin='normal' required>
          <InputLabel>{t('FieldTimezone')}</InputLabel>
          <Select
            label={t('FieldTimezone')}
            variant='outlined'
            onChange={(event) => setTimezone(event.target.value as string)}
            value={timezone}>
            {timezones.map(t => <MenuItem key={t.tzCode} value={t.tzCode}>{t.label}</MenuItem>)}
          </Select>
        </FormControl>

      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display='inline-block'>
            <Button onClick={onClose} size='small'>{t('ButtonCancel')}</Button>
          </Box>
          <Button disabled={errors.length > 0} onClick={() => {
            let dto = new UpdateAdminCompanyRequestDto()
            if (adminCompanyToEdit.email !== email) dto.email = email
            if (adminCompanyToEdit.name !== name) dto.name = name
            if (adminCompanyToEdit.phone !== phone) dto.phone = phone
            if (adminCompanyToEdit.timezone !== timezone) dto.timezone = timezone
            onSave(dto)
          }} variant='contained' size='small'>{t('ButtonSave')}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}


export { AdminCompanyEdit }



