import { Device, Sign } from "@doctomatic/components-react/build/Graphs/models";
import { Box, Button, Modal, SelectChangeEvent } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { AlertConfigByNoMeasureDataRow } from "../../AlertConfigTemplatesDataRow";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";
import { ModalBaseProps } from "../../../../../Modals/modalBaseProps";
import { IUseDevices } from "@doctomatic/sdk/build/modules/Devices";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { SignDto } from "@doctomatic/sdk/build/dto/Sign/Signs";
import { processError } from "../../../../../../App/errorToast";
import { UpdateAlertConfigByNoMeasureTemplateRequestDto } from "@doctomatic/sdk/build/dto/Alerts/config/template/AlertConfigByNoMeasureTemplate";
import { EditAlertConfigByNoMeasure } from "../../../../../AlertsConfig/EditAlertConfigByNoMeasureForm";
import { getDeviceName, getSignName } from "../../../../utils";
import { useNavigate } from "react-router-dom";

interface Props extends ModalBaseProps {
  open: boolean;
  onSave: (
    alertConfig: UpdateAlertConfigByNoMeasureTemplateRequestDto
  ) => Promise<void>;
  currentLanguage: string;
}

export const UpdateAlertConfigByNoMeasureModal = (
  props: Props
): React.ReactElement => {
  const { t } = useTranslation();
  const { useDevices, logout }: IProvideApi = useApi();
  const navigate = useNavigate();
  const { response: responseApiDevices }: IUseDevices = useDevices(
    true,
    processError(logout, navigate, t)
  );

  const initialValue: AlertConfigByNoMeasureDataRow = {
    id: props.item.id,
    color: props.item.color,
    device: props.item.device,
    max_time_between: props.item.max_time_between,
    label: props.item.label,
    alertGroups: props.item.alertGroups,
    active: props.item.active,
  };
  const [devices, setDevices] = useState<Device[]>([]);
  const [alertConfig, setAlertConfig] =
    useState<AlertConfigByNoMeasureDataRow>(initialValue);
  const [time, setTime] = useState<number>(
    Number(alertConfig.max_time_between.slice(0, -1))
  );
  const [timeUnit, setTimeUnit] = useState<string>(
    alertConfig.max_time_between.slice(-1)
  );
  const [signs, setSigns] = useState<Sign[]>([]);
  const [requestedSigns, setRequestedSigns] = useState<any>({});

  const handleAlertConfig = useCallback(
    (value: any, field: string): void => {
      setAlertConfig({ ...alertConfig, [field]: value });
    },
    [alertConfig]
  );

  const handleFormSubmit = async (): Promise<void> => {
    if (alertConfig.id) {
      const dto: UpdateAlertConfigByNoMeasureTemplateRequestDto = Object.assign(
        {} as UpdateAlertConfigByNoMeasureTemplateRequestDto,
        alertConfig
      );
      const requestedSignsFormatted = [];
      for (const property in requestedSigns) {
        if (requestedSigns[property]) {
          requestedSignsFormatted.push(property);
        }
      }
      dto.requestedSigns = requestedSignsFormatted.join(", ");
      await props.onSave(dto);
      setAlertConfig(initialValue);
    }
  };

  useEffect(() => {
    if (
      responseApiDevices &&
      responseApiDevices.success === true &&
      responseApiDevices.data!
    ) {
      const devices: Device[] =
        responseApiDevices?.data?.map((device: GetDeviceResponseDto) => {
          const signs: Sign[] = device.signs?.map(
            (sign: SignDto) =>
              ({
                id: sign.id,
                decimals: sign.decimals,
                max: sign.defaultMax,
                min: sign.defaultMin,
                name: getSignName(props.currentLanguage, sign, t),
                unit: sign.unit,
              } as Sign)
          );
          return {
            id: device.id,
            name: getDeviceName(props.currentLanguage, device, t),
            signs: signs,
          } as Device;
        }) ?? [];
      setDevices(devices);
    }
  }, [responseApiDevices]);

  useEffect(() => {
    if (devices && devices.length > 0) {
      const device: Device | undefined = devices.find(
        (d: Device) => d?.id === alertConfig?.device.id
      );
      if (device !== undefined) {
        setSigns(device.signs);
        const signs: any = {};
        setRequestedSigns(signs);
        const requestedSigns = props.item.requestedSigns?.split(", ");
        device.signs.forEach((sign: any) => {
          const isRequested = requestedSigns?.includes(sign.name);
          signs[sign.name] = isRequested;
        });
        setRequestedSigns(signs);
      }
    }
  }, [devices, alertConfig]);

  useEffect(() => {
    if (devices && devices.length > 0)
      handleAlertConfig(devices[0].id, "deviceId");
  }, [devices]);

  useEffect(() => {
    handleAlertConfig(`${time}${timeUnit}`, "max_time_between");
  }, [time, timeUnit]);

  useEffect(() => {
    if (props.item) {
      setAlertConfig({
        id: props.item.id,
        color: props.item.color,
        device: props.item.device,
        max_time_between: props.item.max_time_between,
        label: props.item.label,
        alertGroups: props.item.alertGroups,
        active: props.item.active,
      });
    }
  }, [props.item]);

  const handleOnChangeLabel = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleAlertConfig(e.target.value, "label");
  };

  const handleOnColorChange = (e: SelectChangeEvent) => {
    handleAlertConfig(e.target.value, "color");
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          <EditAlertConfigByNoMeasure
            label={alertConfig.label}
            handleOnChangeLabel={handleOnChangeLabel}
            color={alertConfig.color}
            handleOnChangeColor={handleOnColorChange}
            time={time}
            setTime={setTime}
            timeUnit={timeUnit}
            setTimeUnit={setTimeUnit}
            signs={signs}
            requestedSigns={requestedSigns}
            setRequestedSigns={setRequestedSigns}
          />
          <Box style={styles.btnSubmit}>
            <Button variant="contained" onClick={handleFormSubmit}>
              {t("UpdateAlert")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
