import React from 'react'
import { Box, IconButton, Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover'
import { GetWebhookResponseDto } from '@doctomatic/sdk/build/dto/Webhooks'
import { useApi } from '@doctomatic/sdk/build/Api'
import { useTranslation } from 'react-i18next'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

interface Props {
  onCreate: () => void,
  onDelete: (webhook: GetWebhookResponseDto) => void
}

const WebhookList = ({ onCreate, onDelete }: Props): React.ReactElement => {
  const { useWebhooks } = useApi()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = React.useState<string>('');
  const { response: webhooksResponse } = useWebhooks();
  const webhooks = webhooksResponse?.data;

  if (!webhooks) return <></>

  const actions = (webhook: GetWebhookResponseDto) => {

    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget)
      setPopoverId(`m${webhook.id}`)
    }

    const onClose = () => {
      setAnchorEl(null)
      setPopoverId('')
    }

    return <>
      <IconButton onClick={onClick}><MoreVertIcon /></IconButton>

      <Popover
        id={`m${webhook.id}`}
        open={popoverId === `m${webhook.id}`}
        anchorEl={anchorEl}
        onClose={onClose}
      >

        <List>
          <ListItem button onClick={() => { onClose(); onDelete(webhook) }}>
            <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
            <ListItemText primary={`${t('ButtonDelete')}`} />
          </ListItem>

        </List>
      </Popover>
    </>
  }


  const tableWithWebhooks = (webhooks: GetWebhookResponseDto[]) => {
    return webhooks.map((webhook) => (
      <TableRow key={webhook.id}>
        <TableCell>{webhook.endpoint}</TableCell>
        <TableCell>{webhook.eventObject}</TableCell>
        <TableCell>{webhook.eventAction}</TableCell>
        <TableCell className='actions'>{actions(webhook)}</TableCell>
      </TableRow>
    ))
  }

  const tableWithoutWebhooks = (
    <TableRow>
      <TableCell>
        <Box>
          <Typography variant='h6'>{t('WebhookListEmptyAlert')}</Typography>
          <Box my={2}>
            <Button size='large' variant='contained' onClick={onCreate}>{t('ButtonCreate')}</Button>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  )

  return (
    <TableContainer component={Paper}>
      <Table id='webhooks'>
        <TableHead>
          <TableRow>
            <TableCell>{t('Tableendpoint')}</TableCell>
            <TableCell>{t('TableObject')}</TableCell>
            <TableCell>{t('TableAction')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {webhooks.length === 0 && tableWithoutWebhooks}
          {tableWithWebhooks(webhooks)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { WebhookList }