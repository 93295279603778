import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { AddCircle } from "@mui/icons-material";
import { Page } from "../../Page";
import { FormsList } from "./FormsList";
import { ItemForm } from "@doctomatic/sdk/build/dto/Form/Form";
import { useLoading } from "../../../Loading/Loading";
import { processError } from "../../../../App/errorToast";
import { useApi } from "@doctomatic/sdk/build/Api";
import { Link, useNavigate } from "react-router-dom";
import ConfirmModal from "@doctomatic/components-react/build/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  total: {
    marginRight: 16,
    padding: "4px 0",
    lineHeight: 1.75,
    fontSize: 16,
  },
  deleted: {
    textTransform: "none",
    color: theme.palette.text.primary,
    fontWeight: 400,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "white",
    },
  },
  showDeletedLabel: {
    lineHeight: 1.75,
    fontSize: 16,
  },
}));

const FormsPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const { useForms, logout } = useApi();
  const { setIsLoading } = useLoading();
  const { t } = useTranslation();
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const [forms, setForms] = useState<ItemForm[]>();
  const [formToDelete, setFormToDelete] = useState<ItemForm | null>(null);
  const [formToRestore, setFormToRestore] = useState<ItemForm | null>(null);
  const classes = useStyles();
  const [quantityDeleted, setQuantityDeleted] = useState<number>(0);

  // declaration of hooks to handle pagination
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  // Store the pagination
  // Update pagination when page or pageSize changes
  const pagination = useMemo(() => {
    return {
      page: page,
      limit: pageSize,
    };
  }, [page, pageSize]);

  const { del, restore, response } = useForms(
    showDeleted,
    pagination,
    true,
    processError(logout, navigate, t)
  );
  const formsPaginationInfo = response?.meta;

  useEffect(() => {
    if (response && response.success === true && response?.data) {
      setForms(response!.data);
      setIsLoading(false);
    } else {
      const isLoading = Boolean(response?.error);
      setIsLoading(isLoading);
    }
  }, [response]);

  useEffect(() => {
    if (forms) {
      setQuantityDeleted(forms.filter((x) => x.deleted_at).length);
    }
  }, [forms]);

  const getBreadCrumbProps = (): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        { url: "", name: t(BreadcrumbNameMap.Forms.name) },
      ],
    } as BreadcrumbProps;
  };

  const buttons = (
    <Box display="flex" justifyContent="flex-end">
      <IconButton component={Link} to={BreadcrumbNameMap.FormsCreate.url}>
        <AddCircle color="primary" fontSize="large" />
      </IconButton>
    </Box>
  );

  const footer = forms && forms.length > 0 && (
    <Box my={2} display="flex" justifyContent="flex-end">
      <Typography style={{ padding: "0 9px" }} className={classes.total}>
        {t("UserListTotal")}: {forms.length}{" "}
      </Typography>
      {showDeleted && (
        <Typography style={{ padding: "0 9px" }} className={classes.total}>
          {t("UserListDeleted")}: {quantityDeleted}{" "}
        </Typography>
      )}
      <FormControlLabel
        control={
          <Checkbox
            style={{ padding: "0 9px " }}
            checked={showDeleted}
            onChange={() => setShowDeleted(!showDeleted)}
          />
        }
        label={
          <Typography className={classes.showDeletedLabel}>
            {t("UserListShowDeleted")}
          </Typography>
        }
        labelPlacement="end"
      />
    </Box>
  );

  const confirmDelete = formToDelete && (
    <ConfirmModal
      open={Boolean(formToDelete)}
      content={
        <Trans
          i18nKey="FormListDeleteConfirm"
          values={{ form: t("Form"), formName: formToDelete.name }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonDelete")}
      onCancel={() => setFormToDelete(null)}
      onClose={() => setFormToDelete(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await del(formToDelete.id);
        } catch (err: any) {
          toast.error(
            `${t("ErrorDelete")}: ${err.response?.data?.message || err.message}`
          );
        }
        setFormToDelete(null);
        setIsLoading(false);
      }}
    />
  );

  const confirmRestore = formToRestore && (
    <ConfirmModal
      open={Boolean(formToRestore)}
      content={
        <Trans
          i18nKey="FormListRestoreConfirm"
          values={{ form: t("Form"), formName: formToRestore.name }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonRestore")}
      onCancel={() => setFormToRestore(null)}
      onClose={() => setFormToRestore(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await restore(formToRestore.id);
          formToRestore.deleted_at = undefined;
          setFormToRestore(formToRestore);
        } catch (err: any) {
          toast.error(
            `${t("UserListRestoreError")}: ${
              err.response?.data?.message || err.message
            }`
          );
        }
        setFormToRestore(null);
        setIsLoading(false);
      }}
    />
  );

  return (
    <Page
      title={""}
      breadCrumbProps={getBreadCrumbProps()}
      buttons={buttons}
      footer={footer}
    >
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <FormsList
          forms={forms}
          onDelete={setFormToDelete}
          onRestore={setFormToRestore}
          setPage={setPage}
          setPageSize={setPageSize}
          formsPaginationInfo={formsPaginationInfo}
        />
      </Box>
      {confirmDelete}
      {confirmRestore}
    </Page>
  );
};

export { FormsPage };
