import {
  AlertState,
  GetAlertUserAckDto,
} from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { GetPatientResponseDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { GetTreatmentResponseDto } from "@doctomatic/sdk/build/dto/Treatment/Treatments";
import { Role } from "@doctomatic/sdk/build/dto/User";
import AlertsDataRow from "../AlertsDataRow";
import { GetAlertConfigIndividualResponseDto } from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigIndividual";
import { GetAlertConfigTemplateResponseDto } from "@doctomatic/sdk/build/dto/Alerts/config/template/AlertConfigTemplate";
export default class AlertsByNoMeasureDataRow extends AlertsDataRow {
  device: GetDeviceResponseDto;

  constructor(
    id: number,
    patient: GetPatientResponseDto,
    color: string,
    label: string,
    comments: string,
    created_at: Date,
    ackBy: GetAlertUserAckDto[],
    trackings: GetTreatmentResponseDto[],
    actorId: number,
    actorRole: Role,
    state: AlertState,
    device: GetDeviceResponseDto,
    onClickCheckbox: (item: any) => void,
    viewModal?: (props: any) => React.ReactElement,
    individualConfig?: GetAlertConfigIndividualResponseDto,
    templateConfig?: GetAlertConfigTemplateResponseDto
  ) {
    super(
      id,
      patient,
      color,
      label,
      comments,
      created_at,
      ackBy,
      trackings,
      actorId,
      actorRole,
      state,
      onClickCheckbox,
      viewModal,
      individualConfig,
      templateConfig
    );
    this.device = device;
  }
}
