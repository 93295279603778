import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import SubscribersDataRow from "./SubscribersDataRow";
import {
  ISubscribersGridColumnsProps,
  SubscribersGridColumns,
} from "./SubscribersGridColumns";
import { Page } from "../Pages/Page";
import { useNavigate } from "react-router-dom";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { GridColumns } from "@mui/x-data-grid";
import { processError } from "../../App/errorToast";
import { ItemAlertConfigUserResponseDto } from "@doctomatic/sdk/build/dto/Alerts/config/user/AlertConfigUser";
import { SubscriberDto } from "@doctomatic/sdk/build/dto/Alerts/group/AlertGroup";
import { IUseCompanyActors } from "@doctomatic/sdk/build/modules/CompanyActors";

interface ISubscribers {
  item: any | undefined;
  subscribe: (id: number, actorId: number) => Promise<any>;
  unsubscribe: (id: number, actorId: number) => Promise<any>;
  breadcrumbs: BreadcrumbProps;
}

export const Subscribers = ({
  item,
  subscribe,
  unsubscribe,
  breadcrumbs,
}: ISubscribers): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { logout, companyId, useCompanyActors }: IProvideApi = useApi();
  // call useCompanyActors and get all the actors of the companyId
  const { response: allActorsResponse }: IUseCompanyActors = useCompanyActors(
    companyId,
    undefined,
    undefined,
    true,
    processError(logout, navigate, t)
  );
  const [actors, setActors] = useState<ItemAlertConfigUserResponseDto[]>();
  const [searchValue, setSearchValue] = useState<string>("");

  const getDataRows = (
    actors: ItemAlertConfigUserResponseDto[] | undefined,
    subscribers: SubscriberDto[] | undefined
  ): SubscribersDataRow[] =>
    actors?.map(
      (actor: ItemAlertConfigUserResponseDto) =>
        ({
          id: actor.id,
          group: actor.role,
          name: actor.name,
          subscribed: !!subscribers?.some(
            (subscriber: SubscriberDto) => subscriber.user?.id === actor.id
          ),
        } as SubscribersDataRow)
    ) ?? [];
  const originalRows = getDataRows(actors, item?.subscribers ?? []);
  const [rows, setRows] = useState<SubscribersDataRow[]>(originalRows);

  const eventSearchValueChanged = (event: any) => {
    setSearchValue(event.target.value);
    let rowFiltered = originalRows.filter((row: SubscribersDataRow) =>
      row.name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(event.target.value.toLowerCase())
    );
    setRows(rowFiltered);
  };

  const columns: GridColumns<SubscribersDataRow> = SubscribersGridColumns({
    itemId: item?.id ?? 0,
    subscribe: subscribe,
    unsubscribe: unsubscribe,
  } as ISubscribersGridColumnsProps);

  const buttons: JSX.Element = (
    <TextField
      margin="dense"
      variant="outlined"
      name="searchValue"
      label={t("FieldSearch")}
      type="search"
      onChange={eventSearchValueChanged}
      value={searchValue}
    />
  );

  useEffect(() => {
    // save all the actors in usestate actor if the response is success and length > 0
    if (allActorsResponse?.success && allActorsResponse?.data.length > 0) {
      setActors(allActorsResponse.data);
    }
  }, [allActorsResponse]);

  // Set rows values
  useEffect(() => {
    if (actors && actors.length > 0 && item) {
      const subscribersDataRows: SubscribersDataRow[] = getDataRows(
        actors,
        item.subscribers
      );
      setRows(subscribersDataRows);
    }
  }, [item, actors]);

  return (
    <Page title="" breadCrumbProps={breadcrumbs} buttons={buttons}>
      <FlexLayoutGrid
        language={i18n.language}
        columns={columns}
        rows={rows}
      ></FlexLayoutGrid>
    </Page>
  );
};
