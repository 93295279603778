import React, { useEffect, useMemo, useState } from "react";
import { Page } from "../../Page";
import { toast } from "react-toastify";
import { TrackingTypeList } from "./TrackingTypeList";
import { TrackingTypeCreate } from "./TrackingTypeCreate";
import { TrackingTypeEdit } from "./TrackingTypeEdit";
import { useLoading } from "../../../Loading/Loading";
import { Box, IconButton } from "@mui/material";
import { useApi, IProvideApi } from "@doctomatic/sdk/build/Api";
import {
  CreateDiseaseRequestDto,
  GetDiseaseResponseDto,
} from "@doctomatic/sdk/build/dto/Diseases";
import { IUseDiseases } from "@doctomatic/sdk/build/modules/Diseases";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { FrequenciesTemplateModal } from "./FrequenciesTemplateModal";
import { processError } from "../../../../App/errorToast";
import ConfirmModal from "@doctomatic/components-react/build/ConfirmModal/ConfirmModal";
import { AddCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const TrackingTypePage = (): React.ReactElement => {
  const { useDiseases, logout }: IProvideApi = useApi();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // declaration of hooks to handle pagination
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  // Store the pagination
  // Update pagination when page or pageSize changes
  const pagination = useMemo(() => {
    return {
      page: page,
      limit: pageSize,
    };
  }, [page, pageSize]);

  const {
    response: diseasesResponse,
    add,
    del,
    patch,
    mutate,
  }: IUseDiseases = useDiseases(
    pagination,
    true,
    processError(logout, navigate, t)
  );
  const diseasesPaginationInfo = diseasesResponse?.meta;
  const diseases = diseasesResponse?.data;
  const { setIsLoading } = useLoading();

  const [isDiseaseFormOpen, setIsDiseaseFormOpen] = useState<boolean>(false);
  const [diseaseToDelete, setDiseaseToDelete] =
    useState<GetDiseaseResponseDto | null>(null);
  const [diseaseToEdit, setDiseaseToEdit] =
    useState<GetDiseaseResponseDto | null>(null);
  const [diseaseForFrequencyTemplates, setDiseaseForFrequencyTemplates] =
    useState<GetDiseaseResponseDto | null>(null);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  const buttons = diseases && (
    <Box display="flex" justifyContent="flex-end">
      <IconButton onClick={() => setIsDiseaseFormOpen(true)}>
        <AddCircle color="primary" fontSize="large" />
      </IconButton>
    </Box>
  );

  const confirmDelete = diseaseToDelete && (
    <ConfirmModal
      open={Boolean(diseaseToDelete)}
      content={
        <Trans
          i18nKey="DiseasesDeleteConfirm"
          values={{ name: diseaseToDelete.name }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonDelete")}
      onCancel={() => setDiseaseToDelete(null)}
      onClose={() => setDiseaseToDelete(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await del(diseaseToDelete.id);
        } catch (err: any) {
          toast.error(
            `${t("ErrorDelete")}: ${err.response?.data?.message || err.message}`
          );
        }
        setDiseaseToDelete(null);
        setIsLoading(false);
      }}
    />
  );

  const diseaseCreateForm = (
    <TrackingTypeCreate
      open={isDiseaseFormOpen}
      onClose={() => setIsDiseaseFormOpen(false)}
      onSave={async (dto: CreateDiseaseRequestDto) => {
        setIsLoading(true);
        try {
          await add(dto);
        } catch (err: any) {
          toast.error(
            `${t("ErrorCreate")}: ${err.response?.data?.message || err.message}`
          );
        }
        setIsDiseaseFormOpen(false);
        setIsLoading(false);
      }}
    />
  );

  const diseaseEditForm = diseaseToEdit && (
    <TrackingTypeEdit
      open={true}
      diseaseToEdit={diseaseToEdit}
      onClose={() => setDiseaseToEdit(null)}
      onSave={async (disease) => {
        setIsLoading(true);
        try {
          await patch(diseaseToEdit.id, disease);
        } catch (err: any) {
          toast.error(
            `${t("ErrorEdit")}: ${err.response?.data?.message || err.message}`
          );
        }
        setDiseaseToEdit(null);
        setIsLoading(false);
      }}
    />
  );

  const frequenciesTemplatesModal = diseaseForFrequencyTemplates && (
    <FrequenciesTemplateModal
      open={true}
      disease={diseaseForFrequencyTemplates}
      onClose={() => setDiseaseForFrequencyTemplates(null)}
      afterSave={async () => {
        setDiseaseForFrequencyTemplates(null);
        await mutate();
      }}
    />
  );

  const getBreadCrumbProps = (): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        { url: "", name: t(BreadcrumbNameMap.TrackingTypes.name) },
      ],
    } as BreadcrumbProps;
  };

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <Page
      title={t("DiseasesTitle")}
      buttons={buttons}
      breadCrumbProps={getBreadCrumbProps()}
    >
      <Box
        style={{
          marginLeft: "10%",
          marginRight: "10%",
        }}
      >
        <TrackingTypeList
          onDelete={setDiseaseToDelete}
          onEdit={setDiseaseToEdit}
          onFrequenciesTemplate={setDiseaseForFrequencyTemplates}
          currentLanguage={currentLanguage}
          diseases={diseases}
          setPage={setPage}
          setPageSize={setPageSize}
          diseasesPaginationInfo={diseasesPaginationInfo}
          patch={patch}
        />
      </Box>
      {diseaseCreateForm}
      {confirmDelete}
      {diseaseEditForm}
      {frequenciesTemplatesModal}
    </Page>
  );
};

export { TrackingTypePage };
