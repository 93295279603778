
import { GridColumns } from "@mui/x-data-grid";
import { GroupPermissionCreateDataRow  } from "../GroupPermissionCreate/GroupPermissionCreateDataRow";

import { Switch } from '@mui/material';
import { ChangeEvent } from 'react';

export const GroupPermissionCreateGridColumns = (
  columnNameName: string,
  columnNameAssigned: string,
  onChangeSwitch:(id:number,event:ChangeEvent<HTMLInputElement>) =>void):GridColumns<GroupPermissionCreateDataRow> => {

  //TODO add viewModals
  return [
    {
      field: 'name',
      headerName: columnNameName,
      width: 200,
      align: 'left',
    },
    {
      field: 'asigned',
      headerName: columnNameAssigned,
      width: 100,
      renderCell: (params) => {
        return (<Switch  
          onChange={(event)=>{
                              onChangeSwitch(params.row.id,event)}}
          inputProps={{ 'aria-label': 'controlled' }} />)
      }
    }
    ]
  }