import React from "react";
import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { useTranslation } from "react-i18next";
import LastAlertsByFrequencyDataRow from "./LastAlertsByFrequencyDataRow";
import { ViewAlertsCell } from "@doctomatic/components-react/build/Alerts/ViewAlertsCell";
import { Link } from "react-router-dom";
import { BreadcrumbNameMap } from "../../../../../breadcrumbMap";
import { AlertState } from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { ResponseApi, ResponseApiList } from "@doctomatic/sdk/build/global";
import { KeyedMutator } from "swr";
import { LastAlertsByFrequencyPatient } from "@doctomatic/sdk/build/dto/Alerts/Alerts";
import { CustomCheckbox } from "@doctomatic/components-react/build/Checkbox/CustomCheckbox";
import { formatAckBysForModal } from "../../../Alerts/utils";
import { ElementGridStringEdit } from "@doctomatic/components-react/build/ElementColumn/ElementGridStringEdit";
import { Link as NavLink } from "react-router-dom";
import { sortText } from "../../../../../App/sortFunctions";
import { toLocaleString } from "../../../utils";
import { Launch } from "@mui/icons-material";

export interface ILastAlertsByFrequencyGridColumnsProps {
  actorId: number;
  timezone: string;
  mutate: KeyedMutator<ResponseApiList<LastAlertsByFrequencyPatient>>;
  setLastAlerts: React.Dispatch<
    React.SetStateAction<LastAlertsByFrequencyPatient[]>
  >;
  showExternalId?: boolean;
  showModal?: (item: any) => void;
  showStateModal?: (
    id: number | undefined,
    item: AlertState | undefined
  ) => void;
  setAlertComments?: (
    id: number,
    comments: string | undefined
  ) => Promise<boolean>;
}

export const LastAlertsByFrequencyGridColumns = ({
  actorId,
  mutate,
  setLastAlerts,
  showExternalId,
  showModal,
  showStateModal,
  setAlertComments,
  timezone,
}: ILastAlertsByFrequencyGridColumnsProps): GridColumns<LastAlertsByFrequencyDataRow> => {
  const { t } = useTranslation();
  const { useAlerts }: IProvideApi = useApi();
  const { setAck } = useAlerts();

  const setAlertAck = async (id: number) => {
    let response: ResponseApi<any>;
    try {
      response = await setAck(id);
    } catch (err) {
      throw err;
    }
    if (response && response.success === true && response.data!) {
      const mutateFrequencyResponse:
        | ResponseApiList<LastAlertsByFrequencyPatient>
        | undefined = await mutate();
      if (
        mutateFrequencyResponse &&
        mutateFrequencyResponse.success === true &&
        mutateFrequencyResponse.data!
      ) {
        setLastAlerts(mutateFrequencyResponse.data);
      }
    }
  };

  let gridColumns: any[] = [];
  gridColumns.push(
    ...[
      {
        field: "alertColor",
        headerName: t("AlertColor"),
        width: 70,
        align: "left",
        valueGetter: (params: GridValueGetterParams) =>
          params.row.alert?.color ?? "green",
        sortable: false,
        renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => (
          <ViewAlertsCell
            alerts={[
              {
                id: params.row.alert?.id ?? 0,
                color: params.row.alert?.color ?? "green",
              },
            ]}
            row={undefined}
          />
        ),
      },
    ]
  );
  showExternalId &&
    gridColumns.push({
      field: "externalId",
      headerName: t("ExternalId"),
      width: 150,
      align: "left",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.patient.externalId ? params.row.patient.externalId : "--",
      sortable: false,
      renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => (
        <p>
          {params.row.patient.externalId ? params.row.patient.externalId : "--"}
        </p>
      ),
    });
  gridColumns.push(
    ...[
      {
        field: "state",
        headerName: t("AlertState"),
        width: 120,
        align: "center",
        valueGetter: (params: GridValueGetterParams) => params.row.state,
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => {
          const lastAlertState = params.row.alert?.state;
          const alertStateCapitalized = `${lastAlertState
            ?.charAt(0)
            .toUpperCase()}${lastAlertState?.substring(1).toLowerCase()}`;
          return (
            <TextWordsQuantityCell
              onClickModal={() =>
                showStateModal!(params.row.alert?.id, lastAlertState)
              }
              item={params.row}
              words={[t(alertStateCapitalized)]}
            ></TextWordsQuantityCell>
          );
        },
      },
      {
        field: "patient.name",
        headerName: t("PatientName"),
        width: 250,
        align: "left",
        valueGetter: (params: GridValueGetterParams) =>
          params.row.patient?.name,
        sortComparator: (v1: any, v2: any) => sortText(v1, v2),
        renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => {
          const userPath = BreadcrumbNameMap.Patient.url.replace(
            ":patientId",
            params.row.patient.id.toString()
          );
          return (
            <TextWordsQuantityCell
              item={params.row}
              words={[params.row.patient?.name]}
              page={userPath}
              routerLink={NavLink}
            />
          );
        },
      },
      {
        field: "created_at",
        headerName: t("CreatedAt"),
        width: 150,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.alert?.createdAt,
        sortComparator: (v1: any, v2: any) =>
          new Date(v2!).getTime() - new Date(v1!).getTime(),
        renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => {
          const content: JSX.Element = (
            <p>
              {params.row.alert?.createdAt
                ? toLocaleString(new Date(params.row.alert?.createdAt))
                : "--"}
            </p>
          );
          return content;
        },
      },
      {
        field: "alertName",
        headerName: t("AlertName"),
        width: 200,
        align: "left",
        valueGetter: (params: GridValueGetterParams) => params.row.alert?.name,
        sortable: false,
        renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => {
          const alertConfigUrl =
            BreadcrumbNameMap.AlertConfigByFrequency.url.replace(
              ":patientId",
              params.row.patient.id.toString()
            );
          const content: JSX.Element = (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {params.row.alert && params.row.alert.alertConfigId ? (
                <NavLink
                  to={alertConfigUrl.concat(
                    `?alertConfigId=${params.row.alert.alertConfigId}`
                  )}
                >
                  <Launch />
                </NavLink>
              ) : (
                "--"
              )}
              <p style={{ marginLeft: 5 }}></p>
              <p>{params.row.alert?.name}</p>
            </div>
          );

          return content;
        },
      },
      {
        field: "typeAlert",
        headerName: t("TypeAlert"),
        width: 120,
        align: "left",
        valueGetter: (params: GridValueGetterParams) =>
          params.row.alert?.typeAlert,
        sortable: false,
        renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => {
          const content: JSX.Element = <p>{params.row.alert?.name ?? ""}</p>;
          return content;
        },
      },
      {
        field: "timeout",
        headerName: t("Timeout"),
        width: 150,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.alert?.max_time_between,
        sortable: false,
        renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => (
          <p>{params.row.alert?.timeout}</p>
        ),
      },
      {
        field: "timeToValidate",
        headerName: t("TimeToValidate"),
        width: 150,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.alert?.max_time_between,
        sortable: false,
        renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => (
          <p>{params.row.alert?.timeToValidate}</p>
        ),
      },
      {
        field: "comments",
        headerName: t("Comments"),
        width: 95,
        align: "center",
        valueGetter: (params: GridValueGetterParams) => params.row.comments,
        sortable: false,
        renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => {
          return (
            <ElementGridStringEdit
              textCancel={t("Cancel")}
              textSave={t("Save")}
              textLabelField={t("Comments")}
              itemToAddCommentId={params.row.alert!.id}
              text={params.row.alert?.comments}
              onSave={
                setAlertComments
                  ? setAlertComments
                  : async (
                      alertId: number,
                      comment: string | undefined
                    ): Promise<boolean> => {
                      return true;
                    }
              }
              onClose={() => {}}
            />
          );
        },
      },
      {
        field: "trackings",
        headerName: t("Trackings"),
        width: 150,
        sortable: false,
        renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => {
          const page: string = `/trackings/patients/${params.row.patient.id}`;

          const content: JSX.Element = (
            <TextWordsQuantityCell
              item={params.row}
              words={[t("Go_to_trankings")]}
              page={page}
              routerLink={NavLink}
            />
          );
          return content;
        },
      },
      {
        field: "active",
        headerName: t("ConfigAlert"),
        width: 200,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.alert?.individualConfig
            ? params.row.alert?.individualConfig?.active
              ? t("Active")
              : t("Deactive")
            : params.row.alert?.templateConfig?.active
            ? t("Active")
            : t("Deactive"),
        sortable: false,
        renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => {
          const active = params.row.alert.individualConfig?.active
          return (
            <TextWordsQuantityCell
              routerLink={Link}
              item={params.row}
              words={[active ? t("Active") : t("Deactive")]}
              page={BreadcrumbNameMap.AlertConfigByFrequency.url.replace(
                ":patientId",
                params.row.patient.id.toString()
              )}
            ></TextWordsQuantityCell>
          );
        },
      },
      {
        field: "ackBy",
        headerName: t("AckBy"),
        width: 100,
        valueGetter: (params: GridValueGetterParams) => {
          const texts = params.row.alert?.ackBys
            ? params.row.alert?.ackBys.map((ack: any) => ack.userName)
            : [];
          if (texts.length === 0) texts.push("---");
          return texts.join(", ");
        },
        sortable: false,
        renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => {
          const texts = params.row.alert?.ackBys
            ? params.row.alert?.ackBys.map((ack) => ack.userName)
            : [];
          if (texts.length === 0) texts.push("---");

          return (
            <TextWordsQuantityCell
              routerLink={Link}
              item={params.row}
              words={texts}
              onClickModal={() => {
                const ackBysFormatted = formatAckBysForModal({
                  ackBys: params.row.alert?.ackBys,
                });
                showModal!(ackBysFormatted);
              }}
              tooltipContent={{ title: t("AckBy"), lines: texts }}
            ></TextWordsQuantityCell>
          );
        },
      },
      {
        field: "setAck",
        headerName: t("HeaderActions"),
        width: 150,
        valueGetter: (params: GridValueGetterParams) => {
          const defaultValue: boolean = !!params.row.alert?.ackBys?.find(
            (x: any) => x.userId === actorId
          );
          return defaultValue;
        },
        sortable: false,
        renderCell: (params: { row: LastAlertsByFrequencyDataRow }) => {
          const defaultValue: boolean = !!params.row.alert?.ackBys?.find(
            (x: any) => x.userId === actorId
          );
          return (
            <CustomCheckbox
              defaultValue={defaultValue}
              onChange={() => setAlertAck(params.row.alert!.id)}
            />
          );
        },
      },
    ]
  );
  return gridColumns;
};
