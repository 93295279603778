import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

type Prop<T> = {
    items: T[];
    setSelectedItem: (item: T) => void;
    title: string,
    selectedItem?: T;
}


const DropdownSelect = <T,>({ selectedItem, setSelectedItem, items, title }: Prop<T>): React.ReactElement => {
    if (!items) return <></>;

    return (
        <FormControl variant="outlined" style={{ maxWidth: 200 }}>
            <InputLabel id="label">{title}</InputLabel>
            <Select
                labelId="company"
                id="item-select"
                data-cy="item-select"
                label={title}
                onChange={(event) => setSelectedItem(event.target.value as T)}
                value={selectedItem}
            >
                {items.map((item: any) => (
                    <MenuItem key={`ds-${item}`} data-cy={`ds-${item}`} value={item}>
                        {item}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export { DropdownSelect }