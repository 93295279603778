// ? https://medium.com/@danny.pule/export-json-to-csv-file-using-javascript-a0b7bc5b00d2
// ? https://stackoverflow.com/questions/69485778/new-typescript-version-does-not-include-window-navigator-mssaveblob?noredirect=1&lq=1
declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

const convertToCSV = (objArray: string) => {
  const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (const index in array[i]) {
      if (line !== "") line += ",";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
};

const exportCSVFile = (headers: any, items: any[], fileTitle: string) => {
  items.unshift(headers);

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);

  const csv = convertToCSV(jsonObject);

  const exportedFilenmae = fileTitle + ".csv" || "export.csv";

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export default function convertAndExportCsv(
  headers: any,
  measurements: any[],
  fileName: string
) {
  exportCSVFile(headers, measurements, fileName);
}
