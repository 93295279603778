import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { validate, ValidationError } from "class-validator";
import { useTranslation } from "react-i18next";
import { UpdateExternalReferenceRequestDto } from "@doctomatic/sdk/build/dto/ExternalReferences/ExternalReferences";

type CreateForm = {
  source: string;
  reference: string;
}

interface Props {
  open: boolean;
  externalReferenceToEdit: UpdateExternalReferenceRequestDto;
  onClose: () => void;
  onSave: (externalReference: UpdateExternalReferenceRequestDto) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  visible: { overflowY: "visible" },
}));

const EditExternalReferenceModal = ({
  open,
  externalReferenceToEdit,
  onClose,
  onSave,
}: Props): React.ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [createForm, setCreateForm] = useState<CreateForm>({
    source: externalReferenceToEdit.source || '',
    reference: externalReferenceToEdit.reference || '',
  });
  const [errors, setErrors] = useState<ValidationError[]>([]);

  const checkErrors = async (dto: UpdateExternalReferenceRequestDto): Promise<boolean> => {
    const errors: ValidationError[] = await validate(dto);
    if (!dto.reference || dto.reference === '') {
      const validationError = new ValidationError();
      validationError.property = "reference";
      errors.push(validationError);
    }

    if (!dto.source || dto.source === '') {
      const validationError = new ValidationError();
      validationError.property = "source";
      errors.push(validationError);
    }
    setErrors(errors);
    return errors.length === 0;
  };

  const getError = (name: string): ValidationError | undefined => {
    return errors.find((v: ValidationError) => v.property === name);
  };

  const getHelperText = (name: string, message: string): string => {
    return getError(name) ? message : "";
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.visible }}
    >
      <DialogTitle>{`${t("ButtonEdit")} ${t("ExternalReference")}`}</DialogTitle>

      <DialogContent className={classes.visible}>
        <TextField
          margin="dense"
          variant="outlined"
          name="source"
          label={t("Source")}
          type="text"
          fullWidth
          onChange={(e) =>
            setCreateForm({ ...createForm, source: e.target.value })
          }
          value={createForm.source}
          autoFocus
          required
          helperText={getHelperText(
            "name",
            t("ErrorRequiredField", { field_name: t("Source") })
          )}
          error={!!getError("source")}
        />
        <TextField
          margin="dense"
          variant="outlined"
          name="reference"
          label={t("Reference")}
          type="text"
          fullWidth
          onChange={(e) =>
            setCreateForm({ ...createForm, reference: e.target.value })
          }
          value={createForm.reference}
          required
          helperText={getHelperText(
            "reference",
            t("ErrorRequiredField", { field_name: t("Reference") })
          )}
          error={!!getError("reference")}
        />
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={onClose} size="small" style={{ color: '#a3a3a3' }}>
              {t("ButtonCancel")}
            </Button>
          </Box>
          <Button
            onClick={async () => {
              const dto: UpdateExternalReferenceRequestDto = {
                source: externalReferenceToEdit.source,
                reference: externalReferenceToEdit.reference
              }
              if (createForm.source !== dto.source) dto.source = createForm.source;
              if (createForm.reference !== dto.reference) dto.reference = createForm.reference;
              const valid: boolean = await checkErrors(dto);
              if (valid) onSave(dto);
            }}
            variant="contained"
            size="small"
          >
            {t("ButtonSave")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { EditExternalReferenceModal };
