import React from 'react';
import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { ResponseApiList } from '@doctomatic/sdk/build/global';
import { KeyedMutator } from 'swr';
import { LastAlertsByValuePatient } from '@doctomatic/sdk/build/dto/Alerts/Alerts';
import LocationDataRow from './LocationDataRow';

export interface ILastAlertsByValueGridColumnsProps {
  actorId: number;
  mutate: KeyedMutator<ResponseApiList<LastAlertsByValuePatient>>,
  setLastAlerts: React.Dispatch<React.SetStateAction<LastAlertsByValuePatient[]>>
  showExternalId?: boolean;
}

export const LocationGridColumns = (t: (text: string) => string): GridColumns<LocationDataRow> => {

  let gridColumns: any[] =[{
    field: 'name',
    flex: 1,
    headerName: t('Name'),
    width: 200,
    align: 'left',
  },
  {
    field: 'quantityPatients',
    flex: 1,
    headerName: t('HeaderColumnQuantityPatient'),
    width: 200,
    align: 'left',
  },
  {
    field: 'quantityLocationChildren',
    flex: 1,
    headerName: t('HeaderColumnQuantityLocationsChildren'),
    width: 200,
    align: 'left',
    valueGetter: (params: GridValueGetterParams) => params.row.children.length,
    sortComparator: (v1: any, v2: any) => v1!.toString().localeCompare(v2!.toString()),
    renderCell: (params: { row: LocationDataRow }) => {
      const childrensLength = params.row.children.length;
      return <p>{childrensLength}</p>
    }
  }];
  return gridColumns;
};