import React, { useState } from "react";
import { Page } from "../Page";
import { Box, Button } from "@mui/material";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { useNavigate, useParams } from "react-router-dom";
import { UserLocationsList } from "./UserLocationsList";
import { processError } from "../../../App/errorToast";
import { BreadcrumbNameMap } from "../../../breadcrumbMap";
import { toast } from "react-toastify";
import { AssignLocationModal } from "./AssignLocationModal";
import { IUseActorByRole } from "@doctomatic/sdk/build/modules/ActorByRole";

interface UrlProps {
  role: "doctors" | "nurses" | "patients";
  actorId: string;
}

const UserLocations = (): React.ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { role: urlRole, actorId } = useParams<UrlProps>();
  const { useProfile, useActorByRole, useActorLocations, logout } = useApi();
  const { response: profileResponse } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const profile = profileResponse?.data;
  const {
    response: locationsResponse,
    assign,
    unassign,
  } = useActorLocations(
    +actorId,
    urlRole,
    true,
    processError(logout, navigate, t)
  );
  const { response: actorResponse }: IUseActorByRole = useActorByRole(
    +actorId,
    urlRole,
    true,
    processError(logout, navigate, t)
  );
  const title = t("HeaderPatients");
  const actor = actorResponse?.data;
  const locations = locationsResponse?.data;
  const [openSelectLocationModal, setOpenSelectLocationModal] = useState(false);

  if (!actor) return <></>;

  const getBreadCrumbProps = (
    actorName: string,
    userName: string = ""
  ): BreadcrumbProps => {
    let breadcrumbItem;
    if (urlRole === "nurses") {
      breadcrumbItem = [
        { url: "", name: actorName },
        { url: BreadcrumbNameMap.AdministrationNurses.url, name: t("Nurses") },
        { url: "", name: userName },
        { url: "", name: t("LocationsPageTitle") },
      ];
    }
    if (urlRole === "patients") {
      breadcrumbItem = [
        { url: "", name: actorName },
        {
          url: BreadcrumbNameMap.AdministrationPatients.url,
          name: t("Patients"),
        },
        { url: "", name: userName },
        { url: "", name: t("LocationsPageTitle") },
      ];
    }
    if (urlRole === "doctors") {
      breadcrumbItem = [
        { url: "", name: actorName },
        {
          url: BreadcrumbNameMap.AdministrationDoctors.url,
          name: t("Doctors"),
        },
        { url: "", name: userName },
        { url: "", name: t("LocationsPageTitle") },
      ];
    }
    return {
      breadcrumbItems: breadcrumbItem,
    } as BreadcrumbProps;
  };

  const toggleAssign = async (item: any, assigned: boolean) => {
    if (assigned) {
      try {
        await assign(item.id, { actorId: +actorId, inheritance: false });
      } catch (err: any) {
        toast.error(`${t("ErrorAssignLocation")}: ${err.message}`);
      }
    } else {
      try {
        await unassign(item.id, +item.location_user[0].user.id);
      } catch (err: any) {
        toast.error(`${t("ErrorUnAssignLocation")}: ${err.message}`);
      }
    }
  };

  const handleAssignFromModal = async (locationId: number) => {
    await assign(locationId, { actorId: +actorId, inheritance: false });
  };

  const buttons = (
    <Box display="flex" justifyContent="flex-end">
      <Button
        size="small"
        variant="contained"
        onClick={() => setOpenSelectLocationModal(true)}
      >
        {t("Assign")}
      </Button>
    </Box>
  );

  const actorName = profile?.name ? profile!.name : "";
  return (
    <Page
      buttons={buttons}
      title={title}
      breadCrumbProps={getBreadCrumbProps(actorName, actor.name)}
    >
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <UserLocationsList
          locations={locations}
          patientId={+actorId}
          toggleAssign={toggleAssign}
        />
      </Box>
      {
        <AssignLocationModal
          open={openSelectLocationModal}
          handleClose={() => setOpenSelectLocationModal(false)}
          handleAssign={handleAssignFromModal}
          item={undefined}
          columns={[]}
          rows={[]}
        ></AssignLocationModal>
      }
    </Page>
  );
};

export { UserLocations };
