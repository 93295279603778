// makeStyles has been deprecated because it seems it crashes with modals, removed to fix issue #687
export var styles = {
    maxTimeBetweenInputLeft: {
        width: '50%',
        height: 'fit-content',
        marginRight: 5,
        backgroundColor: 'transparent'
    },
    maxTimeBetweenInputRight: {
        width: '50%',
        height: 'fit-content',
        marginRight: 5,
        backgroundColor: 'transparent'
    },
    formHelperText: {
        // TODO: fix Theme loading in components-react
        // fontFamily: theme.typography.fontFamily,
        fontFamily: 'Montserrat, sans-serif',
        backgroundColor: 'transparent'
    }
};
