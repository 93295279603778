export var TypeFilter;
(function (TypeFilter) {
    TypeFilter["DATE"] = "date";
    TypeFilter["CHECKBOX"] = "checkbox";
})(TypeFilter || (TypeFilter = {}));
// Same as FilterOperator from nestjs-paginate
export var FilterOperator;
(function (FilterOperator) {
    FilterOperator["EQ"] = "$eq";
    FilterOperator["GT"] = "$gt";
    FilterOperator["GTE"] = "$gte";
    FilterOperator["IN"] = "$in";
    FilterOperator["NULL"] = "$null";
    FilterOperator["LT"] = "$lt";
    FilterOperator["LTE"] = "$lte";
    FilterOperator["BTW"] = "$btw";
    FilterOperator["NOT"] = "$not";
    FilterOperator["ILIKE"] = "$ilike";
})(FilterOperator || (FilterOperator = {}));
