export var styles = {
    dialogContainer: { display: 'flex', flexDirection: 'column', marginTop: 2 },
    dateSelectorsContainer: { display: 'flex', justifyContent: 'space-between' },
    dateSelector: { display: 'flex', flexDirection: 'column', width: '40%' },
    templateContainer: { display: 'flex', marginTop: 24 },
    templateText: { fontSize: 16 },
    cancelButton: { color: '#4BAE51' },
    saveButton: { backgroundColor: '#4BAE51' },
    // visible: { overflowY: 'visible' }
};
