import Skeleton from 'react-loading-skeleton';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Card } from "@mui/material";

interface TableSkeletonProps {
  numRows: number;
  widthCard?: string;
}

function TableSkeleton({ numRows, widthCard }: TableSkeletonProps): React.ReactElement {
  return (
    <Card style={ { width: widthCard } }>
      <TableContainer >
        <Table>
          <TableBody>
            {Array.from({ length: numRows }, (_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Skeleton height={24} width={'100%'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default TableSkeleton;