import React, { useState } from 'react';
import { Checkbox } from '@mui/material';
export var CustomCheckbox = function (_a) {
    var _b = _a.defaultValue, defaultValue = _b === void 0 ? false : _b, onChange = _a.onChange;
    var _c = useState(defaultValue), checked = _c[0], setChecked = _c[1];
    return (React.createElement(Checkbox, { checked: checked, disabled: checked, onChange: function (event, checked) {
            setChecked(checked);
            onChange();
        } }));
};
