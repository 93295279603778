import React, { useMemo, useState } from 'react';
import { useApi } from '@doctomatic/sdk/build/Api';
import { useTranslation } from 'react-i18next';
import { AlertGroupListGridColumns } from './AlertGroupListDataGridColumns'
import { FlexLayoutGrid } from '@doctomatic/components-react/build/DataGrid/DataGrid';
import { IProvideApi } from '@doctomatic/sdk/build/Api';
import { IUseAlertGroups } from '@doctomatic/sdk/build/modules/AlertGroup/AlertGroup';
import { GridColumns } from '@mui/x-data-grid';
import { SxProps } from '@mui/material';
import { DoctomaticStylingRowsGrid } from '../../../Theme/ThemeDataGrid';
import { Theme } from '@mui/material/styles';
import { GetAlertGroupResponseDto } from '@doctomatic/sdk/build/dto/Alerts/group/AlertGroup';

interface AlertGroupListProps {
  onEdit: (alertGroup: GetAlertGroupResponseDto) => void
  onDelete: (alertGroup: GetAlertGroupResponseDto) => void
  userId: number
  alertGroups: GetAlertGroupResponseDto[] | undefined
  setPage: React.Dispatch<React.SetStateAction<number>>
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  alertGroupPaginationInfo: any
}

const AlertGroupList = ({ onDelete, onEdit, userId, alertGroups, setPage, setPageSize, alertGroupPaginationInfo}: AlertGroupListProps): React.ReactElement => {
  const { t, i18n } = useTranslation();
  
  if (!alertGroups) return (<></>);
  
  const datagridColumns: GridColumns<GetAlertGroupResponseDto> = AlertGroupListGridColumns(t, onEdit, onDelete, userId);

  return (
    <>
      <FlexLayoutGrid
        language={i18n.language}
        columns={datagridColumns}
        rows={alertGroups}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        pagination={alertGroupPaginationInfo}
        sx={DoctomaticStylingRowsGrid() as SxProps<Theme>}
      ></FlexLayoutGrid>
    </>
  );
};

export { AlertGroupList };
