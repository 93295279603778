import React, { useEffect, useMemo, useState } from "react";
import { Page } from "../../Page";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { GridColumns } from "@mui/x-data-grid";
import CreateTreatmentPatientDataRow from "./CreateTreatmentPatientDataRow";
import {
  CreateTreatmentPatientGridColumns,
  CreateTreatmentPatientGridColumnsProps,
} from "./CreateTreatmentPatientGridColumns";
import {
  GetDiseaseResponseDto,
  IGetDiseaseResponseDto,
} from "@doctomatic/sdk/build/dto/Diseases";
import { useNavigate, useParams } from "react-router-dom";
import { IUsePatient } from "@doctomatic/sdk/build/modules/Patient";
import { GetPatientResponseDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { Typography } from "@mui/material";
import { processError } from "../../../../App/errorToast";
import { IUseDiseasesByCompany } from "@doctomatic/sdk/build/modules/DiseasesByCompany";
import { IUseTreatments } from "@doctomatic/sdk/build/modules/Treatments/Treatments";
import { AddTreatmentRequestDto } from "@doctomatic/sdk/build/dto/Treatment/Treatments";
import { toast } from "react-toastify";
import { AssignTrackingPatientModal } from "@doctomatic/components-react/build/Trackings/AssignTrackingPatientModal";

interface CreateTreatmentPatientParams {
  patientId: string;
}

export const CreateTreatmentPatient = (): React.ReactElement => {
  const navigate = useNavigate();
  const { patientId }: CreateTreatmentPatientParams =
    useParams<CreateTreatmentPatientParams>();
  const { t, i18n } = useTranslation();
  const {
    usePatient,
    useDiseasesByCompany,
    useTreatments,
    logout,
  }: IProvideApi = useApi();
  const { response: patient }: IUsePatient = usePatient(
    +patientId,
    true,
    processError(logout, navigate, t)
  );

  // declaration of hooks to handle pagination
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  // Store the pagination
  // Update pagination when page or pageSize changes
  const pagination = useMemo(() => {
    return {
      page: page,
      limit: pageSize,
    };
  }, [page, pageSize]);

  const { response: diseasesResponse }: IUseDiseasesByCompany =
    useDiseasesByCompany(
      patient?.data?.companyId ?? 0,
      pagination,
      true,
      processError(logout, navigate, t)
    );
  const diseases = diseasesResponse?.data;
  const diseasesPaginationInfo = diseasesResponse?.meta;

  const [diseaseToCreate, setDiseaseToCreate] =
    useState<GetDiseaseResponseDto | null>(null);
  const { add }: IUseTreatments = useTreatments(
    true,
    processError(logout, navigate, t)
  );
  const [open, setOpen] = useState(false);
  const [diseaseId, setDiseaseId] = useState<number>(0);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const handleAssign = async (
    diseaseId: number,
    applyFrequenciesTemplates: boolean = false,
    startDate?: Date,
    endDate?: Date
  ) => {
    try {
      const dto: AddTreatmentRequestDto = {
        diseaseId,
        patientId: +patientId,
        applyFrequenciesTemplates,
        startDate,
        endDate,
      };
      await add(dto);
      navigate(-1);
    } catch (err: any) {
      toast.error(
        `${t("CreateTreatmentPatientError")}: ${
          err.response?.data?.message || err.message
        }`
      );
    }
  };

  const onAssign = async (diseaseId: number) => {
    const disease: GetDiseaseResponseDto | undefined = diseases?.find(
      (d: GetDiseaseResponseDto) => d.id === diseaseId
    );
    if (disease) {
      if (
        disease?.frequenciesTemplates &&
        disease.frequenciesTemplates.length > 0 &&
        !disease?.forceFrequenciesCreation
      ) {
        setDiseaseToCreate(disease);
      } else {
        setDiseaseToCreate(null);
      }
    }
    setDiseaseId(diseaseId);
    setOpen(true);
  };

  const gridColumns: GridColumns<CreateTreatmentPatientDataRow> =
    CreateTreatmentPatientGridColumns({
      t,
      patientId: +patientId,
      onAssign,
      currentLanguage,
    } as CreateTreatmentPatientGridColumnsProps);

  const dataRows: CreateTreatmentPatientDataRow[] = diseases
    ? diseases.map(
        (disease: IGetDiseaseResponseDto) =>
          ({
            id: disease.id,
            name: disease.name,
            createdBy: disease.doctor_owner?.name,
            devices: disease.devices,
          } as CreateTreatmentPatientDataRow)
      )
    : [];

  const getBreadCrumbProps = (
    patient: ResponseApi<GetPatientResponseDto> | undefined
  ): BreadcrumbProps | undefined =>
    patient?.data?.name
      ? ({
          breadcrumbItems: [
            { url: `/patients/${patientId}`, name: patient.data.name },
            { url: "", name: t("AssignTreatmentTitle") },
          ],
        } as BreadcrumbProps)
      : undefined;

  return (
    <Page title="" breadCrumbProps={getBreadCrumbProps(patient)}>
      <Typography style={{ paddingBottom: "2rem" }}>
        {t("TreatmentProfileTitle")}
      </Typography>
      <FlexLayoutGrid
        language={i18n.language}
        columns={gridColumns as GridColumns}
        rows={dataRows}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        pagination={diseasesPaginationInfo}
      ></FlexLayoutGrid>
      <AssignTrackingPatientModal
        open={open}
        diseaseId={diseaseId}
        disease={diseaseToCreate}
        onClose={() => setOpen(false)}
        onSave={(diseaseId, startDate, endDate, applyFrequenciesTemplates) => {
          handleAssign(
            diseaseId,
            applyFrequenciesTemplates,
            startDate,
            endDate
          );
        }}
        t={t}
      />
    </Page>
  );
};
