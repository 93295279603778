import { Switch } from "@mui/material";
import React, { useState } from "react";
export var SwitchSave = function (props) {
    var _a = useState(props.initialValue), checked = _a[0], setChecked = _a[1];
    var updateDb = function (event) {
        props.updateDb && props.updateDb(props.item, event.target.checked);
        setChecked(event.target.checked);
    };
    return React.createElement(Switch, { checked: checked, onChange: updateDb });
};
