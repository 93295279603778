import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Button,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { UpdateProfileRequestDto } from "@doctomatic/sdk/build/dto/User";
import { toast } from "react-toastify";

import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { processError } from "../../../../App/errorToast";
import { useNavigate } from "react-router-dom";

const defaultErrorsState: any = {
  password: false,
  repeatPassword: false,
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const UserFormOnlyPassword = (): React.ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { useProfile, logout } = useApi();
  const { update, mutate } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const theme = useTheme();

  const defaultUserDto: any = {
    password: "",
  };

  const [loading, setLoading] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [userDto, setUserDto] =
    useState<UpdateProfileRequestDto>(defaultUserDto);
  const [errors, setErrors] = useState<any>(defaultErrorsState);
  let { password } = userDto;
  let validations: any = {
    password: !!password && password.length >= 6,
    repeatPassword: !!repeatPassword && repeatPassword === userDto.password,
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      await update(userDto);
      await mutate();
    } catch (err: any) {
      toast.error(`${err.response?.data?.message || err.message}`);
    }
    setLoading(false);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setUserDto({ ...userDto, [event.target.id]: event.target.value });
  };

  const validate = (): boolean => {
    return Object.values(validations).every((value) => value === true);
  };

  const getHelperText = (id: string, message: string): string => {
    return errors[id] && !validations[id] ? message : "";
  };

  const handleErrors = (id: string): any => {
    setErrors({ ...errors, [id]: !validations[id] });
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <TextField
        id="password"
        type="password"
        label={t("FieldPassword")}
        error={errors.password}
        helperText={getHelperText("password", t("ChangePasswordInvalid"))}
        onChange={handleInputChange}
        onBlur={() => handleErrors("password")}
        margin="normal"
        variant="outlined"
        fullWidth
        required
      />
      <TextField
        id="repeatPassword"
        type="password"
        label={t("FieldRepeatPassword")}
        error={errors.repeatPassword}
        helperText={getHelperText("repeatPassword", t("ErrorPasswordMatch"))}
        onChange={(e) => setRepeatPassword(e.target.value)}
        onBlur={() => handleErrors("repeatPassword")}
        margin="normal"
        variant="outlined"
        fullWidth
        required
      />
      <Box display="flex" justifyContent="center" my={3}>
        <Button
          type="submit"
          variant="contained"
          disabled={!validate()}
          style={{ color: "white", textTransform: "none", fontSize: "20px" }}
        >
          {loading ? <CircularProgress size={20} /> : t("ButtonSave")}
        </Button>
      </Box>
    </form>
  );
};

export { UserFormOnlyPassword };
