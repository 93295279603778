import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    '& .react-tel-input': {
      marginTop: 8,
      marginBottom: 6,
      '& .flag-dropdown': {
        backgroundColor: 'white',
        borderRight: 'none'
      },
      '& input': {
        height: 56,
        width: '100%'
      },
    },
  },
  label: {
    left: '10px',
    zIndex: 99,
    backgroundColor: 'white',
    paddingLeft: 8,
    paddingRight: 8
  },
  error: {
    borderColor: `${theme.palette.error.main}!important`
  },
  errorText: {
    color: `${theme.palette.error.main}!important`
  },
  borderClass: {
    "&.react-tel-input .form-control:focus": {
      border: '1px solid black',
      boxShadow: 'none',
      outline: 'none',
    }
}  
}))
